<header class="mainHeader {{type}} {{mobileBg}}">

    <div class="headerWrapper">

        <!-- Site Logo   -->
        <a routerLink="/" class="headerLink" *ngIf="hidelogo != 'true' " >
            <img src="./assets/_img/header/insure_me_logo.png" alt="">
        </a>
        <!--END Site Logo   -->

        <!--  Main Navigation  -->
        <nav class="{{color}}">
            <ul>
                <li>
                    <a
                        routerLink="/"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact:true}">Home
                    </a>
                </li>

                <li>
                    <a routerLink="/carinsurance"
                    routerLinkActive="active">Private Car Insurance
                    </a>
                </li>

                <li>
                    <a routerLink="/lipapolepoleplan"
                    routerLinkActive="active">Lipa Pole Pole Payment Plan
                    </a>
                </li>

                <li>
                    <a routerLink="/faqs"
                    routerLinkActive="active">FAQs
                    </a>
                </li>
                <li>
                    <a routerLink="/contactus"
                    routerLinkActive="active">Contact Us
                    </a>
                </li>

                <!-- <li *ngIf="isLogin" >
                    <a routerLink="/userprofile"
                        class="highlight"
                        routerLinkActive="active">My Account
                    </a>
                </li> -->
            </ul>

            <a
                href="tel:0709184444"
                class="phoneNumber"
                id="HomePageContactPhoneNumberClick">

                <fa-icon
                    icon="phone-volume"
                ></fa-icon>

                0709 184 444

            </a>


            <!-- User Details   -->
            <button
                class="readMoreYellow userStatus mobileHide"
                id="HomePageMyPoliciesClick"
                (click)="toggleAuthenticationView('login')"
                *ngIf="!isLogin">


                <span >My Policies</span>


                <fa-icon
                    icon="user-circle"
                    class="userIcon"></fa-icon>

            </button>
            <!--END User Details   -->

            <!-- User Details   -->
            <a
                class="readMoreYellow userStatus mobileHide"
                 *ngIf="isLogin"
                 id="HomePageMyPoliciesClick"
                 routerLink="/userprofile">

                <span >My Policies</span>
                <fa-icon

                    icon="user-circle"
                    class="userIcon"></fa-icon>

                <div class="dropdown rounded shadow">

                    <nav>
                        <a routerLink="/userprofile">

                            My Profile

                            <fa-icon

                                icon="user-circle"
                                class="userIcon"></fa-icon>

                        </a>
                        <a routerLink="/userprofile">

                            My Policies

                            <fa-icon

                                icon="shield-alt"
                                class="userIcon"></fa-icon>

                        </a>
                        <a routerLink="/userprofile">

                            My Claims

                            <fa-icon

                                icon="envelope"
                                class="userIcon"></fa-icon>

                        </a>
                    </nav>

                    <button
                        type="button"
                        (click)="logout();">

                        Logout

                        <fa-icon

                            icon="exclamation-circle"
                            class="userIcon"></fa-icon>

                    </button>

                </div>
            </a>
            <!--END User Details   -->

            <button
                class="menuToggle"
                type="button"
                (click)="openMobileMenu()">
                <!-- <fa-icon
                    icon="bars"
                    class="opener icons"
                ></fa-icon>
                <fa-icon
                    icon="times"
                    class="closer icons"
                ></fa-icon> -->
            </button>

        </nav>
        <!--END Main Navigation   -->


    </div>

</header>
<!--    -->
<!--END    -->
<!--  Mobile Navigation  -->
<div class="mobileNav">
    <!--  Main Navigation  -->
    <nav class="{{color}}">
        <ul>
            <li>
                <fa-icon
                    icon="home"
                    class="arrow"></fa-icon>
                <a
                    routerLink="/"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact:true}">Home
                </a>
            </li>
            <!-- <li>
                <a routerLink="/aboutproduct" routerLinkActive="active">About Product
                </a>
            </li> -->

            <li>
                <fa-icon
                    icon="car"
                    class="arrow"></fa-icon>
                <a routerLink="/carinsurance"
                routerLinkActive="active">Private Car Insurance
                </a>
            </li>

            <li>
                <fa-icon
                    icon="wallet"
                    class="arrow"></fa-icon>
                <a routerLink="/lipapolepoleplan"
                routerLinkActive="active">Lipa Pole Pole Plan
                </a>
            </li>

            <li>
                <fa-icon
                    icon="question"
                    class="arrow"></fa-icon>
                <a routerLink="/faqs" routerLinkActive="active">FAQs
                </a>
            </li>
            <li>
                <fa-icon
                    icon="phone"
                    class="arrow"></fa-icon>
                <a routerLink="/contactus" routerLinkActive="active">Contact Us
                </a>
            </li>

            <li *ngIf="!isLogin">
                <!-- User Details   -->
                <fa-icon
                    icon="user-circle"
                    class="arrow"></fa-icon>

                <button
                    class=""
                    (click)="toggleAuthenticationView('login');openMobileMenu();"
                    *ngIf="!isLogin">

                    <span >My Policies</span>

                </button>
                <!--END User Details   -->
            </li>


            <li *ngIf="isLogin" >
                <fa-icon
                    icon="user-circle"
                    class="arrow"></fa-icon>

                <a routerLink="/userprofile"
                    class="highlight"
                    routerLinkActive="active">My Account
                </a>

            </li>
            <li *ngIf="isLogin" >
                <fa-icon

                    icon="exclamation-circle"
                    class="arrow"></fa-icon>

                <button
                    type="button"
                    (click)="logout();">

                    Logout

                </button>
            </li>

        </ul>
    </nav>
</div>
<!--END  Mobile Navigation  -->

<app-authoverlay></app-authoverlay>
