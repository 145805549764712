<section class="centerAligner shadow singlePolicy rounded padding">

    <!--  Policy Summary  -->
    <div class="policySummary singleClaim">

        <a routerLink="/userprofile"
            class="readMoreYellow back">
                View Profile
                <fa-icon
                    icon="arrow-circle-left"
                    class="arrow"></fa-icon>
        </a>

        <div class="row">
          <h4 class="left">Type of claim</h4>
          <h4 class="right">{{claimType}}</h4>
        </div>

        <div class="row">
          <p class="left">Name</p>
          <p class="right">{{claimDetails?.customerName | titlecase}}</p>
        </div>

        <div class="row">
          <p class="left">Policy Number</p>
          <p class="right">{{claimDetails?.claimsData?.policyNumber | titlecase}}</p>
        </div>

        <div class="row" *ngIf="claimType === 'Window Damage' ||  claimType === 'Theft of Parts'">
          <p class="left">Incident Latitude</p>
          <p class="right">{{claimDetails?.claimsData?.placeLatitude | titlecase}}</p>
        </div>

        <div class="row" *ngIf="claimType === 'Window Damage' ||  claimType === 'Theft of Parts'">
          <p class="left">Incident Longitude</p>
          <p class="right">{{claimDetails?.claimsData?.placeLongitude | titlecase}}</p>
        </div>

        <div class="row" *ngIf="claimType === 'Damage of vehicle' " >
          <p class="left">Mobile Number</p>
          <p class="right">{{claimDetails?.claimsData?.driverDetails?.phoneNumber }}</p>
        </div>

        <div class="row" *ngIf="claimType === 'Damage of vehicle'">
          <p class="left">PIN Number</p>
          <p class="right">{{claimDetails?.claimsData?.driverDetails?.kraPin }} </p>
        </div>


        <div class="row" *ngIf="claimType === 'Damage of vehicle'">
          <p class="left">Business/Profession</p>
          <p class="right">{{claimDetails?.claimsData?.driverDetails?.occupation }} </p>
        </div>

        <div class="row">
          <p class="left">Registration No.</p>
          <p class="right">{{claimDetails?.carRegNo}}</p>
        </div>

        <div class="row" *ngIf="claimType === 'Damage of vehicle'">
          <p class="left">Injured passengers</p>
          <p class="right">{{claimDetails?.claimsData?.injuredPassangers.length}}</p>
        </div>


        <div class="row" *ngIf="claimType === 'Damage of vehicle'">
          <p class="left">Properties Damaged</p>
          <p class="right">{{claimDetails?.claimsData?.propertiesDamaged.length}}</p>
        </div>

      </div>

    <!--END  Policy Summary  -->

    <!--  Download Policy Button  -->
    <!-- <button class="readMoreGrey">
        Download Your Claim Document Here

        <fa-icon
            icon="file-pdf"
            class="arrow"
            ></fa-icon>
    </button> -->
    <!--END  Download Policy Button  -->


</section>
