<form [formGroup]="nameForm" class="centerAligner">

    <h1 >My Luo name generator</h1>

    <fieldset>
        <div class="one_fifth">

            <label for="" >Please select your gender?</label>

            <select 
                name="gender" 
                id="gender"
                placeholder="Gender"
                formControlName="gender">
                <option value=""></option>
                <option value="Male" >Male</option>
                <option value="Female" >Female</option>
            </select>

        </div>

        <div class="one_fifth">
            <label for="" >What was your time of birth?</label>
            <select 
                name="timeofbirth" 
                id="timeofbirth"
                formControlName="timeofDay" >
                <option value=""></option>
                <option  value="diuor" >Midnight to 3 am</option>
                <option value="mondi" >Early Morning 3am - 5am</option>
                <option value="kinyi" >Morning 5am to 7am</option>
                <option value="chieng">Mid Afternoon 12pm to 4pm</option>
                <option value="dhiambo">Evening 5pm to 7pm</option>
                <option value="tieno">At Night 7pm to 1am</option>

            </select>
        </div>

        <div class="one_fifth">

            <label for="">Attributes of your birth?</label>
            <select 
                name="attributalName" 
                id="attributalName"
                formControlName="attributalName">
                <option value=""></option>
                <option value="piyo">Quick Birth</option>
                <option value="koko">Very Loud</option>
                <option value="yoo">Born by the roadside</option>
                <option value="ling">Silent at birth</option>
                
            </select>

        </div>

        <div class="one_fifth">

            <label for="">Season of your birth?</label>
            <select 
                name="seasonofbirth" 
                id="seasonofbirth"
                formControlName="seasonofbirth">
                <option value=""></option>
                <option value="boch">Cloudy</option>
                <option value="koth">Rainy</option>
                <option value="chuodho">Muddy</option>
                <option value="loo">Sandy</option>
                <option value="buru">Dusty</option>
                <option value="tuo">Dry</option>
                <option value="yamo">Windy</option>
            </select>

        </div>

        <div class="one_fifth">

            <label for="">Your spirit Animal</label>
            <select 
                name="spritAnimal" 
                id="spritAnimal"
                formControlName="spritAnimal">
                <option value=""></option>
                <option value="Guok">Dog</option>
                <option value="Paka">Cat</option>
                <option value="Liech">Elephant</option>
                <option value="Nyang">Crocodile</option>
                <option value="Sibuor">Simba</option>
                <option value="Kwach">Leopard</option>
                <option value="Ochunglo">Ant</option>
                <option value="Winyo">Bird</option>
                <option value="Ogwal">Frog</option>
            </select>

        </div>

        <div class="one_fifth">

            <label for="">Name of Self Praise / Pakruok</label>
            <input 
                type="text" 
                placeholder="Name of Self Praise / Pakruok"
                formControlName="selfPraiseName">
        </div>
        

        <div class="one_fifth">

            <label for="">Ancestral / Other Names?</label>
            <input 
                type="text" 
                placeholder="Which ancestor are your named after"
                formControlName="favoriteAncestor">
        </div>

        <div class="one_fifth">

            <label for="">What is your family name?</label>

            <input 
                type="text" 
                placeholder="Your Family name"
                formControlName="familyName">

        </div>

        <div class="one_fifth">

            <label for="">What is your father's name?</label>
            <input 
                type="text" 
                placeholder="Your fathers name"
                formControlName="fathersName">
                
        </div>

        <div class="one_fifth">

            <label for="">Where is your clan natively from?</label>

            <input 
                type="text" 
                placeholder="Where is your clan natively from"
                formControlName="clansLocation">
        </div>

        <div class="one_fifth">

            <label for="">What is your clan's name?</label>
            <input 
                type="text" 
                placeholder="What is your clan name"
                formControlName="clansName">
        </div>
        
        
    </fieldset>
</form>

<div class="centerAligner namerenderer">

    <strong>Nyinga </strong> 

    <span *ngIf="nameForm.controls.gender.value == 'Male' " > O</span>
    <span *ngIf="nameForm.controls.gender.value == 'Female' " >A</span>

    <span>{{nameForm.controls.timeofDay.value}} </span>
    
    <span *ngIf="nameForm.controls.gender.value == 'Male' " > O</span>
    <span *ngIf="nameForm.controls.gender.value == 'Female' " >A</span>
    <span>{{nameForm.controls.attributalName.value}} </span>
    
    <span *ngIf="nameForm.controls.gender.value == 'Male' " > O</span>
    <span *ngIf="nameForm.controls.gender.value == 'Female' " >A</span>
    
    <span>{{nameForm.controls.seasonofbirth.value}} </span>

    {{nameForm.controls.favoriteAncestor.value}}
    
    {{ nameForm.controls.spritAnimal.value }}

    {{nameForm.controls.familyName.value }} 

    {{nameForm.controls.selfPraiseName.value}} 
        
         

        <span *ngIf="nameForm.controls.gender.value == 'Male'   && nameForm.controls.fathersName.value !== ''" > Woud </span>

        <span *ngIf="nameForm.controls.gender.value == 'Female' && nameForm.controls.fathersName.value !== '' " > Nyar </span> 
        
        {{nameForm.controls.fathersName.value}} 
        
        <span *ngIf="nameForm.controls.gender.value == 'Male'   && nameForm.controls.clansLocation.value !== ''" > Ja </span>

        <span *ngIf="nameForm.controls.gender.value == 'Female' && nameForm.controls.clansLocation.value !== '' " > Nya </span> 
        
        <span>{{nameForm.controls.clansLocation.value}} </span>

        <span>Jok-{{nameForm.controls.clansName.value}} </span>

        <em>

            <span *ngIf="nameForm.controls.gender.value == 'Male' " >to an Dichuo(Male)</span>
            <span *ngIf="nameForm.controls.gender.value == 'Female' " >to an Dhako(Female)</span>

        </em>
        

    
</div>

<div class="upsell cl centerAligner">

    <article>
        <h1>Books related to the above name generator</h1>
    </article>
    
    <div class="one_third fl">
        <img src="./assets/_img/books/creative.jpg" alt="" class="full_width">
        <h3>Creative Thinking by John D. Akello</h3>
        <p>Learn the how the imaginative process that led to the production of this cultural piece of digital luo history came to be and boost your overall creativity in the process. </p>
        <a href="https://nuriakenya.com/product/creative-thinking-a-journey-through-the-8-facets-of-intelligence-by-john-akello/" target="_blank" class="readMoreBlue">Buy A Copy</a>
    </div>
    <div class="one_third fl">
        <img src="./assets/_img/books/cradletocradle.jpg" alt="" class="full_width">
        <h3>Cradle to Cradle by Tony Olang</h3>
        <p>Learn more about the Luo culture and traditions </p>
        <a href="https://nuriakenya.com/product/from-the-cradle-to-the-cradle-by-tony-olang/" target="_blank" class="readMoreRed">Buy A Copy</a>
    </div>
    <div class="one_third fl">
        <img src="./assets/_img/books/water.jpg" alt="" class="full_width">
        <h3>Not without my water pot by Tony Olang</h3>
        <p>Learn more about the Luo culture,  traditions & marriage customs </p>
        <a href="https://nuriakenya.com/product/not-without-my-water-pot-by-tony-olang/" target="_blank" class="readMoreBlue">Buy A Copy</a>
    </div>
</div>