import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import $ from 'jquery';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css']
})


export class ModalComponent implements OnInit {

    @Input() title: string;
    @Input() description: string;
    @Input() link: string;
    @Input() linkText: string = "Proceed";
    @Input() show: boolean = false;
    @Input() type: string="info";
    @Input() processorText: string = "Processing";
    @Input() modalData: any = {};



    @Output() confirmPaymentEvent = new EventEmitter<string>();

	@Output() okButtonClick = new EventEmitter<string>();
	@Output() yesButtonClick = new EventEmitter<string>();
	@Output() noButtonClick = new EventEmitter<string>();

    constructor() {}

    ngOnInit(): void{

    }

    onConfirmationClick(paymentRef: string) {

        this.confirmPaymentEvent.emit(paymentRef);

    }



    ngOnChanges(){

        if(this.show == true){

            $(".modal").fadeIn();

        }

    }

	onOkClick(){

		this.okButtonClick.emit("true");

	}

	onYesClick(){

		this.yesButtonClick.emit("true");

	}

	onNoClick(){

		this.noButtonClick.emit("true");

	}

    closeModal(){

        $(".modal").fadeOut();

    }


}
