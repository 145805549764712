// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

    production: false,
    apiUrl: 'http://dev-insureme.mfs.co.ke:31248/api/v1/',
    projectConfigApi:'http://dev-insureme.mfs.co.ke:31980/api/v1/',
	dynamicFormApi:"http://dev-mymobiretail.mfs.co.ke:30543/surveyservice/",
    quoteApi:'http://dev-insureme.mfs.co.ke:30353/api/v1/',
    customerFacingApi:'http://dev-insureme.mfs.co.ke:32575/api/v1/',
    payment:'http://dev-insureme.mfs.co.ke:31227/api/v1/',
    googlePlacesApi:'https://maps.googleapis.com/maps/api/place/findplacefromtext/json',
    googleAutoCompleteApi:'https://maps.googleapis.com/maps/api/place/autocomplete/json',
    mapAPIKey:'AIzaSyAJA-Ku2beBvsyGisvl1Au1gkbWm8iyd00',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
