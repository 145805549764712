import { Component, Input, OnInit, Type } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { debounce } from 'lodash';

import { QuestionBase } from '../../_models/question/question-base';
import { Appearance } from '@angular-material-extensions/google-maps-autocomplete';

//services
import { QuestionControlService } from '../../_services/question/question-control.service';
import { AutocompletedataService } from '../../_services/autocompletedata.service';
import { FileUploadsService } from '../../_services/question/file-uploads.service';

import PlaceResult = google.maps.places.PlaceResult;

import $ from 'jquery';

@Component({
	selector: 'app-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	providers: [
		QuestionControlService,
		AutocompletedataService,
		FileUploadsService
	]
})
export class DynamicFormComponent implements OnInit {

	@Input() questions: QuestionBase<string>[] | null = [];
	@Input() dynamicQuestions?:any = [];
	@Input() pages?:any = [];
	@Input() layoutType:string = "tabs";
	@Input() submissionURL:string = "";

	loading: boolean;
	form ! : FormGroup;
	dynamicForm !: FormGroup;
	payLoad = '';
	options:any = [];
	formDetailsVerified: any = {};

	keysToDeleteArray: any = [];
	keysToAddArray: any = [];

	//modal definitions
	modalTitle: string = "";
	modalDescription: string = "";
	showModal: boolean = false;

	//File upload definitions
	uploadDocuments: any = [];
	documentUploadForm = new FormControl('');
	uploadDocRef: string;

	// Google Maps definitions
	appearance = Appearance;
	zoom: number;
	latitude: number;
	longitude: number;
	selectedAddress: PlaceResult;
	filteredOptions: Observable<any[]>;

	//Date definitions
	minDate: Date;
	maxDate: Date;
	adultLimitDate:Date;

	//Time definitions
	showSpinners = false;
	enableMeridian = true;
	showSeconds = false;
	stepHour = 1;
  	stepMinute = 1;
	touchUi = true;
	dateFilter = (date: Date | null): boolean => {
		const day = (date || new Date()).getDay();
		return day !== 0 && day !== 6;
	}

	//range
	formatLabel(value: number) {
		if (value >= 1000) {
		  return Math.round(value / 1000) + 'k';
		}

		return value;

	  }

	//object references
	autoCompleteReference:any = {};
	dynamicSelectReference: any = {};
	dynamicSelectQueue: any = [];
	queueItem: any = {};
	selectQueue: any = [];

	constructor(
		private qcs: QuestionControlService,
		private autoCompleteData: AutocompletedataService,
		private fileUploads: FileUploadsService
	) {

		this.onInputChange = debounce(this.onInputChange,1000);

		this.handleRepeaterInputChange = debounce(this.handleRepeaterInputChange,1000);

		this.handleAccordionInputChange = debounce(this.handleAccordionInputChange,1000)


	}

	ngOnInit() {

		// Assign form value if this.pages.length > 0

		if(this.pages.length > 0){

			for(let p=0; p < this.pages.length; p++){

				let pageName = this.pages[p].pageName;

				if(this.pages[p].validationUrl == "" || this.pages[p].validationUrl == null){

					this.formDetailsVerified [this.pages[p]?.pageName] = true;

				} else {

					this.formDetailsVerified [this.pages[p]?.pageName] = false;
				}

				this.autoCompleteReference[pageName] = {};
				this.dynamicSelectReference[pageName ] = {};

				for( let q = 0; q < this.pages[p].questions.length; q++){

					let questionName = this.pages[p].questions[q]["fieldName"];

					if(this.pages[p].questions[q].fieldType == "autocomplete"){

						this.autoCompleteReference[this.pages[p].pageName][this.pages[p].questions[q]["fieldName"]] = [];

					}

					else if (this.pages[p].questions[q].fieldType == "dynamicselect"){

						this.dynamicSelectReference[ this.pages[p].questions[q]["fieldName"]] = [];

						let queueItem = {
							"url": this.pages[p].questions[q]["autoCompleteUrl"],
							"pageName": this.pages[p]["pageName"],
							"parentQuestion":"",
							"childQuestion":"",
							"childQuestionType":"",
							"questionName": this.pages[p].questions[q]["fieldName"],
						};

						this.selectQueue.push(queueItem);

						console.log("Queue item: ",queueItem);

					}

					else if (this.pages[p].questions[q].fieldType == "accordion" ){

						//looping through the options

						this.dynamicSelectReference[ pageName ][ questionName ] = {};

						this.autoCompleteReference[ pageName ][ questionName ] = {};

						for( let r = 0; r < this.pages[p].questions[q].options.length; r++){

							let optionsWithQuestionsToFollow = this.pages[p].questions[q].options[r];

							let accordionFieldName = this.pages[p].questions[q].options[r]["value"];

							if(optionsWithQuestionsToFollow.hasconditionalquestion == "yes"){

								//looping through the questions to follow

								for( let s = 0; s < optionsWithQuestionsToFollow.questionToFollow.length; s++){

									let questionsToFollow = optionsWithQuestionsToFollow.questionToFollow[s];

									let accordionQuestion = optionsWithQuestionsToFollow.questionToFollow[s]["fieldName"];

									if(questionsToFollow.fieldType == "dynamicselect"){

										let questionsWithDynamicSelect = optionsWithQuestionsToFollow.questionToFollow[s];

										this.dynamicSelectReference[ pageName ][ questionName][accordionFieldName] = {};

										this.dynamicSelectReference[ pageName][ questionName ][ accordionFieldName ][accordionQuestion] = [];

										let queueItem = {
											"url": questionsWithDynamicSelect["autoCompleteUrl"],
											"pageName": this.pages[p]["pageName"],
											"parentQuestion":accordionFieldName,
											"childQuestion":accordionQuestion,
											"childQuestionType":"accordion",
											"questionName": this.pages[p].questions[q]["fieldName"],
										};

										this.selectQueue.push(queueItem);

									} else if ( questionsToFollow.fieldType == "autocomplete"){

										this.autoCompleteReference[ pageName ][ questionName ][accordionFieldName] = {};
										this.autoCompleteReference[ pageName ][ questionName ][accordionFieldName][accordionQuestion] = [];

									}

								}

							}

						}

					}

					else if(this.pages[p].questions[q].fieldType =="repeater"){

						//looping through the options

						for( let r = 0; r < this.pages[p].questions[q].options.length; r++){

							this.autoCompleteReference[ pageName ][ questionName ] = [];

							this.dynamicSelectReference[ pageName ][ questionName ] = [];

							let optionsWithQuestionsToFollow = this.pages[p].questions[q].options[r];

							if(optionsWithQuestionsToFollow.hasconditionalquestion == "yes"){

								//looping through the questions to follow

								for( let s = 0; s < optionsWithQuestionsToFollow.questionToFollow.length; s++){

									let questionsToFollow = optionsWithQuestionsToFollow.questionToFollow[s];

									let repeaterFieldName = optionsWithQuestionsToFollow.questionToFollow[s]["fieldName"];

									if(questionsToFollow.fieldType == "dynamicselect"){

										let repeaterWithDynamicSelect = optionsWithQuestionsToFollow.questionToFollow[s];

										this.dynamicSelectReference[ pageName ][ questionName ].push({[repeaterFieldName] : []});

										let queueItem = {
											"url": repeaterWithDynamicSelect["autoCompleteUrl"],
											"pageName": pageName,
											"parentQuestion":0,
											"childQuestion":repeaterFieldName,
											"childQuestionType":"repeater",
											"questionName": questionName,
										};

										this.selectQueue.push(queueItem);

									} else if (questionsToFollow.fieldType == "autocomplete") {

										this.autoCompleteReference[ pageName ][ questionName ].push({[repeaterFieldName]:[]}) ;

									}

								}

							}

						}
					}

				}

			}

			this.form = this.qcs.toFormGroupDynamic2(this.pages);

			this.loadDynamicSelectValues();

		}

		if(this.layoutType == ""){

			this.layoutType = "tabs";

		}

		setTimeout(() => {

			if(this.layoutType == "accordion"){
				this.inititateAccordion();
			}else {
				this.inititateTabs();
			}

			//$(".autocompleteContainer").hide();

		}, 300);

		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth();
		const currentDate = new Date().getDate();

		this.minDate = new Date();
		this.maxDate = new Date(currentYear, currentMonth, currentDate + 30);
		this.adultLimitDate = new Date(currentYear - 18,currentMonth, currentDate);

	}

	toggleAccordion(event){

        $(".accordionContainer .accordionTitle").removeClass("open");
        $(event.target).addClass("open");
        $(".accordion").slideUp();
        $(event.target).next(".accordion").slideDown();

		this.initiateQuestionsAccordion();

    }

	toggleQuestionAccordion(event){

		$(".questionAccordion .accordionTitle").removeClass("open");
        $(event.target).addClass("open");
        $(".nestedAccordion").slideUp();
        $(event.target).next(".nestedAccordion").slideDown();

	}

	toggleTab(event,tabname){

        $(".tabNavigation li").removeClass("selected");
        $(event.target).addClass("selected");
        $(".tabContent").hide();
        $(".tabContent."+tabname).show();

    }

    // Initiate summary tabs after response from endpoint
    inititateAccordion(){

		$(".accordionContainer .accordionTitle:first").addClass("open");
        $(".accordion").slideUp();
		$(".accordion:first").slideDown();

		this.initiateQuestionsAccordion();

    }

	initiateQuestionsAccordion(){

		$(".questionAccordion .accordionTitle:first").addClass("open");
        $(".nestedAccordion").slideUp();
		$(".nestedAccordion:first").slideDown();

	}

	inititateTabs(){

		$(".tabNavigation li:first").addClass("selected");
        $(".tabContent").hide();
		$(".tabContent:first").show();

    }

    proceedToNextSection(event:any,currentSection:string,nextSection:string,viewType:string){

    	if(viewType == "accordion"){

    		$(".accordion").slideUp();

    		$(".accordionTitle").removeClass("open");

    		$(event.target).parents("."+currentSection).next("."+nextSection).find(".accordionTitle").addClass("open");

			$(event.target).parents("."+currentSection).next("."+nextSection).find(".accordion").slideDown();


    	}else if(viewType == "tabs"){

    		$(".tabNavigation li").removeClass("selected");
	        $(".tabNavigation li."+nextSection).addClass("selected");
	        $(".tabContent").hide();
	        $(".tabContent."+nextSection).show();

    	}


    }

	filter(value: string): any[] {

		const filterValue = value.toLowerCase();

		return this.options.filter(option =>
			option.toString().toLowerCase().includes(filterValue));

	}

	removeRepeaterQuestion(pageIndex:number,questionIndex:number,repeaterIndex:number){

		this.form["controls"][ this.pages[pageIndex]["pageName"]  ]["controls"][ this.pages[pageIndex].questions[questionIndex].fieldName ].controls.splice(repeaterIndex,1);

	}

	addRepeaterQuestion(pageIndex:number,questionIndex:number,repeaterName:string){

		let repeaterFieldReference:any = {};

		this.pages[pageIndex]["questions"][questionIndex]["options"][0]["questionToFollow"];

		repeaterFieldReference = this.pages[pageIndex]["questions"][questionIndex]["options"][0]["questionToFollow"];

		let optionResponse = this.qcs.createDynamicRepeaterFields(repeaterFieldReference);

		this.form["controls"][ this.pages[pageIndex]["pageName"]  ]["controls"][ this.pages[pageIndex].questions[questionIndex].fieldName ].controls.push(new FormGroup(optionResponse) );

		setTimeout(() => {

			$(".autocompleteContainer").hide();

		}, 500);


	}

	/**
	 *
	 * 1. Show input field
	 * 2. Keep track of every key typed in the input
	 * 3. Filter through the names from the apiURL
	 * 4. Have an empty filter array of type  Observable
	 * 5. Assign the filtered data into this filteredOptions array
	 */
	onInputChange(event, apiUrl, pageName, questionName){

		let keyedValue = event.target.value;

		$(".autocompleteContainer").hide();

		$(event.target).next(".autocompleteContainer").show();

		this.autoCompleteData.getAutoCompleteData(keyedValue, apiUrl).subscribe(data=>{

			this.autoCompleteReference[pageName][questionName] = data;

		});



	}

	onItemSelected(event, pageName:any, questionFieldName:any, selectedValue){

		$(event.target).parent(".autocompleteResponse").hide();

		this.form["controls"][pageName]["controls"][questionFieldName]["value"] = selectedValue;

		this.form["controls"][pageName]["controls"][questionFieldName].patchValue( this.form["controls"][pageName]["controls"][questionFieldName]["value"] );

	}

	onFollowUpItemSelected(event, pageName:string, questionFieldName:string, questionToFollowFieldName:string, selectedValue ){

		$(event.target).parent(".autocompleteResponse").hide();

		console.log("Page name: ", pageName);
		console.log("Question Field Name: ", questionFieldName);
		console.log("Question To Follow Fieldname: ", questionToFollowFieldName);
		console.log(" QTF Controls", this.form["controls"][pageName]["controls"]);

		this.form["controls"][pageName]["controls"][questionToFollowFieldName]["value"] = selectedValue;
		
		console.log("Selected value",selectedValue);
	
		this.form["controls"][pageName]["controls"][questionToFollowFieldName].patchValue( this.form["controls"][pageName]["controls"][questionToFollowFieldName]["value"] );

		console.log("Updated form: ",this.form);
	}

	handleRepeaterInputChange(event, apiUrl, pageName, parentQuestion, questionName, repeaterIndex){

		let keyedValue = event.target.value;

		$(".repeaterRow .autocompleteContainer").hide();

      	$(event.target).next(".autocompleteContainer").show();

		this.autoCompleteData.getAutoCompleteData(keyedValue, apiUrl).subscribe(data=>{

			this.autoCompleteReference[ pageName ][ parentQuestion ][0][questionName] = data;


		});

	}

	onRepeaterItemSelected(event, pageName, parentQuestion, repeaterIndex, questionName, selectedValue){

		$(event.target).parent(".autocompleteResponse").hide();

		this.form["controls"][pageName]["controls"][parentQuestion]["controls"][repeaterIndex]["controls"][questionName]["value"] = selectedValue;

		console.log(this.form["controls"][pageName]["controls"][parentQuestion]["controls"][repeaterIndex]["controls"][questionName]["value"]);

		this.form["controls"][pageName]["controls"][parentQuestion]["controls"][repeaterIndex]["controls"][questionName].patchValue(this.form["controls"][pageName]["controls"][parentQuestion]["controls"][repeaterIndex]["controls"][questionName]["value"]);

		console.log("Updated form: ",this.form);

	}

	handleAccordionInputChange(event, apiUrl, pageName, parentQuestion, accordionKey, questionName){

		let keyedValue = event.target.value;

		$(event.target).next(".autocompleteContainer").show();

		this.autoCompleteData.getAutoCompleteData(keyedValue, apiUrl).subscribe(data=>{

			this.autoCompleteReference[ pageName ][ parentQuestion ][ accordionKey ][ questionName ] = data;

		});

		console.log(this.autoCompleteReference);

	}

	onAccordionItemSelected(event, pageName, parentQuestion, accordionKey, questionName, selectedValue){

		console.log(selectedValue);
		//[accordionKey]["controls"][parentQuestion]

		console.log("Page Name",pageName,"Parent Question ", parentQuestion, "Accordion Key ", accordionKey,"Question Name",questionName);

		$(event.target).parents(".autocompleteContainer").hide();

		this.form["controls"][pageName]["controls"][parentQuestion]["controls"][accordionKey]["controls"][questionName]["value"] = selectedValue;

		this.form["controls"][pageName]["controls"][parentQuestion]["controls"][accordionKey]["controls"][questionName].patchValue(this.form["controls"][pageName]["controls"][parentQuestion]["controls"][accordionKey]["controls"][questionName]["value"] );

		console.log("Updated form: ",this.form);

	}

	// handleQuestionFollowInputChange(event, apiUrl, pageName, questionFieldName, questionToFollowKey, questionToFollowFieldName){

	// 	let keyedValue = event.target.value;

	// 	$(event.target).next(".autocompleteContainer").show();

	// 	this.autoCompleteData.getAutoCompleteData(keyedValue, apiUrl).subscribe(data=>{

	// 		this.autoCompleteReference[ pageName ][ questionFieldName ][ questionToFollowKey ][ questionToFollowFieldName ] = data;

	// 	});

	// 	console.log(this.autoCompleteReference);

	// }

	// onQuestionToFollowSelect(event, pageName:any, questionFieldName:any, questionToFollowKey:any, questionToFollowFieldName:any,  selectedValue){

	// 	$(event.target).parent(".autocompleteResponse").hide();

	// 	console.log("Page name: ", pageName);
	// 	console.log("Question Name: ", questionFieldName);
	// 	console.log("Option value: ",questionToFollowKey);
	// 	console.log("Question To Follow: ", questionToFollowFieldName);

	// 	this.form["controls"][pageName]["controls"][questionFieldName]["value"] = selectedValue;

	// 	console.log(this.form["controls"][pageName]["controls"][questionFieldName]["value"]);

	// 	this.form["controls"][pageName]["controls"][questionFieldName]["controls"][questionToFollowKey]["controls"][questionToFollowFieldName].patchValue( this.form["controls"][pageName]["controls"][questionFieldName]["controls"][questionToFollowKey]["controls"][questionToFollowFieldName]["value"] );

	// 	//this.form["controls"][pageName]["controls"][questionFieldName].patchValue( this.form["controls"][pageName]["controls"][questionFieldName]["value"] );

	// }

	onFormControlChange(groupName:string){

		this.form["controls"][groupName].patchValue(this.form["controls"][groupName]);

		console.log("Updated form: ",this.form);

	}

	onFormControlSelectChange(event, groupName:string, questionName:string){

		let inputValue = event.target.value;

		if (inputValue !== ""){

			this.form["controls"][groupName]["controls"][questionName]["value"] = inputValue.trim();

			this.form["controls"][groupName].patchValue(this.form["controls"][groupName]);

		}

		console.log("Updated form: ",this.form);
		console.log("Question name:", questionName);
		console.log("Input value:",inputValue);

	}

	onDynamicSelect(event, page:any, pageName:string, questionName:string, fieldType:string){

		let pageToTraverse = page;

		let inputValue = event.target.value;

		for( let p = 0; p <pageToTraverse.questions.length; p++){

			if(pageToTraverse.questions[p].fieldType == "dynamicselect" && inputValue !== " "){

				this.form["controls"][pageName].patchValue(this.form["controls"][pageName]);

				console.log("Updated form: ",this.form);

			}

		}

	}

	onFollowUpDynamicSelect(event, page:any, pageName:string, questionName:string, fieldType:string){

		console.log(page);
		console.log(pageName);
		console.log(questionName);
		console.log(fieldType);

		let pageToTraverse = page;

		let inputValue = event.target.value;

		for( let p = 0; p <pageToTraverse.questions.length; p++){

			if(pageToTraverse.questions[p].fieldType == "dynamicselect" && inputValue !== " "){

				this.form["controls"][pageName]["controls"][questionName].patchValue(this.form["controls"][pageName]["controls"][questionName]["value"]);

				console.log("Updated form: ",this.form);

			}

		}

	}

	loadDynamicSelectValues(){

		for( let p = 0; p < this.selectQueue.length; p++){

			this.autoCompleteData.fetchDynamicSelectValues(this.selectQueue[p]["url"]).subscribe(response=>{

				let pageName = this.selectQueue[p]["pageName"];
				let question = this.selectQueue[p]["questionName"];
				let parentQuestion = this.selectQueue[p]["parentQuestion"];
				let childQuestion = this.selectQueue[p]["childQuestion"];
				let childQuestionType = this.selectQueue[p]["childQuestionType"];
				let adjacentQuestionFieldType = this.selectQueue[p]["adjacentQuestionFieldType"];

				if(response.body.length > 0){

					if(childQuestionType === "accordion"){

						this.dynamicSelectReference[pageName][question][parentQuestion][childQuestion] = response.body;

					} else if (childQuestionType === "repeater"){

						this.dynamicSelectReference[pageName][question][parentQuestion][childQuestion] = response.body;

					} else {

						this.dynamicSelectReference[pageName][question] = response.body;

						console.log(this.dynamicSelectReference[pageName][question]);
						
					}

				}


			});

		}

	}

	onAutocompleteSelected(result: PlaceResult, groupName:string, questionName:string){

		this.form["controls"][groupName]["controls"][questionName]["value"] = result.formatted_address;

		this.form["controls"][groupName].patchValue(this.form["controls"][groupName]["controls"][questionName]);

		console.log("Updated form: ",this.form);

	}

	onFileChange(event, groupName:string, questionName:string, maxSize:string, instructions:string, docType:string){

			const formDataUpload = new FormData();

				if(event.target.files.length > 0 ){

					for( let i = 0; i < event.target.files.length; i++){

						let fileName = event.target.files[i].name;
						let fileSize = event.target.files[i].size;

						let allowedFileExtensions = /(\.pdf|\.doc|\.docx|\.document|\.xls |\.xlsx)$/i;

						let allowedImageExtensions = /(\.jpg|\.jpeg|\.png)$/i;

						if(docType == 'file'){

							if (allowedFileExtensions.exec(fileName) ) {

								if(fileSize < maxSize){

									this.uploadDocuments.push(event.target.files[i]);

									this.form["controls"][groupName]["controls"][questionName]["value"] = fileName;

									this.form["controls"][groupName].patchValue(this.form["controls"][groupName]["controls"][questionName]);

									formDataUpload.append("file", event.target.files[i], event.target.files[i].name)

									$(event.target).next(".uploadLabel").text(fileName);

								}  else  {

									$(event.target).next(".uploadLabel").text(fileName + " exceeds file limit");

									this.form["controls"][groupName]["controls"][questionName]["value"] = "";

									setTimeout(() => {

										$(event.target).next(".uploadLabel").text(instructions);

									}, 3500);

								}

								} else {

									$(event.target).next(".uploadLabel").text(fileName + " contains invalid extension");

									this.form["controls"][groupName]["controls"][questionName]["value"] = "";

									setTimeout(() => {

										$(event.target).next(".uploadLabel").text(instructions);

									}, 3500);

							}

						} else if(docType == 'image') {

							if (allowedImageExtensions.exec(fileName) ) {

								if(fileSize < maxSize){

									this.uploadDocuments.push(event.target.files[i]);

									this.form["controls"][groupName]["controls"][questionName]["value"] = fileName;

									this.form["controls"][groupName].patchValue(this.form["controls"][groupName]["controls"][questionName]);

									formDataUpload.append("file", event.target.files[i], event.target.files[i].name)

									$(event.target).next(".uploadLabel").text(fileName);

								}  else  {

									$(event.target).next(".uploadLabel").text(fileName + " exceeds file limit");

									this.form["controls"][groupName]["controls"][questionName]["value"] = "";

									setTimeout(() => {

										$(event.target).next(".uploadLabel").text(instructions);

									}, 3500);

								}

								} else {

									$(event.target).next(".uploadLabel").text(fileName + " contains invalid extension");

									this.form["controls"][groupName]["controls"][questionName]["value"] = "";

									setTimeout(() => {

										$(event.target).next(".uploadLabel").text(instructions);

									}, 3500);

							}

						}

					}

				}

	}

	handleFileUpload(type){

		if(this.uploadDocuments.length > 0){

			this.fileUploads.uploadMultipleFiles(this.uploadDocuments, type).subscribe(response => {

				if(response["success"]){

					this.uploadDocRef = response["data"]["docUploadRef"];

				}
			});

		}
	}

	onOptionSelect(event, page:any, pageNumber:string, questionName:string, fieldType:string){

		let inputValue = event.target.value;

		let pageToTraverse = page;

		if(fieldType == "select_one" && pageNumber !== "" && inputValue !== ""){

				if(pageToTraverse.questions.length > 0){

					console.log("About to start traversing");

					this.getKeysToDelete(pageToTraverse, questionName, inputValue);

				}
		}

	}

	getKeysToDelete(pageToTraverse, questionName, inputValue){

			//loop in the questions

			for( let m = 0; m <pageToTraverse.questions.length; m++){

				if(pageToTraverse.questions[m]["fieldName"] == questionName ){

					let optionsWithKeysToDelete = pageToTraverse.questions[m];

					console.log("Options with keys to delete:",optionsWithKeysToDelete);

					//loop in the options

					for( let p = 0; p<optionsWithKeysToDelete.options.length; p++){

						let optionsWithQuestionsToFollow = optionsWithKeysToDelete.options[p];

						if(optionsWithQuestionsToFollow.hasconditionalquestion == "yes" && optionsWithQuestionsToFollow.questionToFollow.length > 0){

							//loop in the questions to follow

							for( let n = 0; n<optionsWithQuestionsToFollow.questionToFollow.length; n++){

								if(inputValue == optionsWithQuestionsToFollow.value){

									let keysToAdd = optionsWithQuestionsToFollow.questionToFollow[n];

									this.keysToAddArray = new Array();

									this.keysToAddArray.push(keysToAdd?.fieldName);

									this.addKeysToArray(pageToTraverse);

								} else {

									let keysToDelete = optionsWithQuestionsToFollow.questionToFollow[n];

									this.keysToDeleteArray = new Array();

									this.keysToDeleteArray.push(keysToDelete?.fieldName);

									this.deleteKeysFromArray(pageToTraverse);

								}

							}

						}

					}

				}

			}
	}

	deleteKeysFromArray(pageToTraverse){

		let uniqueKeysToDelete = [...new Set(this.keysToDeleteArray)];

		for( let b = 0; b < uniqueKeysToDelete.length; b++) {

			delete this.form["controls"][pageToTraverse.pageName]["controls"][uniqueKeysToDelete[b]];

		}

	}

	addKeysToArray(pageToTraverse){

		let uniqueKeysToAdd = [...new Set(this.keysToAddArray)];

		for( let p = 0; p < uniqueKeysToAdd.length; p++) {

			this.form["controls"][pageToTraverse.pageName]["controls"][uniqueKeysToAdd[p]] = new FormControl('');

			console.log("New form controls: ",this.form["controls"][pageToTraverse.pageName]["controls"]);
		}

	}


	onRepeaterFieldChange(groupIndex:string,formArrayName:string){

		//this.form["controls"][formArrayName]["controls"][groupIndex].patchValue(this.form["controls"][groupName].getRawValue());


	}

	onAccordionControlChange(groupName){


	}

	renderComponent(componentType: string, question: any){

		switch(componentType) {

			case 'autocomplete':
				return (
				'<input type="text">'
				)
			break;

		}

	}

	submitPageDetails(pageName:string, validationURL:string){

		this.payLoad= this.form.getRawValue();

		this.loading = true;

		if(validationURL !== ""){

			this.loading = false;

			this.qcs.submitDynamicForm(validationURL,this.payLoad[pageName]).subscribe(response => {

				if( response["success"] == true ){

					this.formDetailsVerified[pageName] = true;

				} else {

					//add error notification

				}

			});

		}

	}

	onSubmit() {

		this.payLoad = this.form.getRawValue() ;

		this.loading = true;

		if(this.submissionURL !== ""){

			this.loading = false;

			this.qcs.submitDynamicForm(this.submissionURL,this.payLoad).subscribe(response => {


			});

		}

	}
}
