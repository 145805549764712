import { Component, OnInit,Input } from '@angular/core';

import { faArrowAltCircleRight} from '@fortawesome/free-solid-svg-icons';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-readmore',
    templateUrl: './readmore.component.html',
    styleUrls: ['./readmore.component.css']
})
export class ReadmoreComponent implements OnInit {

    @Input() link:string;
    @Input() title:string;
    @Input() type:string;

    optionType:string;

    constructor(library:FaIconLibrary) {

        library.addIcons(faArrowAltCircleRight);

    }

    ngOnInit(): void {

        this.optionType = this.type;

    }

}
