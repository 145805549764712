export class PolicyDetails{

    benefits:any = [];
    coverTypeShortDescription:string = "" ;
    coverTypeDescription:string = "" ;
    coverFromDate:string = "" ;
    coverToDate:string = "" ;
    documents:any=[];
    docUploadRef:string="";
    productShortDescription: string = "7700" ;
    sumInsured:number = 0 ;
    premiumAmount:number = 0 ;
    premiumRate: number = 0 ;
    traininglevy:number = 0 ;
    phcf: number = 0 ;
    stampduty:number = 0 ;
    vat:number =0;
    coverSecurityDevice:string = "none";
    nettPremium:number = 0 ;
    valuationServices:number = 0 ;
    transactionfee:number = 0;
    rescueServices: number = 0 ;

}
