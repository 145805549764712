import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { User } from '@/_models';

import { environment } from '../../environments/environment.dev';

@Injectable({ providedIn: 'root' })
export class CustomerService {

  constructor(private http: HttpClient) {}

  getCustomberById(id: string) {

    return this.http.get<Object[]>(
      `${environment.customerFacingApi}customerLead/findByNationalId/${id}`
    );

  }

  verifyPrincipleDetails(principleDetails:any){

      return this.http.post(`${environment.customerFacingApi}customerLead/create`, principleDetails);

  }

  getPolicyPaymentStatus(paymentReference:string){

      return this.http.post(`${environment.customerFacingApi}payment/policyPaymentStatus`, {'paymentRef':paymentReference});

  }

  createCustomerPolicy(leadData: any) {

    return this.http.post(`${environment.apiUrl}/cover/create`, leadData);

  }

  getCustomerPolicySummary(customerId: String) {

    return this.http.get(`${environment.apiUrl}/cover/summary?customerId=${customerId}`);

  }

  getPolicyById(customerId: String) {

    return this.http.get(`${environment.apiUrl}/principle?customerId=${customerId}`);

  }

  getPolicyBenefits(){

    	return this.http.get(`${environment.projectConfigApi}additionalBenefit/findByInsuranceClass/1`);

  }

  submitPolicyBenefits(benefits:any){

	  return this.http.post(`${environment.quoteApi}quote/benefit`, benefits);

  }

  generateLipaPolePoleSchedule(scheduleData:any){

      return this.http.post<any>(`${environment.quoteApi}quote/calculator`, scheduleData);

  }

  getAllPolicyByCustomerId(customerId) {}

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/users/${id}`);
  }
}
