import { Component, OnInit,ViewEncapsulation } from '@angular/core';

import { ActivatedRoute,Router } from "@angular/router";

import $ from 'jquery';

import { HttpClient } from '@angular/common/http';

import { ClaimsService } from './../../../_services/claims.service';
import { UserService } from './../../../_services/user.service';
import { AuthenticationService } from './../../../_services/authentication.service';
import { FileUploadService } from './../../../_services/files/file-upload.service';
import { Document } from './../../../_models/index';
import { DamageClaimDetails } from './../../../_models/claims/DamageClaimDetails.model';
import { TheftRecoveryClaimDetails } from './../../../_models/claims/TheftRecoveryClaimDetails.model';
import { TheftPartsClaimDetails } from './../../../_models/claims/TheftPartsClaimDetails.model';
import { TotalTheftClaimDetails } from './../../../_models/claims/TotalTheftClaimDetails.model';
import { WindscreenDamageClaimDetails } from './../../../_models/claims/WindscreenDamageClaimDetails.model';
import { DamagedVehicles } from './../../../_models/claims/DamagedVehicles.model';
import { InjuredPassangers } from './../../../_models/claims/InjuredPassangers.model';
import { PassangersInVehicle } from './../../../_models/claims/PassangersInVehicle.model';
import { PropertiesDamaged } from './../../../_models/claims/PropertiesDamaged.model';
import { IndependentWitnesses } from './../../../_models/claims/IndependentWitnesses.model';
import { TheftPartsReplacedItems } from './../../../_models/claims/TheftPartsReplacedItems.model';
import { DateOfAccident } from './../../../_models/claims/DateOfAccident.model';
import { DamageClaimOtherDocuments }  from './../../../_models/claims/DamageClaimOtherDocuments.model';

import {Location, Appearance} from '@angular-material-extensions/google-maps-autocomplete';

import PlaceResult = google.maps.places.PlaceResult;
import { TheftRecoveryReplacedItems } from 'src/app/_models/claims/TheftRecoveryReplacedItems.model';
import { CoverSummaryModule } from '../../coversummary/coversummary.module';

interface InjuredPerson {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-claim',
    templateUrl: './claim.component.html',
    styleUrls: ['./claim.component.css'],
    encapsulation: ViewEncapsulation.None,
})

export class ClaimComponent implements OnInit {

    siteImages:any = [];

    clientName:string;
    nationalId:string;

    userProfile:any;
    policies:any = [];

    policyNumber:string="";
    thirdPartyCheck:boolean = false;
    thirdParyIndex:number = -1;

	selectedPolicy:string = "";

    uploadRef:string;

    totalTheftClaim: TotalTheftClaimDetails = new TotalTheftClaimDetails();
    damageClaim: DamageClaimDetails = new DamageClaimDetails();
    theftRecoveryClaim: TheftRecoveryClaimDetails = new TheftRecoveryClaimDetails();
    theftPartsClaim: TheftPartsClaimDetails = new TheftPartsClaimDetails();
    windscreenDamageClaim: WindscreenDamageClaimDetails = new WindscreenDamageClaimDetails();

    loading:boolean = false;
    processorDescription:string = "processing";
    showModal: boolean = false;

    modalTitle: string = "";
    modalDescription: string = "";
    modalLink: string;
    modalLinkText: string = "Proceed";
    modalType:string = "";

    selectedTime: Date = new Date();
    accidentDate: Date = new Date();
    minDate: Date;
    maxDate: Date;
    adultLimitDate:Date;
    //declareCheckBox: boolean = false;
    declarationCheckBox:boolean = false;

    passengersArray: any = [];
    passengersInVehicle: PassangersInVehicle = new PassangersInVehicle();

    injuredPassengersArray: any = [];
    injuredPassangers: InjuredPassangers = new InjuredPassangers();

    damagedVehiclesArray: any = [];
    damagedVehicles: DamagedVehicles = new DamagedVehicles();

    propertiesDamagedArray: any =[];
    propertiesDamaged: PropertiesDamaged = new PropertiesDamaged();

    independentWitnessesArray: any = [];
    independentWitnesses: IndependentWitnesses = new IndependentWitnesses();

    theftRepairsArray: any = [];
    totalRepairsCost: number = 0;
    // theftRepars: IndependentWitnesses = new IndependentWitnesses();

    dateOfAccidentArray: any = [];
    dateOfAccident: DateOfAccident = new DateOfAccident();

    theftReplacedItemsArray: any = [];

    selectedClaim:string = "";

    currentStep:string = "";

    fileContent: any;

    uploadFiles: any = [];

    confirmCheck:boolean = false;



    injuredPersonList: InjuredPerson[] = [
        {value: 'passenger-1', viewValue: 'Passenger 1'},
        {value: 'passenger-2', viewValue: 'Passenger 2'},
        {value: 'other-injured-person', viewValue: 'Any Other Person Injured'}
    ];

    timeListArray: any = [
        {value: '00:00', viewValue: '12:00 am' },
        {value: '00:30', viewValue: '12:30 am' },
        {value: '01:00', viewValue: '01:00 am' },
        {value: '01:30', viewValue: '01:30 am' },
        {value: '02:00', viewValue: '02:00 am' },
        {value: '02:30', viewValue: '02:30 am' },
        {value: '03:00', viewValue: '03:00 am' },
        {value: '03:30', viewValue: '03:30 am' },
        {value: '04:00', viewValue: '04:00 am' },
        {value: '04:30', viewValue: '04:30 am' },
        {value: '05:00', viewValue: '05:00 am' },
        {value: '05:30', viewValue: '05:30 am' },
        {value: '06:00', viewValue: '06:00 am' },
        {value: '06:30', viewValue: '06:30 am' },
        {value: '07:00', viewValue: '07:00 am' },
        {value: '07:30', viewValue: '07:30 am' },
        {value: '08:00', viewValue: '08:00 am' },
        {value: '08:30', viewValue: '08:30 am' },
        {value: '09:00', viewValue: '09:00 am' },
        {value: '09:30', viewValue: '09:30 am' },
        {value: '10:00', viewValue: '10:00 am' },
        {value: '10:30', viewValue: '10:30 am' },
        {value: '11:00', viewValue: '11:00 am' },
        {value: '11:30', viewValue: '11:30 am' },
        {value: '12:00', viewValue: '12:00 pm' },
        {value: '12:30', viewValue: '12:30 pm' },
        {value: '13:00', viewValue: '01:00 pm' },
        {value: '13:30', viewValue: '01:30 pm' },
        {value: '14:00', viewValue: '02:00 pm' },
        {value: '14:30', viewValue: '02:30 pm' },
        {value: '15:00', viewValue: '03:00 pm' },
        {value: '15:30', viewValue: '03:30 pm' },
        {value: '16:00', viewValue: '04:00 pm' },
        {value: '16:30', viewValue: '04:30 pm' },
        {value: '17:00', viewValue: '05:00 pm' },
        {value: '17:30', viewValue: '05:30 pm' },
        {value: '18:00', viewValue: '06:00 pm' },
        {value: '18:30', viewValue: '06:30 pm' },
        {value: '19:00', viewValue: '07:00 pm' },
        {value: '19:30', viewValue: '07:30 pm' },
        {value: '20:00', viewValue: '08:00 pm' },
        {value: '20:30', viewValue: '08:30 pm' },
        {value: '21:00', viewValue: '09:00 pm' },
        {value: '21:30', viewValue: '09:30 pm' },
        {value: '22:00', viewValue: '10:00 pm' },
        {value: '22:30', viewValue: '10:30 pm' },
        {value: '23:00', viewValue: '11:00 pm' },
        {value: '23:30', viewValue: '11:30 pm' }
      ];

      inspectionTime: string = "00:00";
      inspectionDate: string = "";

      eventTime:string = "00:00";
      eventDate:string = "";

      reportTime:string = "00:00";
      reportDate:string = "";

      user:any;

      generalGarages:any;
      windscreenGarages:any;

    constructor(
        private claimService:ClaimsService,
        private userService:UserService,
        private authService:AuthenticationService,
		private route: ActivatedRoute,
        private fileUpload:FileUploadService,
        private http: HttpClient
    ) {

    }

    ngOnInit(): void {

        const currentDate = new Date().getDate();
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();

        this.minDate = new Date(currentDate);
        this.maxDate = new Date();
        this.adultLimitDate = new Date(currentYear - 18,currentMonth,currentDate);

        this.nationalId = this.authService.getUserNationalId();
        this.clientName = this.authService.getUserName();

        $(window).resize(this.setFormMinHeight);

		if(this.route.snapshot.paramMap.get('policyNumber') !== null){

			this.selectedPolicy = this.route.snapshot.paramMap.get('policyNumber');

			this.windscreenDamageClaim.claimDetails.policyNumber = this.selectedPolicy;
			this.theftPartsClaim.claimDetails.policyNumber = this.selectedPolicy;
			this.theftRecoveryClaim.claimDetails.policyNumber = this.selectedPolicy;
			this.totalTheftClaim.claimDetails.policyNumber = this.selectedPolicy;
			this.damageClaim.claimDetails.policyNumber = this.selectedPolicy;

			this.policyNumber = this.selectedPolicy;

		}

        // this.setFormMinHeight();

        this.http.get("/assets/data/GarageList.json").subscribe(data => {

            this.generalGarages = data;

         });

        this.userService.getUserProfile(this.nationalId).subscribe(response => {

            if(response["success"]){

                // this.userProfile = response["data"];
                this.policies = response["data"]["policy"];

            }

        },error => {


        });

        // console.log(this.totalTheftClaim);
        // console.log(this.theftRecoveryClaim);
        // console.log(this.theftPartsClaim);


        $(".welcomeClaim").addClass("active").show();
        $(".multistep").hide();
        $(".multistep:first").show().addClass("active");
        if (localStorage.getItem("selectedClaim")){

            if(localStorage.getItem("selectedClaim") !== '' || localStorage.getItem("selectedClaim") !== 'undefined'){

                this.selectedClaim = localStorage.getItem("selectedClaim");
                this.selectClaimView(this.selectedClaim) ;
            }

        }

        if(localStorage.getItem("currentStep")){

            if(localStorage.getItem("currentStep") !== '' || localStorage.getItem("currentStep") !== 'undefined'){

                this.handleProgression(localStorage.getItem("currentStep"));

            }


        }

        if(localStorage.getItem("insuremeRiders")){

            this.userProfile = JSON.parse(localStorage.getItem("insuremeRiders"));

            this.damageClaim.claimDetails.driverDetails.name = this.userProfile.customerName;
            this.damageClaim.claimDetails.driverDetails.idNumber = this.userProfile.username;
            this.damageClaim.claimDetails.driverDetails.phoneNumber = this.userProfile.phoneNumber;
            this.damageClaim.claimDetails.driverDetails.kraPin = this.userProfile.kra;

        }

        this.passengersArray.push(this.passengersInVehicle);

        if (localStorage.getItem("claim")){

            switch (this.selectedClaim) {


                case 'damageVehicle':

                    this.damageClaim = JSON.parse(localStorage.getItem("claim"));
                    this.accidentDate = new Date(this.damageClaim.claimDetails.date);
                    this.passengersArray = this.damageClaim.claimDetails.passangersInVehicle;
                    this.injuredPassengersArray = this.damageClaim.claimDetails.injuredPassangers;
                    this.damagedVehiclesArray = this.damageClaim.claimDetails.damagedVehicles;
                    this.propertiesDamagedArray = this.damageClaim.claimDetails.propertiesDamaged;
                    this.independentWitnessesArray = this.damageClaim.claimDetails.independentWitnesses;
                    this.damageClaim.claimDetails.otherDocuments = [];

                    break;

                case 'totaltheftVehicle':

                    this.totalTheftClaim = JSON.parse(localStorage.getItem("claim"));
                    this.totalTheftClaim.claimDetails.otherDocuments = [];

                    break;

                case 'theftRecoveryVehicle':

                    this.theftRecoveryClaim = JSON.parse(localStorage.getItem("claim"));
                    this.theftReplacedItemsArray = this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems;
                    this.theftRecoveryClaim.claimDetails.otherDocuments = [];

                    break;

                case 'theftVehicleParts':

                    this.theftPartsClaim = JSON.parse(localStorage.getItem("claim"));
                    this.theftRepairsArray = this.theftPartsClaim.claimDetails.replacedItems;
                    this.theftPartsClaim.claimDetails.otherDocuments = [];

                    break;

                case 'windowDamage':

                    this.windscreenDamageClaim = JSON.parse(localStorage.getItem("claim"));
                    this.windscreenDamageClaim.claimDetails.otherDocuments = [];

                    break;

                default:
                    break;
            }



        }

        if (localStorage.getItem("eventTime")){

            this.eventTime = localStorage.getItem("eventTime");

        }

        if(localStorage.getItem("eventDate")){

            this.eventDate = localStorage.getItem("eventDate");

        }

        if(localStorage.getItem("inspectionDate")){

            this.inspectionDate = localStorage.getItem("inspectionDate");

        }

        if(localStorage.getItem("inspectionTime")){

            this.inspectionTime = localStorage.getItem("inspectionTime");

        }

        if(localStorage.getItem("reportDate")){

            this.reportDate = localStorage.getItem("reportDate");

        }

        this.damageClaim.claimDetails.customerId = this.authService.getUserSystemId();

        // this.userService.getUserProfile()

        this.calculateTotalCost();

    }

    ngAfterViewInit():void{

        this.getImages();

    }

    getImages(){

        $("img").each( (index,value) => {

            if($(value).attr("src") != "" || $(value).attr("src") == "undefined" || $(value).attr("src") == null){

                this.siteImages.push($(value).attr("src"));

            }

        });

    }

    tabProgression(targetStep) {

        this.closeModal();

    }

    showNotification(event) {

        event.stopPropagation();

        let currentForm;
        currentForm = event.target.dataset.form;
        $('.formModal').hide();

        $('.' + currentForm).show();
        $('.overlay').fadeIn();


    }

    closeModal() {

        $('.formModal').hide();
        $('.overlay').fadeOut();

    }

    onAmountChange(amount){

        this.windscreenDamageClaim.claimDetails.estimatedReplacementCost = amount;

    }

    handleModalProgression(event){

        $(".welcomeClaim").hide();

        $('html, body').stop().animate({
            scrollTop: $(".infoForm").offset().top
        }, 300);

        this.closeModal();

        let currentClaim = event.target.dataset.claim;
        let claimType = event.target.dataset.type;

        this.selectedClaim = currentClaim;

        switch (currentClaim) {


            case 'damageVehicle':

                this.damageClaim.claimDetails.claimType = claimType;
                this.damageClaim.claimDetails.customerId = this.userProfile.id;

                localStorage.setItem("claim",JSON.stringify(this.damageClaim));

                break;

            case 'totaltheftVehicle':

                this.totalTheftClaim.claimDetails.claimType = claimType;
                this.totalTheftClaim.claimDetails.customerId = this.userProfile.id;
                localStorage.setItem("claim",JSON.stringify(this.totalTheftClaim));

                break;

            case 'theftRecoveryVehicle':

                this.theftRecoveryClaim.claimDetails.claimType = claimType;
                this.theftRecoveryClaim.claimDetails.customerId = this.userProfile.id;
                localStorage.setItem("claim",JSON.stringify(this.theftRecoveryClaim));

                break;

            case 'theftVehicleParts':

                this.theftPartsClaim.claimDetails.claimType = claimType;
                this.theftPartsClaim.claimDetails.customerId = this.userProfile.id;

                localStorage.setItem("claim",JSON.stringify(this.theftPartsClaim));

                break;

            case 'windowDamage':

                this.windscreenDamageClaim.claimDetails.claimType = claimType;
                this.windscreenDamageClaim.claimDetails.customerId = this.userProfile.id;

                localStorage.setItem("claim",JSON.stringify(this.windscreenDamageClaim));

                break;

            default:
                break;
        }


        //this.damageClaim.claimDetails.policyNumber = parseString(23451);

        this.selectClaimView(currentClaim);


        localStorage.setItem("selectedClaim",currentClaim);


    }

    selectClaimView(claim){

        $(".welcomeClaim").hide();
        $('.claimType.' + claim).show();
        $(".multistep:first-child").addClass("active").show();
        $('.summaryDetails').show();

        this.setFormMinHeight();
        $('.summaryDetails').removeClass("hideForm");

    }

    handleBackProgression(event){

        $('html, body').stop().animate({
            scrollTop: $(".infoForm").offset().top
        }, 300);

        $("." + event).addClass("active").show();
        $('.claimType').hide();
        $('.summaryDetails,.insuranceSummary').hide();
        $('.summaryDetails,.insuranceSummary').addClass("hideForm");

        localStorage.setItem("currentStep",event);

        if(event === 'welcomeClaim'){

            localStorage.removeItem("currentStep");
            localStorage.removeItem("selectedClaim");

            $(".welcomeClaim").addClass("active").show();
            $('.claimType').hide();
            $('.summaryDetails,.insuranceSummary').hide().addClass("hideForm");


        }

    }

    handleCancelProgression(event){

        $('.insuranceSummary').addClass("hideForm").hide();
        $("." + event).addClass("active").show();

        localStorage.removeItem("currentStep");

    }

    handleProgression(targetStep) {


        $(".hideForm").hide();
        $(".multistep").removeClass("active").fadeOut();
        $(".multistep." + targetStep).addClass("active").fadeIn();

        if(targetStep == "insuranceSummary"){
            $(".hideForm." + targetStep).addClass("active").fadeIn();
            $('.summaryDetails').addClass("hideForm").fadeOut();
        }


        this.setFormMinHeight();

        localStorage.setItem("currentStep",targetStep);



    }

    onChange(event) {


        let name = event.target.name;
        let value = event.target.value;
        let key = event.target.dataset.key;

        if(key){

            switch(key){

                case 'claimDetails' :

                    if(name === 'policyNumber'){

                        this.windscreenDamageClaim.claimDetails.policyNumber = value;
                        this.theftPartsClaim.claimDetails.policyNumber = value;
                        this.theftRecoveryClaim.claimDetails.policyNumber = value;
                        this.totalTheftClaim.claimDetails.policyNumber = value;
                        this.damageClaim.claimDetails.policyNumber = value;

                        this.policyNumber = value;
                        this.thirdPartyCheck = $("#policyNumber option:selected").text().replace("Motor Private-","").includes("Third Party Only");
                        this.thirdParyIndex = $("#policyNumber option:selected").text().replace("Motor Private-","").indexOf("Third Party Only");

                    }

                    if(this.selectedClaim === 'damageVehicle'){

                        this.damageClaim.claimDetails[name] = value;

                        if(name === "noOfPassangesInVahicle"){

                            this.injuredPassengersArray = [];

                            this.addPassengers(value);
                        }

                        localStorage.setItem('claim', JSON.stringify(this.damageClaim));

                    }else if(this.selectedClaim === 'windowDamage'){

                        this.windscreenDamageClaim.claimDetails[name] = value;

                         if (name === 'nameOfGarage'){

                            this.windscreenDamageClaim.claimDetails.nameOfGarage = value;

                        }

                        localStorage.setItem('claim', JSON.stringify(this.windscreenDamageClaim));

                    }else if(this.selectedClaim === 'theftVehicleParts'){

                        this.theftPartsClaim.claimDetails[name] = value;
                        localStorage.setItem('claim', JSON.stringify(this.theftPartsClaim));

                    }else if(this.selectedClaim === 'theftRecoveryVehicle'){

                        this.theftRecoveryClaim.claimDetails[name] = value;
                        localStorage.setItem('claim', JSON.stringify(this.theftRecoveryClaim));

                    }else if(this.selectedClaim === 'totaltheftVehicle'){

                        this.totalTheftClaim.claimDetails[name] = value;
                        localStorage.setItem('claim', JSON.stringify(this.totalTheftClaim));

                    }


                break;


                case 'driverDetails' :

                    if(this.selectedClaim === 'damageVehicle'){

                        this.damageClaim.claimDetails.driverDetails[name] = value;
                        localStorage.setItem('claim', JSON.stringify(this.damageClaim));

                    }else if(this.selectedClaim === 'totaltheftVehicle'){

                        this.totalTheftClaim.claimDetails.driverDetails[name] = value;
                        localStorage.setItem('claim', JSON.stringify(this.totalTheftClaim));

                    }else if(this.selectedClaim === 'theftRecoveryVehicle'){

                        this.theftRecoveryClaim.claimDetails.driverDetails[name] = value;
                        localStorage.setItem('claim', JSON.stringify(this.theftRecoveryClaim));

                    }

                break;

                case 'theftRecoveryDetails' :

                    if(name === 'milageOfRecovery'){

                        this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.milageOfRecovery = value;

                    } else if(name === 'descriptionOfAnyDamage'){

                        this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.descriptionOfAnyDamage = value;

                    } else if(name === 'estimatedCost'){

                        this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems.estimatedCost = value;

                    } else if(name === 'replacementGarage'){

                        this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacementGarage = value;

                    } else if(name === 'AOBNumber'){

                        this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.OBNumber = value;

                    }

                    localStorage.setItem('claim', JSON.stringify(this.theftRecoveryClaim));
                break;

                case 'theftReportDetails' :

                    if(this.selectedClaim === 'totaltheftVehicle'){

                        this.totalTheftClaim.claimDetails.theftReportDetails[name] = value;
                        localStorage.setItem('claim', JSON.stringify(this.totalTheftClaim));

                    }

                    break;

            }
        }
        //this.totalTheftClaim.claimDetails.driverDetails[name] = value;

    }

    onClaimDetailsRadioChange(event){

        let name = event.source.name;
        let value = event.value;

        switch (this.selectedClaim){

            case 'damageVehicle':

                this.damageClaim.claimDetails[name] = value;

                if (name === 'reportedAccidentToPolice'){

                    if(value === 'false'){
                        this.damageClaim.claimDetails.AOBNumber = '';
                    }
                }

                if (name === 'vehicleInsuredDamaged'){

                    if (value === 'false'){

                        this.damageClaim.claimDetails.apparentDamageDescription = '';
                        this.damageClaim.claimDetails.inspectionPlaceLatitude = '';
                        this.damageClaim.claimDetails.inspectionPlaceLongitude = '';
                        this.damageClaim.claimDetails.inspectionDateTime = '';
                        this.damageClaim.claimDetails.inspectionDate = '';
                    }
                }

                if(name === 'driver'){

                    if(value === 'self'){

                        this.damageClaim.claimDetails.driverDetails.name = this.userProfile.customerName;
                        this.damageClaim.claimDetails.driverDetails.idNumber = this.userProfile.username;
                        this.damageClaim.claimDetails.driverDetails.phoneNumber = this.userProfile.phoneNumber;
                        this.damageClaim.claimDetails.driverDetails.kraPin = this.userProfile.kra;

                        // this.damageClaim.claimDetails.driverDetails.phoneNumber = this.userProfile.phoneNumber;
                        //this.damageClaim.claimDetails.driverDetails.dateOfBirth = this.userProfile.dateOfBirth;

                    }else{

                        this.damageClaim.claimDetails.driverDetails.name = '';
                        this.damageClaim.claimDetails.driverDetails.idNumber = '';
                        this.damageClaim.claimDetails.driverDetails.phoneNumber = '';
                        this.damageClaim.claimDetails.driverDetails.occupation = '';
                        this.damageClaim.claimDetails.driverDetails.kraPin = '';


                    }

                }

                if(name === 'werePassangesInVehicle'){

                    this.passengersArray = [];
                    this.damageClaim.claimDetails.passangersInVehicle = [];

                    this.damageClaim.claimDetails.noOfPassangesInVahicle = 1;

                    if(value === 'true'){
                        this.addPassenger();
                    }

                }

                if(name === 'wasAnyoneInjured'){

                    this.injuredPassengersArray = [];
                    this.damageClaim.claimDetails.injuredPassangers = [];

                    if(value === 'true'){

                        this.addInjuredPerson();

                    }

                }

                if(name === 'otherVehicleDamaged'){

                    this.damagedVehiclesArray = [];
                    this.damageClaim.claimDetails.damagedVehicles = [];

                    if(value === 'true'){

                        this.addAnotherCar();

                    }

                }

                if(name === 'wasAnyPropertyDamaged'){

                    this.propertiesDamagedArray = [];
                    this.damageClaim.claimDetails.propertiesDamaged = [];

                    if(value === 'true'){

                        this.addProperty();

                    }
                }

                if(name === 'haveAnyIndependentWitnesses'){

                    this.independentWitnessesArray = [];
                    this.damageClaim.claimDetails.independentWitnesses = [];

                    if(value === 'true'){

                        this.addAnotherWitness();

                    }
                }

                localStorage.setItem('claim', JSON.stringify(this.damageClaim));

                break;

            case 'windowDamage':

                this.windscreenDamageClaim.claimDetails.hasWindscreenBeenReplaced = value;

                    if (name === "hasWindscreenBeenReplaced" && value === "false"){

                        this.windscreenDamageClaim.claimDetails.estimatedReplacementCost = '';
                        this.windscreenDamageClaim.claimDetails.nameOfGarage = '';

                    }


                if (name === "anyDamageOtherThanWindscreen" && value === "false"){

                    this.windscreenDamageClaim.claimDetails.otherDamageDetails = '';

                }

                localStorage.setItem('claim', JSON.stringify(this.windscreenDamageClaim));

                break;

            case 'theftVehicleParts' :

                if(name === "hasAnyItemBeenReplaced" && value === "true"){

                    this.addAnotherRepair();
                    this.theftPartsClaim.claimDetails[name] = value;
                    this.theftPartsClaim.claimDetails.garageName = '';

                }else if(name === "hasAnyItemBeenReplaced" && value === "false"){

                    this.theftRepairsArray = [];
                    this.theftPartsClaim.claimDetails.replacedItems['estimatedCost'] = '';
                    this.theftPartsClaim.claimDetails.replacedItems = this.theftRepairsArray;
                    this.theftPartsClaim.claimDetails[name] = value;

                }

                this.theftPartsClaim.claimDetails[name] = value;
                localStorage.setItem('claim', JSON.stringify(this.theftPartsClaim));

                break;

            case 'totaltheftVehicle' :

                if(name === 'driver'){

                    if(value === 'self'){

                        this.totalTheftClaim.claimDetails.driverDetails.name = this.userProfile.customerName;
                        this.totalTheftClaim.claimDetails.driverDetails.idNumber = this.userProfile.username;
                        this.totalTheftClaim.claimDetails.driverDetails.phoneNumber = this.userProfile.phoneNumber;
                        this.totalTheftClaim.claimDetails.driverDetails.email = this.userProfile.email;

                    }else{

                        this.totalTheftClaim.claimDetails.driverDetails.name = '' ;
                        this.totalTheftClaim.claimDetails.driverDetails.idNumber = '' ;
                        this.totalTheftClaim.claimDetails.driverDetails.phoneNumber = '' ;
                        this.totalTheftClaim.claimDetails.driverDetails.email = '' ;

                    }

                }

                if( name=== 'anyModification'){

                    this.totalTheftClaim.claimDetails.modificationDetails = '';
                    this.totalTheftClaim.claimDetails[name] = value;

                }

                if( name=== 'anySpecialFitments'){

                    this.totalTheftClaim.claimDetails.specialFitmentDetails = '';
                    this.totalTheftClaim.claimDetails[name] = value;

                }

                if( name === 'anyIdentifyingFeatures'){


                        this.totalTheftClaim.claimDetails.identifyingFeaturesDescription = '';
                        this.totalTheftClaim.claimDetails[name] = value;

                }

                if(name === "theftReportedToPolice"){

                    if(value === "false"){

                        this.totalTheftClaim.claimDetails.theftReportDetails.policeStation = "";
                        this.totalTheftClaim.claimDetails.theftReportDetails.OBNumber = "";
                        this.totalTheftClaim.claimDetails.theftReportDetails.theftReportedDateTime = "";
                        this.totalTheftClaim.claimDetails.theftReportDetails.theftReportedDate = '';

                    }
                }



                if(name === "driverHaspermission"){

                    this.totalTheftClaim.claimDetails.driverDetails[name] = value ;


                }else{

                    this.totalTheftClaim.claimDetails[name] = value;

                }


                localStorage.setItem('claim', JSON.stringify(this.totalTheftClaim));

                break;

            case 'theftRecoveryVehicle' :

                if(name === 'driver'){

                    this.theftRecoveryClaim.claimDetails[name] = value;

                    if(value === 'self'){

                        this.theftRecoveryClaim.claimDetails.driverDetails.name = this.userProfile.customerName;
                        this.theftRecoveryClaim.claimDetails.driverDetails.idNumber = this.userProfile.username;
                        this.theftRecoveryClaim.claimDetails.driverDetails.phoneNumber = this.userProfile.phoneNumber;
                        this.theftRecoveryClaim.claimDetails.driverDetails.email = this.userProfile.email;

                    }else{

                        this.theftRecoveryClaim.claimDetails.driverDetails.name = '';
                        this.theftRecoveryClaim.claimDetails.driverDetails.idNumber = '';
                        this.theftRecoveryClaim.claimDetails.driverDetails.phoneNumber = '';
                        this.theftRecoveryClaim.claimDetails.driverDetails.email = '';
                        this.theftRecoveryClaim.claimDetails.driverDetails.driverHavePermission = 'false';

                    }

                } else if(name === 'hasItemBeenReplaced' && value === "true"){

                    this.addAnotherReplacedItem();
                    this.theftRecoveryClaim.claimDetails[name] = value;
                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacementGarage = '';

                } else if(name === 'hasItemBeenReplaced' && value === "false"){

                    this.theftReplacedItemsArray = [];
                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems = this.theftReplacedItemsArray;
                    this.theftRecoveryClaim.claimDetails[name] = value;

                    // this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems.estimatedCost = '';
                    // this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems.dateOfPurchase = '';

                } else if(name === "theftReportedToPlace" && value === "false"){
                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.OBNumber = '';
                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.policeStation = '';
                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.theftReportedDate = '';
                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.theftReportedDateTime = '';
                }

                this.theftRecoveryClaim.claimDetails[name] = value;
                localStorage.setItem('claim', JSON.stringify(this.theftRecoveryClaim));


                break;

        }



    }

    onDriverDetailsRadioChange(event){

        let name = event.source.name;
        let value = event.value;

        this.damageClaim.claimDetails.driverDetails[name] = value;

        if(name === 'isDriverEmployedByYou'){

            this.damageClaim.claimDetails.driverDetails.howLongHasHeBeenWorking = "";

        }

        if(name === 'doesDriverOwnsVehicle'){

            this.damageClaim.claimDetails.driverDetails.nameOfInsurer = "";
            this.damageClaim.claimDetails.driverDetails.vehiclePolicyNumber = "";

        }

        if (name === 'DoesDriverOwnsVehicle' && value === 'false'){

                this.damageClaim.claimDetails.driverDetails.nameOfInsurer = "";
                this.damageClaim.claimDetails.driverDetails.vehiclePolicyNumber = "";

        }

        if (name === 'driverHadPreviousAccidents' && value === 'false'){

            this.damageClaim.claimDetails.driverDetails.dateOfAccident = '';
        }

        localStorage.setItem('claim', JSON.stringify(this.damageClaim));

    }

    onDriverPermissionRadioChange(event){

        let name = event.source.name;
        let value = event.value;
        this.theftRecoveryClaim.claimDetails.driverDetails[name] = value;

        if(name === 'driverHavePermission'){

            this.theftRecoveryClaim.claimDetails.driverDetails.driverHavePermission = value;

        }

        localStorage.setItem('claim', JSON.stringify(this.theftRecoveryClaim));

    }

    onDateChange(event) {

        let name = event.targetElement.name;
        let id = event.targetElement.id;
        let value = event.value;
        let key = event.targetElement.dataset.key;
        let selectedDate = new Date(value);
        let selectedDay = selectedDate.getDate();
        let selectedDayString = selectedDay.toString().padStart(2, "0");
        let selectedMonth = selectedDate.getMonth() + 1;
        let selectedMonthString = selectedMonth.toString().padStart(2, "0");
        let selectedYear = selectedDate.getFullYear();
        let date = selectedYear+"-"+selectedMonthString+"-"+selectedDayString;
        let date2 = selectedYear+"/"+selectedMonthString+"/"+selectedDayString;
        let theDate = selectedDay + "/" + selectedMonth + "/" + selectedYear;

        this.eventDate = date;
        this.reportDate = date;
        this.inspectionDate = date;

        if(key){

            switch(key){
                case 'claimDetails' :

                    if(name === "date" || name === "time"){

                        this.damageClaim.claimDetails["dateTime"] = this.eventDate + " " + this.eventTime;
                        this.damageClaim.claimDetails["date"] = date;

                        localStorage.setItem('eventDate', this.eventDate);

                    } else if ( name === "inspectionDate") {

                        this.damageClaim.claimDetails[name] = this.inspectionDate + " " + this.inspectionTime;
                        this.damageClaim.claimDetails[id] = date;
                        localStorage.setItem('inspectionDate', this.inspectionDate);

                    } else{

                        this.damageClaim.claimDetails[name] = date;
                        localStorage.setItem('eventDate', this.eventDate);

                    }

                    localStorage.setItem('claim', JSON.stringify(this.damageClaim));

                break;

                case 'driverDetails' :

                    this.damageClaim.claimDetails.driverDetails[name] = date;

                    localStorage.setItem('claim', JSON.stringify(this.damageClaim));

                break;

                case 'windscreenDamage' :

                    this.windscreenDamageClaim.claimDetails.dateTime = this.eventDate + " " + this.eventTime;
                    this.windscreenDamageClaim.claimDetails.date = date;


                  localStorage.setItem('claim', JSON.stringify(this.windscreenDamageClaim));
                  localStorage.setItem('eventDate', this.eventDate);

                break;

                case 'theftVehicleParts' :

                this.theftPartsClaim.claimDetails[name] = this.eventDate + " " + this.eventTime;
                this.theftPartsClaim.claimDetails[id] = date;

                localStorage.setItem('claim', JSON.stringify(this.theftPartsClaim));
                localStorage.setItem('eventDate', this.eventDate);

                break;

                case 'totaltheftVehicle' :

                    this.totalTheftClaim.claimDetails["dateTime"] = this.eventDate + " " + this.eventTime;
                    this.totalTheftClaim.claimDetails[id] = date;

                    if(name === "dateOfPurchase"){

                        this.totalTheftClaim.claimDetails.dateOfPurchase = date;

                    }

                    localStorage.setItem('claim', JSON.stringify(this.totalTheftClaim));
                    // localStorage.setItem("eventTime",this.eventTime);
                    localStorage.setItem('eventDate', this.eventDate);

                break;

                case 'theftReportDetails' :

                    this.totalTheftClaim.claimDetails.theftReportDetails[name] = this.eventDate + " " + this.eventTime;
                    this.totalTheftClaim.claimDetails.theftReportDetails[id] = date;

                    localStorage.setItem('claim', JSON.stringify(this.totalTheftClaim));
                    localStorage.setItem("eventTime",this.eventTime);
                    localStorage.setItem('eventDate', this.eventDate);

                break;

                case 'theftRecoveryVehicle' :

                if(name === "date"){

                    this.theftRecoveryClaim.claimDetails.dateTime = this.eventDate + " " + this.eventTime;
                    this.theftRecoveryClaim.claimDetails.date = date;

                    localStorage.setItem('eventDate', this.eventDate);

                } else if (name === "dateOfRecovery"){

                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.dateOfRecovery = date;

                }
                // else if (name === "dateOfPurchase"){

                //     this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems.dateOfPurchase = date;

                // }
                else if (name === "theftReportDate"){

                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.theftReportedDateTime = this.reportDate + " " + this.reportTime;
                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.theftReportedDate = date;

                }

                localStorage.setItem('claim', JSON.stringify(this.theftRecoveryClaim));

                break;

            }
        }


    }

    onTimeChange(event) {

        let name = event.target.name;
        let value = event.target.value;

        this.eventTime = value;
        this.inspectionTime = value;
        this.reportTime = value;

        switch (this.selectedClaim) {

            case 'windowDamage':

                this.windscreenDamageClaim.claimDetails[name] = this.eventDate + " " + this.eventTime;

                localStorage.setItem('claim', JSON.stringify(this.windscreenDamageClaim));

                localStorage.setItem("eventTime", this.eventTime);

                break;

            case 'damageVehicle':

                if(name === "inspectionDateTime"){

                    this.damageClaim.claimDetails[name] = this.inspectionDate + " " + this.inspectionTime;
                    localStorage.setItem("inspectionTime", this.inspectionTime);

                } else {

                    this.damageClaim.claimDetails[name] = this.eventDate + " " + this.eventTime;
                    localStorage.setItem("eventTime", this.eventTime);
                }

                localStorage.setItem('claim', JSON.stringify(this.damageClaim));

                break;

            case 'theftRecoveryVehicle':

                if( name === "theftReportedDateTime" ){

                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.theftReportedDateTime = this.reportDate + " " + this.reportTime;
                    localStorage.setItem("reportTime", this.reportTime);
                    localStorage.setItem("reportDate", this.reportDate);

                } else {
                    this.theftRecoveryClaim.claimDetails[name] = this.eventDate + " " + this.eventTime;
                    localStorage.setItem("eventTime", this.eventTime);

                }

                localStorage.setItem('claim', JSON.stringify(this.theftRecoveryClaim));

                break;

            case 'totaltheftVehicle' :

                if( name === "theftReportedDateTime"){

                    this.totalTheftClaim.claimDetails.theftReportDetails.theftReportedDateTime = this.eventDate + " " + this.eventTime;

                } else {

                    this.totalTheftClaim.claimDetails.dateTime = this.eventDate + " " + this.eventTime;

                }

                localStorage.setItem('claim', JSON.stringify(this.totalTheftClaim));

                break;

            case 'theftVehicleParts' :

                this.theftPartsClaim.claimDetails[name] = this.eventDate + " " + this.eventTime;

                localStorage.setItem('claim', JSON.stringify(this.theftPartsClaim));

                localStorage.setItem("eventTime", this.eventTime);

                break;
        }



        // localStorage.setItem("eventDate",this.eventDate);
        //let selectedDate = new Date(value);
        //let selectedHour = selectedDate.getHours() + 1;
        //let selectedMinutes = selectedDate.getMinutes();
        //let theTime = selectedHour + ":" + selectedMinutes;


    }

    addAnotherAccident(){

        const dateOfAccidentData = new DateOfAccident();


    }

    removeAccident(i){

    }

    addPassenger(){

        const passengerRef = new PassangersInVehicle();

        this.passengersArray.push(passengerRef);
        this.damageClaim.claimDetails.passangersInVehicle = this.passengersArray;
        this.damageClaim.claimDetails.noOfPassangesInVahicle = this.passengersArray.length;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));

    }

    addPassengers(number){

        this.passengersArray = [];

        for (let i = 0; i < number; i++) {

            this.passengersArray.push(this.passengersInVehicle);

        }

        this.damageClaim.claimDetails.passangersInVehicle = this.passengersArray;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));
    }

    removePassenger(i){

        this.passengersArray.splice(i,1);
        this.damageClaim.claimDetails.passangersInVehicle = this.passengersArray;
        this.damageClaim.claimDetails.noOfPassangesInVahicle = this.passengersArray.length;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));

    }

    onPassengerChange(event,arrayIndex){

        let id = event.target.id;
        let value = event.target.value;
        let index = arrayIndex+1;
        let key = event.target.dataset.key;
        let replacement = "passenger"+index;
        let formattedName = id.replace(replacement, "");

        this.passengersArray[arrayIndex][formattedName] = value;
        this.damageClaim.claimDetails.passangersInVehicle = this.passengersArray;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));

    }

    addInjuredPerson(){

        const injuredPassengersData = new InjuredPassangers();

        if (this.injuredPassengersArray.length < 5) {

            this.injuredPassengersArray.push(injuredPassengersData);

            this.damageClaim.claimDetails.injuredPassangers = this.injuredPassengersArray;

        }

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));

    }

    removeInjuredPerson(i){

        this.injuredPassengersArray.splice(i,1);
        this.damageClaim.claimDetails.injuredPassangers = this.injuredPassengersArray;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));
    }

    onInjuredPassengerChange(event,arrayIndex){

        let id = event.target.id;
        let value = event.target.value;
        let index = arrayIndex+1;
        let key = event.target.dataset.key;
        let replacement = "injured" + index;
        let formattedName = id.replace(replacement, "");

        if(formattedName === "vehicleNo"){

            this.injuredPassengersArray[arrayIndex][formattedName] = value.toString().replace(/\s/g, "").toUpperCase();

        }else{

            this.injuredPassengersArray[arrayIndex][formattedName] = value;

        }

        this.damageClaim.claimDetails.injuredPassangers = this.injuredPassengersArray;

        localStorage.setItem("claim", JSON.stringify(this.damageClaim));

    }

    addAnotherCar(){

        const damagedVehiclesData = new DamagedVehicles();

        this.damagedVehiclesArray.push(damagedVehiclesData);
        this.damageClaim.claimDetails.damagedVehicles = this.damagedVehiclesArray;

        localStorage.setItem("claim", JSON.stringify(this.damageClaim));

    }

    removeAnotherCar(i){

        this.damagedVehiclesArray.splice(i, 1);
        this.damageClaim.claimDetails.damagedVehicles = this.damagedVehiclesArray;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));

    }

    onOtherVehicleDamageChange(event,arrayIndex){

        let id = event.target.id;
        let value = event.target.value;
        let index = arrayIndex+1;
        let key = event.target.dataset.key;
        let replacement = "damageToVehicle"+index;
        let formattedName = id.replace(replacement, "");

        if(formattedName === "carRegistrationNo"){

            this.damagedVehiclesArray[arrayIndex][formattedName] = value.toString().replace(/\s/g, "").toUpperCase();

        }else{

            this.damagedVehiclesArray[arrayIndex][formattedName] = value;

        }


        this.damageClaim.claimDetails.damagedVehicles = this.damagedVehiclesArray;

        localStorage.setItem("claim", JSON.stringify(this.damageClaim));


    }

    addProperty(){

        const damagedPropertiesData = new PropertiesDamaged();



        this.propertiesDamagedArray.push(damagedPropertiesData);
        this.damageClaim.claimDetails.propertiesDamaged = this.propertiesDamagedArray;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));

    }

    removeProperty(i){

        this.propertiesDamagedArray.splice(i,1);
        this.damageClaim.claimDetails.propertiesDamaged = this.propertiesDamagedArray;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));

    }

    onPropertyDamageChange(event, arrayIndex){

        let id = event.target.id;
        let value = event.target.value;
        let index = arrayIndex + 1;
        let key = event.target.dataset.key;
        let replacement = "damageToProperty"+index;
        let formattedName = id.replace(replacement, "");

        this.propertiesDamagedArray[arrayIndex][formattedName] = value;
        this.damageClaim.claimDetails.propertiesDamaged = this.propertiesDamagedArray;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));


    }

    addAnotherWitness(){

        const witnessData = new IndependentWitnesses();

        this.independentWitnessesArray.push(witnessData);
        this.damageClaim.claimDetails.independentWitnesses = this.independentWitnessesArray;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));

    }

    removeWitness(i){

        this.independentWitnessesArray.splice(i,1);
        this.damageClaim.claimDetails.independentWitnesses = this.independentWitnessesArray;

        localStorage.setItem("claim",JSON.stringify(this.damageClaim));


    }

    onWitnessChange(event,arrayIndex){

        let id = event.target.id;
        let value = event.target.value;
        let index = arrayIndex+1;
        let key = event.target.dataset.key;
        let replacement = "witness"+index;
        let formattedName = id.replace(replacement, "");

        this.independentWitnessesArray[arrayIndex][formattedName] = value;
        this.damageClaim.claimDetails.independentWitnesses = this.independentWitnessesArray;

        localStorage.setItem("claim", JSON.stringify(this.damageClaim));

    }

    addAnotherRepair(){

        const repairData = new TheftPartsReplacedItems();

        this.theftRepairsArray.push(repairData);
        this.theftPartsClaim.claimDetails.replacedItems = this.theftRepairsArray;

        localStorage.setItem("claim",JSON.stringify(this.theftPartsClaim));

    }

    addAnotherReplacedItem(){

        const replacedItemData = new TheftRecoveryReplacedItems();

        this.theftReplacedItemsArray.push(replacedItemData);
        this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems = this.theftReplacedItemsArray;

        localStorage.setItem("claim",JSON.stringify(this.theftRecoveryClaim));

    }

    removeRepair(i){

        this.theftRepairsArray.splice(i,1);
        this.theftPartsClaim.claimDetails.replacedItems = this.theftRepairsArray;

        localStorage.setItem("claim",JSON.stringify(this.theftPartsClaim));


    }

    removeReplacedItem(i){

        this.theftReplacedItemsArray.splice(i,1);
        this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems = this.theftReplacedItemsArray;

        localStorage.setItem("claim", JSON.stringify(this.theftRecoveryClaim));
    }

    onRepairChange(event,arrayIndex){

        let id = event.target.id;
        let value = event.target.value;
        let index = arrayIndex+1;
        let key = event.target.dataset.key;
        let replacement = "repair"+index;
        let formattedName = id.replace(replacement, "");

        this.theftRepairsArray[arrayIndex][formattedName] = value;
        this.theftPartsClaim.claimDetails.replacedItems = this.theftRepairsArray;

        localStorage.setItem("claim", JSON.stringify(this.theftPartsClaim));

    }

    calculateTotalCost(){

        this.totalRepairsCost = 0;

        switch(this.selectedClaim) {

            case 'theftVehicleParts':

                for ( let i = 0; i<this.theftPartsClaim.claimDetails.replacedItems.length; i++){

                    this.totalRepairsCost += parseInt(this.theftPartsClaim.claimDetails.replacedItems[i]['estimatedCost']);

                }

                break;

            case 'theftRecoveryVehicle':

                for ( let i = 0; i<this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems.length; i++){

                    this.totalRepairsCost += parseInt(this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems[i]['estimatedCost']);

                }

                break;
        }


    }

    onReplacedItemChange(event,arrayIndex){

        let id = event.target.id;
        let value = event.target.value;
        let index = arrayIndex+1;
        let key = event.target.dataset.key;
        let replacement = "replacement"+index;
        let formattedName = id.replace(replacement, "");

        this.theftReplacedItemsArray[arrayIndex][formattedName] = value;

        this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems = this.theftReplacedItemsArray;

        localStorage.setItem("claim", JSON.stringify(this.theftRecoveryClaim));

    }

    onDatePurchaseChange(event, arrayIndex){

        //let id = event.target.id;
        let value = event.target.value;
        let index = arrayIndex+1;
        //let key = event.target.dataset.key;
        //let replacement = "replacement"+index;
        //let formattedName = id.replace(replacement, "");
        let selectedDate = new Date(value);
        let selectedDay = selectedDate.getDate();
        let selectedDayString = selectedDay.toString().padStart(2, "0");
        let selectedMonth = selectedDate.getMonth() + 1;
        let selectedMonthString = selectedMonth.toString().padStart(2, "0");
        let selectedYear = selectedDate.getFullYear();
        let date = selectedYear+"-"+selectedMonthString+"-"+selectedDayString;

        this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems.dateOfPurchase = date;
        localStorage.setItem('claim', JSON.stringify(this.theftRecoveryClaim));
    }


    // onAutocompletePoliceStation(result: PlaceResult) {

    //     this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.policeStation = result.name;
    //     localStorage.setItem("claim", JSON.stringify(this.theftRecoveryClaim));

    // }


    // onAutocompleteLocation(result: PlaceResult) {

    //     this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.placeOfInspection = result.name;
    //     localStorage.setItem("claim", JSON.stringify(this.theftRecoveryClaim));

    // }

    onAutocompleteSelected(result: PlaceResult, key) {

        switch (this.selectedClaim) {

            case 'damageVehicle':

                if( key === "damageLocation"){

                    this.damageClaim.claimDetails.damageLocation = result.name;

                } else if (key === "inspectionLocation"){

                    this.damageClaim.claimDetails.inspectionLocation = result.name;

                } else if (key === "policeStationLocation"){

                    this.damageClaim.claimDetails.policeSTation = result.name;

                }

                break;

            case 'windowDamage':

                if( key === "windscreenDamageLocation"){

                    this.windscreenDamageClaim.claimDetails.damagePlace = result.name;

                }


                break;

            case 'theftVehicleParts':

                if( key === "theftVehicleLocation"){

                    this.theftPartsClaim.claimDetails.theftPlace = result.name;

                }


                break;

            case 'totaltheftVehicle':

                 if ( key === "totalTheftPoliceStation"){

                    this.totalTheftClaim.claimDetails.theftReportDetails.policeStation = result.name;

                } else if ( key === "totaltheftLocation"){

                    this.totalTheftClaim.claimDetails.totalTheftLocation = result.name;

                }


                break;

            case 'theftRecoveryVehicle':

                if(key === "theftLocation"){

                    this.theftRecoveryClaim.claimDetails.theftLocation = result.name;

                } else if (key === "vehicleInspectionLocation"){

                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.placeOfInspection = result.name;

                    localStorage.setItem("claim", JSON.stringify(this.theftRecoveryClaim));

                } else if (key === "policeStationLocation"){

                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.policeStation = result.name;

                    localStorage.setItem("claim", JSON.stringify(this.theftRecoveryClaim));

                } else if (key === "locationRecovery"){

                    this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.placeOfRecovery = result.name;

                    localStorage.setItem("claim", JSON.stringify(this.theftRecoveryClaim));

                }


                break;

        }



        //this.damageClaim.claimDetails.autoCompleteLocation = result.name;

        //this.windscreenDamageClaim.claimDetails.damagePlace = result.name;
        //this.theftPartsClaim.claimDetails.theftPlace = result.name;
        //this.theftRecoveryClaim.claimDetails.recoveryPlace = result.name;

    }


    // onAutocompleteRecovery(result: PlaceResult) {

    //     this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.placeOfRecovery = result.name;

    // }

    onLocationSelected(location: Location,key) {

        if(key === "claimDetails"){

            this.damageClaim.claimDetails.placeLatitude = location.latitude.toString();
            this.damageClaim.claimDetails.placeLongitude = location.longitude.toString();

            localStorage.setItem("claim", JSON.stringify(this.damageClaim));

        }else if(key ==='windscreenDamage'){

            this.windscreenDamageClaim.claimDetails.placeLatitude = location.latitude.toString();
            this.windscreenDamageClaim.claimDetails.placeLongitude = location.longitude.toString();

            localStorage.setItem("claim", JSON.stringify(this.windscreenDamageClaim));

        }else if(key === 'theftVehicleParts'){

            this.theftPartsClaim.claimDetails.placeLatitude = location.latitude.toString();
            this.theftPartsClaim.claimDetails.placeLongitude = location.longitude.toString();

            localStorage.setItem("claim", JSON.stringify(this.theftPartsClaim));

        } else if (key === 'totaltheftVehicle'){
            this.totalTheftClaim.claimDetails.placeLatitude = location.latitude.toString();
            this.totalTheftClaim.claimDetails.placeLongitude = location.longitude.toString();

            localStorage.setItem("claim", JSON.stringify(this.totalTheftClaim));

        } else if (key === 'theftLocation'){
            this.theftRecoveryClaim.claimDetails.placeLatitude = location.latitude.toString();
            this.theftRecoveryClaim.claimDetails.placeLongitude = location.longitude.toString();

            localStorage.setItem("claim", JSON.stringify(this.theftRecoveryClaim));

        } else if (key === 'locationRecovery'){

            this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.placeOfRecoveryLatitude = location.latitude.toString();
            this.theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.placeOfRecoveryLongitude = location.longitude.toString();

            localStorage.setItem("claim", JSON.stringify(this.theftRecoveryClaim));

        } else if (key === 'damageClaim'){

            this.damageClaim.claimDetails.inspectionPlaceLatitude = location.latitude.toString();
            this.damageClaim.claimDetails.inspectionPlaceLongitude = location.longitude.toString();

            localStorage.setItem("claim", JSON.stringify(this.damageClaim));

        } else if (key === 'policeStationLocation'){

            this.damageClaim.claimDetails.policeStationLongitudeString = location.longitude.toString();
            this.damageClaim.claimDetails.policeStationLattitudeString = location.latitude.toString();

            localStorage.setItem("claim", JSON.stringify(this.damageClaim));

        }

        // else if (key === 'theftLocation'){

        //     this.theftRecoveryClaim.claimDetails.placeLatitude = location.latitude.toString();
        //     this.theftRecoveryClaim.claimDetails.placeLongitude = location.longitude.toString();

        //     localStorage.setItem("claim", JSON.stringify(this.theftRecoveryClaim));

        // }

    }

    onFileChange(event,form?) {

        const originalLabel = $(event.target).next("label").find("p").text();

        if (event.target.files.length > 0) {

            const formDataMultiple = new FormData();

            for  (var i =  0; i <  event.target.files.length; i++)  {

                let fileName = event.target.files[i].name;
                let fieldName = event.target.name;
                let fileType = event.target.files[i].type;
                let documentType = event.target.files[i].type;
                let extensionType = event.target.dataset.extension;
                let allowedExtensions = this.selectValidator(extensionType);

                // Get allowed extension as document,images,videos or documentType
                // case image allow image extensions
                //


                if(allowedExtensions.exec(fileName)){

                    let fileReference = new DamageClaimOtherDocuments();

                    fileReference.type = fileType;
                    fileReference.file = documentType;
                    fileReference.name = fieldName+"-"+fileName;

                    //console.log(this.selectedClaim);

                    // Set a switch case here
                    switch(this.selectedClaim){

                        case 'damageVehicle':

                            this.damageClaim.claimDetails.otherDocuments.push(fileReference);
                            this.uploadFiles.push(event.target.files[i]);
                            formDataMultiple.append("file",event.target.files[i], event.target.files[i].name);


                        break;

                        case 'windowDamage':

                            this.windscreenDamageClaim.claimDetails.otherDocuments.push(fileReference);
                            this.uploadFiles.push(event.target.files[i]);
                            formDataMultiple.append("file",event.target.files[i], event.target.files[i].name);

                        break;

                        case 'theftVehicleParts':

                            this.theftPartsClaim.claimDetails.otherDocuments.push(fileReference);
                            this.uploadFiles.push(event.target.files[i]);
                            formDataMultiple.append("file",event.target.files[i], event.target.files[i].name);

                        break;


                        case 'totaltheftVehicle':

                            this.totalTheftClaim.claimDetails.otherDocuments.push(fileReference);
                            this.uploadFiles.push(event.target.files[i]);
                            formDataMultiple.append("file",event.target.files[i], event.target.files[i].name);


                        break;

                        case 'theftRecoveryVehicle':

                            this.theftRecoveryClaim.claimDetails.otherDocuments.push(fileReference);
                            this.uploadFiles.push(event.target.files[i]);
                            formDataMultiple.append("file",event.target.files[i], event.target.files[i].name);

                        break;

                    }

                    // createFormData here and set it to uploadDocuments Key in root Key



                }else{

                    $(event.target).next("label").find("p").text(fileName+" contains invalid extension");

                    setTimeout(() => {

                        form.reset();
                        $(event.target).next("label").find("p").text("Add files");

                    }, 2000);

                }

            }

            if(event.target.files.length === 1){

                $(event.target).next("label").find("p").text(event.target.files[0].name);

            }else{

                $(event.target).next("label").find("p").text(event.target.files.length + "files selected");

            }

            // Set a switch case here
            switch(this.selectedClaim){

                case 'damageVehicle':

                    localStorage.setItem("claim",JSON.stringify(this.damageClaim));

                    break;

                case 'windowDamage':

                    localStorage.setItem("claim",JSON.stringify(this.windscreenDamageClaim));

                    break;

                case 'theftVehicleParts':

                    localStorage.setItem("claim",JSON.stringify(this.theftPartsClaim));

                    break;

                case 'theftRecoveryVehicle':

                    localStorage.setItem("claim",JSON.stringify(this.theftRecoveryClaim));

                    break;

                case 'totaltheftVehicle':

                    localStorage.setItem("claim",JSON.stringify(this.totalTheftClaim));

                    break;
            }


        } else {

            // Delete from array

            $(event.target).next("label").find("p").text("Add files");
            form.reset();

        }

    }

    setFormMinHeight(){

        let activeClaim = "."+this.selectedClaim;

        $(".claimType .multistep").css({
            "min-height":$(activeClaim+" .summaryDetails").outerHeight()
        });

    }

    selectValidator(pattern){

        switch (pattern) {

            case 'document':

                return /(\.pdf)$/i;

                break;

            case 'image':

                return /(\.jpg|\.jpeg|\.png|\.gif)$/i;

                break;

            case 'multimedia':

                return /(\.jpg|\.jpeg|\.png|\.gif|\.pdf|)$/i;

                break;

            case 'video':

                return /(\.mp4|\.mov|\.wav|\.gif)$/i;

                break;

            case 'audio':

                return /(\.mp4|\.mp3|\.m4a|\.aa)$/i;

                break;

            default:

                return /(\.pdf)$/i;

                break;
        }

    }

    submitClaim(claimData:any){

        this.loading = true;
        this.processorDescription = "Step 1/2 submitting claim documents";

        this.handleUpload("claim",claimData);


    }

    handleUpload(type, claimData){

        if(this.uploadFiles.length > 0 || this.selectedClaim === "theftVehicleParts" ){

            // this.uploadFiles=[];

            this.fileUpload.uploadMultipleFiles(this.uploadFiles,type).subscribe(response =>{


                if(response["success"]){

                    this.processorDescription = "Step 2/2 submitting claim details";


                    this.uploadRef = response["data"]["docUploadRef"];
                    claimData.claimDetails.docUploadRef = this.uploadRef;


                    // this.policyData.policyDetails.docUploadRef = this.uploadRef;

                    this.claimService.createClaim(claimData).subscribe(response => {

                        if(response["success"]){

                            this.loading = false;

                            this.modalTitle = "Claim Submitted Successfully";
                            this.modalDescription = response["message"];
                            this.showModal = true;
                            this.modalLink = "/userprofile/thankyouclaim";
                            this.modalLinkText = "Proceed";
                            $(".modal").fadeIn();

                        }else{

                            this.loading = false;

                            this.modalTitle = "Claim Error";
                            this.modalDescription = response["message"];
                            this.showModal = true;
                            $(".modal").fadeIn();

                        }

                    },error => {

                        this.loading = false;
                        this.modalTitle = " Error";
                        if (error.error.message != null && error.error.message != "") {
                            this.modalDescription = error.error.message;
                        } else {
                            this.modalDescription = "There was an error processing your claim, please try again";
                        }
                        this.showModal = true;
                        $(".modal").fadeIn();


                    });


                }else{

                    this.modalTitle = "Error uploading files";
                    this.modalDescription = "There was an error uploading your files";
                    this.modalLink = '/';
                    this.showModal = true;
                    this.modalType="info";
                    $(".modal").fadeIn();
                    this.loading = false;

                }

                this.loading = false;

            },error =>{

                this.loading = false;

                this.modalTitle = " Error";
                this.modalDescription = "There was an error processing your claim, please try again";
                this.showModal = true;
                $(".modal").fadeIn();

            });

        }else{

            this.modalTitle = "No Files to upload";
            this.modalDescription = "No files were selected for upload, please upload to proceed";
            this.modalLink = null;
            this.showModal = true;
            this.modalType="info";
            $(".modal").fadeIn();

            switch (this.selectedClaim) {

                case 'damageVehicle':

                    this.handleProgression('aboutAccident');

                    break;

                case 'windowDamage':

                    this.handleProgression('aboutWindowDamage');

                    break;
            }



            this.loading = false;

            // this.handleProgression("otherDetails");

        }


    }



}
