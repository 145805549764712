import { Component, OnInit } from '@angular/core';

import {ContentManagementService} from './../../_services/content-management.service';

import $ from 'jquery';

import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-contactus',
    templateUrl: './contactus.component.html',
    styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

    siteImages:any = [];
    loading:boolean = false;
    statusMessage:string = "";
    errorStatus:boolean = false;

    constructor(
        private contentManagement:ContentManagementService,
        private titleService: Title,
        private metaService:Meta,
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle("Insure Me - Contact Us");
        this.metaService.updateTag(
            { name: 'keywords', content: 'InsureMe Contact Us, Contact InsureMe, InsureMe Contact Details, InsureMe Phone Number, InsureMe Email, InsureMe WhatsApp Number, InsureMe Customer Support, InsureMe Customer Care, What is InsureMe?, '
            }
        );

        this.metaService.updateTag(
            { name: 'description', content: 'Get in Touch With InsureMe Customer Support for All Your Car insurance Queries. We’d Love to Hear From You Today. We Are Live 24/7.'
            }
        );
    }

    ngAfterViewInit():void{
        this.getImages();
    }

    getImages(){

        $("img").each( (index,value) => {

            // console.log($(value).attr("src"));

            if($(value).attr("src") != "" || $(value).attr("src") == "undefined" || $(value).attr("src") == null){

                this.siteImages.push($(value).attr("src"));

            }

        });

    }

    onSubmit(contactDetails){

        this.contentManagement.submitContactDetails(contactDetails).subscribe(response =>{

            this.loading = false;
            if(response["success"]){

                this.loading = false;
                this.errorStatus = false;
                this.statusMessage = response["message"];

                setTimeout(() => {
                    window.location.reload();
                }, 2000);


            }else{

                this.loading = false;
                this.errorStatus = true;
                this.statusMessage = response["message"];

            }

        },error => {

            this.loading = false;
            this.errorStatus = true;
            this.statusMessage = "Error submitting details";


        });

    }

}
