import { Component, OnInit } from '@angular/core';

import {ContentManagementService} from '../../_services/content-management.service';

@Component({
    selector: 'app-aboutproduct',
    templateUrl: './aboutproduct.component.html',
    styleUrls: ['./aboutproduct.component.css'],
    providers:[
        ContentManagementService
    ],
})
export class AboutproductComponent implements OnInit {

    aboutDescription:any = [];

    constructor(
        private cmsService:ContentManagementService
    ) { }

    ngOnInit(): void {

        this.cmsService.getAllFaq().subscribe(response =>{

            if(response != "" || response != null){

                this.aboutDescription = response;

            }



        });


    }

}
