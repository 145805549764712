import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

import {CustomerService} from '../../_services/customer.service';

import {AuthenticationService } from '../../_services';
import Utils from '../../utils/utils';

import $ from 'jquery';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers:[
        CustomerService
    ]
})
export class HomeComponent implements OnInit {


    userProfile:any;
    siteImages:any = [];
    pageLoaded:boolean;
    sliderImages:any = [];

    isLogin:boolean = false;

    constructor(
        private route: ActivatedRoute,
        private customerService:CustomerService,
        private authenticationService: AuthenticationService,
        private titleService: Title,
        private metaService:Meta,
    ){

    }

    ngOnInit(): void {

        this.isLogin = this.authenticationService.userLoggedIn();
        this.pageLoaded = false;
        this.sliderImages = [
            "./assets/_img/slider/1.jpg",
            "./assets/_img/slider/2.jpg",
            "./assets/_img/slider/3.jpg",
        ]

        this.titleService.setTitle("Dapper Jd");
        this.metaService.updateTag(
            { name: 'keywords', content: 'John Akello, Creativity, Creative Thinking'
            }
        );


    }

    ngAfterViewInit():void{

        //this.getImages();

       

    }

    getImages(){

        $("img").each( (index,value) => {


            if($(value).attr("src") != "" || $(value).attr("src") == "undefined" || $(value).attr("src") == null){

                this.siteImages.push($(value).attr("src"));

            }

        });

    }

    showPage(loaded:boolean){

        this.pageLoaded = loaded;


    }

}
