import { Component, OnInit } from '@angular/core';
import { faMale,faArrowAltCircleRight,faFemale,faPlus,faUserShield,faChild } from '@fortawesome/free-solid-svg-icons';

import {FooterComponent} from '../footer/footer.component';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

    spouse:[];
    children:[];
    nextOfKin:[];

    constructor(
        library: FaIconLibrary
    ) {

        library.addIcons(faMale, faArrowAltCircleRight,faFemale,faPlus,faUserShield,faChild);

    }

    ngOnInit(): void {
    }

}
