import { TheftRecoveryDriverDetails } from "../claims/TheftRecoveryDriverDetails.model";
import { TheftRecoveryVehicleDetails } from './TheftRecoveryVehicleDetails.model';

export class TheftRecoveryClaim{

  customerId:number = 1;
  policyNumber:string = "";
  claimType:string = "";
  date: string = "";
  dateTime:string = "";
  docUploadRef:string="";
  placeLatitude:string = "";
  placeLongitude:string= "";
  theftLocation:string = "";
  driver:string = "self";
  otherDocuments:any = [];
  driverDetails:TheftRecoveryDriverDetails = new TheftRecoveryDriverDetails();
  vehicleDetailsAfterRecovery:TheftRecoveryVehicleDetails = new TheftRecoveryVehicleDetails();
}
