<div
    class="claimSummary {{type}}"
    (click)="toggleDetail($event)"
    (mouseenter)="showDetails($event)"
    (mouseleave)="hideDetails($event)"
    >

    <div class="numberOfClaims">


        <img
            class="claimIcon"
            src="{{image}}"
            alt="">

    </div>


    <h4>{{title}}</h4>

    <!-- <a href="#" class="smallReadMore">
        Read More

        <fa-icon
            icon="arrow-circle-right"
        ></fa-icon>
    </a> -->

    <article class="desription">
        <p>{{description}}</p>
    </article>
</div>
