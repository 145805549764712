import { DamageClaimOtherDocuments } from './DamageClaimOtherDocuments.model';
import { DamageClaimDriverDetails } from "../claims/DamageClaimDriverDetails.model";

export class DamageClaim{

  customerId:number = 0;
  policyNumber:string = "";
  claimType:string = "";
  date:string = "";
  dateTime:string = "";
  placeLatitude:string = "";
  placeLongitude:string= "";
  damageLocation:string = "";
  purpose:string = "";
  docUploadRef:string="";
  roadSurface:string = "";
  visibilty:string = "";
  weatherCondition:string = "";
  estematedSpeed:string = "";
  wereLightsOn:string = "false";
  driver:string = "self";
  driverDetails:DamageClaimDriverDetails = new DamageClaimDriverDetails();
  werePassangesInVehicle:string = "false";
  noOfPassangesInVahicle:number = 2;
  passangersInVehicle:any = [];
  wasAnyoneInjured:string = "false";
  injuredPassangers:any = [];
  vehicleInsuredDamaged:string = "false";
  apparentDamageDescription:string = "";
  vehicleStillInUse:string = "false";
  inspectionPlaceLatitude:string = "";
  inspectionPlaceLongitude:string= "";
  inspectionLocation:string = "";
  inspectionDateTime:string = "";
  inspectionDate:string = "";
  otherVehicleDamaged:string = "false";
  damagedVehicles:any = [];
  wasAnyPropertyDamaged:string = "false";
  propertiesDamaged:any = [];
  haveAnyIndependentWitnesses:string = "false";
  independentWitnesses:any = [];
  reportedAccidentToPolice:string = "false";
  policeSTation:string = "";
  policeStationLongitudeString = "";
  policeStationLattitudeString= "";
  AOBNumber:string = "";
  policeAbstractDoc:string = "";
  otherDocuments:any = [];
}
