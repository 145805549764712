export class Quote{

    vehicle: string = '';
    estimatedValue: number = 0;
    year: number = 0;
	paymentType?:string = "LIPA_POLE_POLE";
    productId:number = 1;
    insuranceClassId:number = 1;
    insuranceSubClassId:number = 1;
    insurancePolicyTypeId:number = 1;

}
