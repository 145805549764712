import { BrowserModule,Title } from '@angular/platform-browser';

import { ReactiveFormsModule,FormsModule } from '@angular/forms';

import { BrowserAnimationsModule  } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {AgmCoreModule} from '@agm/core';

import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';

import {AuthGuard} from './_helpers/auth.guard';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

// View Imports
import { HomeComponent } from './views/home/home.component';
import { FaqComponent } from './views/faq/faq.component';
import { TermsandconditionsComponent } from './views/termsandconditions/termsandconditions.component';

import { ContactusComponent } from './views/contactus/contactus.component';
import { AboutproductComponent } from './views/aboutproduct/aboutproduct.component';
import { PolicysummaryComponent } from './views/policysummary/policysummary.component';
import { ThankyouComponent } from './views/thankyou/thankyou.component';

//module
import { SharedModule } from './_shared/shared.module';

import { PoliciesComponent } from './views/userprofile/policies/policies.component';
import { ProfileComponent } from './views/userprofile/profile/profile.component';

import { ClaimComponent } from './views/userprofile/claim/claim.component';
import { ThankyouclaimComponent } from './views/thankyouclaim/thankyouclaim.component';
import { SingleclaimComponent } from './views/userprofile/singleclaim/singleclaim.component';
import { EventemitterComponent } from './components/eventemitter/eventemitter.component';
import { GeneralmotorfaqComponent } from './components/generalmotorfaq/generalmotorfaq.component';
import { ClaimsfaqComponent } from './components/claimsfaq/claimsfaq.component';
import { LipapolepolefaqComponent } from './components/lipapolepolefaq/lipapolepolefaq.component';
import { PolicycancellationComponent } from './views/policycancellation/policycancellation.component';
import { LipapolepoleComponent } from './views/lipapolepole/lipapolepole.component';
import { PolepolefaqsComponent } from './views/polepolefaqs/polepolefaqs.component';
import { MotorvehiclecoverComponent } from './views/motorvehiclecover/motorvehiclecover.component';
import { LipapolepolePlanComponent } from './views/lipapolepole-plan/lipapolepole-plan.component';

import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { CancelpolicyComponent } from './views/userprofile/cancelpolicy/cancelpolicy.component';

//DynamicFormComponent,DynamicFormQuestionComponent

import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { MynameComponent } from './components/myname/myname.component';


import { DynamicFormQuestionComponent } from './components/dynamic-form-question/dynamic-form-question.component';
import { DynamictestComponent } from './views/dynamictest/dynamictest.component';
import { PersonalAccidentJourneyComponent } from './views/personal-accident-journey/personal-accident-journey.component';
import { PersonalAccidentFormComponent } from './views/personal-accident-form/personal-accident-form.component';
import { EstimateQuotationComponent } from './components/estimate-quotation/estimate-quotation.component';
import { FormArrayComponent } from './components/form-array/form-array.component';
import { AddbeneficiaryComponent } from './views/addbeneficiary/addbeneficiary.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "left",
    allowNegative: false,
    decimal: ".",
    precision: 0,
    prefix: "",
    suffix: "",
    thousands: ","
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FaqComponent,
    TermsandconditionsComponent,
    ContactusComponent,
    AboutproductComponent,
    PolicysummaryComponent,
    ThankyouComponent,
    PoliciesComponent,
    ProfileComponent,
    ClaimComponent,
    ThankyouclaimComponent,
    SingleclaimComponent,
    EventemitterComponent,
    GeneralmotorfaqComponent,
    ClaimsfaqComponent,
    LipapolepolefaqComponent,
    PolicycancellationComponent,
    LipapolepoleComponent,
    PolepolefaqsComponent,
    MotorvehiclecoverComponent,
    LipapolepolePlanComponent,
    CancelpolicyComponent,
	DynamicFormComponent,
	DynamicFormQuestionComponent,
	DynamictestComponent,
	PersonalAccidentJourneyComponent,
	PersonalAccidentFormComponent,
	EstimateQuotationComponent,
	FormArrayComponent,
	AddbeneficiaryComponent,
  MynameComponent

  ],
  imports: [
    BrowserModule,
	ReactiveFormsModule,
  FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    SharedModule,
	CurrencyMaskModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAJA-Ku2beBvsyGisvl1Au1gkbWm8iyd00',
      libraries: ['places']
    }),

  ],
  providers: [
    Title,
	{ provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
