<section class="fullWidthSlider">



    <div class="singleSlide">

        <div
            id="mainSlider"
            class="slider-pro"
            *ngIf="type == 'slider'" >

            <div class="sp-slides">
                <div
                    class="sp-slide"
                    *ngFor="let slide of sliderImages" >

                    <img
                        class="sp-image"
                        src="{{slide}}" alt="">

                </div>
            </div>


        </div>

        <img
            *ngIf="type == 'image' || type == '' "
            src="./assets/_img/home/slider1.jpg" class="mainImage" alt="">


        <div class="overlay"></div>



        <article>
            <h1>Last Expense Insurance Cover</h1>
            <p>Get Funeral Expense Cover for as Little as KES 50 a Month!</p>

        <app-readmore
        title="Get Cover Today"
        type="white"
        link="/getcover"

        ></app-readmore>

        <app-readmore
        title="Find Out More"
        type="whiteHollow"
        (click)="openDetailView()"

        ></app-readmore>

        <h3>
            <fa-icon icon="shield-alt"></fa-icon>
            Cover Options
        </h3>

        <div class="coverSummary">
            <app-claimsummary
            numberOfClaims="1"
            title="Single Claim Cover"
            type="translucent"
            

            description="Pays upon the first death of an insured person per family then cover falls due for renewal."

            ></app-claimsummary>

            <app-claimsummary
            numberOfClaims="3"
            title="Multiple Claim Cover"
            type="translucent"
            description="Pays upto the third death of an insured person per family then cover falls due for renewal."


            ></app-claimsummary>

            <app-claimsummary
            title="Claim Process"
            type="yellow"
            description="The Claim Process is "


            ></app-claimsummary>
        </div>

    </article>
</div>

<div class="coverDescription">

    <article>

        <h1> <fa-icon icon="user-shield"></fa-icon> Last Expense Cover</h1>

        <p>This insurance plan offers peace of mind to the family by paying for funeral expenses of fixed amounts on the untimely demise of any member covered.</p>

        <p>Planning for funerals can be quite strenuous to the family and requires a lump-sum of cash. This Plan gives your family the support they need to pull through the pain of loss by catering for the costs of arranging the funeral. This cover is specially tailored for any legally registered entity with 20 main members and above.</p>

        <ul>
            <h4>The cover provides cover for the following members:</h4>
            <li>Principal – member of company/association</li>
            <li>Spouse of the member</li>
            <li>Children of the member</li>
            <li>Parents of the member</li>
            <li>Parents-in-law of the member</li>
        </ul>
        <ul>
            <h4>Benefits</h4>
            <li>Efficient fund management</li>
            <li>Ease of premium payment</li>
            <li>Member coverage by default</li>
            <li>Death and disability cover</li>
            <li>No waiting period</li>
        </ul>

        <button
            (click)="closeDetailView()"
            class="readMoreBlack">
            Close
            <fa-icon
            class="arrow"

            icon="times-circle">

        </fa-icon>
    </button>

</article>
</div>

</section>
