<div class="socialNav">

    <a
        href="https://www.facebook.com/Insuremeke"
        target="_blank">

        <fa-icon
            class="socialIcon"
            [icon]="['fab', 'facebook-square']">

        </fa-icon>
    </a>

    <a
        href="https://mobile.twitter.com/InsureMe_Ke"
        target="_blank">
        <fa-icon
            class="socialIcon"
            [icon]="['fab', 'twitter-square']">

        </fa-icon>
    </a>

    <a
        href="https://www.youtube.com/channel/UC_PB8cfO4F209Nle4ht8IZw"
        target="_blank">
        <fa-icon
            class="socialIcon"
            [icon]="['fab', 'youtube']">

        </fa-icon>
    </a>

    <a
        href="https://www.instagram.com/insureme_ke/"
        target="_blank">
        <fa-icon
            class=" "
            [icon]="['fab', 'instagram']">

        </fa-icon>
    </a>
</div>
