
<!--  Overlay Modal  -->
<app-modal
	*ngIf="showModal"
	title="{{modalTitle}}"
	link="{{modalLink}}"
	(okButtonClick)="restoreEndorsementAccordion();"
	(yesButtonClick)="acknowledgeEndorsement($event)"
	(noButtonClick)="declineEndorsement($event);"
	linkText="{{modalLinkText}}"
	description="{{modalDescription}}"
	type = "{{modalType}}"
	 >
</app-modal>
<!--END  Overlay Modal  -->

<!--  Valuation Notification  -->
<div
	class="centerAligner valuationNotification"
	*ngIf="valuationSummary.length > 0" >

	<!--  Payment Module  -->
	<div class="yellowHighlight hide">

		<div class="paymentNumber">

			<button
				class="readMoreBlack back"
				(click)="restoreEndorsementAccordion();">

				Back

				<fa-icon
					icon="arrow-circle-left"
					class="arrow">

				</fa-icon>

			</button>

			<h3>Which number would you like the amount sent to?</h3>

			<label for="">Enter your Safaricom mobile number
				<span *ngIf="policyEndorsement?.endorsementAction === 'ADDITIONAL_PAYMENT' " >
					to use to make your payment?
				</span>

				<span
					*ngIf="policyEndorsement?.endorsementAction === 'REFUND' ">

					the amount will be sent to?

				</span>
			</label>

			<form action="">

				<div class="row noborder">

					<div class="three_quarter fl">

						<span class="countryHighlight">+254</span>

						<input

							type="text"
							class="phoneNumber"
							name="customerMobile"
							(keyup)="changePaymentPhoneNumber($event);"
							placeholder="Enter mobile number"
							[(ngModel)]="phoneNumber"
							#paymentMobile="ngModel"
							pattern="^(?:0)((?:7|1)[0-9]{8})$"
							required>

							<div
							class="invalid-field"
							*ngIf=" paymentMobile.invalid">

								<label
								*ngIf="paymentMobile.errors.required"
								class="invalid-field">Phone Number is required
								</label>

								<label *ngIf="paymentMobile.errors.pattern"
								class="invalid-field">Phone Number invalid</label>

							</div>

					</div>

					<div class="one_quarter fl">

						<button class="readMoreBlack"
						[disabled]="paymentMobile.invalid"
						(click)="updatePaymentPhoneNumber();approveEndorsement($event);">

							Submit

							<fa-icon
								class="arrow"
								icon="arrow-alt-circle-right">
							</fa-icon>

						</button>

					</div>

				</div>


			</form>

		</div>

	</div>
	<!--END  Payment Module  -->

	<section class="accordionContainer" *ngIf="valuationSummary.length > 0" >

		<ng-container
			*ngFor="let valuationItem of valuationSummary">

			<section
				class="accordionTitle"
				*ngIf="(valuationItem?.valuationValue != '' || valuationItem?.valuationValue > 0) && valuationItem?.endorsementComputations != null"
				 >

				 <section
				 	class="clickTarget"
				 	(click)="openAccordion($event);$event.stopPropagation();"
				 	></section>

				<h3 (click)="$event.stopPropagation();">Valuation Report - {{valuationItem?.vehicleDetails?.make | titlecase}} {{valuationItem?.vehicleDetails?.model | titlecase}} - {{valuationItem?.vehicleDetails?.registrationNo | uppercase}}</h3>

				<fa-icon

		        icon="plus-circle"
		        class="arrow closed"></fa-icon>

		        <fa-icon

		        icon="times-circle"
		        class="arrow open"></fa-icon>

			</section>

			<section
				class="accordion shadow"
				*ngIf="(valuationItem?.valuationValue != '' || valuationItem?.valuationValue > 0) && valuationItem?.endorsementComputations != null"
			>

				<section class="valuationDetails">

					<div class="row noborder">

						<p class="left">Estimate Value</p>
						<p class="right">{{valuationItem?.estimatedValue | currency : 'KES ' : 'code' :'1.0-0'}}</p>

					</div>

					<div class="row ">

						<p class="left">Previous Basic Premium</p>
						<p class="right">{{valuationItem?.endorsementComputations?.previousBasicPremium | currency : 'KES ' : 'code' :'1.0-0'}}</p>

					</div>

					<div class="row noborder">

						<p class="left">Final Value</p>
						<p class="right">{{valuationItem?.valuationValue | currency : 'KES ' : 'code' :'1.0-0'}}</p>

					</div>

					<div class="row ">

						<p class="left">New Basic Premium</p>
						<p class="right">{{valuationItem?.endorsementComputations?.newBasicPremium | currency : 'KES ' : 'code' :'1.0-0'}}</p>

					</div>

					<div class="row ">

						<p class="left">Basic Premium Difference</p>
						<p class="right">{{valuationItem?.endorsementComputations?.diffPremium | currency : 'KES ' : 'code' :'1.0-0'}}</p>

					</div>

					<div class="row noborder">

						<h3 *ngIf="valuationItem?.endorsementDetails?.endorsementAction == 'REFUND'">Refundable taxes and benefits</h3>

						<h3 *ngIf="valuationItem?.endorsementDetails?.endorsementAction == 'PAY_ADDITIONAL_AMOUNT'">Additional taxes and benefits</h3>

					</div>

					<ng-container *ngIf="valuationItem?.endorsementComputations !== null || valuationSummary?.endorsementComputations !== 'null' " >

						<div class="row">

							<p class="left">Training Levy </p>

							<p class="right">{{valuationItem?.endorsementComputations?.newTransLevy | currency : 'KES ' : 'code' :'1.0-0' }}</p>

						</div>

						<div class="row">

							<p class="left">Policy Holders Compensation Fund (PHCF)</p>

							<p class="right">
								{{valuationItem?.endorsementComputations?.newPhcf | currency : 'KES ' : 'code' :'1.0-0' }}
							</p>
						</div>

						<div class="row">

							<p class="left">Transaction Fee</p>

							<p class="right">
								{{valuationItem?.endorsementComputations?.transactionFee | currency : 'KES ' : 'code' :'1.0-0' }}
							</p>
						</div>

						<ng-container *ngIf="valuationItem?.endorsementComputations?.endorseBenefits.length > 0" >

							<ng-container class="row" *ngFor="let benefit of valuationItem?.endorsementComputations?.endorseBenefits" >

								<div class="row" *ngIf="benefit?.diffPremium > 0" >

									<p class="left">{{benefit?.name}}</p>

									<p class="right">
										{{benefit?.diffPremium | currency : 'KES ' : 'code' :'1.0-0' }}
									</p>

								</div>


							</ng-container>

						</ng-container>

					</ng-container>

					<div class="row"
						*ngIf="valuationItem?.endorsementDetails?.endorsementAction == 'REFUND' || valuationItem?.endorsementDetails?.endorsementAction == 'PAY_ADDITIONAL_AMOUNT'">

						<h3 class="left" *ngIf="valuationItem?.endorsementDetails?.endorsementAction == 'REFUND'" >Refundable Amount</h3>

						<h3 class="left" *ngIf="valuationItem?.endorsementDetails?.endorsementAction == 'PAY_ADDITIONAL_AMOUNT'" >Premium Top Up Amount</h3>

						<h3 class="right">{{valuationItem?.endorsementDetails?.endorsementAmount | currency : 'KES ' : 'code' :'1.0-0'}}</h3>

					</div>

					<div class="row noborder buttons">

						<button
							class="readMoreBlack"
							(click)="endorsePolicy(valuationItem);"
							*ngIf="valuationItem?.endorsementDetails?.endorsementAction == 'REFUND'">

							Get Refund

							<fa-icon
			                    class="arrow yellow"
			                    icon="arrow-circle-right"
			                    ></fa-icon>

						</button>

						<button
							class="readMoreBlack"
							(click)="endorsePolicy(valuationItem);"
							*ngIf="valuationItem?.endorsementDetails?.endorsementAction == 'PAY_ADDITIONAL_AMOUNT'">

							Make Payment

							<fa-icon
			                    class="arrow yellow"
			                    icon="arrow-circle-right"
			                    ></fa-icon>

						</button>

						<button
						 	class="readMoreBlack"
							(click)="proceedWithCurrentEndorsement(valuationItem)">

							Continue With Estimate Value

							<fa-icon
			                    class="arrow yellow"
			                    icon="arrow-circle-right"
			                    ></fa-icon>

						</button>

						<!--  Download Valution Button  -->
					    <a
							class="readMoreBlack downloadDocuments"
							[href]="valuationItem?.valuationReportURL"
							*ngIf="valuationItem?.valuationReportURL !== ''"
							target="_blank"
							download >

					        Download Valuation Report

					        <fa-icon
					            icon="file-pdf"
					            class="arrow"
					            ></fa-icon>

					    </a>
					    <!--END  Download Valution Button  -->
					</div>



				</section>

			</section>

		</ng-container>

	</section>

</div>
<!--END  Valuation Notification  -->

<div class="centerAligner rounded profileContainer">

	<!--  MY POLICIES CONTAINER  -->

    <div class="half myPolicies">

        <h2>My Policies</h2>

		<app-processor *ngIf="loadingPolicies" description="Loading policies" >
		</app-processor>

        <!--  Policy Grid  -->
        <div class="policyGrid" *ngIf="policies.length > 0" >

            <!--  Single Policy  -->
            <a
                class="singlePolicy"
                routerLink="/userprofile/policy/{{policy?.policyNumber}}" *ngFor="let policy of policies" >
                <fa-icon
                    class="car"
                    icon="car"
                    ></fa-icon>
                <h5>{{policy?.registrationNo}}</h5>
            </a>
            <!--END Single Policy -->



        </div>
        <!--END  Policy Grid  -->

        <!--  Purchase Highlight  -->
        <section class="purchaseHighlight">

            <h3>More Insurance Solutions</h3>

            <p>We protect what matters most to you.</p>

            <a routerLink="" class="readMoreYellow">
                Buy a New Policy Now
                <fa-icon
                    class="arrow"
                    icon="arrow-circle-right"
                    ></fa-icon>
            </a>

        </section>
        <!--END  Purchase Highlight  -->


    </div>

	<!--END  MY POLICIES CONTAINER  -->

    <!--  Claims Listing  -->

    <div class="half myClaims">

        <h2>My Claims</h2>

        <p>You have lodged {{claims.length}} claims</p>

		<app-processor
			*ngIf="loadingClaims"
			description="Loading claims">
		</app-processor>

        <!--  Claims Listing  -->
        <div class="claimListing" *ngIf="claims.length > 0" >

            <!--  Single Claim  -->
            <a
                class="singleClaim"
                *ngFor="let claim of claims"
                routerLink="/userprofile/singleclaim/{{claim.id}}" >

                <fa-icon
                    class="car"
                    icon="car"
                    ></fa-icon>

                <article>

                    <h5>{{claim?.registration_no}}</h5>
                    <p>{{claim?.vehicleMake}}</p>

                </article>



                <fa-icon
                    class="arrow"
                    icon="arrow-circle-right"
                ></fa-icon>

            </a>
            <!--END  Single Claim  -->
        </div>
        <!--END  Claims Listing  -->
        <section class="purchaseHighlight" *ngIf="policies.length > 0">
            <a
                routerLink=""
                class="readMoreGrey"
                routerLink="/userprofile/claim">

                Submit New Claim Now

                <fa-icon
                    class="arrow"
                    icon="arrow-circle-right"
                    ></fa-icon>
            </a>
        </section>


    </div>

    <!--END Claims Listing   -->

</div>
