import { Injectable } from '@angular/core';

import {HttpClient,HttpHeaders} from '@angular/common/http';
import { DropdownQuestion } from '../../_models/question/question-dropdown';
import { QuestionBase } from '../../_models/question/question-base';
import { TextboxQuestion } from '../../_models/question/question-textbox';

import { environment } from '../../../environments/environment.dev';

import { of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class QuestionService {

	constructor(
		private http:HttpClient
	) { }

	getQuestions() {

		const questions: QuestionBase<string>[] = [

			new DropdownQuestion({
				key: 'brave',
				label: 'Bravery Rating',
				options: [
					{key: 'solid',  value: 'Solid'},
					{key: 'great',  value: 'Great'},
					{key: 'good',   value: 'Good'},
					{key: 'unproven', value: 'Unproven'}
				],
				order: 3
			}),

			new TextboxQuestion({
				key: 'firstName',
				label: 'First name',
				value: 'Bombasto',
				required: true,
				order: 1
			}),

			new TextboxQuestion({
				key: 'emailAddress',
				label: 'Email',
				type: 'email',
				order: 2
			})
		];

		return of(questions.sort((a, b) => a.order - b.order));

	}

	getQuestionsById(dynamicId){

		return this.http.get(`${environment.dynamicFormApi}survey-definitions/getById?surveyDefinitionId=${dynamicId}`);	

	}

	// getAllQuestionsListingJSON(){

	// 	return this.http.get("/assets/data/questionsList.json");
		
	// }

	getJSONLocalFile(jsonFile){

		return this.http.get(`/assets/data/${jsonFile}.json`);	

		//return this.http.get("/assets/data/questionsList.json");
			
	}
	


}
