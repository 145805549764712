export class User {
  id: string;
  username: string;
  email: string;
  customer_id: string;
  transmittal_no: string;
  type: string;
  token: string;
  refreshToken?: string;
}
