<app-preloader
    *ngIf="siteImages.length > 0"
    [images]="siteImages"
></app-preloader>

<section class="centerAligner shadow rounded mt-30 mb-30">

  <div class="motorBannerSection">

    <div class="imageContainer">

      <!--  City Landscape  -->

      <figure class="cityLandscape">
        <img src="img" alt="" src="./assets/_img/home/city_skyline.png">
      </figure>
      <!--END  City Landscape  -->

      <figure class="accidentScene">
        <img src="img" alt="" src="./assets/_img/home/accident.png">
      </figure>

      <figure class="road">
        <img src="img" alt="" src="./assets/_img/home/road.png">
      </figure>

    </div>
    <!--END Cityscape banner   -->
  </div>

  <div class="introSection">

    <h1>
      <span class="yellowLine">Private Car Insurance</span>
    </h1>
    <p>
      At InsureMe, we work to protect what matters most to you and restoring that 
      smile on your face, helping you keep going with simple and personalized solutions.
    </p>

  </div>

  <div class="greyContentSection">
    <h3>What is Car Insurance?</h3>

    <p>
      Car Insurance, also known as auto or motor insurance, is a type of vehicle insurance policy that protects you and your car
      from any risks and damages caused from accidents, thefts, fire or natural disasters. So, you will be financially secure in 
      case of any losses that may be incurred because of any such unforeseen circumstances. 
      In addition to that, you will also be protected from third-party liabilities.
    </p>

    <p>
      Whether you want to just legally comply with the law with the most basic, third party car insurance, or give your
      car ultimate protection with a comprehensive car insurance, <span class="textYellow">InsureMe</span> offers you a
      <strong> comprehensive, third-party fire & theft</strong> and <strong>third-party only</strong> car insurance at affordable premiums online.
    </p>

    <p>
      The best part? You can pay your premium in easy monthly installments with our <strong>Lipa Pole Pole</strong> payment plan. You can
      also customize your policy yourself, by adding more benefits to suit your car. So, whether you want to buy/renew a
      car insurance or make a claim- everything can be done online with our quick and simple mobile-enabled process.
    </p>
  </div>

  <div class="insuranceTypesSection">

    <h3>Types of Private Car Insurance</h3>

    <div class="insuranceTypes">

      <div class="singleTab one_third">
        <div class="clickArea" data-target="comprehensive-cover"(click)="openInsuranceTab($event);scrollDownPage($event);"></div>
        <div class="insuranceIcon">
          <img src="./assets/_img/icons/comprehensive-cover.png" alt="" class="currentIcon">
          <img src="./assets/_img/icons/comprehensive-cover-active.png" alt="" class="activeIcon">
        </div>

        <h4>Comprehensive Cover</h4>

          <button 
            class="readMoreBlack">
            Learn More
            <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
          </button>

        <div class="activeCaret hideCaret">
          <fa-icon icon="caret-down" class="iconLarge iconYellow"></fa-icon>
        </div>

      </div>

      <div class="singleTab one_third">
        <div class="clickArea" data-target="third-party-fire-theft" (click)="openInsuranceTab($event); scrollDownPage($event);"></div>
        <div class="insuranceIcon">
          <img src="./assets/_img/icons/third-party-fire-theft.png" alt="" class="currentIcon">
          <img src="./assets/_img/icons/third-party-fire-theft-active.png" alt="" class="activeIcon">
        </div>
        <h4>Third Party, Fire and Theft</h4>

          <button class="readMoreBlack">
            Learn More
            <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
          </button>

          <div class="activeCaret hideCaret">
            <fa-icon icon="caret-down" class="iconLarge iconYellow"></fa-icon>
          </div>

      </div>

      <div class="singleTab one_third">
        <div class="clickArea" data-target="third-party-only" (click)="openInsuranceTab($event); scrollDownPage($event);"></div>
        <div class="insuranceIcon">
          <img src="./assets/_img/icons/third-party-only.png" alt="" class="currentIcon">
          <img src="./assets/_img/icons/third-party-only-active.png" alt="" class="activeIcon">
        </div>
        <h4>Third Party Only</h4>

        <button class="readMoreBlack">
            Learn More
          <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
        </button>

        <div class="activeCaret hideCaret">
          <fa-icon icon="caret-down" class="iconLarge iconYellow"></fa-icon>
        </div>

      </div>

    </div>

     <!--comprehensive-cover-->
    <div class="insuranceContent comprehensive-cover hideCover">

        <div class="contentSection">

            <h3>
              Comprehensive Car Insurance Cover
            </h3>

            <p>
              This is a superior cover to all the others. In addition to Third party, fire and theft, it covers your vehicle
              against most risks, including accidents and damages caused by natural disasters. You can modify your cover with additional
              benefits such as Windscreen protector, Entertainment unit, Excess protector, Political violence among others.
              <span class="textYellow">Keep Smiling, We’ve Got You.</span>
            </p>

        </div>

        <div class="contentSection">

            <h3>
              What <span class="textYellow">is covered</span> in this policy?
            </h3>

            <p>Our comprehensive private car Insurance cover protects you against:</p>
            <ul>
              <li>Loss or damage to your car and its accessories, arising from:
                  <ul>
                    <li>Theft</li>
                    <li>Vandalism</li>
                    <li>Natural disasters such as earthquakes, storms, landslides, etc</li>
                    <li>Civil disturbances such as riots, strikes and political violence.</li>
                    <li>Vehicle stored with Motor Trader (Garage, etc.)</li>
                    <li>Fire</li>
                  </ul>
              </li>
              <li>It also protects you or your authorized driver from legal liabilities for damages (including the related costs and
                expenses) to Third Parties and passengers for:
                <ul>
                  <li>Falling objects such as trees</li>
                  <li>Death or bodily injury to any person</li>
                  <li>Damage to property arising as a result of an accident by or in connection with your vehicle, including
                    while loading and unloading the vehicle as long as such costs will not exceed the amounts of cover provided for under this Policy</li>
                </ul>
              </li>
            </ul>

        </div>

        <div class="contentSection">

          <h3>
            What <span class="textYellow">is not covered</span> in this policy?
          </h3>

          <p>Some losses which are not covered by comprehensive car insurance include:</p>

          <ul>
            <li>Damage incurred while in use within the airport (beyond areas allowed to the public)</li>
            <li>Passenger liability to insured household.</li>
            <li>Nuclear Fission, War, Terrorism and Kindred Risks.</li>
            <li>Consequential loss such as loss of profit or income</li>
            <li>Depreciation from wear and tear, mechanical, electrical or electronic breakdown, failures or breakages.</li>
            <li>Damage to tires unless damage is caused to other parts of the vehicle at the same time.</li>
            <li>Loss of or damage to personal contents being carried in or on the vehicle.</li>
            <li>Damage caused by overloading or strain.</li>
            <li>Death of or bodily injury to any person in your employment arising out of and in the course of such
              employment.</li>
            <li>Damage to property held in your trust, custody or control or belonging to you or any member of your
              household.</li>
          </ul>

        </div>

        <div class="contentSection">

          <h3>
            What are the <span class="textYellow">default benefits</span> on this cover?
          </h3>

          <p>With our comprehensive cover, you will be compensated for:</p>
          <ol>
            <li>Third Party Persons
              <ul>
                <li>Up to KES 3,000,000 for any one person and</li>
                <li>Unlimited Cover for any one event.</li>
              </ul>
            </li>
            <li>Third Party Property
              <ul>
                <li>Up to KES 10,000,000</li>
              </ul>
            </li>
            <li>Passenger Liability
              <ul>
                <li>Up to KES 3,000,000 per person</li>
                <li>KES 20,000,000 in total</li>
              </ul>
            </li>
            <li>Towing charges
              <ul>
                <li>Up to KES 50,000</li>
                <li>ETR Receipt required to claim refund.</li>
              </ul>
            </li>
            <li>Repair Authority
              <ul>
                <li>Up to KES 50,000</li>
                <li>ETR Receipt required to claim refund in the event of repair/replacement.</li>
              </ul>
            </li>
            <li>Medical Expenses
              <ul>
                <li>Up to KES 50,000</li>
                <li>ETR Receipt required to claim refund.</li>
              </ul>
            </li>
            <li>Car entertainment
              <ul>
                <li>Up to KES 30,000</li>
                <li>Unlimited Cover for any one event.</li>
              </ul>
            </li>
            <li>Windscreen
              <ul>
                <li>Up to KES 30,000</li>
              </ul>
            </li>
            <li>Geographical Area
              <ul>
                <li>East Africa region (Kenya, Uganda, Tanzania, Rwanda, Burundi) Subject to Yellow Card and vehicle being
                  towed back to Kenya.</li>
              </ul>
            </li>
          </ol>

        </div>

        <div class="greyContentSection">
          <h3><span class="textYellow">Additional Benefits</span> On Comprehensive Cover</h3>
      
          <p>
            These are the extra benefits that are not covered in the policies or are only covered
            up to a certain amount that you may choose to add to your cover. 
          </p>
      
          <ul>
            <li>Political Violence & Terrorism
              <ul>
                <li>This benefit is charged 0.25% of vehicle value. The minimum payment you can make per vehicle is KES 2,500.</li>
              </ul>
            </li>
      
            <li>Excess Protector
              <p>
                Car insurance excess is the amount of money you are required to pay when you make a claim on your policy. Simply put, your excess 
                is the amount of money that you agree to put forward towards a claim, with your insurer covering the remaining costs. Excess protection
                is an additional cover that is included as standard in some car insurance policies or can be added on to others for a fee. 
                It basically refunds the excess that you have to pay in a claim where there is no responsible third party.
              </p>
              <ul>
                <li>Values up to KES 1,499,999 which is 0.5% of the car value. The minimum payment you can make per vehicle is 
                  KES 5,000.</li>
                <li>For values of KES 1,500,000 and above the rate is at 0.25% of vehicle value with a minimum payment of KES 6,000.</li>
              </ul>
            </li>
      
            <li>Entertainment Unit
              <ul>
                <li>The cover is free up to KES 30,000/=</li>
                <li>10% for additional cover</li>
              </ul>
            </li>
      
            <li>Windscreen
              <ul>
                <li>The cover is free up to KES 30,000/=</li>
                <li>10% for additional cover</li>
              </ul>
            </li>
      
            <li>Car Hire
              <ul>
                <li>Free up to KES 30,000/=</li>
                <li>Limited to KES 3,000/= per day.</li>
                <li>10% for additional cover</li>
              </ul>
            </li>
      
            <li>Rescue Services
              <ul>
                <li>KES 6,500/=</li>
              </ul>
            </li>
      
          </ul>

          <div class="buttonSection">
            <a routerLink="/">
              <button
               class="readMoreYellow">
               Get a Comprehensive Quote Now
               <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
              </button>
            </a>
          </div>


        </div>



    </div>
    <!--end of comprehensive-cover-->

    <!--third-party-fire-theft-->
    <div class="insuranceContent third-party-fire-theft hideCover">

      <div class="contentSection">

          <h3>
            Third Party, Fire and Theft
          </h3>

          <p>
            Third-party, Fire and Theft Car Insurance covers damages you have caused to other parties and their property 
            and provides cover for your car against fire and theft related damages.
          </p>

      </div>

      <div class="contentSection">

          <h3>
            What <span class="textYellow">is covered</span> in this policy?
          </h3>

          <p>Our Third Party, Fire and Theft Insurance cover protects you against:</p>
          <ul>
            <li>Loss or damage to your car and its accessories caused directly by; 
                <ul>
                  <li>Fire</li>
                  <li>Self-ignition</li>
                  <li>Lightning </li>
                  <li>Explosion </li>
                  <li>Theft or attempted theft. </li>
                </ul>
            </li>
            <li>It also protects you or your authorized driver from legal liabilities for damages
              (including the related costs and expenses) to Third Parties and passengers for:  
              <ul>
                <li>Death or bodily injury to any person. </li>
                <li>Damage to property arising as a result of an accident by or in connection with your vehicle, 
                  including while loading and unloading the vehicle as long as such costs will not exceed the amounts
                  of cover provided for under this Policy.</li>
              </ul>
            </li>
          </ul>

      </div>

      <div class="contentSection">

        <h3>
          What <span class="textYellow">is not covered</span> in this policy?
        </h3>

        <p>Losses not covered by the Third Party, Fire and Theft Policy include:</p>

        <ul>
          <li>Damages incurred while in use within the airport (beyond areas allowed to the public)</li>
          <li>Passenger liability to insured household.</li>
          <li>Damages or loss from Nuclear Fission, War, Terrorism and Kindred Risks.</li>
          <li>Consequential loss such as loss of profit or income</li>
          <li>Failures or breakages caused by depreciation, wear and tear, mechanical, electrical or electronic breakdown,</li>
          <li>Damage to tires unless damage is caused to other parts of the vehicle at the same time.</li>
          <li>Loss of or damage to the contents being carried in or on the vehicle.</li>
          <li>Damage caused by overloading or strain.</li>
          <li>Loss of or damage to the contents being carried in or on the vehicle.</li>
          <li>Death of or bodily injury to any person in your employment arising out of and in the course of such employment. </li>
          <li>Damage to property held in your trust, custody or control or belonging to you or any member of your
            household.</li>
        </ul>

      </div>

      <div class="contentSection">

        <h3>
          With Third Party, Fire and Theft cover, you will be <span class="textYellow">compensated for:</span>
        </h3>

        <ol>
          <li>Third Party Persons
            <ul>
              <li>Up to KES 3,000,000 for any one person and</li>
              <li>Unlimited Cover for any one event.</li>
            </ul>
          </li>
          <li>Third Party Property
            <ul>
              <li>Up to KES 10,000,000</li>
            </ul>
          </li>
          <li>Passenger Liability
            <ul>
              <li>Up to KES 3,000,000 per person</li>
              <li>KES 20,000,000 in total</li>
            </ul>
          </li>
          <li>Towing charges
            <ul>
              <li>Up to KES 50,000</li>
              <li>ETR Receipt required to claim refund.</li>
            </ul>
          </li>
          <li>Repair Authority
            <ul>
              <li>Up to KES 50,000</li>
              <li>ETR Receipt required to claim refund in the event of repair/replacement.</li>
            </ul>
          </li>
          <li>Medical Expenses
            <ul>
              <li>Up to KES 50,000</li>
              <li>ETR Receipt required to claim refund.</li>
            </ul>
          </li>
          <li>Car entertainment
            <ul>
              <li>Up to KES 30,000</li>
              <li>    ETR Receipt required to claim refund in the event of repair/replacement.</li>
            </ul>
          </li>
          <li>Windscreen
            <ul>
              <li>Up to KES 30,000</li>
              <li>ETR Receipt required to claim refund in the event of repair/replacement.</li>
            </ul>
          </li>
          <li>Geographical Area
            <ul>
              <li>East Africa region (Kenya, Uganda, Tanzania, Rwanda, Burundi) Subject to Yellow Card
                and vehicle being towed back to Kenya.</li>
            </ul>
          </li>
        </ol>

      </div>

      <div class="buttonSection">
        <a routerLink="/">
          <button
           class="readMoreYellow">
           Get a Quote Now
           <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
          </button>
        </a>
      </div>

    </div>
    <!--end of third-party-fire-theft-->

    <!--third-party-only-->
    <div class="insuranceContent third-party-only hideCover">

      <div class="contentSection">

        <h3>
          Third Party Only
        </h3>

        <p>
          Third party only Car Insurance is the most basic insurance policy covering the damages to other parties and their property in the event of an accident where you were the cause. 
          This is a mandatory cover that every motorist should have.
        </p>

      </div>

      <div class="contentSection">

        <h3>
          What <span class="textYellow">is covered</span> in this policy?
        </h3>

        <p>Our Third Party Only Insurance covers you against:</p>
        <ul>
          <li>You or your authorized driver from legal liabilities for damages (including the related costs and expenses) to Third Parties and passengers for: 
              <ul>
                <li>Death or bodily injury to any person</li>
                <li>Damage to property arising as a result of an accident by or in connection with your vehicle, including while 
                  loading and unloading the vehicle as long as such costs will not exceed the amounts of cover provided for 
                  under this Policy.</li>
              </ul>
          </li>
        </ul>

      </div>

      <div class="contentSection">

        <h3>
          What <span class="textYellow">is not covered</span> in this policy?
        </h3>

        <p>Some losses which are not covered by third party only car insurance include:</p>

        <ul>
          <li>Damages incurred while in use within the airport (beyond areas allowed to the public)</li>
          <li>Passenger liability to insured household.</li>
          <li>Damages or loss from Nuclear Fission, War, Terrorism and Kindred Risks.</li>
          <li>Consequential loss such as loss of profit or income</li>
          <li>Failures or breakages caused by depreciation, wear and tear, mechanical, electrical or electronic breakdown, failures or breakages.</li>
          <li>Damage to tires unless damage is caused to other parts of the vehicle at the same time.</li>
          <li>Loss of or damage to the contents being carried in or on the vehicle.</li>
          <li>Damage caused by overloading or strain.</li>
          <li>Loss of or damage to the contents being carried in or on the vehicle.</li>
          <li>Death of or bodily injury to any person in your employment arising out of and in the course of such employment. </li>
          <li>Damage to property held in your trust, custody or control or belonging to you or any member of your
            household.</li>
        </ul>

      </div>

      <div class="contentSection">

        <h3>
          With Third Party Only cover, you will be <span class="textYellow">compensated for:</span>
        </h3>
        
        <ol>
          <li>Third Party Persons
            <ul>
              <li>Up to KES 3,000,000 for any one person and</li>
              <li>Unlimited Cover for any one event.</li>
            </ul>
          </li>
          <li>Third Party Property
            <ul>
              <li>Up to KES 10,000,000</li>
            </ul>
          </li>
          <li>Passenger Liability
            <ul>
              <li>Up to KES 3,000,000 per person</li>
              <li>KES 20,000,000 in total</li>
            </ul>
          </li>
          <li>Geographical Area
            <ul>
              <li>East Africa region (Kenya, Uganda, Tanzania, Rwanda, Burundi) Subject to Yellow Card
              and vehicle being towed back to Kenya.</li>
            </ul>
          </li>
        </ol>

      </div>

      <div class="buttonSection">
        <a routerLink="/">
          <button
           class="readMoreYellow">
           Get a Quote Now
           <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
          </button>
        </a>
      </div>

    </div>
    <!--end of third-party-only-->

  </div>

  <div class="mobileView">

    <h3>Types of Private Car Insurance</h3>

      <!--Comprehensive Cover-->
      <h4 class="accordionTitle" (click)="openAccordion($event)">Comprehensive Cover

        <fa-icon

          icon="plus-circle"
          class="arrow closed"></fa-icon>

        <fa-icon

            icon="minus-circle"
            class="arrow open"></fa-icon>
      
      </h4>

      <div class="insuranceContent accordion hideCover">

        <div class="contentSection">
  
          <p>
            This is a superior cover to all the others. In addition to Third party, fire and theft, it covers your vehicle
            against most risks, including accidents and damages caused by natural disasters. You can modify your cover with additional
            benefits such as Windscreen protector, Entertainment unit, Excess protector, Political violence among others.
            <span class="textYellow">Keep Smiling, We’ve Got You.</span>
          </p>
  
        </div>
  
        <div class="contentSection">
    
            <h3>
              What <span class="textYellow">is covered</span> in this policy?
            </h3>
    
            <p>Our comprehensive private car Insurance cover protects you against:</p>
            <ul>
              <li>Loss or damage to your car and its accessories, arising from:
                  <ul>
                    <li>Theft</li>
                    <li>Vandalism</li>
                    <li>Natural disasters such as earthquakes, storms, landslides, etc</li>
                    <li>Civil disturbances such as riots, strikes and political violence.</li>
                    <li>Vehicle stored with Motor Trader (Garage, etc.)</li>
                    <li>Fire</li>
                  </ul>
              </li>
              <li>It also protects you or your authorized driver from legal liabilities for damages (including the related costs and
                expenses) to Third Parties and passengers for:
                <ul>
                  <li>Falling objects such as trees</li>
                  <li>Death or bodily injury to any person</li>
                  <li>Damage to property arising as a result of an accident by or in connection with your vehicle, including
                    while loading and unloading the vehicle as long as such costs will not exceed the amounts of cover provided for under this Policy</li>
                </ul>
              </li>
            </ul>
    
        </div>
  
        <div class="contentSection">
    
          <h3>
            What <span class="textYellow">is not covered</span> in this policy?
          </h3>
    
          <p>Some losses which are not covered by comprehensive car insurance include:</p>
    
          <ul>
            <li>Damage incurred while in use within the airport (beyond areas allowed to the public)</li>
            <li>Passenger liability to insured household.</li>
            <li>Nuclear Fission, War, Terrorism and Kindred Risks.</li>
            <li>Consequential loss such as loss of profit or income</li>
            <li>Depreciation from wear and tear, mechanical, electrical or electronic breakdown, failures or breakages.</li>
            <li>Damage to tires unless damage is caused to other parts of the vehicle at the same time.</li>
            <li>Loss of or damage to personal contents being carried in or on the vehicle.</li>
            <li>Damage caused by overloading or strain.</li>
            <li>Death of or bodily injury to any person in your employment arising out of and in the course of such
              employment.</li>
            <li>Damage to property held in your trust, custody or control or belonging to you or any member of your
              household.</li>
          </ul>
    
        </div>
      
        <div class="contentSection">
    
          <h3>
            What are the <span class="textYellow">default benefits</span> on this cover?
          </h3>
    
          <p>With our comprehensive cover, you will be compensated for:</p>
          <ol>
            <li>Third Party Persons
              <ul>
                <li>Up to KES 3,000,000 for any one person and</li>
                <li>Unlimited Cover for any one event.</li>
              </ul>
            </li>
            <li>Third Party Property
              <ul>
                <li>Up to KES 10,000,000</li>
              </ul>
            </li>
            <li>Passenger Liability
              <ul>
                <li>Up to KES 3,000,000 per person</li>
                <li>KES 20,000,000 in total</li>
              </ul>
            </li>
            <li>Towing charges
              <ul>
                <li>Up to KES 50,000</li>
                <li>ETR Receipt required to claim refund.</li>
              </ul>
            </li>
            <li>Repair Authority
              <ul>
                <li>Up to KES 50,000</li>
                <li>ETR Receipt required to claim refund in the event of repair/replacement.</li>
              </ul>
            </li>
            <li>Medical Expenses
              <ul>
                <li>Up to KES 50,000</li>
                <li>ETR Receipt required to claim refund.</li>
              </ul>
            </li>
            <li>Car entertainment
              <ul>
                <li>Up to KES 30,000</li>
                <li>Unlimited Cover for any one event.</li>
              </ul>
            </li>
            <li>Windscreen
              <ul>
                <li>Up to KES 30,000</li>
              </ul>
            </li>
            <li>Geographical Area
              <ul>
                <li>East Africa region (Kenya, Uganda, Tanzania, Rwanda, Burundi) Subject to Yellow Card and vehicle being
                  towed back to Kenya.</li>
              </ul>
            </li>
          </ol>
    
        </div>

        <div class="greyContentSection">
          <h3><span class="textYellow">Additional Benefits</span> On Comprehensive Cover</h3>
      
          <p>
            These are the extra benefits that are not covered in the policies or are only covered
            up to a certain amount that you may choose to add to your cover. 
          </p>
      
          <ul>
            <li>Political Violence & Terrorism
              <ul>
                <li>This benefit is charged 0.25% of vehicle value. The minimum payment you can make per vehicle is KES 2,500.</li>
              </ul>
            </li>
      
            <li>Excess Protector
              <p>
                Car insurance excess is the amount of money you are required to pay when you make a claim on your policy. Simply put, your excess 
                is the amount of money that you agree to put forward towards a claim, with your insurer covering the remaining costs. Excess protection
                is an additional cover that is included as standard in some car insurance policies or can be added on to others for a fee. 
                It basically refunds the excess that you have to pay in a claim where there is no responsible third party.
              </p>
              <ul>
                <li>Values up to KES 1,499,999 which is 0.5% of the car value. The minimum payment you can make per vehicle is 
                  KES 5,000.</li>
                <li>For values of KES1,500,000 and above the rate is at 0.25% of vehicle value with a minimum payment of KES 6,000.</li>
              </ul>
            </li>
      
            <li>Entertainment Unit
              <ul>
                <li>The cover is free up to KES 30,000/=</li>
                <li>10% for additional cover</li>
              </ul>
            </li>
      
            <li>Windscreen
              <ul>
                <li>The cover is free up to KES 30,000/=</li>
                <li>10% for additional cover</li>
              </ul>
            </li>
      
            <li>Car Hire
              <ul>
                <li>Free up to KES 30,000/=</li>
                <li>Limited to KES3,000/= per day.</li>
                <li>10% for additional cover</li>
              </ul>
            </li>
      
            <li>Rescue Services
              <ul>
                <li>KES 6,500/=</li>
              </ul>
            </li>
      
          </ul>

          <div class="buttonSection">
            <a routerLink="/">
              <button
               class="readMoreYellow">
               Get a Comprehensive Quote Now
               <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
              </button>
            </a>
          </div>

        </div>
  
      </div>
      <!--End of Comprehensive Cover--> 

      <!--Third Party Fire Theft Cover-->
      <h4 class="accordionTitle" (click)="openAccordion($event)">Third Party, Fire and Theft

          <fa-icon
      
            icon="plus-circle"
            class="arrow closed"></fa-icon>
      
          <fa-icon
      
            icon="minus-circle"
            class="arrow open"></fa-icon>
            
      </h4>
      
      <div class="insuranceContent accordion hideCover">

        <div class="contentSection">

          <p>
            Third-party, Fire and Theft Car Insurance covers damages you have caused to other parties and their property 
            and provides cover for your car against fire and theft related damages.
          </p>
  
        </div>
  
        <div class="contentSection">
  
          <h3>
            What <span class="textYellow">is covered</span> in this policy?
          </h3>
  
          <p>Our Third Party, Fire and Theft Insurance cover protects you against:</p>
          <ul>
            <li>Loss or damage to your car and its accessories caused directly by; 
                <ul>
                  <li>Fire</li>
                  <li>Self-ignition</li>
                  <li>Lightning </li>
                  <li>Explosion </li>
                  <li>Theft or attempted theft. </li>
                </ul>
            </li>
            <li>It also protects you or your authorized driver from legal liabilities for damages
               (including the related costs and expenses) to Third Parties and passengers for:  
              <ul>
                <li>Death or bodily injury to any person. </li>
                <li>Damage to property arising as a result of an accident by or in connection with your vehicle, 
                  including while loading and unloading the vehicle as long as such costs will not exceed the amounts
                  of cover provided for under this Policy.</li>
              </ul>
            </li>
          </ul>
  
        </div>
  
        <div class="contentSection">
    
          <h3>
            What <span class="textYellow">is not covered</span> in this policy?
          </h3>
    
          <p>Losses not covered by the Third Party, Fire and Theft Policy include:</p>
    
          <ul>
            <li>Damages incurred while in use within the airport (beyond areas allowed to the public)</li>
            <li>Passenger liability to insured household.</li>
            <li>Damages or loss from Nuclear Fission, War, Terrorism and Kindred Risks.</li>
            <li>Consequential loss such as loss of profit or income</li>
            <li>Failures or breakages caused by depreciation, wear and tear, mechanical, electrical or electronic breakdown,</li>
            <li>Damage to tires unless damage is caused to other parts of the vehicle at the same time.</li>
            <li>Loss of or damage to the contents being carried in or on the vehicle.</li>
            <li>Damage caused by overloading or strain.</li>
            <li>Loss of or damage to the contents being carried in or on the vehicle.</li>
            <li>Death of or bodily injury to any person in your employment arising out of and in the course of such employment. </li>
            <li>Damage to property held in your trust, custody or control or belonging to you or any member of your
              household.</li>
          </ul>
    
        </div>
    
        <div class="contentSection">
    
          <h3>
            With Third Party, Fire and Theft cover, you will be <span class="textYellow">compensated for:</span>
          </h3>
    
          <ol>
            <li>Third Party Persons
              <ul>
                <li>Up to KES 3,000,000 for any one person and</li>
                <li>Unlimited Cover for any one event.</li>
              </ul>
            </li>
            <li>Third Party Property
              <ul>
                <li>Up to KES 10,000,000</li>
              </ul>
            </li>
            <li>Passenger Liability
              <ul>
                <li>Up to KES 3,000,000 per person</li>
                <li>KES 20,000,000 in total</li>
              </ul>
            </li>
            <li>Towing charges
              <ul>
                <li>Up to KES 50,000</li>
                <li>ETR Receipt required to claim refund.</li>
              </ul>
            </li>
            <li>Repair Authority
              <ul>
                <li>Up to KES 50,000</li>
                <li>ETR Receipt required to claim refund in the event of repair/replacement.</li>
              </ul>
            </li>
            <li>Medical Expenses
              <ul>
                <li>Up to KES 50,000</li>
                <li>ETR Receipt required to claim refund.</li>
              </ul>
            </li>
            <li>Car entertainment
              <ul>
                <li>Up to KES 30,000</li>
                <li>    ETR Receipt required to claim refund in the event of repair/replacement.</li>
              </ul>
            </li>
            <li>Windscreen
              <ul>
                <li>Up to KES 30,000</li>
                <li>ETR Receipt required to claim refund in the event of repair/replacement.</li>
              </ul>
            </li>
            <li>Geographical Area
              <ul>
                <li>East Africa region (Kenya, Uganda, Tanzania, Rwanda, Burundi) Subject to Yellow Card
                  and vehicle being towed back to Kenya.</li>
              </ul>
            </li>
          </ol>
    
        </div>

        <div class="buttonSection">
          <a routerLink="/">
            <button
             class="readMoreYellow">
             Get a Quote Now
             <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
            </button>
          </a>
        </div>

      </div>
      <!--End of Third Party Fire Theft Cover-->
      
      <!--Third Party Only-->
      <h4 class="accordionTitle" (click)="openAccordion($event)">Third Party Only

        <fa-icon
    
          icon="plus-circle"
          class="arrow closed"></fa-icon>
    
        <fa-icon
    
          icon="minus-circle"
          class="arrow open"></fa-icon>
          
      </h4>

      <div class="insuranceContent accordion hideCover">

        <div class="contentSection">
          <p>
            Third party only Car Insurance is the most basic insurance policy covering the damages to other parties and their property in the event of an accident where you were the cause. 
            This is a mandatory cover that every motorist should have.
          </p>
        </div>
  
        <div class="contentSection">
  
          <h3>
            What <span class="textYellow">is covered</span> in this policy?
          </h3>
  
          <p>Our Third Party Only Insurance covers you against:</p>
          <ul>
            <li>You or your authorized driver from legal liabilities for damages (including the related costs and expenses) to Third Parties and passengers for: 
                <ul>
                  <li>Death or bodily injury to any person</li>
                  <li>Damage to property arising as a result of an accident by or in connection with your vehicle, including while 
                    loading and unloading the vehicle as long as such costs will not exceed the amounts of cover provided for 
                    under this Policy.</li>
                </ul>
            </li>
          </ul>
  
        </div>
  
        <div class="contentSection">
    
          <h3>
            What <span class="textYellow">is not covered</span> in this policy?
          </h3>
    
          <p>Some losses which are not covered by third party only car insurance include:</p>
    
          <ul>
            <li>Damages incurred while in use within the airport (beyond areas allowed to the public)</li>
            <li>Passenger liability to insured household.</li>
            <li>Damages or loss from Nuclear Fission, War, Terrorism and Kindred Risks.</li>
            <li>Consequential loss such as loss of profit or income</li>
            <li>Failures or breakages caused by depreciation, wear and tear, mechanical, electrical or electronic breakdown, failures or breakages.</li>
            <li>Damage to tires unless damage is caused to other parts of the vehicle at the same time.</li>
            <li>Loss of or damage to the contents being carried in or on the vehicle.</li>
            <li>Damage caused by overloading or strain.</li>
            <li>Loss of or damage to the contents being carried in or on the vehicle.</li>
            <li>Death of or bodily injury to any person in your employment arising out of and in the course of such employment. </li>
            <li>Damage to property held in your trust, custody or control or belonging to you or any member of your
              household.</li>
          </ul>
    
        </div>
    
        <div class="contentSection">
    
          <h3>
            With Third Party Only cover, you will be <span class="textYellow">compensated for:</span>
          </h3>
          
          <ol>
            <li>Third Party Persons
              <ul>
                <li>Up to KES 3,000,000 for any one person and</li>
                <li>Unlimited Cover for any one event.</li>
              </ul>
            </li>
            <li>Third Party Property
              <ul>
                <li>Up to KES 10,000,000</li>
              </ul>
            </li>
            <li>Passenger Liability
              <ul>
                <li>Up to KES 3,000,000 per person</li>
                <li>KES 20,000,000 in total</li>
              </ul>
            </li>
            <li>Geographical Area
              <ul>
                <li>East Africa region (Kenya, Uganda, Tanzania, Rwanda, Burundi) Subject to Yellow Card
                and vehicle being towed back to Kenya.</li>
              </ul>
            </li>
          </ol>
    
        </div>

        <div class="buttonSection">
          <a routerLink="/">
            <button
             class="readMoreYellow">
             Get a Quote Now
             <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
            </button>
          </a>
        </div>

      </div>
       <!--End of Third Party Only-->

  </div>



</section>