    <a
        *ngIf="type == 'yellow'"
        routerLink={{link}}
        class="readMoreYellow">
        {{title}}

        <fa-icon

            icon="arrow-alt-circle-right"
            class="arrow"></fa-icon>

    </a>

    <a
        *ngIf="type == 'white'"
        routerLink={{link}}
        class="readMore">
        {{title}}

        <fa-icon

            icon="arrow-alt-circle-right"
            class="arrow"></fa-icon>

    </a>

    <a
        *ngIf="type == 'whiteHollow'"
        routerLink={{link}}
        class="readMoreWhite">
        {{title}}

        <fa-icon

            icon="arrow-alt-circle-right"
            class="arrow"></fa-icon>

    </a>

    <a
        *ngIf="type == 'black'"
        routerLink={{link}}
        class="readMoreBlack">
        {{title}}

        <fa-icon

            icon="arrow-alt-circle-right"
            class="arrow"></fa-icon>

    </a>

    <a
        *ngIf="type == ''"
        routerLink={{link}}
        class="readMore">
        {{title}}

        <fa-icon

            icon="arrow-alt-circle-right"
            class="arrow"></fa-icon>

    </a>
