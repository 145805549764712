<app-header></app-header>

<app-sidebar></app-sidebar>

<section class="mainContent">
    <h1>About Product Content</h1>

    <p>This is about us content</p>

    <p>{{aboutDescription.body}}</p>
</section>

<feature-banner></feature-banner>
