import { Component, OnInit,Input } from '@angular/core';

@Component({
    selector: 'app-mobilebanner',
    templateUrl: './mobilebanner.component.html',
    styleUrls: ['./mobilebanner.component.css']
})
export class MobilebannerComponent implements OnInit {

    @Input() user:string;
    @Input() numberOfAdults:string;
    @Input() numberOfChildren:string;

    constructor() { }

    ngOnInit(): void {
    }

}
