import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormGroup, AbstractControl,} from '@angular/forms';

import { CustomValidationService } from '../_helpers/must-must.validator';

@Directive({
  selector: '[appMatchDeposit]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MatchDepositDirective, multi: true },
  ],
})
export class MatchDepositDirective implements Validator {
  @Input('appMatchDeposit') MatchDeposit: any = [];

  constructor(private customValidationService: CustomValidationService) {}

  validate(formGroup: FormGroup): ValidationErrors {

    const isError = this.customValidationService.MatchDeposit(
        this.MatchDeposit[0],
        this.MatchDeposit[1]
      )(formGroup);

      
    return isError;
  }
}
