
<div class="faqGroupContainer">

    <div class="faqHeader">
        <h2>General Motor Insurance FAQs</h2>
    </div>

    <!--  Accordion Container  -->
    <div class="accordionContainer">

        <!--  Single Accordion  -->
        <div class="singleFAQ" *ngFor="let faq of generalMotorFaqs">
           
            <h3 class="accordionTitle inactive" (click)="openAccordion($event)">
                    
                {{faq.question}}

                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                    icon="minus-circle"
                    class="arrow open"></fa-icon>

            </h3>

            <div class="accordion">
                <p>
                    {{faq.answer}}
                </p>
            </div>

        </div>
        <!--  End of Single Accordion  -->

    </div>

</div>