import {TotalTheftDriverDetails} from "../claims/TotalTheftDriverDetails.model";
import { TotalTheftOtherDocuments } from "../claims/TotalTheftOtherDocuments.model";
import { TotalTheftReportDetails } from "../claims/TotalTheftReportDetails.model";

export class TotalTheftClaim{

  customerId:number = 1;
  policyNumber:string = "";
  claimType:string = "";
  dateTime:string = "";
  placeLatitude:string = "";
  placeLongitude:string= "";
  totalTheftLocation:string ="";
  driver:string = "self";
  docUploadRef:string="";
  driverDetails:TotalTheftDriverDetails = new TotalTheftDriverDetails();
  carDescriptionBeforeTheft:string = "";
  hasAnyItemBeenReplaced:string = "false";
  theftOccurDetails:string = "";
  otherInsuranceAgainstTheft:string = "false";
  vehicleHasTrackingDevice:string = "false";
  wasVehicleLocked:string = "false";
  anyModification:string = "false";
  modificationDetails:string = "";
  anySpecialFitments:string = "false";
  specialFitmentDetails: string = "";
  anyIdentifyingFeatures:string = "false";
  identifyingFeaturesDescription:string = "";
  estimatedMileage:string = "";
  isThereHigherPurchaseInterest:string = "false";
  placeOfCarPurchase:string = "";
  dateOfPurchase:string= "";
  areYouTheSoleOwner:string = "true";
  theftReportedToPolice:string = "false";
  theftReportDetails:TotalTheftReportDetails = new TotalTheftReportDetails();
  desclaimerChecked:boolean = false;
  otherDocuments:any = [];

}
