import { Component, OnInit } from '@angular/core';

import { QuestionService } from '../../_services/question/question.service';
import { QuestionBase } from '../../_models/question/question-base';
import { QuestionControlService } from '../../_services/question/question-control.service';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-personal-accident-form',
  templateUrl: './personal-accident-form.component.html',
  styleUrls: ['./personal-accident-form.component.css'],
  providers: [
    QuestionService,
    QuestionControlService
  ]
})

export class PersonalAccidentFormComponent implements OnInit {

  questions$: Observable<QuestionBase<any>[]>;

  personsCoveredQuestions: any = [];
  personsCoveredDetails: any = [];
  fullPage: any = [];

  constructor(
    private questionService: QuestionService
  ) { 

    this.questions$ = questionService.getQuestions();
  }

  ngOnInit(): void {

    this.getDynamicQuestion();

  }

  getDynamicQuestion(){

    this.questionService.getQuestionsById(68).subscribe(response => {

			if(response["body"]["surveyDefinitionPages"].length > 0){

					this.personsCoveredQuestions = response["body"]["surveyDefinitionPages"][0]["questions"];

					this.fullPage = response["body"]["surveyDefinitionPages"];

					console.log("The personal accident form",this.personsCoveredQuestions);

					console.log("The full page", this.fullPage);

			}

    });

    this.questionService.getQuestionsById(71).subscribe(response => {

			if(response["body"]["surveyDefinitionPages"].length > 0){

					this.personsCoveredDetails = response["body"]["surveyDefinitionPages"];

          console.log("Persons covered:", this.personsCoveredDetails);
          
			}

		});

  }

}
