import { Component, OnInit } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-motorvehiclecover',
  templateUrl: './motorvehiclecover.component.html',
  styleUrls: ['./motorvehiclecover.component.css']
})
export class MotorvehiclecoverComponent implements OnInit {

  userProfile: any;
  siteImages: any = [];
  pageLoaded: boolean;

  constructor() {}

  ngOnInit(): void {
    this.getImages();
  }

  getImages() {

    $("img").each((index, value) => {


      if ($(value).attr("src") != "" || $(value).attr("src") == "undefined" || $(value).attr("src") == null) {

        this.siteImages.push($(value).attr("src"));

      }

    });

  }


  openInsuranceTab(event) {

    let currentInsurance = event.target.dataset.target;
    $(".insuranceContent").hide();
    $('.' + currentInsurance).show();

    $(".insuranceTypes .singleTab").removeClass("activeTab");
    $(event.target).parent().addClass("activeTab");

    $(".insuranceTypes .singleTab button").addClass("activeBtn");

    $(".insuranceTypes .singleTab .activeCaret").hide();
    $(event.target).parent().find(".activeCaret").show();

  }

  scrollDownPage(event) {
    let targetDiv = event.target.dataset.target;
    let contentDiv = $('.' + targetDiv);

    $('html, body').stop().animate({
      scrollTop: contentDiv.offset().top
    }, 400);

  }

  openAccordion(event){

    $(".accordion").stop().slideUp();
    
    if ($(event.target).hasClass("activeTab")) {

      $(".accordionTitle").removeClass("activeTab");
      $(event.target).removeClass("activeTab").next(".accordion").slideUp();

    } else {

      $(".accordionTitle").removeClass("activeTab");
      $(event.target).addClass("activeTab").next(".accordion").slideDown();

    }
    
  }


}
