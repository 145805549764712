import {
  Principle,
  Payment,
  Valuation,
  Customer,
  PolicyDetails,
} from './index';

export class PolicyData {
	
  customerDetails: Customer = new Customer();
  paymentDetails: Payment = new Payment();
  policyDetails: PolicyDetails = new PolicyDetails();
  valuationDetails: Valuation = new Valuation();

}
