export class Principle{

    fullName: string = "";
    phoneNumber: string = "";
    nationalId: string = "";
    email: string = "";
    gender: string = "";
    vehicleRegistration: string = "";
    make:string = "";
	coverType:string = "";
    ip:string = "";
    idType:string = "";

}
