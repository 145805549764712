<div class="dynamicForm">

	<!-- DYANMIC FORM  -->

	<form (ngSubmit)="onSubmit()" [formGroup]="form">

		<ng-container >

			<ul
				class=""
				[ngClass]="layoutType == 'tabs' ? 'tabNavigation' : 'hide' ">

				<li
					class="{{page.pageName}}"

					*ngFor="let page of pages"
					(click)="toggleTab($event,page.pageName)">
					{{page.pageTitle}}
				</li>

			</ul>

		</ng-container>
		<!-- END TAB NAVIGATION -->

		<!-- ACCORDION CONTAINER -->
		<section class="accordionContainer multistep ">

			<section
				*ngFor="let page of pages;
				let i=index;"
				class="{{page.pageName}}"
				id="{{page.pageOrder}}"
				[ngClass]="layoutType == 'tabs' ? 'tabContent shadow' : '' ">

				<!-- [disbled]="form.controls[pages[i-1].pageName].invalid" -->

				<h2
					[ngClass]="layoutType == 'accordion' ? 'accordionTitle' : 'hide' "
					class="pageTitle"
					(click)="toggleAccordion($event);" >
					{{page.pageTitle}}

					<fa-icon
						icon="plus-circle"
						class="arrow closed">
					</fa-icon>

			        <fa-icon
						icon="minus-circle"
						class="arrow open">
					</fa-icon>

				</h2>

				<form
					[formGroupName]="page.pageName"
					class="pageDetails "
					[ngClass]="layoutType == 'accordion' ? 'accordion shadow' : '' ">

					<h4>
						{{page.instructions}}
					</h4>

						<div class="row clear">

							<button
								class="readMoreBlack"
								(click)="proceedToNextSection($event,pages[i].pageName,pages[i-1].pageName,layoutType)"
								*ngIf=" i > 0">

								Back to {{pages[i-1].pageTitle}}

								<fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>

							</button>

						</div>

					<div class="flexContainer">

						<div
							*ngFor="let question of page.questions;
							let questionIndex = index "
							class="form-row {{question.questionLayout}} singleQuestion"
							id="{{question.questionOrder}}">

								<label
									[attr.for]="question?.questionText"
									*ngIf="question?.questionText !== ''"
									[ngClass]="question?.fieldType == 'select_multiple' ? 'labelCheckbox' : '' ">
									{{question?.questionText}}
								</label>

								<input
									*ngIf="question?.fieldType == 'textfield' || question?.fieldType == 'decimal'"
									[formControlName]="question.fieldName"
									[pattern]="question?.regularExpression"
									[maxLength]="question?.max"
									[minLength]="question?.min"
									(change)="onFormControlChange(page.pageName)"
									[placeholder]="question?.instructions"
									[id]="question.fieldName"
									[type]="question.fieldType">

								<textarea
									*ngIf="question?.fieldType == 'textArea'"
									[formControlName]="question.fieldName"
									(change)="onFormControlChange(page.pageName)"
									[placeholder]="question?.instructions"
									[id]="question.fieldName">
								</textarea>

								<input
									*ngIf="question?.fieldType == 'integer'"
									[formControlName]="question.fieldName"
									[pattern]="question.regularExpression"
									currencyMask
									[max]="question?.max"
									[min]="question?.min"
									(change)="onFormControlChange(page.pageName)"
									[id]="question.fieldName"
									[type]="question.fieldType">

								<mat-slider
									*ngIf="question?.fieldType == 'range'"
									thumbLabel
									[displayWith]="formatLabel"
									[formControlName]="question.fieldName"
									(dateChange)="onFormControlChange(page.pageName)"
									[tickInterval]="question?.tip"
									[step]="question?.tip"
									[min]="question?.min"
									[max]="question?.max"
									aria-label="units">
								</mat-slider>

								<div [ngClass]="question?.fieldType == 'dateTime' ? 'dateTimeInput' : '' "
									*ngIf=" question?.fieldType == 'dateTime' ">

									<label
										[ngClass]="form['controls'][ page['pageName'] ]['controls'][question.fieldName ]['value'] !== '' ? 'dateFormatLabel' : '' "
										[attr.for]="question.fieldName">
											{{form['controls'][ page['pageName'] ]['controls'][question.fieldName ]['value'] | date: 'medium'}}
									</label>

									<input
										matInput
										[ngxMatDatetimePicker]="picker"
										[ngxMatDatetimePickerFilter]="dateFilter"
										[formControlName]="question.fieldName"
										[placeholder]="question?.instructions"
										[id]="question.fieldName"
										(dateChange)="onFormControlChange(page.pageName)"
										(click)="picker.open()">

									<button
										mat-icon-button
										matSuffix
										class="iconPosition"
										(click)="picker.open();">

										<fa-icon icon="calendar-alt"></fa-icon>

									</button>

									<ngx-mat-datetime-picker
										#picker
										[showSpinners]="showSpinners"
										[showSeconds]="showSeconds"
										[stepHour]="stepHour"
										[stepMinute]="stepMinute"
										[touchUi]="touchUi"
										[enableMeridian]="enableMeridian">
									</ngx-mat-datetime-picker>

									<mat-datepicker #picker></mat-datepicker>

								</div>

								<div [ngClass]="question?.fieldType == 'date' ? 'dateTimeInput' : '' "
									*ngIf=" question?.fieldType == 'date' ">

									<label
										[attr.for]="question.fieldName"
										[ngClass]="form['controls'][ page['pageName'] ]['controls'][question.fieldName ]['value'] !== '' ? 'dateFormatLabel' : '' ">
											{{form['controls'][ page['pageName'] ]['controls'][question.fieldName ]['value'] | date: 'fullDate'}}
									</label>

									<input
										matInput
										[matDatepicker]="datePicker"
										[max]="adultLimitDate"
										[placeholder]="question?.instructions"
										[formControlName]="question.fieldName"
										[id]="question.fieldName"
										(dateChange)="onFormControlChange(page.pageName)"
										(click)="datePicker.open()">

									<button
										mat-icon-button
										matSuffix
										class="iconPosition"
										(click)="datePicker.open();">

										<fa-icon icon="calendar-alt"></fa-icon>

									</button>

									<mat-datepicker #datePicker></mat-datepicker>

								</div>

								<input
									*ngIf="question?.fieldType == 'autocomplete'"
									[formControlName]="question.fieldName"
									(keyup)="onInputChange($event, question.autoCompleteUrl, page.pageName, question.fieldName)"
									[id]="question.fieldName"
									[type]="question.fieldType">

									<div
										*ngIf="question?.fieldType == 'autocomplete'"
										[ngClass]="question?.fieldType == 'autocomplete' ? 'autocompleteContainer' : ''">

										<ul class="autocompleteResponse">

											<li
												class="itemList"
												*ngFor="let singleAutoCompleteItem of autoCompleteReference[page.pageName][question.fieldName]"
												[value]="singleAutoCompleteItem.hasOwnProperty(question?.tip)? singleAutoCompleteItem[question?.tip]  : singleAutoCompleteItem"
												(click)="onItemSelected($event, page?.pageName, question?.fieldName, singleAutoCompleteItem.hasOwnProperty(question?.tip)? singleAutoCompleteItem[question?.tip]  : singleAutoCompleteItem)">
													{{ singleAutoCompleteItem.hasOwnProperty(question?.instructions) ? singleAutoCompleteItem[question?.instructions]  : singleAutoCompleteItem }}
											</li>

										</ul>

									</div>

								<!-- <mat-autocomplete
									#auto="matAutocomplete"
									(optionSelected)="onFormControlChange(page.pageName)">

										<mat-option
											*ngFor="let option of autoCompleteReference[page.pageName][question.fieldName]"
											[value]="option.name">
											{{option.name}}
										</mat-option>

								</mat-autocomplete> -->


								<div [ngClass]="question?.fieldType == 'file' || question?.fieldType == 'image' ? 'uploadValuation' : '' ">

									<ng-container *ngIf="question?.fieldType == 'file' ">

										<input
											*ngIf="question?.fieldType == 'file' "
											[formControlName]="question?.fieldName"
											(change)="onFileChange($event, page.pageName, question.fieldName, question?.max, question?.instructions, question?.fieldType)"
											[id]="question?.fieldName"
											type="file"
											accept=".pdf,.docx,.xls, .xlsx, .document"/>

											<label
												[attr.for]="question?.fieldName"
												class="uploadLabel">
													{{question?.instructions}}
											</label>

											<fa-icon
												class="iconPosition"
												icon="upload">
											</fa-icon>

									</ng-container>

									<ng-container *ngIf="question?.fieldType == 'image' ">

										<input
											*ngIf="question?.fieldType == 'image' "
											[formControlName]="question.fieldName"
											(change)="onFileChange($event, page.pageName, question.fieldName, question.max, question?.instructions, question?.fieldType)"
											[id]="question?.fieldName"
											type="file"
											accept=".jpg,.jpeg,.png"/>

											<label
												[attr.for]="question?.fieldName"
												class="uploadLabel">
													{{question?.instructions}}
											</label>

											<fa-icon
												class="iconPosition"
												icon="upload">
											</fa-icon>

									</ng-container>

								</div>

								<div [ngClass]="question?.fieldType == 'geopoint' ? 'locationInput' : '' ">

									<mat-google-maps-autocomplete
										*ngIf="question?.fieldType == 'geopoint'"
										[formControlName]="question.fieldName"
										[id]="question.fieldName"
										[type]="question.fieldType"
										country="ke"
										type="establishment"
										(onAutocompleteSelected)="onAutocompleteSelected($event, page.pageName, question.fieldName)">
									</mat-google-maps-autocomplete>

								</div>

								<div class="repeater"
									*ngIf="question?.fieldType == 'repeater' "
									[formArrayName] = "question.fieldName">

									<ng-container
										*ngFor="let repeater of form['controls'][pages[i]['pageName']  ]['controls'][pages[i].questions[questionIndex].fieldName ].controls;
										let repeaterIndex = index">

										<div class="repeaterRow" [formGroupName]="repeaterIndex">

											<div class="clear">

												<button
													*ngIf="repeaterIndex > 0"
													(click)="removeRepeaterQuestion(i,questionIndex,repeaterIndex)"
													class="readMoreYellow marginTop-20 marginBottom-20">
														Remove {{question?.questionText}}
												</button>

											</div>

											<div class="flexContainer">

												<ng-container
													*ngFor="let repeaterQuestion of question.options[0]['questionToFollow'];
													let repeaterQuestionIndex = index;">

													<div class="half marginBottom-20">

														<label>{{repeaterQuestion.questionText}}</label>

														<!-- (change)="onRepeaterFieldChange(repeaterIndex,question.fieldName)" -->

															<input
																*ngIf="repeaterQuestion?.fieldType == 'textfield'"
																id="{{repeaterQuestion.fieldName}}"
																name="{{repeaterQuestion.fieldName}}"
																[formControlName]="repeaterQuestion.fieldName"
																(change)="onFormControlChange(page.pageName)"/>

																<div [ngClass]="question?.fieldType == 'geopoint' ? 'locationInput' : '' ">

																	<mat-google-maps-autocomplete
																		*ngIf="repeaterQuestion?.fieldType == 'geopoint'"
																		[formControlName]="repeaterQuestion.fieldName"
																		[id]="repeaterQuestion.fieldName"
																		[type]="repeaterQuestion.fieldType"
																		country="ke"
																		type="establishment"
																		(onAutocompleteSelected)="onAutocompleteSelected($event, page.pageName, question.fieldName)">
																	</mat-google-maps-autocomplete>

																</div>

															<select
																*ngIf="repeaterQuestion?.fieldType == 'dynamicselect'"
																[id]="repeaterQuestion.fieldName"
																(click)="onDynamicSelect($event, page, page?.pageName, repeaterQuestion?.fieldName, repeaterQuestion?.fieldType)"
																[formControlName]="repeaterQuestion?.fieldName">

																<option
																	*ngFor="let singleItem of dynamicSelectReference[page.pageName][question.fieldName][0][repeaterQuestion.fieldName]"
																	[value]="singleItem.hasOwnProperty(repeaterQuestion?.tip)? singleItem[repeaterQuestion?.tip]  : singleItem">
																	{{ singleItem.hasOwnProperty(repeaterQuestion?.instructions) ? singleItem[repeaterQuestion?.instructions]  : singleItem }}
																</option>

															</select>

															<input
																*ngIf="repeaterQuestion?.fieldType == 'autocomplete'"
																[formControlName]="repeaterQuestion.fieldName"
																(keyup)="handleRepeaterInputChange($event, repeaterQuestion.autoCompleteUrl, page.pageName, question.fieldName, repeaterQuestion?.fieldName, repeaterIndex)"
																[id]="repeaterQuestion.fieldName"
																[type]="repeaterQuestion.fieldType">

																<div
																	*ngIf="repeaterQuestion?.fieldType == 'autocomplete'"
																	[ngClass]="repeaterQuestion?.fieldType == 'autocomplete' ? 'autocompleteContainer' : ''">

																	<ul class="autocompleteResponse">

																		<li
																			class="itemList"
																			*ngFor="let singleRepeaterItem of autoCompleteReference[page.pageName][question.fieldName][0][repeaterQuestion.fieldName]"
																			[value]="singleRepeaterItem.hasOwnProperty(repeaterQuestion?.tip)? singleRepeaterItem[repeaterQuestion?.tip]  : singleRepeaterItem"
																			(click)="onRepeaterItemSelected($event, page?.pageName, question.fieldName, repeaterIndex, repeaterQuestion?.fieldName, singleRepeaterItem.hasOwnProperty(repeaterQuestion?.tip)? singleRepeaterItem[repeaterQuestion?.tip]  : singleRepeaterItem)">
																				{{ singleRepeaterItem.hasOwnProperty(repeaterQuestion?.instructions) ? singleRepeaterItem[repeaterQuestion?.instructions]  : singleRepeaterItem }}
																		</li>

																	</ul>

																</div>

													</div>

												</ng-container>

											</div>

										</div>

									</ng-container>

									<div class="row clear">

										<button
											class="readMoreYellow marginTop-20"
											(click)="addRepeaterQuestion(i,questionIndex,question?.fieldName)">
											Add {{question?.questionText}}
										</button>

									</div>

								</div>

								<div *ngIf="question?.fieldType == 'accordion' " >

									<div class="accordionRow">

										<div class="full_width questionAccordion"
											[formGroupName]="question?.fieldName">

													<ng-container *ngFor="let accordion of question.options">

															<h2
																class="accordionTitle"
																(click)="toggleQuestionAccordion($event);">
																{{accordion.label}}
															</h2>

															<!-- <pre> {{accordion | json }}</pre> -->

															<div class="nestedAccordion flexContainer" [formGroupName]="accordion.value">

																<ng-container *ngFor="let accordionField of accordion.questionToFollow">

																	<div class="half marginTop-20">

																		<label [attr.for]="question?.questionText">
																			{{accordionField.questionText}}
																		</label>

																		<input
																			*ngIf=" accordionField?.fieldType == 'textfield'"
																			[formControlName]="accordionField.fieldName"
																			(change)="onFormControlChange(page.pageName)"
																			[id]="accordionField.fieldName"
																			[placeholder]="accordionField?.instructions"
																			[type]="accordionField.fieldType">

																		<select
																			*ngIf="accordionField?.fieldType == 'dynamicselect'"
																			[id]="accordionField.fieldName"
																			(click)="onDynamicSelect($event, page, page?.pageName, accordionField?.fieldName, accordionField?.fieldType)"
																			[formControlName]="accordionField?.fieldName">

																			<option
																				*ngFor="let singleItem of dynamicSelectReference[page.pageName][question.fieldName][accordion.value][accordionField.fieldName]"
																				[value]="singleItem.hasOwnProperty(accordionField?.tip)? singleItem[accordionField?.tip]  : singleItem">
																				{{ singleItem.hasOwnProperty(accordionField?.instructions) ? singleItem[accordionField?.instructions]  : singleItem }}
																			</option>

																		</select>

																		<!-- [formControlName]="accordionField.fieldName" -->

																		<input
																			*ngIf="accordionField?.fieldType == 'autocomplete'"
																			(keyup)="handleAccordionInputChange($event, accordionField.autoCompleteUrl, page.pageName, question.fieldName, accordion.value, accordionField.fieldName)"
																			[id]="accordionField.fieldName"
																			[type]="accordionField.fieldType"
																			[formControlName]="accordionField?.fieldName">

																			<div
																				*ngIf="accordionField?.fieldType == 'autocomplete'"
																				[ngClass]="accordionField?.fieldType == 'autocomplete' ? 'autocompleteContainer' : ''">

																				<ul class="autocompleteResponse">

																					<li
																						class="itemList"
																						*ngFor="let singleAccordionItem of autoCompleteReference[page.pageName][question.fieldName][accordion.value][accordionField.fieldName]"
																						[value]="singleAccordionItem.hasOwnProperty(accordionField?.tip)? singleAccordionItem[accordionField?.tip]  : singleAccordionItem"
																						(click)="onAccordionItemSelected($event, page?.pageName, question?.fieldName, accordion.value, accordionField.fieldName, singleAccordionItem.hasOwnProperty(accordionField?.tip)? singleAccordionItem[accordionField?.tip]  : singleAccordionItem)">
																							{{ singleAccordionItem.hasOwnProperty(accordionField?.instructions) ? singleAccordionItem[accordionField?.instructions]  : singleAccordionItem }}
																					</li>

																				</ul>

																			</div>



																		<div [ngClass]="question?.fieldType == 'geopoint' ? 'locationInput' : '' ">

																			<mat-google-maps-autocomplete
																				*ngIf="accordionField?.fieldType == 'geopoint'"
																				[formControlName]="question.fieldName"
																				[id]="question.fieldName"
																				[type]="question.fieldType"
																				country="ke"
																				type="establishment"
																				(onAutocompleteSelected)="onAutocompleteSelected($event, page.pageName, question.fieldName)">
																			</mat-google-maps-autocomplete>

																		</div>

																	</div>

																</ng-container>

															</div>

													</ng-container>

										</div>

									</div>

								</div>

							<!-- <input
								*ngIf=" question?.fieldType == 'select_one' "
								[formControlName]="question.fieldName"
								[id]="question.fieldName"
								type="radio"> -->

									<input
										*ngIf=" question?.fieldType == 'select_multiple' "
										[formControlName]="question.fieldName"
										(change)="onFormControlChange(page.pageName)"
										[id]="question.fieldName"
										[ngClass]= "question?.fieldType == 'select_multiple' ? 'inputCheckbox' : '' "
										type="checkbox"/>

									<span
										[ngClass]= "question?.fieldType == 'select_multiple' ? 'customCheckbox' : '' ">
									</span>

									<select
										*ngIf="question?.fieldType == 'select_one'"
										[id]="question.fieldName"
										(change)="onFormControlChange(page.pageName)"
										(click)="onOptionSelect($event, page, page?.pageOrder, question?.fieldName, question?.fieldType)"
										[formControlName]="question?.fieldName">

										<option selected value="">{{question?.instructions}}</option>

										<option
											*ngFor="let option of question?.options"
											[value]="option?.value">
											{{option?.label}}
										</option>

									</select>

									<select
										*ngIf="question?.fieldType == 'dynamicselect'"
										[id]="question.fieldName"
										(click)="onDynamicSelect($event, page, page?.pageName, question?.fieldName, question?.fieldType)"
										[formControlName]="question?.fieldName">

										<!-- <option selected value="">{{question?.instructions}}</option> -->

										<option
											*ngFor="let singleItem of dynamicSelectReference[page.pageName][question.fieldName]"
											[value]="singleItem.hasOwnProperty(question?.tip)? singleItem[question?.tip]  : singleItem">
											{{ singleItem.hasOwnProperty(question?.instructions) ? singleItem[question?.instructions]  : singleItem }}
										</option>

									</select>

									<ng-container *ngFor="let followUpOption of question?.options;
										let optionIndex = index">

										<!-- *ngIf="followUpOption.hasconditionalquestion =='yes' &&
											followUpOption.value == form['controls'][ page['pageName'] ]['controls'][question.fieldName ]['value']" -->

										<div
											*ngIf="followUpOption.hasconditionalquestion =='yes' &&
											followUpOption.value == form['controls'][ page['pageName'] ]['controls'][question.fieldName ]['value']">

											<div class="flexContainer"
												[formGroupName]="followUpOption.value">

												<ng-container *ngFor="let questionToFollow of followUpOption.questionToFollow">

													<div class="conditionRow">

														<label [attr.for]="questionToFollow?.questionText">
															{{questionToFollow.questionText}}
														</label>
														<!-- [formControlName]="questionToFollow.fieldName" -->
														<input
															*ngIf=" questionToFollow?.fieldType == 'textfield' || questionToFollow?.fieldType == 'textArea'"
															(change)="onFormControlSelectChange($event, page.pageName, questionToFollow.fieldName)"
															[placeholder]="questionToFollow?.instructions"
															[id]="questionToFollow.fieldName"
															[type]="questionToFollow.fieldType"
															[maxLength]="questionToFollow?.max"
															[minLength]="questionToFollow?.min">

														<input
															*ngIf="questionToFollow?.fieldType == 'integer'"
															(change)="onFormControlSelectChange($event, page.pageName, questionToFollow.fieldName)"
															[placeholder]="questionToFollow?.instructions"
															currencyMask
															[id]="questionToFollow.fieldName"
															[type]="questionToFollow.fieldType"
															[max]="questionToFollow?.max"
															[min]="questionToFollow?.min">

														<input
															*ngIf="questionToFollow?.fieldType == 'decimal'"
															(change)="onFormControlSelectChange($event, page.pageName, questionToFollow.fieldName)"
															[placeholder]="questionToFollow?.instructions"
															currencyMask
															[id]="questionToFollow.fieldName"
															[type]="questionToFollow.fieldType"
															[max]="questionToFollow?.max"
															[min]="questionToFollow?.min">

														<select
															*ngIf="questionToFollow?.fieldType == 'dynamicselect'"
															[id]="questionToFollow.fieldName"
															(click)="onFollowUpDynamicSelect($event, page, page?.pageName, question.fieldName, questionToFollow?.fieldType)">

															<option
																*ngFor="let singleDynamicSelectItem of dynamicSelectReference[page.pageName][question.fieldName]"
																[value]="singleDynamicSelectItem.hasOwnProperty(questionToFollow?.tip)? singleDynamicSelectItem[questionToFollow?.tip]  : singleDynamicSelectItem">
																{{ singleDynamicSelectItem.hasOwnProperty(questionToFollow?.instructions) ? singleDynamicSelectItem[questionToFollow?.instructions]  : singleDynamicSelectItem }}
															</option>
					
														</select>

														<!-- <pre>
															{{page.pageName | json}}
															{{question.fieldName | json}}
															{{followUpOption.value | json}}
															{{questionToFollow.fieldName | json}}
															{{questionToFollow.autoCompleteUrl | json}}
														</pre> -->

														<input
															*ngIf="questionToFollow?.fieldType == 'autocomplete'"
															(keyup)="onInputChange($event, questionToFollow.autoCompleteUrl, page.pageName, questionToFollow.fieldName)"
															[id]="questionToFollow.fieldName"
															[type]="questionToFollow.fieldType">

														<div
															*ngIf="questionToFollow?.fieldType == 'autocomplete'"
															[ngClass]="questionToFollow?.fieldType == 'autocomplete' ? 'autocompleteContainer' : ''">

															<ul class="autocompleteResponse">

																<li class="itemList"
																	*ngFor="let singleAutoCompleteItem of autoCompleteReference[page.pageName][questionToFollow.fieldName]"
																	[value]="singleAutoCompleteItem.hasOwnProperty(questionToFollow?.tip)? singleAutoCompleteItem[questionToFollow?.tip]  : singleAutoCompleteItem"
																	(click)="onFollowUpItemSelected($event, page?.pageName, question.fieldName, questionToFollow?.fieldName, singleAutoCompleteItem.hasOwnProperty(questionToFollow?.tip)? singleAutoCompleteItem[questionToFollow?.tip]  : singleAutoCompleteItem)">
																		{{ singleAutoCompleteItem.hasOwnProperty(questionToFollow?.instructions) ? singleAutoCompleteItem[questionToFollow?.instructions]  : singleAutoCompleteItem }}
																</li>

															</ul>

														</div>

														<!-- <pre>
															<p>Page Name:{{page.pageName | json}}</p>
															<p>questionFieldName: {{question.fieldName | json}}</p>
															<p>questionToFollowValue: {{followUpOption.value | json}}</p>
															<p>questionToFollowFieldName: {{questionToFollow.fieldName | json}}</p>
														</pre> -->


														<div [ngClass]="questionToFollow?.fieldType == 'file' || questionToFollow?.fieldType == 'image' ? 'uploadValuation' : '' ">

																<ng-container *ngIf="questionToFollow?.fieldType == 'file' ">

																	<input
																		*ngIf="questionToFollow?.fieldType == 'file' "
																		(change)="onFileChange($event, page.pageName, questionToFollow?.fieldName, questionToFollow?.max, questionToFollow?.instructions, questionToFollow?.fieldType)"
																		[id]="questionToFollow?.fieldName"
																		type="file"
																		accept=".pdf, .docx, .xls, .xlsx, .document"/>

																			<label
																				[attr.for]="questionToFollow?.fieldName"
																				class="uploadLabel">
																				{{questionToFollow?.instructions}}
																			</label>

																			<fa-icon
																				class="iconPosition"
																				icon="upload">
																			</fa-icon>

																</ng-container>

																<ng-container *ngIf="questionToFollow?.fieldType == 'image' ">

																	<input
																		*ngIf="questionToFollow?.fieldType == 'image' "
																		(change)="onFileChange($event, page.pageName, questionToFollow?.fieldName, questionToFollow?.max, questionToFollow?.instructions, questionToFollow?.fieldType)"
																		[id]="questionToFollow?.fieldName"
																		type="file"
																		accept=".jpg,.jpeg,.png"/>

																			<label
																				[attr.for]="questionToFollow?.fieldName"
																				class="uploadLabel">
																				{{questionToFollow?.instructions}}
																			</label>

																			<fa-icon
																				class="iconPosition"
																				icon="upload">
																		</fa-icon>

																</ng-container>

														</div>

													</div>

												</ng-container>

											</div>

										</div>

									</ng-container>

									<div [ngClass]="form?.controls[page.pageName]?.controls[question.fieldName]?.invalid ? 'errorMessage' : '' "
										*ngIf="form?.controls[page.pageName]?.controls[question.fieldName]?.invalid">

										<label for="" class="invalid-field">

											{{question?.errorMessage}}

										</label>

										<!--
										<div *ngIf="form?.controls[page.pageName]?.controls[question.fieldName]?.errors?.['minlength']">

											Name must be at least 4 characters long.
										</div> -->

									</div>

									<!-- <div [ngClass]="form?.controls[page.pageName]?.controls[question.fieldName]?.errors ? 'errorMessage' : '' "
										*ngIf="form?.controls[page.pageName]?.controls[question.fieldName]?.errors">

										<label for="" class="invalid-field">

											{{question.questionText}} is required

										</label>

									</div> -->

						</div>

					</div>

						<div class="row clear">

							<!-- (click)="" -->

							<button
								class="readMoreYellow"
								*ngIf="i+1 < pages.length && !formDetailsVerified[pages[i].pageName]"
								(click)="submitPageDetails(pages[i].pageName, pages[i].validationUrl)"
								[disabled]="form.controls[page.pageName].invalid">

								Submit Details

								<fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>

							</button>

							<button
								class="readMoreYellow"
								*ngIf=" i+1 < pages.length && formDetailsVerified[pages[i].pageName]"
								(click)="proceedToNextSection($event,pages[i].pageName,pages[i+1].pageName,layoutType)"
								[disabled]="form.controls[page.pageName].invalid">

								Proceed to {{pages[i+1].pageTitle}}

								<fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>

							</button>

						</div>

						<div *ngIf="page.pageName === 'personsCoveredDetails'">
							<button
								type="submit"
								class="readMoreYellow"
								[routerLink]="['/quotation']">
									View Quotes
							</button>
						</div>

						<div class="form-row">

							<!-- [disabled]="this.form.invalid" -->

							<button
								type="submit"
								class="readMoreYellow"
								*ngIf="pages.length-1 == i">

									Submit

									<fa-icon
										class="arrow"
										icon="arrow-circle-right">
									</fa-icon>

							</button>

							<app-processor *ngIf="loading"></app-processor>

						</div>

				</form>

			</section>

		</section>

		<!-- END ACCORDION CONTAINER -->

	</form>
	<!-- END DYANMIC FORM  -->

	<div *ngIf="payLoad" class="form-row">

		<strong>Saved the following values</strong><br>

		<pre>{{payLoad | json}}</pre>

	</div>

</div>
