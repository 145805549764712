<app-preloader *ngIf="siteImages.length > 0" [images]="siteImages"></app-preloader>


<section class=" ">

  <section class="centerAligner shadow termsContainer">


    <article class="terms">

      <h3>Terms and Conditions For The Purchase Of Insurance Policies Through The Insureme Platform</h3>

      <ol class="">

        <!--Introduction-->
        <li>

          <h4>Introduction </h4>

          <ol>

            <li>
              These terms and conditions (hereinafter referred to as the <strong>Terms and Conditions</strong>) are a
              legal agreement between you (as the <strong>Customer</strong>) and Heritage Insurance Company Kenya
              Limited
              (hereinafter referred
              to as <strong>Qore</strong>) applicable to the purchase by you of insurance policies offered by Qore (as
              an insurance
              agent) on behalf of various insurers via Qore’s platform (the <strong>InsureMe Platform</strong>) that
              uses mobile, web and
              applications to enable Customers to select, purchase and pay for insurance policies and of which a
              resultant virtual account has been opened by you on the InsureMe Platform.
            </li>
            <li>
              Qore reserves the right to update and change these Terms and Conditions by posting updates and
              changes to the InsureMe Platform. You are advised to check these Terms from time to time for any updates
              or changes that may impact you. Your continued use of the InsureMe Platform will be deemed to constitute
              acceptance of the Terms and Conditions.
            </li>
            <li>
              These Terms and Conditions and any amendments or variations thereto shall come into force on the
              Effective Date.
            </li>

          </ol>
        </li>
        <!--End of Introduction-->

        <!--Definitions-->
        <li>
          <h4>Definitions </h4>
          <ol>

            <li>
              In these Terms the following words and expressions (save where the context requires otherwise) bear
              the following meanings:
              <ol>

                <li>
                  <strong>Applicable Law</strong> means all laws, regulations, rules and regulatory guidance applicable
                  to the
                  operation of the InsureMe Platform and <strong>“Applicable Laws”</strong> shall be construed
                  accordingly;
                </li>
                <li>
                  <strong>Contact Center</strong> means the Customer Care Center of Qore as may be notified to the
                  Customer by Qore
                  from time to time;
                </li>
                <li>
                  <strong>Customer</strong> means any person in whose name an InsureMe Account is registered and is in
                  existence with Qore;
                </li>
                <li>
                  <strong>Credentials</strong> means your personal credentials used to operate your InsureMe Account and
                  access the Services;
                </li>
                <li>
                  <strong>Premium Deposit</strong> means the minimum premium deposit that a Customer must pay to Qore to
                  activate the insurance premium financing option called Lipa Pole Pole
                </li>
                <li>
                  <strong>Device</strong> includes but is not limited to your personal computer; mobile phone handset;
                  SIM Card; tablets and/or other electronic devices which when used together enable you to access the
                  Network and the the InsureMe Platform and/or the Services;
                </li>
                <li>
                  <strong>Effective Date</strong> means the date of the publication of these Terms and Conditions;
                </li>
                <li>
                  <strong>Force Majeure Event</strong> means any circumstance not within a party’s reasonable control
                  including without limitation:
                  <ol>
                    <li>acts of God, flood, drought, earthquake or other natural disaster;</li>
                    <li>epidemic or pandemic;</li>
                    <li>terrorist attack, civil war, civil commotion or riots, war, threat of or preparation for war,
                      armed conflict, imposition of sanctions, embargo, or breaking off of diplomatic relations;</li>
                    <li>nuclear, chemical or biological contamination or sonic boom;</li>
                    <li>any law or any action taken by a government or public authority, including without limitation
                      imposing an export or import restriction, quota or prohibition, or failing to grant a necessary
                      licence or consent;</li>
                    <li>collapse of buildings, fire, explosion or accident;</li>
                    <li>non-performance by suppliers or subcontractors; and</li>
                    <li>interruption or failure of utility service</li>
                  </ol>
                </li>
                <li>
                  <strong>Insurance Products </strong> means a financial arrangement in which an insurer states its
                  guarantee to pay a policyholder on covered events;
                </li>
                <li>
                  <strong>InsureMe Account </strong> means an account opened by a Customer or user as part of the sign up
                  process on the InsureMe Platform;
                </li>
                <li>
                  <strong>Insurer</strong> means insurance companies registered under the Insurance Act, Cap 487
                  providing Insurance Products;
                </li>
                <li>
                  <strong>Intellectual Property</strong> means and includes, but is not limited to all algorithms,
                  alloys, application program interfaces, compositions, customer lists, databases, schemata, equipment
                  design, design documents and analyses, diagrams, documentation, drawings, formulae, discoveries and
                  inventions (whether or not patentable), know-how, literary works, copyrightable works, works of
                  authorship, manufacturing processes, mask works, logos, marks (including names, logos, slogans, and
                  trade dress), methods, methodologies, architectures, processes, program listings, programming tools,
                  proprietary information, protocols, schematics, specifications, software, software code (in any form
                  including source code and executable or object code), subroutines, user interfaces, techniques,
                  uniform resource locators, web sites, and all other forms and types of technology (whether or not
                  embodied in any tangible form and including all tangible embodiments of the foregoing such as
                  compilations of information, instruction manuals, notebooks, prototypes, reports, samples, studies,
                  and summaries);
                </li>
                <li>
                  <strong>InsureMe Menu </strong> means the menu on the InsureMe Platform;
                </li>
                <li>
                  <strong>IPRS</strong> means the Integrated Population Registration System set up and maintained by the
                  Government of Kenya under the Ministry of State for Immigration and Registration of persons;
                </li>
                <li>
                  <strong>Lipa Pole Pole </strong> means the insurance premium financing service offered by Qore as part
                  of the Services pursuant to these Terms and Conditions and under which Qore may offer you insurance
                  premium financing for Insurance Products that you purchase on the InsureMe Platform;
                </li>
                <li>
                  <strong>Mobile Money Network System </strong> means systems through which a mobile phone is used to
                  access financial services;
                </li>
                <li>
                  <strong>Mobile Banking Service </strong> means M-Pesa, Airtel Money, PesaLink or such other mobile
                  banking service that a Customer has subscribed to;
                </li>
                <li>
                  <strong>Network </strong> means the mobile telephone network operated by various mobile networks in
                  Kenya;
                </li>
                <li>
                  <strong>NTSA </strong> means the National Transport and Safety Authority which operates the vehicle
                  registration system set up and maintained by the Government of Kenya under the Ministry of Transport
                  and Infrastructure Housing, Urban Development and Public Works;
                </li>
                <li>
                  <strong>Personal Data </strong> means any information relating to an identified individual user or an
                  individual user who can be identified directly or indirectly, by reference to an identifier such as a
                  name, an identification number, location data, an online identifier or to one or more factors specific
                  to the physical, physiological, genetic, mental, economic, cultural or social identity of such user;
                </li>
                <li>
                  <strong>Premium Amount </strong> means the insurance premium amounts received by the Insurer in respect
                  of the Insurance Products;
                </li>
                <li>
                  <strong>Service </strong> means the services offered pursuant to these Terms and Conditions (including
                  Lipa Pole Pole) and any other services or products as Qore may offer and you may from time to time
                  subscribe to and <strong>“Service” </strong> shall be construed accordingly;
                </li>
                <li>
                  <strong>SMS </strong> means short messaging service;
                </li>
                <li>
                  <strong>Subscriber </strong> means any person that uses a mobile telephone network operated in Kenya;
                  and
                </li>
                <li>
                  <strong>Transaction Fees</strong> includes the financing or interest charge, the penalty Interest and
                  any other fees and charges payable for the use of the Services as published on Qore’s website and/or
                  the daily newspapers in Kenya or by such other means as Qore shall in its sole discretion determine.
                </li>
              </ol>
            </li>

            <li>Words importing the singular meaning where the context so admits include the plural meaning and vice
              versa.</li>
            <li>Headings in these Terms and Conditions are for convenience purposes only and they do not affect the
              interpretation of these Terms and Conditions.</li>
            <li>The words include or including (or any similar term) are not to be construed as implying any limitation
              and general words introduced by the word other (or any similar term) shall not be given a restrictive
              meaning by reason of the fact that they are preceded or followed by words indicating a particular class of
              acts, matters or things.</li>
            <li>Statutory provisions or enactments shall include references to any amendment, modification, extension,
              consolidation, replacement or re-enactment of any such provision or enactment (whether before or after the
              date of these Terms) and to any regulation, instrument or order or other subordinate legislation made
              under such provision or enactment. </li>
            <li>Where any term is defined within the context of any particular clause, the term so defined, unless
              it is clear from the clause in question that the term so defined has limited application to the relevant
              clause, shall bear the meaning ascribed to it for all purposes under these Terms and Conditions
              notwithstanding that that term has not been separately defined in this clause 2.</li>

          </ol>
        </li>
        <!--End of Definitions-->

        <!--Acceptance of the Terms and Conditions-->
        <li>
          <h4>Acceptance of the Terms and Conditions</h4>
          <ol>
            <li>
              Before applying to use the InsureMe Platform, you should carefully read and understand these Terms
              and Conditions which will govern the use and operation of the InsureMe Platform. You also acknowledge that
              these Terms and Conditions incorporate all the Terms and Conditions contained in Qore’s website
              [www.insureme.co.ke].</li>
            <li>
              You will be prompted to decline or accept these Terms and Conditions when applying to use the InsureMe
              Platform and at each time you transact. If you decline to accept the Terms and Conditions when you
              transact, the transaction will terminate.
            </li>
            <li>You will be deemed to have read, understood and accepted these Terms and Conditions:
              <ol>
                <li>upon clicking the <strong>“Yes”</strong> option on the InsureMe Menu requesting you to confirm that
                  you have
                  read and accepted the Terms and Conditions as stated in the website [www.insureme.co.ke]; and/or
                </li>
                <li>
                  Upon registration on the InsureMe Platform and/or by using or continuing to use and operate the
                  InsureMe Platform or any of the Services.
                </li>
              </ol>
            </li>
            <li>
              By applying to register for the InsureMe Platform, you agree to comply with and be bound by these
              Terms and Conditions for the time being and from time to time in force governing the operation of the
              InsureMe Platform and affirm that these Terms and Conditions herein are without prejudice to any right
              that Qore may have with respect to the InsureMe Platform in law or otherwise.
            </li>
            <li>
              By accepting these Terms and Conditions you are accepting that:
              <ol>
                <li>
                  Qore may conduct any know your customer diligence, identity confirmation, fraud and credit checks
                  which it deems necessary on you and for this purpose Qore may seek confirmation of any details which
                  you upload to the InsureMe Platform from any third party or governmental authority in accordance with
                  Applicable Laws; </li>
                <li>
                  Qore may request the NTSA, the IPRS, credit reference bureaus, telecommunication companies and
                  any other data base for your personal information pursuant to the agreement between you and Qore
                  including your phone number, name, date of birth, identification number or passport number, vehicle
                  registration number, tax registration pin, driving license number and such other information that will
                  enable Qore to identify you and comply with the regulatory <strong>"Know Your Customer"</strong>
                  requirements;
                </li>
                <li>
                  Qore may obtain any related data available on you from any third party or governmental authority as
                  Qore in its sole discretion deems appropriate; and</li>
                <li>
                  Qore shall not be liable in any way (including but not limited to contract, tort (including
                  negligence), misrepresentation, restitution or otherwise) for any direct or indirect/consequential
                  losses, howsoever caused (including as a result of interruptions in access to the InsureMe Platform
                  due to circumstances beyond the control of Qore for example, as a result of interruptions in provision
                  of services by third parties that Qore relies on including the NTSA, the IPRS, credit reference
                  bureaus and telecommunication companies). In addition, and for the avoidance of doubt, Qore shall not
                  be liable for any direct or indirect/consequential losses, incurred by you in respect of its access to
                  and use of information under this clause 3.5, provided however that this limitation shall not apply to
                  liability in respect of death or personal injury caused by negligence or fraud or fraudulent
                  misrepresentation.</li>
              </ol>
            </li>
            <li>From time to time updates to the InsureMe Platform may be issued by Qore. Depending on the update, you
              may not be able to use the InsureMe Platform until you have accepted any new terms and conditions.</li>
            <li>In the course of your use of the InsureMe Platform or the Services, Qore may collect technical
              information about your Device and related software, hardware and peripherals for Services that are
              internet-based or wireless. By undertaking any of the actions under clause 3.5 above, you consent to us
              collecting and using such technical information about your Device and related software, hardware and
              peripherals for Services that are internet-based or wireless to improve our products and to provide any
              Service to you.</li>
            <li>Should any of the said technical information constitute Personal Data, the provisions of clause 5 below
              shall apply.</li>
            <li>You acknowledge and accept that Qore offers the InsureMe Platform and/or the Services only
              electronically and you agree to do business with Qore and to operate the InsureMe Platform only by
              electronic means via the InsureMe Menu, and any query and complaint you may have relating to the services
              shall be addressed to Qore through the Contact Centre or by getting in touch with Qore’s representative
              working in your area. For the avoidance of doubt, you acknowledge and accept that you will not be allowed
              or entitled to receive or demand the Services pertaining to the InsureMe Platform at any branch or
              branches of Qore unless otherwise advised by Qore in its sole discretion.</li>
            <li>By using the InsureMe App or any Service, you consent to the transmission, collection, retention,
              maintenance, processing and use of your data by Qore, its affiliates and licensees for purposes of
              improving the Services and/or your experience while using the InsureMe Platform. Should you, at any time,
              choose to withdraw such consent, you, by accepting these Terms, undertake to notify Qore through the
              contact details provided under clause 15 and take notice of the fact that the withdrawal of such notice
              would require your cessation of use of the Services and the InsureMe Platform.</li>
            <li>In order to purchase a product on InsureMe, you must be a registered and active mobile money or bank
              account holder.</li>
          </ol>
        </li>
        <!--End of Acceptance of the Terms and Conditions-->

        <!--Registration of the Customer on the InsureMe Platform-->
        <li>
          <h4>Registration of the Customer on the InsureMe Platform</h4>
          <ol>
            <li>In order to register for an InsureMe Account on the InsureMe Platform with Qore, you must:
              <ol>
                <li>be at least 18 years old; </li>
                <li>have the legal capacity to do so; and</li>
                <li>be a registered and active Subscriber with a mobile service provider with which Qore has an
                  agreement with for the provision of the Services. </li>
              </ol>
            </li>
            <li>
              Qore reserves the right to verify the authenticity of your details with any system maintained by a
              governmental authority including but not limited to NTSA, IPRS, credit reference bureaus,
              telecommunication companies, the National Hospital Insurance Fund (<strong>NHIF</strong>) and any other data base.
            </li>
            <li>
              You hereby agree and authorize Qore to request any governmental authority for your information held by
              such governmental authority including, without limitation, your phone number, name, date of birth, ID or
              Passport Number, vehicle registration details, corporate information (where you are a corporate entity)
              and such other information that will enable Qore to identity you and comply with the regulatory
              <strong>“Know Your Customer”</strong> requirements (together <strong>“Your Information”</strong>).
            </li>
            <li>
              Qore reserves the right to request for further information from you pertaining to your application for
              an InsureMe Account at any time. Failure to provide such information within the time required by Qore may
              result in Qore declining to accept the Borrower’s application for an Account. </li>
            <li>
              Acceptance by Qore of your application to register an InsureMe Account shall be communicated to you via
              SMS sent to the mobile phone number associated with your InsureMe Account. </li>
            <li>
              Qore reserves the right to decline your application for an InsureMe Account or to revoke the same at any
              stage at its sole discretion and without assigning any reason or giving any notice thereto. </li>
            <li>
              You are responsible for keeping your InsureMe Account information and password secure. Qore shall not be
              liable for any loss or damage arising from your failure to maintain the security of your InsureMe Account.
            </li>
          </ol>
        </li>
        <!--End of Registration of the Customer on the InsureMe Platform-->

        <!--Data Protection-->
        <li>
          <h4>Data Protection</h4>
          <ol>
            <li>
              By entering into these Terms and Conditions, you hereby consents to Qore collecting and processing your
              Information solely for the purpose of enabling the performance of the obligations under these Terms and
              Conditions.
            </li>
            <li>
              Qore undertakes not to pass on, sell or swap your Information for marketing purposes to third parties
              without obtaining your consent.
            </li>
            <li>
              Qore processes your Information (including your Personal Data) on the basis of article 6.1 of the
              General Data Protection Regulation and Section 30 of the Data Protection Act, (Act No. 24 of 2019, Laws of
              Kenya) namely on the basis of:
              <ol>
                <li>your consent;</li>
                <li>the necessity for compliance with a legal obligation;</li>
                <li>the necessity for Qore’s legitimate interest;</li>
                <li>the necessity for the protection for the vital interest of you as a data subject or another natural
                  person;</li>
                <li>the necessity for the performance of a task carried out in the public interest or in the exercise of
                  official authority;</li>
                <li>the necessity for the performance of any task carried out by a public authority.</li>
              </ol>
            </li>
            <li>
              As a data subject, you have the following rights in respect of Your Information processed by Qore:
              <ol>
                <li>the right to be informed of the use to which your Personal Data is to be put;</li>
                <li>the right to access your Personal Data held by Qore at any time;</li>
                <li>the right to request rectification of your Personal Data if there is an error;</li>
                <li>the right to correction of false or misleading Personal Data;</li>
                <li>the right to deletion of false or misleading Personal Data about you; </li>
                <li>the right to ask Qore to erase any of Your Information processed by Qore at any time, and Qore will
                  consider the request subject to the duty of retention imposed by law;</li>
                <li>the right to withdraw at any time your consent to Your Information being processed by Qore where
                  such data processing is based on your written and explicit consent;</li>
                <li>the right to request that Qore restricts the processing of your Personal Data; and</li>
                <li>the right to object in writing to the processing of all or part of your Personal Data.</li>
                <li>the right to be informed of:
                  <ol>
                    <li>the fact that Qore is collecting your Personal Data from you (we shall send an alert prior to
                      the collection of such Personal Data); </li>
                    <li>the purpose for which we intend to collect your Personal Data including why we are collecting
                      your Personal Data and how we plan to use it (see clause 3.7 above);</li>
                    <li>the third parties to whom your Personal Data will be shared or transferred, including details of
                      safeguards adopted (see clause 3.10 below); </li>
                    <li>the contacts of the data controller or data processor who will process your Personal Data (see
                      clause 15 below);</li>
                    <li>a description of the technical and organizational security measures taken to ensure the
                      integrity and confidentiality of the Personal Data; </li>
                    <li>whether the data being collected pursuant to any law and whether such collection is voluntary or
                      mandatory; and </li>
                    <li>the consequences where you fail to provide all or any part of the requested Personal Data where
                      we make a request for the same. </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              To exercise one of the above-mentioned rights you shall notify Qore of your intentions to exercise such
              rights in accordance with clause 15 of these Terms and Conditions.
            </li>
            <li>
              The time for which Qore shall keep the Your Information depends on the context of the Services provided
              and on Qore’s legal obligations as a data processor. The following elements usually influence the period
              of storage:
              <ol>
                <li>how long Qore requires Your Information for providing the Services;</li>
                <li>the sensitivity of the Your Information;</li>
                <li>whether you have given consent for a longer storage period; or</li>
                <li>the presence of a legal, contractual or similar obligation of storing Your Information.</li>
              </ol>
            </li>
            <li>
              If you consider that Qore has processed the Your Information in an incorrect manner, you shall notify Qore
              in accordance with clause 15 of these Terms and Conditions.
            </li>
            <li>
              Notwithstanding the other provisions under this clause 5, by agreeing to these Terms and Conditions, you
              agree and consent that:
              <ol>
                <li>
                  Qore may disclose any information in the possession of Qore relating to your InsureMe Account details
                  to any and all agents used by Qore in the course of the operation of your InsureMe Account including
                  but not limited to agents appointed by Qore to manage your InsureMe Account.
                </li>
                <li>
                  Qore may disclose any information in the possession of Qore relating to your InsureMe Account, the
                  Services and or account to any third party (including other institutions licensed under Laws of the
                  Republic of Kenya and Credit Reference Agencies and/or other legally authorised persons required by
                  the laws of Kenya from time to time) without legal recourse against Qore, if in Qore’s opinion such
                  disclosure is necessary for the purpose of evaluating any application made to Qore, for enforcement of
                  Qore’s rights under these Terms and Conditions or for other purposes, subject always to Applicable
                  Law.
                </li>
                <li>
                  You agree and confirm that Qore may disclose any information in the possession of Qore relating to
                  your InsureMe Account and your details to any third party (including other institutions licensed under
                  the banking laws of the Republic of Kenya and Credit Reference Agencies) for the purpose of evaluating
                  your credit worthiness or for any other lawful purpose.
                </li>
                <li>
                  You agree and consent to Qore obtaining any information relating to your application for an InsureMe
                  Account and/or the Services from any third party including credit reference institutions, credit
                  scoring agencies or other competent authority or body licensed under the Laws of the Republic of Kenya
                  if in Qore’s opinion such disclosure is necessary for the purpose of evaluating your credit
                  worthiness.
                </li>
              </ol>
            </li>
            <li>You acknowledge that disclosure by Qore pursuant to clause 5.8 shall not amount to breach of
              confidentiality or otherwise on Qore’s part and you shall indemnify and keep Qore indemnified against any
              claims, damages and/or expenses suffered or incurred by Qore pursuant to such access use and/or disclosure
              of the information.</li>
          </ol>
        </li>
        <!--End of Data Protection-->

        <!--Reliance on information to third parties-->
        <li>
          <h4>Reliance on information to third parties</h4> <br />
            Representations and warranties you make to a third party in connection with information related to the use
            of
            the InsureMe Platform will be deemed to also have been made to Qore for its benefit and for it to rely on.
        </li>
        <!--End of Reliance on information to third parties-->

        <!--Anti money laundering-->
        <li>
          <h4>Anti money laundering</h4>
          <ol>
            <li>
              You agree to provide such evidence of your identity and/or other relevant information as Qore may
              reasonably require in order to comply with our obligations under applicable legislation and regulations
              against money laundering and terrorist financing (the <strong>“anti-money laundering laws”</strong>). Qore
              may terminate
              your InsureMe Account and/or your use of the Services if you fail to provide such evidence or information
              promptly.
            </li>
            <li>
              If, while using the Services, it becomes necessary for us to make a report under the anti-money laundering
              laws, Qore will normally be prohibited from disclosing to you that such a report has been made and Qore
              will suspend your InsureMe Account unless and until we have the approval of the relevant authority to do
              so. Qore may terminate your access to the InsureMe Platform entirely. In such circumstances Qore will not
              be liable to you for any loss, damage or delay you may suffer as a result of Qore’s termination of your
              access to the InsureMe Platform or otherwise complying with the anti-money laundering laws so long as Qore
              has acted in good faith.
            </li>
            <li>
              As part of Qore’s compliance with the anti-money laundering laws, Qore monitors lists identifying those
              individuals and entities who are the targets of various national and international financial sanctions
              regimes (<strong>“Restricted Persons”</strong>). If you or an individual or entity connected with you
              becomes a Restricted
              Person during while you are subscribed to the InsureMe Platform and hold an InsureMe Account, we may
              terminate your InsureMe Account, your access to the InsureMe Platform and/or your use of the Services.
            </li>
          </ol>
        </li>
        <!--End of Anti money laundering-->

        <!--Lipa Pole Pole Service-->
        <li>
          <h4>The Lipa Pole Pole Service</h4>
          <ol>
            <li>
              <strong>General</strong>
              <ol>
                <li>Once you have been registered on the InsureMe Platform, you may after selecting an Insurance Product
                  you wish to purchase, apply for the Lipa Pole Pole Service in respect of the Premium Amount due to the
                  insurer providing such Insurance Product by following the instructions on the InsureMe Menu, subject
                  to these Terms and Conditions.</li>
                <li>
                  Where you apply for a Lipa Pole Pole loan facility, Qore shall digitally appraise your application
                  according to the applicable loan appraisal processes of the InsureMe Platform. Qore reserves the right
                  at its sole discretion and without assigning any reason to approve or decline your application for a
                  Lipa Pole Pole loan facility.
                </li>
              </ol>
            </li>

            <li>
              <strong>Computation of Premium Instalments</strong>
              <ol>
                <li>Where your application for the Lipa Pole Pole loan facility is approved:
                  <ol>
                    <li>
                      Qore shall compute the monthly premium instalment payment (the <strong> Monthly Premium</strong>)
                      by taking the total
                      annual premium for the selected Insurance Product, the relevant interest and transaction costs (as
                      computed pursuant to clause 8.3 below) and dividing by twelve (12) calendar months;
                    </li>
                    <li>
                      You shall prior to procurement by Qore of the selected Insurance Product, pay a deposit premium
                      amount
                      equal to atleast two (2) times the Monthly Premium amount plus a deposit premium amount equal to a
                      Motor Third Party Only insurance policy valued at KES 7,500 (Kenya Shillings Seven Thousand Five
                      Hundred Only), including any applicable taxes, levies stamp duties and Transaction Fees thereon
                      (the <strong>Premium Deposit</strong>);
                    </li>
                    <li>
                      You hereby agree and confirm that Qore is entitled to restrict you from claiming in whole or in
                      part the Premium Deposit without incurring any liability to you whatsoever in that connection.
                    </li>
                    <li>
                      Upon receipt of the Premium Deposit, the InsureMe Platform will calculate the balance of the
                      premium payable to the Insurer and create a Lipa Pole Pole loan repayment schedule for the
                      remaining nine (10) months.
                    </li>
                    <li>
                      You shall pay such balance of the Lipa Pole Pole loan facility after deducting the Premium Deposit
                      within a period of two (2) to ten (10) months as selected by you on the InsureMe Menu. The maximum
                      duration within which the Lipa Pole Pole Loan together with the applicable interest and
                      transaction costs must be paid shall not exceed ten (10) months.
                    </li>
                  </ol>
                </li>
                <li>The total Premium Amount is subject to a minimum amount of Kenya Shillings fifty (Kshs. 50/=) and a
                  maximum amount of Kenya Shillings three hundred thousand (Kshs. 300,000/=) or such other minimum or
                  maximum amount as Qore may from time to time in its sole discretion determine and indicate on the
                  InsureMe Platform. </li>
                <li>Qore shall credit the proceeds of the Lipa Pole Pole loan facility, together with the Premium
                  Deposit into the Insurer’s account, less any deductions on account of applicable Transaction Fees and
                  applicable taxes. </li>
              </ol>
            </li>

            <li>
              <strong>Interest and Charges</strong>
              <ol>
                <li>
                  In consideration of Qore granting you a Lipa Pole Pole loan facility, you shall pay Qore a monthly
                  interest charge being 5.0% of the outstanding Lipa Pole Pole financed Premium Amount (the
                  <strong>Interest Charge</strong>).
                </li>
                <li>
                  The outstanding premium and Interest Charge shall be paid by you monthly and will be calculated on a
                  reducing balance basis. The first Lipa Pole Pole instalment shall be due on the 30<sup>th</sup> day
                  from the date
                  of disbursement of the Lipa Pole Pole loan facility (the <strong>1<sup>st</sup> Instalment Due
                    Date</strong>).
                </li>
                <li>
                  In consideration of Qore processing your application for insurance premium financing digitally, you
                  shall pay Qore a Transaction Fee of 1.0%, including any applicable taxes of the total calculated
                  Premium Amount payable to Qore.
                </li>
                <li>
                  You shall repay the Lipa Pole Pole loan facility in scheduled and equal monthly instalments as
                  calculated by the InsureMe Platform and displayed as the Lipa Pole Pole loan schedule on the InsureMe
                  Menu.
                </li>
                <li>
                  You shall make all repayments due from you to InsureMe in respect of the Interest Charge, Transaction
                  Fees and Penalty Interest (as defined in clause 8.4 below) using available payment channels on the
                  InsureMe Platform.
                </li>
              </ol>
            </li>

            <li>
              <strong>Default</strong>
              <ol>
                <li>In the event that you do not repay the first Lipa Pole Pole instalment in full within fourteen (14)
                  calendar days of the 1<sup>st</sup> Instalment Due Date:
                  <ol>
                    <li>
                      the InsureMe Platform will automatically charge a Penalty Interest of 7.5% on the outstanding
                      premium and interest amount such Penalty Interest being computed from the 1<sup>st</sup> Instalment Due Date
                      until the date of payment. The status of your loan will change to <strong>“OVERDUE”</strong>;
                    </li>
                    <li>in the event your policy was a comprehensive motor insurance policy:
                      <ol>
                        <li>Qore shall procure that your insurer downgrades your policy to a third party motor policy;
                        </li>
                        <li>The insurer shall deduct the cost of the third party cover and the associated levies and
                          taxes from the Premium Desposit; and</li>
                      </ol>
                    </li>
                    <li>if the policy is for third party only or third party fire & theft, then Qore shall procure that
                      the insurer cancels the policy with immediate effect.</li>
                  </ol>
                </li>
                <li>Qore reserves the right to vary the terms of the Lipa Pole Pole loan facility including the fees
                  payable thereon from time to time having regard to the prevailing rules and regulations of the Central
                  Bank of Kenya and the Insurance Regulatory Authority. </li>
                <li>Qore shall have a right of lien and set off over funds held in the Lipa Pole Pole instalment account
                  and any other Lipa Pole Pole instalment account held by you and/or any other account held by you on
                  the InsureMe Platform. </li>
              </ol>
            </li>

            <li>
              <strong>Your Representations and Warranties</strong>
              <ol>
                <li>
                  In consideration of Qore agreeing to advance to you a Lipa Pole Pole loan facility, you represent
                  and warrant to Qore that:
                  <ol>
                    <li>no credit facilities availed to you (including, if a body corporate or your related companies)
                      from any bank or financial institution have been recalled and/or no legal action has been
                      initiated by any bank or financial institution against you for non-payment of any such credit
                      facilities availed thereof; and </li>
                    <li>you have the power to enter into the transaction contemplated by these Terms and Conditions and
                      that neither the execution hereof nor the performance or observance of your obligations hereunder
                      will conflict with or result in any breach of any law regulation mortgage agreement or other
                      instruments whatsoever.</li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <strong>Events of Default</strong>
              <ol>
                <li>
                  You agree and acknowledges that any balance outstanding on the payment of the Lipa Pole Pole loan
                  facility shall be payable on demand together with interest and all other monies owed by you to Qore
                  upon the happening of any one or more of the following events (the <strong>Events of
                    Default</strong>):-
                  <ol>
                    <li>
                      the termination of the insurance policy for the Insurance Product in respect of which you applied
                      for a Lipa Pole Pole loan facility (the <strong>Policy</strong>) by either you or the Insurer;
                    </li>
                    <li>
                      the termination of these Terms and Conditions;
                    </li>
                    <li>
                      a breach by you of your obligations under these Terms and Conditions;
                    </li>
                    <li>
                      the appointment of a receiver(s), manager(s) or insolvency practitioner(s) appointed over you or
                      if Qore receives any notice of an intention to appoint any such receiver, manager or liquidator as
                      aforesaid;
                    </li>
                    <li>
                      any person (including yourself) lodging of a petition for bankruptcy or liquidation, as the case
                      may be, against you;
                    </li>
                    <li>
                      if it becomes illegal for Qore or yourself to continue with the present arrangement; or
                    </li>
                    <li>
                      if there are circumstances which in the sole opinion of the Qore may have an adverse effect on
                      your ability to perform your obligations under these Terms and Conditions.
                    </li>
                  </ol>
                </li>
                <li>
                  You shall immediately notify Qore in writing of the occurrence of any Event of Default as specified in
                  clause 8.6.1 above (or of any event which with the giving of notice or the passage of time or both
                  would constitute an Event of Default) or any event of whatever nature which could materially affect
                  your ability to perform your obligations hereunder or of the occurrence of any change in your business
                  or affairs which could adversely affect your ability to perform your obligations hereunder.
                </li>
              </ol>
            </li>
            <li>
              You confirm that you shall not be entitled to a refund of any of the Premium Deposit Amounts already paid
              to Qore, if Qore exercises its right under clause 8.4 above.
            </li>
            <li>
              You warrant to Qore that the Policy is valid and you covenant to Qore that the Policy shall remain valid
              throughout the course of your indebtedness with Qore and to give effect thereto you undertake not to
              cancel the Policy without Qore’s prior consent or do any act or commit any default whereby the Policy may
              become void or voidable or whereby an increased premium or charge may become payable (unless you promptly
              pay the additional charge or premium to the Insurer) provided that in case of default by you on any of the
              your obligations herein it will be lawful but not obligatory for Qore to do whatever it may deem necessary
              to make good such default and any money expended by Qore in that behalf with interest as provided above
              shall be debited in your InsureMe Account and shall be repaid by you on demand. The provisions of this sub
              clause shall be without prejudice to Qore’s rights set out in clause 8.4 above.
            </li>
            <li>
              You authorize and instruct the Insurer through the signing of these Terms and Conditions to register
              Qore’s interest in the Policy as a financier as well as Qore’s right under the power of attorney granted
              hereunder to terminate the Policy and to receive a refund of the Premium Amount. You further covenant with
              Qore that you shall procure that the Insurer complies with the aforesaid authorization and in any event
              you confirm that you shall hold any monies paid to you under the Policy by the Insurer in trust for Qore
              and shall immediately upon receipt surrender such monies to be credited to your InsureMe Account. This
              obligation shall subsist until Qore confirms in writing that the insurance premiums have been repaid in
              full.
            </li>
            <li>
              You appoint Qore to be your Attorney and in your name and on your behalf to execute and do any assurances
              acts, and things which you ought to execute and to do under the Policy including without limitation, the
              power to terminate the Policy and to give good receipt and discharge thereof for the refund of any Premium
              Amount or part thereof received by you on such termination or for such settlement paid under the Policy
              and you hereby ratify all acts or deeds done by Qore in the exercise of the power herein conferred and
              absolve from blame any and all parties giving effect to such power and you further confirm and agree that
              any Premium Amount or part thereof refunded as aforesaid or any settlement paid to Qore will first be
              applied towards repaying the Lipa Pole Pole loan facility and the balance (if any) released to you.
            </li>
            <li>
              You undertake to pay all stamp duty and other levies and taxes (if any) to which these Terms and
              Conditions or any other document executed pursuant to these Terms and Conditions may be subject to (which
              duties and taxes shall be debited in your InsureMe Account).
            </li>
            <li>
              You undertake to reimburse Qore on demand all expenses including legal expenses incurred by Qore in suing
              for or recovering any sum due to Qore under these Terms and Conditions or otherwise in protecting or
              enforcing its rights under these Terms and Conditions.
            </li>
            <li>
              If Qore shall so request you, you covenants to execute in favour of Qore by way of security a legal
              assignment or mortgage over the Policy or cede the Policy to Qore and you undertake to meet all costs and
              expenses including the legal fees payable as a result.
            </li>
            <li>
              You confirm that no failure or delay by Qore in exercising any right power or privilege under these Terms
              and Conditions shall impair the same or operate as a waiver for the same nor shall any single or partial
              exercise of any right power or privilege preclude any further exercise of the same or the exercise of any
              other right power or privilege.
            </li>
            <li>
              You will from time to time and at the request of Qore provide to Qore such information as Qore shall
              require.
            </li>
          </ol>
        </li>
        <!--End of Lipa Pole Pole Service-->

        <!--Covenants of the Insurer-->
        <li>
          <h4>Covenants of the Insurer</h4>

          <ol>
            <li>
              The Insurer, being the Guarantor, in consideration of the Premium Amount being paid to the Insurer by
              Qore, hereby irrevocably and unconditionally guarantees as primary obligor that on the request of Qore,
              the Insurer will refund directly to Qore with or without formal demand and in any event no later than
              seven (7) days from the first demand the pro rata refund (<strong>Refundable Premium</strong>) without any
              deduction or set
              off whatsoever on failure by you to meet any or all monthly payments and/or credit charges in respect to
              the insurance premium collectable from you together with interest or charges thereof. The Refundable
              Premium shall be calculated as follows:
              <br />
              The Premium Amount X (365 – number of days from the date of commencement of the policy to date of
              demand) / 365 days

            </li>
            <li>
              The right of Qore to the Refundable Premium and its interest as a financier or assignee shall be noted on
              the Policy or by way of an endorsement to be issued in respect thereof following the execution of these
              Terms and Conditions and the Insurer shall provide Qore written proof of compliance with this clause upon
              demand.
            </li>
            <li>
              The Insurer warrants that the right to cancel the Policy and to pay the Refundable Premium shall override
              and prevail over any conflicting provisions in the Policy and the Refundable Premium shall be deemed to be
              refundable notwithstanding that a claim has arisen or payment has been made under the Policy unless the
              payment is made to Qore.
            </li>
            <li>
              Upon the cancellation of the Policy hereunder, the Insurer shall comply with all statutory and common law
              obligations resulting from such cancellation and including without limitation the provision of notice to
              any third party of such cancellation where required.
            </li>
            <li>
              The Insurer covenants with Qore that the Policy is validly in existence and that the details set out in
              the Policy Schedule regarding the Policy are correct.
            </li>
            <li>
              The Insurer undertakes that a certificate under the hand of an officer of Qore as to the amounts payable
              hereunder shall be conclusive evidence that such amounts are due and shall be binding upon the Insurer and
              the Insurer confirms that no failure or delay on the part of Qore to cancel or terminate the agreement due
              to default by you shall impair the rights or privileges of Qore .
            </li>
            <li>This guarantee:
              <ol>
                <li>shall not be affected, discharged or diminished by any act or omission which would but for this
                  provision have exonerated a guarantor but would not have affected or discharged the Insurer’s
                  liability had it been a principal debtor.</li>
                <li>shall continue in force until all the amounts due to Qore under these Terms and Conditions have been
                  paid and when all liability hereunder shall cease save to the extent that Qore shall previously have
                  called upon the Insurer in writing to pay specified moneys payable then remaining outstanding.</li>
                <li>does not extend to liabilities incurred after the date of receipt by Qore of a notice of termination
                  by the Insurer and on service of such notice, the Insurer shall be at liberty to pay-off any moneys or
                  liabilities guaranteed by the Insurer and to provide cash cover in respect of any contingent
                  liabilities guaranteed.</li>
                <li>shall be governed and interpreted in all respects in accordance with the Laws of Kenya and the
                  parties hereto irrevocably submit to the jurisdiction of Kenyan courts.</li>
              </ol>
            </li>
            <li>
              The undertakings contained in this guarantee shall override any other undertakings given by the Insurer in
              any other documents.
            </li>
          </ol>
        </li>
        <!--End of Covenants of the Insurer-->

        <!--Lodging of Claims-->
        <li>
          <h4>Lodging of Claims</h4>
          <ol>
            <li>
              All claims under the Policy shall be made digitally via the InsureMe Platform provided however that prior
              to lodging a claim via the InsureMe Platform, you shall procure that:
              <ol>
                <li>
                  all Premium Amounts due under the Policy are up to date; and
                </li>
                <li>
                  all documentation required by the Insurer under the Policy are provided,
                </li>
              </ol>
            </li>
            <li>
              Subject to compliance by you of your obligations under clause 10.1, and provided that all other
              obligations under the Policy have been complied with, the Insurer shall process the claim in accordance
              with the provisions of the Policy and pay any amounts due under the Policy to the InsureMe Account.
            </li>
          </ol>
        </li>
        <!--End of Lodging of Claims-->

        <!--Other Products and Services-->
        <li>
          <h4>Other Products and Services</h4>
          <br />
          Qore may from time to time provide other products and services on the InsureMe Platform and extend the Lipa
          Pole Pole loan facility to such additional products and services and the provisions of clause 8 to 10
          (inclusive) shall apply with any necessary modifications to such additional products and services, provided
          however that subject to clause 1.2, Qore may vary these Terms and Conditions in respect of the applicability
          of the Lipa Pole Pole loan facility to such additional products and services.

        </li>
        <!--End of Other Products and Services-->

        <!--Notices-->
        <li>
          <h4>Notices</h4>
          <ol>
            <li>
              Qore may send information concerning your InsureMe Account via:
              <ol>
                <li>SMS to the mobile phone number associated with your InsureMe Account;</li>
                <li>your e-mail; </li>
                <li>push notifications; or</li>
                <li>such other channel as Qore may determine in future.</li>
              </ol>
            </li>
            <li>
              You acknowledge that you have no claim against Qore for damages resulting from losses, delays,
              misunderstandings, mutilations, duplications or any other irregularities due to transmission of any
              communication pertaining to your InsureMe Account.
            </li>
          </ol>
        </li>
        <!--End of Notices-->

        <!--Governing Law and Dispute Resolution-->
        <li>
          <h4>Governing Law and Dispute Resolution</h4>
          <ol>
            <li>
              These Terms shall be construed in accordance with and governed by the laws of Kenya.
            </li>
            <li>
              You may contact the Contact Centre to report any disputes, claims or InsureMe Account discrepancies.
            </li>
            <li>
              Any dispute arising out of or in connection with these Terms and Conditions that is not resolved by the
              Customer Care Centre representatives shall be referred to arbitration by a single arbitrator to be
              appointed by agreement between the parties or in default of such agreement within 60 days of the
              notification of a dispute, upon the application of either party, by the Chairman for the time being of the
              Chartered Institute of Arbitrators (Kenya Branch). Such arbitration shall be conducted in the English
              language in Nairobi in accordance with the Rules of Arbitration of the said Institute and subject to and
              in accordance with the provisions of the Arbitration Act 1995.
            </li>
            <li>
              To the extent permissible by Law, the determination of the Arbitrator shall be final, conclusive and
              binding upon the parties hereto.
            </li>
            <li>
              Notwithstanding the above provisions of this clause, a party is entitled to seek preliminary injunctive
              relief or interim or conservatory measures from any court of competent jurisdiction pending the final
              decision or award of the arbitrator.
            </li>
          </ol>
        </li>
        <!--End of Governing Law and Dispute Resolution-->

        <!--General Provisions-->
        <li>
          <h4>General Provisions</h4>
          <ol>
            <li>
              It is further agreed that if the Borrower is more than one person then all obligations on the part of
              the Borrower shall be joint and several. </li>
          </ol>
        </li>
        <!--End of General Provisions-->

        <!--Contact Information-->
        <li>
          <h4>Contact Information</h4>
          <ol>
            <li>
              If you would like more information concerning these Terms and Conditions and how they affect you, please
              contact our contact centre, (email <a href="mailto:info@insureme.co.ke">info@insureme.co.ke</a>, phone – <a href="tel:0709184444 ">0709 184 444 </a>).</li>
            <li>
              Your Personal Data may from time to time be transferred to Credit Reference Bureaus in line with
              applicable laws and regulations. </li>
          </ol>
        </li>
        <!--End of Contact Information-->

        <!--Miscellaneous-->
        <li>
          <h4>Miscellaneous</h4>
          <ol>
            <li>
              In the event that individual provisions of these Terms and Conditions shall be or become invalid or for
              any reason unenforceable, all other terms and conditions shall remain in full force and effect.
            </li>
            <li>
              No waiver by Qore of any breach, failure or default in performance by you, and no failure, refusal or
              neglect by Qore to exercise any right hereunder or to insist upon strict compliance with or performance of
              your obligations under these Terms and Conditions, shall constitute a waiver of the provisions of these
              Terms and Conditions and Qore may at any time require strict compliance with the provisions of these Terms
              and Conditions.
            </li>
            <li>
              The rights and remedies herein provided are cumulative and not exclusive of any rights or remedies
              provided by law.
            </li>
            <li>
              Qore may assign its rights and duties under these Terms and Conditions to any party at any time without
              any notice to you. You may not assign these Terms to anyone.
            </li>
            <li>
              No agency, partnership, joint venture or employment relationship is created by these Terms and Conditions
              or your use of the Services, and you do not have any authority of any kind to bind Qore in any respect
              whatsoever.
            </li>
            <li>
              If any provision of these Terms and Conditions shall be found by any duly appointed arbitrator, court or
              administrative body of competent jurisdiction to be invalid or unenforceable the invalidity or
              unenforceability of such provision shall not affect the other provisions herein.
            </li>
            <li>
              Any addition or alteration to these Terms and Conditions may be made from time to time by Qore and of
              which notice has been given to you by way of publication as provided in subparagraph 12.1 shall be binding
              upon you as fully as if the same were contained in these Terms and Conditions.
            </li>
            <li>
              Qore will have no liability to you or to any third party for any failure by Qore to perform its
              obligations under these Terms in the event that such non-performance arises as a result of the occurrence
              of an event beyond the reasonable control of Qore, including, without limitation, an act of war or
              terrorism, natural disaster, failure of electricity supply, riot, civil disorder, or civil commotion or
              other Force Majeure event.
            </li>
            <li>
              You hereby waive any and all defences you may have based on the electronic form of these Terms and
              Conditions and the lack of signing by the parties hereto to execute these Terms and Conditions.
            </li>
            <li>
              Except with respect to your obligation to pay charges, neither Party shall be liable for any loss
              resulting from delay or failure to perform these Terms and Condition, either in whole or in part, where
              any such delay or failure is due to a Force Majeure Event.
            </li>
          </ol>
        </li>
        <!--End of Miscellaneous-->

      </ol>


      <!--
      <ol>
        -- Term 1

        <li>

          <h4>Introduction</h4>

          <ol>

            <li>These terms and conditions (hereinafter referred to as the Terms and Conditions) are a legal agreement
              between you and Mobile Financial Solutions Limited (herein after referred to as MFS) applicable to the
              registration, access and use of the InsureMe Application (the InsureMe App) including the Services (as
              hereinafter defined) (hereinafter collectively referred to as the InsureMe Platform) offered by MFS and of
              which a resultant virtual account has been opened by you.
            </li>
            <li>MFS reserves the right to update and change these Terms and Conditions by posting updates and changes to
              the InsureMe App. You are advised to check these Terms from time to time for any updates or changes that
              may impact you. Your continued use of the InsureMe App will be deemed to constitute acceptance of the
              Terms and Conditions.
            </li>
            <li>These Terms and Conditions and any amendments or variations thereto shall come into force on the
              Effective Date.
            </li>

          </ol>
        </li>

        END  Term 1

      </ol>
    -->

    </article>
  </section>

</section>
