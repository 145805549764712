export class Payment{

    paymentFrequency:string = "";
    paymentPhoneNumber:string = "";
    amount:number = 0;
    paymentType:string = "LIPA_FULL";
    depositAmount:number = 0;
    period:number = 0;
	//quoteRef:string = "";
	
}
