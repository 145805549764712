import { Component, OnInit } from '@angular/core'
import { FormControl,FormGroup } from '@angular/forms';

@Component({
  selector: 'app-myname',
  templateUrl: './myname.component.html',
  styleUrls: ['./myname.component.scss']
})
export class MynameComponent implements OnInit {

  nameForm:FormGroup;

  constructor(){

    this.nameForm = new FormGroup({

      gender:new FormControl(''),
      timeofDay:new FormControl(''),
      seasonofbirth:new FormControl(''),
      fathersName:new FormControl(''),
      spritAnimal:new FormControl(''),
      selfPraiseName:new FormControl(''),
      familyName:new FormControl(''),
      clansLocation:new FormControl(''),
      clansName:new FormControl(''),
      attributalName: new FormControl(''),
      favoriteAncestor:new FormControl('')

    })
  }

  ngOnInit(): void {
    
  }

}
