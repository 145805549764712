import {
  Component,
  OnInit
} from '@angular/core';

import $ from 'jquery';

import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  siteImages: any = [];

  constructor(
      private titleService: Title,
      private metaService:Meta,
  ) {}

  ngOnInit(): void {

      this.titleService.setTitle("Insure Me - Faqs");

      this.metaService.updateTag(
          { name: 'keywords', content: 'General Car Insurance FAQs, InsureMe, InsureMe FAQs, InsureMe Facts, Car Insurance claims, Car Valuation, Claims FAQs, Lipa Pole Pole FAQs, Insurance Frequently Asked Questions, Insurance Premium financing FAQs, Car Insurance Facts, Private car insurance FAQs, ' },
      );

      this.metaService.updateTag(
          { name: 'description', content: 'Want to Learn More About InsureMe Car Insurance Cover? View Our Frequently Asked Questions. Understand Why We Are Trusted By Hundreds of Customers. '
          }
      );

    $(".accordionTitle").click(this.openAccordion);

    $(".faqHeader").click(this.toggleTabs);

  }

  ngAfterViewInit(): void {
    this.getImages();
  }

  getImages() {

    $("img").each((index, value) => {


      if ($(value).attr("src") != "" || $(value).attr("src") == "undefined" || $(value).attr("src") == null) {

        this.siteImages.push($(value).attr("src"));

      }

    });

  }

  openAccordion() {

    $(".accordion").stop().slideUp();

    if ($(this).hasClass("open")) {

      $(".accordionTitle").removeClass("open");
      $(this).removeClass("open").next(".accordion").stop().slideUp();
    } else {

      $(".accordionTitle").removeClass("open");
      $(this).addClass("open").next(".accordion").stop().slideDown();

    }

  }

  toggleTabs() {

    $(".accordionContainer").stop().slideUp();

    if ($(this).hasClass("activeFaq")) {

      $(".faqHeader").removeClass("activeFaq");
      $(this).removeClass("activeFaq").next(".accordionContainer").stop().slideUp();

    } else {

      $(".faqHeader").removeClass("activeFaq");
      $(this).addClass("activeFaq").next(".accordionContainer").stop().slideDown();

    }
  }

}
