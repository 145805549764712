import { Component, OnInit } from '@angular/core';

import { ActivatedRoute,Router } from "@angular/router";

import {Document,SelectedBenefit} from '../../../_models/index';


import {Endorsement} from '../../../_models/policy/Endorsement.model';

import {BenefitsEndorsement} from '../../../_models/policy/BenefitsEndorsement.model';

import {CustomerService} from '../../../_services/customer.service';

import {AuthenticationService} from './../../../_services/authentication.service';

import {UserService} from './../../../_services/user.service';

import {PolicyService} from './../../../_services/policy/policy.service';

import {PaymentService} from './../../../_services/payment.service';

import {VehicleService} from './../../../_services/vehicle.service';

import $ from 'jquery';

@Component({
    selector: 'app-policies',
    templateUrl: './policies.component.html',
    styleUrls: ['./policies.component.css']
})

export class PoliciesComponent implements OnInit {

    siteImages:any = [];

    policyId:string;
    policyDetails:any;
	loanBalance:number = 0;
	totalTaxes:number;
	paymentType:string = "Lipa Full"
    paymentSchedule:any = [];
    benefits:any = [];
	endorsedBenefits:any = {};

	policyBenefits:any =[];
	benefitsTotal:number = 0;
	selectedBenefits: any = [];
	benefitsReference: SelectedBenefit = new SelectedBenefit();

    showModal:boolean = false;

    modalTitle: string = "";
    modalDescription: string = "";
    modalLink: string;
    modalLinkText: string = "Proceed";
    modalType:string = "info";

    loanCleared:boolean = false;
    loanStatus:string = "";

    //paymentData:any = {};
    modalData:any = {};

	valuationSummary:any = {};

    userProfile:any = {};

    phoneNumber:string = "";
    customerName: string = "";

    loading:boolean = false;
	disableProgress: boolean = false;

	policyEndorsement:Endorsement = new Endorsement();

	benefitsEndorsement:BenefitsEndorsement = new BenefitsEndorsement();

	pendingDocumentsArray:any = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService:UserService,
        private paymentService:PaymentService,
		private policyService:PolicyService,
		private customerService: CustomerService,
		private vehicleService:VehicleService,
    ) {

    }

    ngOnInit(): void {

        $(".accordionTitle").on('click',this.openAccordion);


        this.policyId = this.route.snapshot.paramMap.get('policyId');

		this.benefitsEndorsement.policyNo = this.policyId;

        this.loading = true;

        this.userService.getSinglePolicy(this.policyId).subscribe(response =>{

            // console.log(response);


            if(response["success"]){

				$(".accordionTitle:first").addClass("open").next(".accordion").stop().slideDown();

                this.loading = false;

                this.policyDetails = response["data"]["policy"];
				this.loanBalance = response["data"]["totalLoanBalance"];

				this.selectedBenefits = this.policyDetails["additionalBenefits"];

				this.totalTaxes = parseInt(this.policyDetails?.taxesAndLevies?.phcf) + parseInt(this.policyDetails?.taxesAndLevies?.stampDuty) + parseInt(this.policyDetails?.taxesAndLevies?.transactionLevy);


				this.paymentType = response["data"]["paymentType"].replace("_"," ");

                this.paymentSchedule = response["data"]["payment"];

                this.benefits = response["data"]["additionalBenefit"];

                this.loanStatus = response["data"]["loanStatus"];

                if(response["data"]["loanStatus"] === "CURRENT" || response["data"]["loanStatus"] === "DEFAULT"){

                    this.loanCleared =  false;

                }else{

                    this.loanCleared =  true;

                }

                // this.checkPolicyPaymentStatus(this.paymentSchedule);

            }else{

                this.modalTitle = "Policy loading error";
                this.modalDescription = response["message"];
                this.modalLink = null;
                this.modalLinkText = "";
                this.showModal = true;

				$(".modal").fadeIn();

                this.loading = false;

            }

        },error =>{

            this.modalTitle = "Error";
            this.modalDescription = "An error occurred while processing your policy request";
            this.modalLink = null;
            this.modalLinkText = "";
            this.showModal = true;

			$(".modal").fadeIn();

            this.loading = false;

        });

		this.vehicleService.getValuationReportByPolicyNumber(this.policyId).subscribe(response => {

  		  if(response["success"]){

			  this.valuationSummary = response["data"];

  		  }else{

			  // show modal error

  		  }

  	  },error => {

		  // show modal error

  	  });

		this.policyService.getBenefitsByPolicyId(this.policyId).subscribe(response => {

			if(response["success"]){

				this.policyBenefits = response["data"]

			}else{


			}

		},error => {


			// Nofify of error loading benefits

		})

        this.userProfile = JSON.parse(localStorage.getItem("insuremeRiders"));

        this.phoneNumber = this.userProfile.phoneNumber.replace("254","0");
        this.customerName = this.userProfile.customerName;

    }

    openAccordion(event){

        $(".accordion").stop().slideUp();

        if($(event.target).hasClass("open")){

            $(".accordionTitle").removeClass("open");
            $(event.target).removeClass("open").next(".accordion").stop().slideUp();

        }else{

            $(".accordionTitle").removeClass("open");
            $(event.target).addClass("open").next(".accordion").stop().slideDown();

        }

    }

	openDynamicAccordion(event){

        $(".accordion").stop().slideUp();


  	  if($(event.target).parent(".accordionTitle").hasClass("open")){

  		  $(".accordionTitle").removeClass("open");
  		  $(event.target).parent(".accordionTitle").removeClass("open").next(".accordion").stop().slideUp();

  	  }else{

  		  $(".accordionTitle").removeClass("open");
  		  $(event.target).parent(".accordionTitle").addClass("open").next(".accordion").stop().slideDown();

  	  }

    }

    reloadPolicyView(){


        if($(".modalContainer h3").text() === "Payment processing"){

            window.location.reload();

        }

    }


	/*
		1. Handle file input change
		2. Ensure files are greater than 0
		3. Aquire individual entry form data
		4. Aquire fileName,fileType,documentType,fileSize,fieldName
		5. Confirm the file extensions and do an exec function check
		6. In case file does not match extension, show error message and reset label value
	*/
	onFileChange(event, form) {

		if (event.target.files.length > 0) {

			const formDataMultiple = new FormData();

			for (var i = 0; i < event.target.files.length; i++) {

				let fileName = event.target.files[i].name;
				let fileType = event.target.files[i].type;
				let documentType = event.target.files[i].type;
				let fileSize = event.target.files[i].size;
				let fieldName = event.target.name;

				// /(\.jpg|\.jpeg|\.png|\.gif)$/i;

				let allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png)$/i;

				if (allowedExtensions.exec(fileName)) {

					let fileReference = new Document();

					if (fileSize < 5242000) {

						fileReference.fileType = fileType;
						fileReference.documentType = documentType;
						fileReference.document = fileName;
						fileReference.reference = fieldName;

						// this.policyData.policyDetails.documents.push(fileReference);
						// this.uploadFiles.push(event.target.files[i]);

						formDataMultiple.append("file", event.target.files[i], event.target.files[i].name);

						$(event.target).prev(".uploadLabel").text(fileName);

						this.disableProgress = false;

					} else {

						$(event.target).prev(".uploadLabel").text(fileName + " Too Large to upload");


						if (fieldName === "nationalIdUpload") {

							form.reset();

						} else if (fieldName === "logBook") {

							form.reset();

						}


						setTimeout(() => {
							$(event.target).prev(".uploadLabel").text("Choose File To Upload");
						}, 3000);

					}

				} else {


					$(event.target).prev(".uploadLabel").text(fileName + " contains invalid extension");

					if (fieldName === "nationalIdUpload") {

						form.reset();

					} else if (fieldName === "logBook") {

						form.reset();

					}

					setTimeout(() => {
						$(event.target).prev(".uploadLabel").text("Choose File To Upload");
					}, 3000);

				}

			}

		} else {

			// Delete from array

			$(".uploadLabel").text("Choose File To Upload");
			$("#uploadid").val("");

		}


	}

	removeSelectedFile(fieldName) {

		// document.getElementById(fieldName).value = null;
		// $("#"+fieldName).val(null);

	}

    checkPolicyPaymentStatus(policyData:any){


        for (let i = 0; i < policyData.length; i++) {

            if(policyData[i]["status"] === "PAID"){

                this.loanCleared = true;

            }else{

                this.loanCleared = false;

            }

        }

    }

    /*
        Post payment details
        Requires paymentPhoneNumber,amount,policyNumber
    */
    postPayment(amount,policyNumber,paymentPhoneNumber){

        this.modalData = {
            "amount":amount,
            "policyNumber":policyNumber,
            "paymentPhoneNumber":paymentPhoneNumber
        }

        this.loading = true;

        this.modalTitle = "Processing";
        this.modalDescription = `Dear ${this.userProfile.customerName}, please wait as your payment for  is processing`;
        this.modalLink = null;
        this.modalLinkText = "";
        this.modalType = "processInstallmentPay";
        this.showModal = true;
        $(".modal").fadeIn();
        this.loading = false;


        this.modalData.date = new Date();

        console.log(this.modalData);

        this.paymentService.postRepayment(this.modalData).subscribe(response =>{

            // console.log(response);

            if(response["success"]){

                this.modalTitle = "Payment processing";
                this.modalDescription = response["message"];
                this.modalLink = null;
                this.modalLinkText = "Ok";
                this.modalType = "info";
                this.showModal = true;
                $(".modal").fadeIn();
                this.loading = false;

                setTimeout(() => {
                  window.location.reload();
              }, 7000);


            }else{

                this.modalTitle = "Error";
                this.modalDescription = response["message"];
                this.modalLink = null;
                this.modalLinkText = "";
                this.showModal = true;
                this.modalType = "info";
                $(".modal").fadeIn();
                this.loading = false;

            }

        },error => {

            // console.log(error);
            this.modalTitle = "Error";
            this.modalDescription = error.error["message"];
            this.modalLink = null;
            this.modalLinkText = "";
            this.showModal = true;
            $(".modal").fadeIn();

            this.loading = false;

        });

    }

	declineEndorsement(event){

  	  this.showModal = false;

    }

    acknowledgeEndorsement(event){

  	  // hide modal
  	  this.showModal = false;

  	 // Show payment confirmation view
  	 $(".accordionContainer,.benefitsAccordion  .valuationDetails").stop().slideUp();

  	 $(".yellowHighlight").stop().slideDown();

	 $('html, body').stop().animate({
		 scrollTop: $(".yellowHighlight").offset().top-20
	 }, 500);

    }

	scrollToTarget(target){

		 $('html, body').stop().animate({
			 scrollTop: $("."+target).offset().top-20
		 }, 500);

	}


	submitBenefits() {

		this.loading = true;

		this.benefitsEndorsement.benefits = this.selectedBenefits.filter(benefit => benefit.name !== "Valuation service");

		this.policyService.getBenefitEndorsementQuote(this.benefitsEndorsement).subscribe( response => {

			if (response['success']) {

				this.loading = false;

				this.endorsedBenefits = response['data'];

				$(".benefitsAccordion .valuationDetails").stop().slideDown();

				setTimeout(() => {

					this.scrollToTarget('benefitsEndorsed');

				}, 500);



			} else {

				this.loading = false;

				this.modalTitle = "Benefits Submission Error";
				this.modalDescription = response["message"];
				this.showModal = true;
				this.modalType = "info";
				$(".modal").fadeIn();

			}

		}, error => {

			this.loading = false;
			this.modalTitle = "Error";
			this.modalDescription = error.error["message"];
			this.showModal = true;
			this.modalType = "info";
			$(".modal").fadeIn();


		});

	}

	changePaymentPhoneNumber(event){

  	  let name = event.target.name;
  	  let value = event.target.value;

  	  this.phoneNumber = value.replace("254 ","0").replace("+254","0");

    }

    updatePaymentPhoneNumber(){

  	  this.policyEndorsement.paymentPhoneNo = this.phoneNumber;

    }

    restoreEndorsementAccordion(){

  	  $(".accordionContainer").stop().slideDown();

   	 $(".yellowHighlight").stop().slideUp();

    }

    approveEndorsement(event){

  	  this.modalTitle = "Processing policy endorsement";
  	  this.modalDescription = "Your selected policy is in process of being endorsed";
  	  this.modalLink = null;
  	  this.modalLinkText = "";
  	  this.showModal = true;
	  $(".modal").fadeIn();
  	  this.modalType = "process";

  	  // Send endorsement Request

  	  this.policyService.endorsePolicy(this.policyEndorsement).subscribe(response => {

  		  if(response["success"]){

  			  this.modalTitle = "Endorsement successful";
  			  this.modalDescription = response["message"];
  			  this.modalLink = null;
  			  this.modalLinkText = "Ok";
  			  this.showModal = true;
			  $(".modal").fadeIn();
  			  this.modalType = "info";

  		  }else{

  			   this.modalTitle = "Endorsement failed";
   			  this.modalDescription = "Your selected policy has been not been endorsed";
   			  this.modalLink = null;
   			  this.modalLinkText = "";
   			  this.showModal = true;
			  $(".modal").fadeIn();
   			  this.modalType = "info";

  		  }

  	  },error => {

  		  this.modalTitle = "Network error";
  		 this.modalDescription = error.error.message;
  		 this.modalLink = null;
  		 this.modalLinkText = "";
  		 this.showModal = true;
		 $(".modal").fadeIn();
  		 this.modalType = "info";


  	  });

    }

    proceedWithCurrentEndorsement(valuationItem){

  	  this.policyEndorsement.policyNo = this.policyId;

  	  this.policyEndorsement.endorsementAmount = "0";

  	  this.policyEndorsement.endorsementType = "VALUATION";

  	  this.policyEndorsement.endorsementQuote = valuationItem?.endorsementDetails?.endorsementQuote;

  	   this.policyEndorsement.endorsementBenefits = valuationItem?.endorsementDetails?.endorsementQuote?.benefits;

  	   this.policyEndorsement.endorsementAction = "NO_ENDORSEMENT";

  	   this.policyEndorsement.comment = "NO_ENDORSEMENT";

  	   this.modalTitle = "Policy endorsement confirmation";
  	   this.modalDescription = "Are you sure you want to endorse policy ?";
  	   this.modalLink = null;
  	   this.modalLinkText = "";
  	   this.showModal = true;
	   $(".modal").fadeIn();
  	   this.modalType = "confirmation";

    }

    endorsePolicy(valuationItem,type?){

  	  this.policyEndorsement.policyNo = this.policyId;

	  if(type !== "benefits"){

		  this.policyEndorsement.endorsementAmount = valuationItem?.endorsementDetails?.endorsementAmount;

	  	   this.policyEndorsement.endorsementType = valuationItem?.endorsementDetails?.endorsementType;

	  	    this.policyEndorsement.endorsementQuote = valuationItem?.endorsementDetails?.endorsementQuote;

	  		 this.policyEndorsement.endorsementBenefits = valuationItem?.endorsementDetails?.endorsementQuote?.benefits;

	  		 this.policyEndorsement.endorsementAction = valuationItem?.endorsementDetails?.endorsementAction;

	  		 this.policyEndorsement.comment = valuationItem?.endorsementDetails?.endorsementAction;

	  }else{

		  this.policyEndorsement.endorsementAmount = valuationItem?.endorsementAmount;

	  	   this.policyEndorsement.endorsementType = "ADDITIONAL_BENEFIT";

		   this.policyEndorsement.endorsementAction = "ADDITIONAL_PAYMENT";

		   this.policyEndorsement.comment = "ADDITIONAL_PAYMENT";

	  	    this.policyEndorsement.endorsementQuote = valuationItem?.endorsement_quote;

			this.policyEndorsement.endorsementComputations = valuationItem?.endorsementComputations;

	  		 // this.policyEndorsement.endorsementBenefits = valuationItem?.endorsement_quote?.benefits;

			  this.benefitsEndorsement.benefits = this.selectedBenefits.filter(benefit => benefit.name !== "Valuation service");

		  	   this.policyEndorsement.endorsementBenefits = this.benefitsEndorsement?.benefits;

			 this.policyEndorsement.paymentPhoneNo = this.phoneNumber;


	  }


  		  this.modalTitle = "Policy endorsement confirmation";
  		  this.modalDescription = "Are you sure you want to endorse policy ?";
  		  this.modalLink = null;
  		  this.modalLinkText = "";
  		  this.showModal = true;
		  $(".modal").fadeIn();
  		  this.modalType = "confirmation";


    }

	onBenefitChange(event, index) {

		let name = event.target.name;
		let value = event.target.value;
		let key = event.target.dataset.key;

		for (let i = 0; i < this.selectedBenefits.length; i++) {

			if (this.selectedBenefits[i].id === index) {

				this.selectedBenefits[i].additionalAmount = value;

			}
		}

	}

	onBenefitToggle(event, index, rateMultiplier, defaultAmount) {

		let name = event.source.name;
		let value = event.checked;
		let benefitId = event.source.id;

		if (value === true) {

			let instanceReference = new SelectedBenefit();

			// assign benefits array to selected benefit
			instanceReference.id = parseInt(benefitId);
			instanceReference.name = name;
			//instanceReference.premiumAmount = this.premiumAmount;
			instanceReference.checked = value;

			if (rateMultiplier === 'default') {

				instanceReference.additionalAmount = parseInt(defaultAmount);
				this.enableBenefitConditional(event, index + 1);


			} else if (rateMultiplier === 'vehicle') {

				// instanceReference.estimatedValue = parseInt(this.vehicleDetails.carDetails.estimatedValue);

			}

			this.selectedBenefits.push(instanceReference);


		} else {


			this.selectedBenefits = this.selectedBenefits.filter(benefit => benefit.id !== parseInt(benefitId));

			if (this.selectedBenefits.length < 1) {

				// this.submitBenefits();
				this.benefitsTotal = 0;
				// this.policyData.policyDetails.benefits = this.selectedBenefits;

			}

			if (rateMultiplier === 'default') {

				this.disableBenefitConditional(event, index + 1);

			}


		}

		this.benefitsEndorsement.benefits = this.selectedBenefits;

	}

	enableBenefitConditional(event, index) {

		$(".benefitsContainer .row:nth-child(" + index + ") .benfitField").show();
	}

	confirmBenefitConditional(multiplier,checked):string{

		if( multiplier === 'default' && checked === true ){

			return 'visible';

		}else{

			return 'hide';

		}

	}

	disableBenefitConditional(event, index) {

		$(".benefitsContainer .row:nth-child(" + index + ") .benfitField").hide();
	}

}
