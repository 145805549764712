import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import { environment } from '../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})

export class VehicleService {

    constructor(
        private http:HttpClient
    ){

    }

    getAllVehicleListing(){


        return this.http.get(`${environment.apiUrl}vehicle/findByName`);

    }

    getAllVehicleListingJSON(){

        return this.http.get("/assets/data/VehicleList.json");

    }

    getVehicleByString(vehicle:string):Observable<any>{

        return this.http.get(`${environment.apiUrl}vehicle/findByName?name=${vehicle}`);

    }

    getVehicleByMake(vehicle:string){

        // return this.http.get(`${environment.apiUrl}vehicle/findByName?name=${vehicle}`).pipe(
        //     map((response) => response.data.map(item => item['make']+' '+item['model']));
        // );

    }

    scheduleValuation(valuationInfo){

        return this.http.post(`${environment.customerFacingApi}valuation/schedule`, valuationInfo);

    }

	getValuationReportByNationalId(nationalId){

		return this.http.post(`${environment.customerFacingApi}valuation/valuationResultByNationalId`, {"nationalId":nationalId});

	}

	getValuationReportByPolicyNumber(policyNumber){

		return this.http.post(`${environment.customerFacingApi}valuation/valuationResult`, {"policyNo":policyNumber});

	}



}
