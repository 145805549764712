import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thankyouclaim',
  templateUrl: './thankyouclaim.component.html',
  styleUrls: ['./thankyouclaim.component.css']
})
export class ThankyouclaimComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

      localStorage.removeItem("claim");
      localStorage.removeItem("eventDate");
      localStorage.removeItem("selectedClaim");
      localStorage.removeItem("currentStep");
      localStorage.removeItem("customerVerified");
      localStorage.removeItem("eventTime");
      

  }

}
