export class DamageClaimDriverDetails{

  name:string = "";
  phoneNumber:string = "";
  occupation:string = "";
  dateOfBirth:string = "";
  idNumber:string = "";
  kraPin:string = "";
  copyOfDriverLicense: string = "";
  isDriverEmployedByYou:string = "false";
  howLongHasHeBeenWorking:string = "";
  driverToBlameForAccident:string = "false";
  hasDriverAdmittedLiability:string = "false";
  isDriverLicenseValid:string = "false";
  DoesDriverOwnsVehicle:string = "false";
  nameOfInsurer:string = "";
  vehiclePolicyNumber:string = "";
  driverHadPreviousAccidents:string = "false";
  dateOfAccident:any = [];

}
