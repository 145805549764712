import { Component, OnInit } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-lipapolepole-plan',
  templateUrl: './lipapolepole-plan.component.html',
  styleUrls: ['./lipapolepole-plan.component.css']
})
export class LipapolepolePlanComponent implements OnInit {

  userProfile: any;
  siteImages: any = [];
  pageLoaded: boolean;

  constructor() { }

  ngOnInit(): void {
    this.getImages();
  }

  getImages() {

    $("img").each((index, value) => {


      if ($(value).attr("src") != "" || $(value).attr("src") == "undefined" || $(value).attr("src") == null) {

        this.siteImages.push($(value).attr("src"));

      }

    });

  }

}
