<div class="faqGroupContainer">

    <div class="faqHeader activeFaq">
        <h2>Lipa Pole Pole FAQs</h2>
    </div>

    <!--  Accordion Container  -->
    <div class="accordionContainer">
        <!--  Single Accordion  -->
        <div class="singleFAQ" *ngFor="let faq of lipaPolePoleFaqs">

            <h3 class="accordionTitle" (click)="openAccordion($event)">
                
                {{faq.question}}

                <fa-icon

                icon="plus-circle"
                class="arrow closed"></fa-icon>

                <fa-icon

                    icon="minus-circle"
                    class="arrow open"></fa-icon>

            </h3>

            <div class="accordion">
                <p>
                    {{faq.answer}}
                </p>
            </div>

        </div>
        <!--  End of Single Accordion  -->

    </div>

</div>