import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import {TooltipPosition} from '@angular/material/tooltip';
import {VehicleService} from '../../_services/vehicle.service';
import {QuoteService} from '../../_services/quote.service';
import {Quote}  from '../../_models/index';
import {CustomerLead,PolicyData} from '../../_models/index';

import { HttpClient } from '@angular/common/http';


import $ from 'jquery';

@Component({
    selector: 'app-motorbanner',
    templateUrl: './motorbanner.component.html',
    styleUrls: ['./motorbanner.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers:[VehicleService,QuoteService],
})

export class MotorbannerComponent implements OnInit {

    quoteReceived:boolean = false;
    policySummary:any;
    vehicleDetails:any;
    carSelection = new FormControl('');
    carYom = new FormControl();
    options: any;
    filteredOptions: Observable<Object[]>;
    termsCheckBox:boolean = false;
    resetCar:boolean = false;
    quote:Quote = new Quote();
    searcTimer: any;
    delaySearch: boolean = true;
    yearsArray:any = [];

    //loading boolean triggers loader
    loading:boolean = false;

    //Modal related variables
    showModal:boolean = false;
    modalTitle:string = "";
    modalDescription:string = "";
    modalLink:string;
    modalLinkText:string = "Proceed";

    formattedVehicleJSON:any = [];

	leadData :CustomerLead = new CustomerLead();
	policyData:PolicyData = new PolicyData();

    constructor(
        private vehicleService:VehicleService,
        private quoteService:QuoteService,
        private router:Router,
        private http: HttpClient
    ){

		

    }

    ngOnInit(): void {

		// console.log(this.policyData);

        this.animateCar();

        this.vehicleService.getAllVehicleListingJSON().subscribe(data => {

            this.options = data;

            console.log(this.options);

            // this.processVehicleMakes(this.options);

         });

        // Generate years and loop them into an array
        for(var i= new Date().getFullYear(); i > 1970; i--){

            this.yearsArray.push(i);

        }

        this.filteredOptions = this.carSelection.valueChanges.pipe(
            startWith(''),
            map(value => this.filter(value))
         );


         // Add class to heritage summary icon to make is smaller
         $(".claimSummary:last-child").addClass("heritage");


    }

    processVehicleMakes(options){


        for(let i=0; i < options.length; i++){

            this.formattedVehicleJSON.push({text:options[i].text.replace(" : "," "),value:options[i].text});
        }


    }

    ngAfterContentChecked() {


    }

    // Remove colon from vehicle name

    formatVehicleName(name){

        return name.replace(":", "");

    }

    // Combine vehicle make and model and return pairing
    formatModel(make,model){

        return make + ' ' +model;

    }

    // Tab Quote summary to switch among cover types
    tabQuoteSummary(event,tabname){


        $(".tabNavigation button").removeClass("selected");
        $(event.target).addClass("selected");
        $(".tabContent").hide();
        $(".tabContent."+tabname).show();



    }

    // Initiate summary tabs after response from endpoint
    inititateSummaryTabs(){

        $(".quoteSummary .tabContent").hide();
        $(".quoteSummary .tabContent:first").show();
        $(".tabNavigation button").removeClass("selected");
        $(".tabNavigation button:first").addClass("selected");
        $(".tabNavigation.mobile:first button").addClass("selected");

    }

    //
     filter(value: string): Object[] {

         // this.quote.vehicle = value;

        const filterValue = value.toLowerCase();

        return this.options.filter(option => option.toLowerCase().includes(filterValue));

    }

    filterDynamic(value:string){

        this.vehicleService.getVehicleByString(value).subscribe(response =>{

            this.options = response.data;

        })
    }


    // Animate vehicle after component is loaded
    animateCar(){

        $(".traffic").animate({
            left:"100%"
        },6000);

    }

    seedTraffic():void{

        this.resetCar = true;

        $(".traffic").css({
            left:"-200px"
        });

    }


    // Show summary on successful submission by customer
    /*
        1. When user submits details get - car make and model, estimate value and Year of manufacture
        2. Submit values to quote service
        3. Set loading and quote received boolean to respective values
        4. On successful response from quote service
            1. set loading to false
            2. Extract vehicle details from response
                1. Save vehicle response to local storage
                2. Assign vehicle details to variables
            3. Assign policy summary details to variable
            4. Assign quote received to true
            5. Loop through navigation items
            6. Loop through tab content
            7. Set first tab navigation to class selected
            8. Hide all tab content and show first child
        5. If reseponse is failed or errors or exception occurs
            1. Loading to false
            2. quote reveiced false
            3. Show modal overlay with error message or expception message
    */
    showSummary(){


        $(".quoteSummary").show();
        this.quoteReceived = false;
        this.loading = true;

        this.quoteService.getQuote(this.quote).subscribe(response => {

            if(response["success"]){

                this.loading = false;

                $(".quoteOverlay").fadeIn();
                $(".introduction h1,.introduction p").hide();

                $(".formQuote label").addClass("white");

                this.scrollDownPage();

                this.vehicleDetails = response.data.shift();

				// this.quoreRef = response.data.pop();

                this.policySummary = response.data;

                this.quoteReceived = true;

                $(".coverSummary").hide();

                setTimeout(() => {
                    this.inititateSummaryTabs();
                }, 300);

            }else{

                this.loading = false;
                this.quoteReceived = false;
                this.showModal = true;
                this.modalTitle = "Error loading summary";
                this.modalDescription = response["message"];

                this.hideSummary();
                $(".quoteSummary").hide();

                $(".formQuote label").removeClass("white");

                $(".modal").fadeIn();

            }
            //Show summary only on succesfull response from api


        },error => {

            this.loading = false;
            this.quoteReceived = false;

            this.showModal = true;
            this.modalTitle = "Error";
            // this.modalDescription = error.error["message"];
            this.modalDescription ="Oops something went wrong, please try again";

            this.hideSummary();
            $(".quoteSummary").hide();

            $(".modal").fadeIn();

        });


    }

    scrollDownPage(){

        let targetDiv = $(".submitView");

        $('html, body').stop().animate({
            scrollTop: targetDiv.offset().top-30
        }, 350);

    }

    scrollUpTerms(){
        $('html, body').stop().animate({
            scrollTop: $(".termsModal").offset().top-20
        }, 500);
    }

    // Hide quote summary when user clicks the closer or back button
    /*
        1. Hide quotesummary and quote Overlay
        2. Show cover summary
        3. Show introduction heading and paragraph
        4. Revert form label from white back to black
    */
    hideSummary(){

        $(".quoteSummary,.quoteOverlay").hide();
        $(".coverSummary").show();
        $(".introduction h1,.introduction p").show();
        $(".formQuote label").removeClass("white");
        this.quoteReceived = false;

        $('html, body').stop().animate({
            scrollTop: $(".introduction").offset().top-20
        }, 500);

    }

    /*

    Filter vehicle model when use types input
    1.  convert value into lowercase and convert object value into lowercase
    2. user filter method to get value and assign it to filteredOptions value

    */
    onVehicleChange(event){

        this.filteredOptions = this.options.filter(option => option.text.toLowerCase().includes(event.target.value.toLowerCase()));

        if(event.target.value === ""){
            this.quote.vehicle = "";
        }

    }

    searchVehicleModels(params:string){

        this.vehicleService.getVehicleByString(params).subscribe(response => {

            this.options = response.data;
            this.filteredOptions = response.data;


        },error =>{


        });


    }

    vehicleSelected(event){

        this.quote.vehicle = event;


    }

    onInputChange(event){

        let name = event.target.name;
        let value = event.target.value;
        let key = event.target.dataset.key;

        this.quote[name] = value;

    }

    // Handle quote amount change
    onAmountChange(amount){

        // this.quote.estimatedValue = parseInt(amount.replace(",", ""));

        this.quote.estimatedValue = amount;

        //parseInt(amount.replace(",", ""));

    }

    // Handle quote year change
    yearChange(event){

        this.quote.year = event.value;

    }

    // Navigate to new cover page
    /*

        1. Clear previous data in new cover local storage
        2. Assign vehicle details to localstorage variable
        3. Assign selectedPolicy to local storage
        4. Show terms and conditions overlay

    */
    navigateToNewCover(index){


        localStorage.removeItem("policyData");
        localStorage.removeItem("lipaPolePoleSchedule");
        localStorage.removeItem("coverstep");
        localStorage.removeItem("customerVerified");
        localStorage.removeItem("benefitsTotal");
        localStorage.removeItem("benefitsSummary");
        localStorage.removeItem("paymentRef");
        localStorage.removeItem("totalTaxes");


        localStorage.setItem("vehicleDetails", JSON.stringify(this.vehicleDetails));
        localStorage.setItem("selectedPolicy", JSON.stringify(this.policySummary[index]));

        $("header").css({
            "position":"fixed"
        });

        $(".termsModal").fadeIn();


    }



}
