<!--Thank You Message-->
<div class="centerAligner shadow rounded mt-30">

  <!--Claims Section-->
  <section class="thankYouSection">

    <div class="introContent">
      <fa-icon icon="check-circle" class="arrow"></fa-icon>
      <h3>
        Thank you for submitting your claim form.
      </h3>

      <p>Please head to your profile page</p>

    </div>

    <!--  Form Row  -->
    <!-- <form action="">

      <div class="row marginTopBottom-40">

        <h4>Please share the remaining documents as soon as possible</h4>
        <label for="">Upload Abstract (Within 14 Days)</label>

        <div class="full_width uploadContainer">
          <input type="file" id="uploadid" accept=".pdf,.jpg,.png" type="file" />
          <fa-icon icon="upload" class="arrow upload"></fa-icon>
          <label for="uploadid" class="icon-section">
            <p>Upload Abstract</p>
          </label>
        </div>

      </div>

    </form> -->

    <div class="btnFooter">
      <a
          class="readMoreYellow"
          routerLink="/userprofile">
        View Profile
        <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>

      </a>
    </div>

    <!-- End of Form Row-->

  </section>
  <!--End of Claims Section-->

</div>
<!--END Thank You Message-->
