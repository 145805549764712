export class TotalTheftDriverDetails{

  name:string = "";
  phoneNumber:string = "";
  email:string = "";
  idNumber:string = "";
  driverHaspermission:string = "false";
  copyOfDriverLicense:string = "";

}
