import { Component, OnInit } from '@angular/core';

import $ from 'jquery';

@Component({
    selector: 'app-car-generator',
    templateUrl: './car-generator.component.html',
    styleUrls: ['./car-generator.component.css']
})
export class CarGeneratorComponent implements OnInit {

    loaderCar:string;

    constructor() { }

    ngOnInit(): void {

        this.loaderCar = this.randomiseCar();
    }

    randomiseCar(){

        const  carArray:any = [
            {src: 'e24.png',audioSrc:'engine.mp3'},
            {src: 'e60.png'},
            {src: 'e46.png'},
            {src: '911.png'},
            {src: 'f30.png'},
            {src: 'forester.png'},
            {src: 'gtr.png'},
            {src: 'lancer.png'},
            {src: 'wrx.png'},
            {src: 'ambulance.png'},
        ];

        const imageIndex = Math.floor(Math.random() * carArray.length);

        const selectedCar = carArray[imageIndex].src;

        return "./assets/_img/home/cars/"+selectedCar;

        //console.log(this.loaderCar);

    }

}
