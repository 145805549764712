<footer class="mainfooter">

    <article class="centerAligner">

        <section class="two_third fl">

            <p>© {{footerDate}} InsureMe. All Rights Reserved. Regulated by the IRA.</p>

            <a routerLink="/termsandconditions">Terms of Use/Privacy Policy</a>

        </section>


        <app-socialnav class="one_third fr footerSocialNav">
        </app-socialnav>

    </article>

</footer>
