export class Child{

     firstName:string  = "";
     middleName:string= "";
     lastName:string = "";
     phoneNumber:string= "";
     email:string = "";
     dateOfBirth:string = "";
     gender:string = "";
     birthCertificate:string= "";

}
