import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from "@angular/router";

import $ from 'jquery';

import {ClaimsService} from './../../../_services/claims.service';
import {UserService} from './../../../_services/user.service';
import {AuthenticationService} from './../../../_services/authentication.service';


@Component({
    selector: 'app-singleclaim',
    templateUrl: './singleclaim.component.html',
    styleUrls: ['./singleclaim.component.css']
})

export class SingleclaimComponent implements OnInit {

    claimId:string;
    claimDetails:any;
    claimType:string = "";

    showModal:boolean = false;

    modalTitle: string = "";
    modalDescription: string = "";
    modalLink: string;
    modalLinkText: string = "Proceed";
    modalType:string = "info";

    loading:boolean = false;

    constructor(
        private authService:AuthenticationService,
        private claimService:ClaimsService,
        private userService:UserService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {

        $(".authoverlay").fadeOut();

        this.claimId = this.route.snapshot.paramMap.get('claimId');

        this.loading = true;

        this.claimService.getClaimByClaimId(this.claimId).subscribe(response =>{

            // console.log(response);

            if(response["success"]){

                this.claimDetails = response["data"];

                this.claimType = this.claimDetails.claimsData.claimType;

                this.loading = false;

            }else{

                // Modal Errors

                this.modalTitle = "Claim loading error";
                this.modalDescription = response["message"];
                this.modalLink = null;
                this.modalLinkText = "";
                this.showModal = true;

                this.loading = false;


            }


        },error => {

            // Modal Errors
            this.modalTitle = "Error";
            this.modalDescription = "An error occurred while processing your claim request";
            this.modalLink = null;
            this.modalLinkText = "";
            this.showModal = true;

            this.loading = false;


        });

    }

}
