import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import $ from 'jquery';
@Component({
  selector: 'app-eventemitter',
  templateUrl: './eventemitter.component.html',
  styleUrls: ['./eventemitter.component.css']
})
export class EventemitterComponent implements OnInit {

  visible: boolean = false;

  @Output() open: EventEmitter<any> = new EventEmitter();

  @Output() close: EventEmitter<any> = new EventEmitter();

  toggle() {
    this.visible = !this.visible;
    if ($(this).visible) {
      this.open.emit("Emit: Hello");
    } else {
      this.close.emit("Emit: Goodbye");
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
