<app-preloader *ngIf="siteImages.length > 0" [images]="siteImages"></app-preloader>

<section class="centerAligner shadow rounded mt-30 mb-30">

  <div class="motorBannerSection">

    <div class="imageContainer">

      <!--  City Landscape  -->

      <figure class="cityLandscape">
        <img src="img" alt="" src="./assets/_img/home/city_skyline.png">
      </figure>
      <!--END  City Landscape  -->

      <figure class="lipaPolePoleAccidentScene">
        <img src="img" alt="" src="./assets/_img/home/accident-2.png">
      </figure>

      <figure class="road">
        <img src="img" alt="" src="./assets/_img/home/road.png">
      </figure>

    </div>
    <!--END Cityscape banner   -->
  </div>

  <div class="introSection">

    <h1>
      <span class="yellowLine">Lipa Pole Pole Payment Plan</span>
    </h1>
    <p>
      Motor Insurance cover is a requirement by law, and one cannot drive their vehicle on public roads without a valid
      motor insurance certificate.
      smile on your face, helping you keep going with simple and personalized solutions.
    </p>
    <p>
      A comprehensive policy provides the best motor vehicle cover as it shields the owner or driver against losses
      should incidents such as fire,
      theft, injury, vehicle damage or accident occur.
    </p>
    <p>
      InsureMe recognizes that every Kenyan deserves the best insurance cover available. However, purchasing
      such a comprehensive cover becomes difficult due to the high upfront premium required.
    </p>
    <p>
      Introducing Lipa Pole Pole! This pay-as-you-go financing feature allows you to enjoy the benefits of a
      comprehensive motor cover whilst paying for the premiums in favorable monthly installments.
    </p>

  </div>

  <div class="greyContentSection">
    <h4>The qualifications for our Lipa Pole Pole payment option are simple. All you need is:</h4>

    <ul>
      <li>Valid KYC information about the insured, i.e., yourself</li>
      <li>Valid copy of ID</li>
      <li>Valid copy of Vehicle Logbook</li>
      <li>Valid Vehicle registered with the National Transport System Authority (NTSA)</li>
      <li>Payment of the required deposit to activate your Lipa Pole Pole facility.</li>
    </ul>

    <h4>We need a deposit from you to activate the Lipa Pole Pole facility which covers the following:</h4>

    <ul>
      <li>An annual Third Party Only cover for your vehicle</li>
      <li>A 60-days comprehensive premium [(Annual Premium / 12 months) * 2months] </li>
      <li>Mandatory 3rd party service charges such as vehicle valuation.
      </li>
      <li>Any mandatory, non-refundable taxes as such as Policy Holder Compensation Fund (PHCF, 0.25%) and Training Levy
        (TL, 0.20%)</li>
      <li>Service Fee of 1% + VAT </li>
    </ul>

  </div>

  <div class="contentDescription">

    <p>
      Our Lipa Pole Pole facility charges a nominal interest of 5% per month, computed on a reducing balance basis. The
      installments have been smoothened out over the period to make both principle + interest payments consistent and
      easier for you to manage.
      InsureMe also has a loan calculator that empowers you to change the minimum deposit amount and loan period to suit
      your financial capacity.
    </p>

    <ul>
      <li>You can increase the minimum deposit required. This action automatically reduces the starting loan principal
        and
        interest charged over the period.
      </li>
      <li>You can amend the repayment period to between 3 - 10 months (10 months is the default period). This action
        automatically increases the starting loan principal and interest charged over the period.
      </li>
      <li>You can also pre-pay your facility with no penalty charge to you. This action saves you on future interest and
        premium payments.</li>
    </ul>

    <p>
      Principal and interest payments are made on a monthly basis. In the event that a loan becomes overdue, you will
      have a 14-day period in which to make payment to keep your policy active. During this period, InsureMe platform
      will send you relevant reminders via SMS encouraging you to clear your arrears.
    </p>

    <p>
      In the event that you fail to honor the installment within the 14-day period, your comprehensive cover will be
      downgraded to a Third Party Only cover.
    </p>

    <p>
      Please also take note that during any major vehicle insurance claim, the Lipa Pole facility will be required to be
      fully paid up as part of the claims document processing.
    </p>

    <div class="buttonSection">
        <a routerLink="/">
          <button
           class="readMoreYellow">
           Get a Quote Now
           <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
          </button>
        </a>
      </div>

  </div>

</section>
