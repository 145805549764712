import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

// import { User } from '@/_models';

import { environment } from '../../environments/environment.dev';

import {AuthenticationService} from './authentication.service';

@Injectable({ providedIn: 'root' })

export class UserService {

  constructor(

      private http: HttpClient,
      private authenticationService:AuthenticationService,

  ) {}

  register() {
    return this.http.post(`${environment.apiUrl}/users/register`, '');
  }

  getUserProfile(nationalId){

      const headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.authenticationService.getUserToken()}`
      });

      return this.http.get<Object[]>(`${environment.customerFacingApi}userProfile?page=1&per_page=40&nationalId=${nationalId}`,{'headers' : headers});

  }

  getSinglePolicy(policyId){

      const headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.authenticationService.getUserToken()}`
      });

      return this.http.get<Object[]>(`${environment.customerFacingApi}userProfile/viewPolicy?policyNo=${policyId}`,{'headers' : headers});


  }
}
