import {TheftRecoveryReplacedItems} from "./TheftRecoveryReplacedItems.model";
import {RecoveryTheftReportDetails} from "./RecoveryTheftReportDetails.model";

export class TheftRecoveryVehicleDetails{

  dateOfRecovery:string = "";
  milageOfRecovery:string = "";
  placeOfRecoveryLatitude:string = "";
  placeOfRecoveryLongitude:string = "";
  placeOfInspection:string = "";
  descriptionOfAnyDamage:string = "";
  hasAnyItemBeenReplaced:string = "false";
  replacedItems:any = [];
  //replacedItems:TheftRecoveryReplacedItems = new TheftRecoveryReplacedItems();
  receipts:any = [];
  desclaimerChecked:string = "false";
  otherDamageDescription:string = "";
  replacementGarage:string = "";
  theftReportedToPlace:string = "false";
  theftReportDetails:RecoveryTheftReportDetails = new RecoveryTheftReportDetails();
  placeOfRecovery:string = "";
}
