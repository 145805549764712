import { Component, OnInit } from '@angular/core';

import {Endorsement} from './../../../_models/policy/Endorsement.model';

import {ClaimsService} from './../../../_services/claims.service';

import {AuthenticationService} from './../../../_services/authentication.service';

import {UserService} from './../../../_services/user.service';

import {PolicyService} from './../../../_services/policy/policy.service';

import {VehicleService} from './../../../_services/vehicle.service';


import $ from 'jquery';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

    nationalId:string;
    policies:any = [];
    claims:any = [];

	modalTitle: string = "";
    modalDescription: string = "";
    modalLink: string;
    modalLinkText: string = "Proceed";
    modalType:string = "info";

	showModal:boolean = false;

	valuationSummary:any = [];

	userProfile:any = {};

	phoneNumber:string = "";

	loadingPolicies:boolean = false;
	loadingClaims:boolean = false;

	policyEndorsement:Endorsement = new Endorsement();

  constructor(
      private authService:AuthenticationService,
      private claimService:ClaimsService,
      private userService:UserService,
	  private vehicleService:VehicleService,
	  private policyService:PolicyService
  ) { }

  ngOnInit(): void {

	  $(".accordionTitle").on('click',this.openAccordion);

      this.nationalId = this.authService.getUserNationalId();

      $(window).resize(this.equalizeColumns);

	  this.loadingPolicies = true;

	  this.loadingClaims = true;

	  this.userProfile = JSON.parse(localStorage.getItem("insuremeRiders"));

	  this.phoneNumber = this.userProfile.phoneNumber.replace("254","0").replace("+254","0");

	  this.policyEndorsement.paymentPhoneNo = this.phoneNumber;

	  this.vehicleService.getValuationReportByNationalId(this.nationalId).subscribe(response => {

		  if(response["success"]){


			  this.valuationSummary = response["data"];

			  // set timeout here

			  setTimeout(() => {
				  //valuationNotification

				  $(".accordionContainer .accordion").slideUp();

				  $(".valuationNotification .accordion:first").slideDown();

				  $(".valuationNotification .accordionTitle:first").toggleClass("open");

			  }, 500);

		  }else{


		  }

	  },error => {


	  });

      this.claimService.findClaimByNationalId(this.nationalId).subscribe(response =>{

          if(response["success"]){

			  this.loadingClaims = false;
              this.claims = response["data"];

          }else{

			  this.loadingClaims = false;

              // Modal messages
          }

      },error =>{

		  this.loadingClaims = false;
          // Error Message
      });

      this.userService.getUserProfile(this.nationalId).subscribe(response =>{


          if(response["success"]){

			  this.loadingPolicies = false;

              this.policies = response["data"]["policy"];

			  $(".accordionTitle:first").addClass("open").next(".accordion").stop().slideDown();

          }else{

              // Modal Message
			  this.loadingPolicies = false;
          }

      },error =>{

          // Error Message
		  this.loadingPolicies = false;

      });


  }

  changePaymentPhoneNumber(event){

	  let name = event.target.name;
	  let value = event.target.value;

	  this.phoneNumber = value.replace("254 ","0").replace("+254","0");

  }

  updatePaymentPhoneNumber(){

	  this.policyEndorsement.paymentPhoneNo = this.phoneNumber;

  }

  openAccordion(event){

	  $(".accordion").stop().slideUp();


	  if($(event.target).parent(".accordionTitle").hasClass("open")){

		  $(".accordionTitle").removeClass("open");
		  $(event.target).parent(".accordionTitle").removeClass("open").next(".accordion").stop().slideUp();

	  }else{

		  $(".accordionTitle").removeClass("open");
		  $(event.target).parent(".accordionTitle").addClass("open").next(".accordion").stop().slideDown();

	  }

  }

  declineEndorsement(event){

	  this.showModal = false;

  }

  acknowledgeEndorsement(event){

	  // hide modal
	  this.showModal = false;

	 // Show payment confirmation view
	 $(".accordionContainer").stop().slideUp();

	 $(".yellowHighlight").stop().slideDown();



  }

  restoreEndorsementAccordion(){

	  $(".accordionContainer").stop().slideDown();

 	 $(".yellowHighlight").stop().slideUp();

  }

  approveEndorsement(event){

	  this.modalTitle = "Processing policy endorsement";
	  this.modalDescription = "Your selected policy is in process of being endorsed";
	  this.modalLink = null;
	  this.modalLinkText = "";
	  this.showModal = true;
	  this.modalType = "process";

	  $(".modal").fadeIn();

	  // Send endorsement Request

	  this.policyService.endorsePolicy(this.policyEndorsement).subscribe(response => {

		  if(response["success"]){

			  this.modalTitle = "Valuation endorsement succesful";
			  this.modalDescription = response["message"];
			  this.modalLink = null;
			  this.modalLinkText = "Ok";
			  this.showModal = true;
			  this.modalType = "info";

			  $(".modal").fadeIn();

		  }else{

			   this.modalTitle = "Valuation endorsement failed";
 			  this.modalDescription = "Your selected policy has been not been endorsed";
 			  this.modalLink = null;
 			  this.modalLinkText = "";
 			  this.showModal = true;
 			  this.modalType = "info";

			  $(".modal").fadeIn();

		  }

	  },error => {

		  this.modalTitle = "Network error";
		 this.modalDescription = error.error.message;
		 this.modalLink = null;
		 this.modalLinkText = "";
		 this.showModal = true;
		 this.modalType = "info";

		 $(".modal").fadeIn();


	  });

  }

  proceedWithCurrentEndorsement(valuationItem){

	  this.policyEndorsement.policyNo = valuationItem?.PolicyNumber;

	  this.policyEndorsement.endorsementAmount = "0";

	  this.policyEndorsement.endorsementType = "VALUATION";

	  this.policyEndorsement.endorsementQuote = valuationItem?.endorsementDetails?.endorsementQuote;

	   this.policyEndorsement.endorsementBenefits = valuationItem?.endorsementDetails?.endorsementQuote?.benefits;

	   this.policyEndorsement.endorsementAction = "NO_ENDORSEMENT";

	   this.policyEndorsement.comment = "NO_ENDORSEMENT";

	   this.modalTitle = "Policy endorsement confirmation";
	   this.modalDescription = "Are you sure you want to endorse policy ?";
	   this.modalLink = null;
	   this.modalLinkText = "";
	   this.showModal = true;
	   this.modalType = "confirmation";

	   $(".modal").fadeIn();

  }

  endorsePolicy(valuationItem){


	  this.policyEndorsement.policyNo = valuationItem?.PolicyNumber;

	  this.policyEndorsement.endorsementAmount = valuationItem?.endorsementDetails?.endorsementAmount;

	   this.policyEndorsement.endorsementType = valuationItem?.endorsementDetails?.endorsementType;

	    this.policyEndorsement.endorsementQuote = valuationItem?.endorsementDetails?.endorsementQuote;

		 this.policyEndorsement.endorsementBenefits = valuationItem?.endorsementDetails?.endorsementQuote?.benefits;

		 this.policyEndorsement.endorsementAction = valuationItem?.endorsementDetails?.endorsementAction;

		 this.policyEndorsement.comment = valuationItem?.endorsementDetails?.endorsementAction;

		  this.modalTitle = "Policy endorsement confirmation";
		  this.modalDescription = "Are you sure you want to endorse policy?";
		  this.modalLink = null;
		  this.modalLinkText = "";
		  this.showModal = true;
		  this.modalType = "confirmation";

		  $(".modal").fadeIn();


  }

  ngAfterViewInit(){

      setTimeout(() => {

          this.equalizeColumns();

      }, 800);


  }

  equalizeColumns(){

      let talletstColum = 0;

      $(".profileContainer .half").each(function(index,value){



          let profileIndex = index+1;


          //console.log($(".profileContainer .half:nth-child("+profileIndex+")").outerHeight());

          if($(".profileContainer .half:nth-child("+profileIndex+")").height() > talletstColum){

              talletstColum = $(".profileContainer .half:nth-child("+profileIndex+")").height();

          }


      });

      $(".profileContainer .half").css({
          "min-height":talletstColum
      });

      // console.log($(window).width());


  }

}
