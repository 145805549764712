import { Component, OnInit,Input } from '@angular/core';
declare var $:any;

@Component({
    selector: 'app-claimsummary',
    templateUrl: './claimsummary.component.html',
    styleUrls: ['./claimsummary.component.css']
})
export class ClaimsummaryComponent implements OnInit {

    @Input() numberOfClaims:number;
    @Input() title:string;
    @Input() description:string;
    @Input() type:string;
    @Input() image:string;

    constructor() { }

    ngOnInit(): void {



    }

    showDetails(event){

        $(event.target).find("article").stop().fadeIn(100).css({
            display:"table"
        });

    }

    hideDetails(event){

        $(event.target).find("article").stop().fadeOut(100);
    }

    toggleDetail(event){

        let target = event.target;

    }

}
