<app-preloader
    *ngIf="siteImages.length > 0"
    [images]="siteImages"
></app-preloader>


<div class="thankyousummary" >

    <article class="description" [ngClass]="paymentType !=='LIPA_POLE_POLE' ? 'description full_width' : 'description full_width'">

        <h3>Thank you <strong >{{customerName}}</strong>, your policy is being processed!</h3>

        <p class="confirmation">Your Certificate Of Insurance is being processed. You’ll receive a notification on your email address and SMS once it’s ready. Visit <a routerLink="/userprofile">My Policies</a> page to view and manage your policies.</p>


        <button
            class="readMoreYellow"
            type="button"
            *ngIf="!isLogin"
            (click)="toggleAuthenticationView('login');">

            My Policy

            <fa-icon icon="user-circle" class="arrow"></fa-icon>

        </button>

        <a
            class="readMoreYellow"
             *ngIf="isLogin"
             routerLink="/userprofile">
            <span >My Policies</span>
            <fa-icon

                icon="user-circle"
                class="arrow"></fa-icon>
        </a>

        <!-- <div class="pendingDocuments shadow rounded padding">
            <form action="">

                <h4>Please share the remaining documents within 14 days</h4>

                <div class="row">

                    <div class="full_width">

                        <label for="">Upload Copy of Logbook (Within 14 days)</label>

                        <div class="uploadContainer">
                            <fa-icon

                                icon="upload"
                                class="upload"
                                ></fa-icon>

                            <label for="uploadid" class="uploadLabel">Upload</label>
                            <input
                                type="file"
                                id="uploadid"
                                (change)="onFileChange($event);"
                                accept=".pdf,.jpg,.png"
                                placeholder="Log Book"
                                type="file" />
                        </div>

                    </div>
                </div>

            </form>

        </div> -->

        <!--  Valuer Details  -->
        <section class="valuerContainer rounded shadow padding" *ngIf="valuerDetails.hasOwnProperty('name')" >

            <div class="valuertitle">
                <h4>Meet your valuer</h4>
            </div>

            <div class="valuerprofile">

                <img src="{{valuerDetails?.solver_image}}" alt="">
                <h5>{{valuerDetails?.name}}</h5>
                <a href="tel:{{valuerDetails.mobile_no}} " class="readMoreBlack">
                    Contact {{valuerDetails?.name}} - {{valuerDetails.mobile_no}}

                    <fa-icon
                        class="arrow"
                        icon="phone-volume"></fa-icon>

                </a>
            </div>



            <section class="dateDetails policysummary" *ngIf="valuationDetails.hasOwnProperty('date')" >

                <div class="row">

                    <p class="left">Date:</p>
                    <p class="right">{{valuationDetails?.date | date : 'fullDate'}}</p>

                </div>

                <div class="row">

                    <p class="left">Time:</p>
                    <p class="right">{{valuationDetails?.time }}</p>

                </div>

                <!-- <div class="row">

                    <p class="left">Location:</p>
                    <p class="right">getLocationByCoordinates(valuationDetails?.latitude,valuationDetails?.longitude)</p>

                </div> -->

            </section>
        </section>

        <section class="valuerContainer rounded shadow padding" *ngIf="!valuerDetails.hasOwnProperty('name')">
            <h3>Dear <strong>{{customerName}}</strong>, your valuation request is being processed! </h3>

            <p class="confirmation">You will receive a confirmation via SMS once a Valuer is assigned to your request</p>

        </section>
        <!--END Valuer Details   -->

    </article>

    <!--    -->
<!--END    -->
    <section class="lipaPolePoleSchedule full_width" *ngIf="paymentType === 'LIPA_POLE_POLE'" >

        <h5>Your next payment date</h5>


        <div class="schedule">

            <div class="row" *ngFor="let schedule  of lipaPolePoleSchedule; let i = index; " [ngClass]="i == 0 ? 'active' : ''" >

                <div class="left">

                    <span class="dated">{{schedule.payDate | date : 'longDate'}}</span>

                </div>

                <!-- <p class="center">Comprehensive Loan repayment</p> -->

                <p class="right"><strong>{{schedule.scheduledPayment| currency : 'code' : 'KES '  : '1.0-0'}}</strong></p>


            </div>


        </div>


    </section>


</div>


<app-modal
    *ngIf="showModal"

    title="{{modalTitle}}"
    link="{{modalLink}}"
    linkText="{{modalLinkText}}"
    description="{{modalDescription}}">

</app-modal>
