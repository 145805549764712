import { Component, OnInit,Input } from '@angular/core';

@Component({
    selector: 'app-fullscreenbanner',
    templateUrl: './fullscreenbanner.component.html',
    styleUrls: ['./fullscreenbanner.component.css']
})
export class FullscreenbannerComponent implements OnInit {

    @Input() image:string;
    @Input() position:string;

    constructor() { }

    ngOnInit(): void {
    }

}
