import { Component, OnInit,Input } from '@angular/core';
import {ReadmoreComponent} from '../readmore/readmore.component';

@Component({
    selector: 'feature-banner',
    templateUrl: './featurebanner.component.html',
    styleUrls: ['./featurebanner.component.css']
})
export class FeaturebannerComponent implements OnInit {

    @Input() image:string;
    constructor() { }

    ngOnInit(): void {
        
    }

}
