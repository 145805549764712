export class Kin{

     firstName:string = "";
     middleName:string = "";
     lastName:string = "";
     phoneNumber:string = "";
     email:string ="";
     dateOfBirth:string ="";
     gender:string ="";
     nationalId:string ="";
     kraPin:string = "";

}
