<div class="add-lessons-form" [formGroup]="form">

    <!-- <ng-container formArrayName="dynamicObject">

        <ng-container 
			*ngFor="let lessonForm of details.controls; 
			let i = index">

            <div class="lesson-form-row" [formGroup]="lessonForm">

                <input
                    formControlName="name"
                    placeholder="Enter Name">

				<input
                    formControlName="mobileNumber"
                    placeholder="Enter Mobile Number">

				<input
                    formControlName="emailAddress"
                    placeholder="Enter Email Address">

                <select formControlName="relationship">
                    <option value="father">Father</option>
                    <option value="mother">Mother</option>
					<option value="brother">Brother</option>
                    <option value="sister">Sister</option>
				</select>

            </div>

            <button (click)="removeDetail(i)">
                Remove Detail
            </button>

        </ng-container>

    </ng-container> -->

    <button (click)="addDetails()">
        Add Detail
    </button>

    <br /> <br />

    <button (click)="submitDetails()">
        Submit Details
    </button>

    <div *ngIf="payLoad">

        <strong>Saved lessons:</strong>
        <p>{{payLoad | json}}</p>

    </div>

</div>
