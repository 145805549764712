<div class="processor">

        <mat-spinner
            class="spinner"
            diameter="60"
            strokeWidth="3"
            color="#ffc401"
        ></mat-spinner>

        <h5>{{description | titlecase}}</h5>

</div>
