import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../../environments/environment.dev';


@Injectable({
    providedIn: 'root'
})

export class FileUploadService {


    ioBaseUrl:string = "https://file.io/";


    constructor(
        private http:HttpClient
    ){

    }

    // Returns an observable
  upload(file,refId?,type?):Observable<any> {

      // Create form data
      const formData = new FormData();

      // Store form name as "file" with file data
      formData.append("file", file, file.name);

      // Make http post request over api
      // with formData as req
      // return this.http.post(this.ioBaseUrl, formData);
      return this.http.post(`${environment.customerFacingApi}document/postDocuments?ref=${refId}&service=${type}`, formData);
  }


  uploadMultipleFiles(files,type){

      const formDataMultiple = new FormData();

      if(files.length > 0){

          for (let i = 0; i < files.length; i++) {

              formDataMultiple.append("file",files[i],files[i]['name']);
              // formDataMultiple.append("file",files[i]);

          }

      }

      return this.http.post(`${environment.customerFacingApi}document/postDocuments?service=${type}`, formDataMultiple);
      // return this.http.post(this.ioBaseUrl, formDataMultiple);

  }

}
