<!--  SideBar  -->
<div class="sidebar">

    <img src="/assets/_img/header/logo.png" alt="">

    <article>
        <h3><em>Welcome  To</em> Insure Me Last expense</h3>


    </article>



    <app-footer></app-footer>

</div>
<!--END  SideBar  -->
