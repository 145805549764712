<div class="media quotation-section">

    <div class="media-body">
    
          <h3>
            Your estimate quote is ready
          </h3>

          <h4>
            Choose your preferred quote and make payment to receive your Digital Certificate of Insurance. 
          </h4>
    
    </div>

    <section class="centerAligner shadow">

        <div class="faqGroupContainer">

            <div class="form-group">

                <label>
                  You can change the policy duration below:
                </label>

                <div class="form-group">

                    <div class="row">
        
                      <input 
                        matInput
                        [matDatepicker]="picker"
                        [matDatepickerFilter]="dateFilter"
                        class="dateInput" 
                        id="dob" 
                        placeholder="Start Date"
                        readonly 
                        (click)="picker.open()"  
                        placeholder="Start Date"
                        required 
                    />
    
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
          
                      <i 
                        class="fa fa-calendar position-absolute calender-icon"
                        aria-hidden="true">
                      </i>
          
                    </div>
                    
                </div>

            </div>

            <p>
                  Showing results for:
            </p>
    
            <div class="col-md-12">
    
                <div class="card border-0 border-rounded overflow-hidden q-box">
    
                  <div class="card-header bg-theme">
    
                    <ul class="list-group">
    
                      <li class="list-group-item border-0 bg-transparent d-flex justify-content-between flex-wrap flex-md-nowrap ">
                        <h2 class="mb-0 site-title-2">AUDI Q5, 2015</h2>
                        <button class="btn btn-dark rounded-pill font-16" type="button">
                          Edit
                        </button>
                      </li>
    
                      <li class="list-group-item border-0 bg-transparent d-flex justify-content-between flex-wrap flex-md-nowrap pt-0 pb-2">
                        <p class="paragraph mb-0 font-20 mb-0">
                          Estimated car value:
                        </p>
                        <p class="paragraph mb-0 font-20 mb-0">
                            KES 2,400,000
                        </p>
                      </li>
    
                      <li class="list-group-item border-0 bg-transparent d-flex justify-content-between flex-wrap flex-md-nowrap pt-0 pb-2">
                        <p class="paragraph mb-0 font-20 mb-0">
                          Cover Period:
                        </p>
                        <p class="paragraph mb-0 font-20 mb-0">
                          12 months
                        </p>
                      </li>
    
                    </ul>
    
                  </div>
          
                </div>
    
                <div class="">
    
                    <div 
                      class="accordion quote-list-accordion mt-5" 
                      id="quote-list-accordion">
              
                        <ng-container>
                    
                            <div class="card mb-0">
                    
                                <div class="card-header" id="quote-heading-1">
                    
                                    <div class="sec-1">
    
                                        <img src="" 
                                        class="img-fluid" 
                                        style="max-width: 100px;" 
                                        alt="" />
                                        
                                        <h5 class="site-title-3 mt-1 mb-0">
                                            Comprehensive Car Insurance
                                        </h5>
    
                                    </div>
                    
                                    <div class="sec-1 pl-3 pr-3">
                    
                                        <h4 class="site-title-3 font-18 mb-0 text-lg-center">
                                            Total Amount
                                        </h4>
                        
                                        <h1 class="site-title-2 font-30 text-lg-center">
                                            KES 49,500
                                        </h1>
    
                                    </div>
                    
                                    <div class="sec-2">
                    
                                        <div class="button-group">
    
                                            <button class="btn">
                                                View Benefits
                                            </button>
    
                                            <button 
                                                class="btn" 
                                                type="button"
                                                [routerLink]="['/personalaccident']">
                                            Buy Now
                                            </button>
    
                                        </div>
                    
                                        <div class="dropdown dots-drp">
    
                                            <button 
                                                type="button" 
                                                class="btn dropdown-toggle" 
                                                id="dotd-dropdown" 
                                                data-toggle="dropdown"
                                                aria-haspopup="true" 
                                                aria-expanded="false">
                                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                            </button>
                                        </div>
                    
                                    </div>
                    
                                </div>
    
                                <div 
                                    id="quote-body" 
                                    class="collapse">
                                    
                                    <div class="card-body" id="policy-detail-accordion">
    
                                        <div class="card bg-white">
    
                                            <div class="card-header" id="inner-heading">
    
                                                <h2 class="site-title-3 font-18 pl-3">
                                                    AUDI Q5, 2015
                                                </h2>
    
                                            </div>
    
                                            <div class="card-body collapse show">
    
                                                <ul class="list-group mb-5 pl-lg-3 pr-lg-3 quatelist">
    
                                                    <li class="list-group-item border-0 bg-transparent d-flex justify-content-between flex-wrap flex-md-nowrap">
                                                        <p class="ffs mb-0 font-18 font-weight-bold">
                                                            Base Premium
                                                        </p>
                                                        <p class="ffs mb-0 font-18 font-weight-bold">
                                                            KES 38,000
                                                        </p>
                                                    </li>
    
                                                    <li class="list-group-item border-0 bg-transparent d-flex justify-content-between flex-wrap flex-md-nowrap">
                                                        <p class="ffs mb-0 font-18">
                                                            PHCF &amp; Other Levies
                                                        </p>
                                                        <p class="ffs mb-0 font-18">
                                                            KES 138
                                                        </p>
                                                    </li>
    
                                                    <li class="list-group-item border-0 bg-transparent d-flex justify-content-between flex-wrap flex-md-nowrap">
                                                        <p class="ffs mb-0 font-18">
                                                            Stamp Duty
                                                        </p>
                                                        <p class="ffs mb-0 font-18">
                                                            KES 40
                                                        </p>
                                                    </li>
    
                                                    <li>
                                                        <p>
                                                            Excess Protector
                                                        </p>
                                                        <p>
                                                            KES 5,000
                                                        </p>
                                                    </li>
    
                                                </ul>
    
                                            </div>
    
                                        </div>
    
                                    </div>
                                    
                                </div>
    
                            </div>
    
                        </ng-container>
          
                    </div>

                </div>

            </div>

        </div>
    
    </section>

    
</div>