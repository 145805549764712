
<!--  App Header  -->
<!-- <app-header
    type="full_width"
    user=""
>
</app-header> -->
<!--END App Header   -->


<div class="container-fluid">
    <router-outlet></router-outlet>
</div>

<!-- <app-footer></app-footer> -->
