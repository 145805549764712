import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { environment } from '../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})

export class PaymentService {

    constructor(
        private http:HttpClient
    ){

    }

    getQuote(quoteDetails:any){

        return this.http.post<any>(`${environment.quoteApi}/quote`, quoteDetails);


    }

    initiatePayment(policyData:any){

        return this.http.post<any>(`${environment.customerFacingApi}payment/postPayment`, policyData);

    }

    postRepayment(paymentData:any){

        return this.http.post<any>(`${environment.customerFacingApi}payment/postRepayment`, paymentData);

    }


}
