export class Customer{

     customerName:string = "";
     customerMobile:string = "";
     clientType:string =  "INDIVIDUAL";
     country:string = "KENYA";
     emailAddress:string = "" ;
     dateOfBirth:string  = "";
     gender:string = "" ;
     nationalId:string = "";
     kraPin:string = "";
     vehicleRegNo:string = "";
     occupation:string = "";
     town:string = "";
     isMarried:boolean = false;
     isEmployed:boolean = false;
     physicalAddress:string =  "";
     postalAddress:string = "";
     nextOfKin:string = "";
     kinRelationship:string="";
     vehicleHasLoan:boolean = false;
     vehicleFinancier:string = "";
     

}
