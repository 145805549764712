import {Quote} from '../Quote.model';
import {Lead} from './Lead.model';
import {PolicyStartDate} from './PolicyStartDate.model';

export class CustomerLead{

	  customerLead:Lead = new Lead();
	  quoteDetails:Quote = new Quote();
	  policyStartDate: PolicyStartDate = new PolicyStartDate();


}
