import { Component, OnInit } from '@angular/core';

import { QuestionService } from '../../_services/question/question.service';
import { QuestionBase } from '../../_models/question/question-base';
import { QuestionControlService } from '../../_services/question/question-control.service';

import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-personal-accident-journey',
  templateUrl: './personal-accident-journey.component.html',
  styleUrls: ['./personal-accident-journey.component.css'],
  providers:[
	QuestionService,
    QuestionControlService
  ]
})

export class PersonalAccidentJourneyComponent implements OnInit {

	questions$: Observable<QuestionBase<any>[]>;

  personsCoveredDetails:any = [];
	fullPage:any = [];

  constructor(
	private questionService: QuestionService
  ) { 

    this.questions$ = questionService.getQuestions();
  }

  ngOnInit(): void {

    this.getDynamicQuestion();

  }

  getDynamicQuestion(){

    this.questionService.getQuestionsById(69).subscribe(response => {

			if(response["body"]["surveyDefinitionPages"].length > 0){

					this.fullPage = response["body"]["surveyDefinitionPages"];

          this.fullPage.sort((firstPage, secondPage) => firstPage.pageOrder - secondPage.pageOrder);

          for (let i = 0; i <this.fullPage.length; i++) {

            this.fullPage[i]["questions"].sort((firstQuestion, secondQuestion) => firstQuestion.questionOrder -  secondQuestion.questionOrder);

          }
			}

		});

    // this.questionService.getQuestionsById(71).subscribe(response => {

		// 	if(response["body"]["surveyDefinitionPages"].length > 0){

		// 			this.personsCoveredDetails = response["body"]["surveyDefinitionPages"];

    //       this.personsCoveredDetails.sort((firstPage, secondPage) => firstPage.pageOrder - secondPage.pageOrder);

    //       for (let i = 0; i <this.personsCoveredDetails.length; i++) {

    //         this.personsCoveredDetails[i]["questions"].sort((firstQuestion, secondQuestion) => firstQuestion.questionOrder -  secondQuestion.questionOrder);

    //       }

		// 	}

		// });

    // this.questionService.getQuestionsById(63).subscribe(response => {

		// 	if(response["body"]["surveyDefinitionPages"].length > 0){

		// 			this.nextOfKinDetails = response["body"]["surveyDefinitionPages"];

    //       this.nextOfKinDetails.sort((firstPage, secondPage) => firstPage.pageOrder - secondPage.pageOrder);

    //       for (let i = 0; i <this.nextOfKinDetails.length; i++) {

    //         this.nextOfKinDetails[i]["questions"].sort((firstQuestion, secondQuestion) => firstQuestion.questionOrder -  secondQuestion.questionOrder);

    //       }

		// 	}

		// });

  }

}
