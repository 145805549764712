import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policycancellation',
  templateUrl: './policycancellation.component.html',
  styleUrls: ['./policycancellation.component.css']
})
export class PolicycancellationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
