import { TheftPartsReplacedItems } from '../claims/TheftPartsReplacedItems.model';
//import { DamageClaimDriverDetails } from "../claims/DamageClaimDriverDetails.model";

export class TheftPartsClaim{

  customerId:number = 1;
  policyNumber:string = "";
  claimType:string = "";
  dateTime:string = "";
  docUploadRef:string="";
  placeLatitude:string = "";
  placeLongitude:string= "";
  vehiclePartsStolen:string = "";
  incidentDescription:string = "";
  garageName:string="";
  hasAnyItemBeenReplaced:string = "false";
  replacedItems:any = [];
  otherDocuments:any = [];
  desclaimerChecked:boolean = false;
  theftPlace:string = "";


}
