import { Component, OnInit,Input } from '@angular/core';
import { Router,Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AlertService, AuthenticationService } from 'src/app/_services';

import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';


import $ from 'jquery';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    @Input() user;
    // Type options full_width, half_width
    @Input() type:string;
    @Input() color:string;
    @Input() hidelogo:string;
    @Input() mobileBg:string;

    isLogin:boolean = false;

    constructor(
        library:FaIconLibrary,
        private authenticationService: AuthenticationService,
        private router:Router,
    ) {

        library.addIcons(faUserCircle)

    }

    ngOnInit(): void {

        this.isLogin = this.authenticationService.userLoggedIn();

        this.router.events.subscribe(val => {

            if(val instanceof NavigationEnd && $(".menuToggle").hasClass("open")){

                 this.openMobileMenu();

            }

        });

        $(window).resize(this.closeMobileMenu);

		// this.authenticationService.headerBasedRequest("https://insureme.co.ke/");

    }

    // openMobileMenu(event){
    //
    //     $(event.target).toggleClass("open");
    //
    //     if($(event.target).hasClass("open")){
    //
    //         $(".mobileNav").show().stop().animate({
    //             left:0
    //         },1000);
    //
    //     }else{
    //
    //         $(".mobileNav").stop().animate({
    //             left:'-100%'
    //         },1000,function(){
    //
    //             $(".mobileNav").hide();
    //
    //         });
    //
    //     }
    //
    //
    // }
    openMobileMenu(){

        $(".menuToggle").toggleClass("open");

        if($(".menuToggle").hasClass("open")){

            $(".mobileNav").show().stop().animate({
                left:0
            },1000);

        }else{

            $(".mobileNav").stop().animate({
                left:'-100%'
            },1000,function(){

                $(".mobileNav").hide();

            });

        }


    }

    closeMobileMenu(){

        if($(window).width() > 1199){

            $(".mobileNav").hide();

        }

    }

    showLogin(){

        //$(".authoverlay").show();


        $(".authoverlay .formWrapper").hide();
        $(".authoverlay .login").show();
        $("header").css({
            "position":"fixed"
        });
        $("body").css({
            "padding-top":"115px"
        });

    }

    toggleAuthenticationView(viewName:string){

        $(".authoverlay").stop().fadeIn(1000,function(){

            $("header").css({
                "position":"fixed"
            });

            $("body").css({
                "padding-top":"115px"
            });

            $(".authoverlay .formWrapper").stop().slideUp(1000,function(){

                $(".authoverlay ."+viewName).stop().slideDown(1000);

            });

        });


    }


    closeLogin(){

        $(".authoverlay").stop().fadeOut(1000,function(){

            $("header").css({
                "position":"relative"
            });
            $("body").css({
                "padding-top":"0px"
            });

        });



    }

    logout(){

        this.authenticationService.logout();
        window.location.reload();

    }

}
