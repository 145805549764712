<p>eventemitter works!</p>

<div class="zippy">
    <div (click)="toggle()">Toggle</div>
    <div [hidden]="!visible">
      <p>Peek-a-boo</p>
    </div>
    <div (click)="toggle()">Toggle</div>
    <div [hidden]="!visible">
      <p>Peek-a-boo</p>
    </div>
    <div (click)="toggle()">Toggle</div>
    <div [hidden]="!visible">
      <p>Peek-a-boo</p>
    </div>
 </div>

