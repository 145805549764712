import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from '../components/header/header.component';
import { ModalComponent } from '../components/modal/modal.component';
import { BannerImageComponent } from '../components/banner-image/banner-image.component';
import { ChatboxComponent } from '../components/chatbox/chatbox.component';
import { ClaimsummaryComponent } from '../components/claimsummary/claimsummary.component';
import { MobilebannerComponent } from '../components/cover/mobilebanner/mobilebanner.component';
import { FeaturebannerComponent } from '../components/featurebanner/featurebanner.component';
import { FooterComponent } from '../components/footer/footer.component';
import { FullscreenbannerComponent } from '../components/fullscreenbanner/fullscreenbanner.component';
import { FullscreensliderComponent } from '../components/fullscreenslider/fullscreenslider.component';
import { FullwidthheaderComponent } from '../components/fullwidthheader/fullwidthheader.component';
import { PreloaderComponent } from '../components/preloader/preloader.component';
import { ProcessorComponent } from '../components/preloader/processor/processor.component';
import { ReadmoreComponent } from '../components/readmore/readmore.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { SocialnavComponent } from '../components/socialnav/socialnav.component';
import { MotorbannerComponent } from '../components/motorbanner/motorbanner.component';
import { CarGeneratorComponent } from '../components/cars/car-generator/car-generator.component';
import { AuthoverlayComponent } from '../components/authoverlay/authoverlay.component';
import { TermssummaryComponent } from '../components/termssummary/termssummary.component';
import { CurrencyMaskModule } from "ng2-currency-mask";

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faTwitterSquare,
  faFacebookSquare,
  faYoutube,
  faInstagram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  faMale,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faFemale,
  faPlus,
  faCar,
  faEnvelope,
  faUserShield,
  faChild,
  faWallet,
  faMinusCircle,
  faPlusCircle,
  faUpload,
  faFileUpload,
  faEdit,
  faClock,
  faChevronCircleDown,
  faPills,
  faShieldAlt,
  faArrowCircleRight,
  faArrowCircleLeft,
  faPhoneVolume,
  faHeadset,
  faBars,
  faTimes,
  faFilePdf,
  faDownload,
  faTimesCircle,
  faHome,
  faQuestion,
  faPhone,
  faArrowCircleDown,
  faArrowDown,
  faHeart,
  faShareAlt,
  faInfo,
  faCalendarAlt,
  faMapMarkerAlt,
  faCheckCircle,
  faExclamationCircle,
  faMobile,
  faMobileAlt,
  faExchangeAlt,
  faCaretDown,
  faAddressCard,
  faMapPin,
} from '@fortawesome/free-solid-svg-icons';

// import {
//
// 	faAlbum as fasAlbum,
//
// }from '@fortawesome/pro-solid-svg-icons';
//
// import {
//
// 	faAlarmSnooze as farAlarmSnooze,
//
// }from '@fortawesome/pro-regular-svg-icons';
//
// import {
//
// 	faAirplay,
// 	faAlien
//
// }from '@fortawesome/pro-duotone-svg-icons';

//pro-duotone-svg-icons

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider'; 
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
           NgxMatDatetimePickerModule,
           NgxMatNativeDateModule,
           NgxMatTimepickerModule
  } from '@angular-material-components/datetime-picker';

import {MatProgressBarModule} from '@angular/material/progress-bar';

  //import {NgxMatTimepickerModule as NgxMatTimepickerModule2} from 'ngx-mat-timepicker';

import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import { MustMatchDirective } from '../_directives/must-match.directive';
import { MatchDepositDirective } from '../_directives/match-deposit.directive';
import { AnimatepreloaderComponent } from '../components/preloader/animatepreloader/animatepreloader.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@NgModule({
  declarations: [
    ModalComponent,
    HeaderComponent,
    BannerImageComponent,
    FeaturebannerComponent,
    SidebarComponent,
    ReadmoreComponent,
    FooterComponent,
    FullscreensliderComponent,
    SocialnavComponent,
    FullwidthheaderComponent,
    ClaimsummaryComponent,
    FullscreenbannerComponent,
    MobilebannerComponent,
    PreloaderComponent,
    ProcessorComponent,
    ChatboxComponent,
    MotorbannerComponent,
    CarGeneratorComponent,
    AuthoverlayComponent,
    TermssummaryComponent,
    MustMatchDirective,
    MatchDepositDirective,
    AnimatepreloaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatGoogleMapsAutocompleteModule,
    CurrencyMaskModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    NgxUiLoaderModule
  ],

  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ModalComponent,
    HeaderComponent,
    BannerImageComponent,
    FeaturebannerComponent,
    SidebarComponent,
    ReadmoreComponent,
    FooterComponent,
    FullscreensliderComponent,
    SocialnavComponent,
    FullwidthheaderComponent,
    ClaimsummaryComponent,
    FullscreenbannerComponent,
    MobilebannerComponent,
    PreloaderComponent,
    ProcessorComponent,
    ChatboxComponent,
    MustMatchDirective,
    FontAwesomeModule,
    FormsModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MotorbannerComponent,
    CarGeneratorComponent,
    AuthoverlayComponent,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatGoogleMapsAutocompleteModule,
    TermssummaryComponent,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatchDepositDirective,
    NgxUiLoaderModule
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faMale,
      faCar,
      faWallet,
      faArrowAltCircleRight,
      faFemale,
      faPlus,
      faUserShield,
      faChild,
      faMinusCircle,
      faPlusCircle,
      faUpload,
      faClock,
      faFileUpload,
      faEdit,
      faChevronCircleDown,
      faPills,
      faTwitterSquare,
      faFacebookSquare,
      faYoutube,
      faInstagram,
      faShieldAlt,
      faArrowCircleRight,
      faPhoneVolume,
      faHeadset,
      faBars,
      faTimes,
      faEnvelope,
      faFilePdf,
      faDownload,
      faTimesCircle,
      faHome,
      faQuestion,
      faPhone,
      faArrowCircleDown,
      faArrowDown,
      faHeart,
      faShareAlt,
      faInfo,
      faArrowAltCircleLeft,
      faArrowCircleLeft,
      faCalendarAlt,
      faMapMarkerAlt,
      faCheckCircle,
      faWhatsapp,
      faExclamationCircle,
      faMobile,
      faMobileAlt,
      faExchangeAlt,
      faCaretDown,
    );
  }
}
