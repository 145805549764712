
export class WindscreenDamageClaim{

  customerId:number = 1;
  policyNumber:string = "";
  claimType:string = "";
  date: string = "";
  dateTime:string = "";
  placeLatitude:string = "";
  docUploadRef:string="";
  placeLongitude:string= "";
  describeIncidentAndDamage:string = "";
  anyDamageOtherThanWindscreen:boolean = false;
  otherDamageDetails:string = "";
  hasWindscreenBeenReplaced:boolean = false;
  estimatedReplacementCost:string = "";
  nameOfGarage:string = "";
  otherDocuments:any = [];
  declarationCheck:boolean = false;
  damagePlace:string = "";
}
