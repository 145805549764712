<div class="modal">

    <div class="modalContainer" *ngIf="type === 'info'">

        <div class="modalContent">

            <button
                (click)="closeModal()"
                *ngIf="link == null || link ==''"
                class="closer">

                <fa-icon
                    icon="times"
                    class="arrow"></fa-icon>

            </button>

            <h3>{{title}}</h3>
            <p>{{description}}</p>


            <button
                (click)="closeModal();onOkClick();"
                *ngIf="link == null || link ==''"
                class="readMoreYellow">

                Ok

                <fa-icon
                    icon="arrow-alt-circle-right"
                    class="arrow"></fa-icon>

            </button>

            <a
                *ngIf="link != null && link != ''"
                [routerLink]="link"
                class="readMoreYellow">

                {{linkText}}

                <fa-icon
                    icon="arrow-alt-circle-right"
                    class="arrow"></fa-icon>

            </a>

        </div>

        <div class="modalImage">

            <figure class="tint"></figure>
            <img src="./assets/_img/preloader/modal_bg.png" alt="">

            <article>

                <fa-icon
                    icon="headset"
                    class="headset">

                </fa-icon>

                <h5>How can we brighten your day today? AskMe anything, or share your feedback.</h5>

                <a href="tel:0709 184 000" class="readMoreWhiteSolid">
                    <fa-icon
                        icon="phone-volume"
                    ></fa-icon>
                    0709 184 444
                </a>

            </article>
        </div>


    </div>

	<div class="modalContainer" *ngIf="type === 'confirmation'">

        <div class="modalContent">

            <button
                (click)="closeModal()"
                *ngIf="link == null || link ==''"
                class="closer">

                <fa-icon
                    icon="times"
                    class="arrow"></fa-icon>

            </button>

            <h3>{{title}}</h3>
            <p>{{description}}</p>


            <button
                (click)="onYesClick();"
                class="readMoreYellow">

                Yes

                <fa-icon
                    icon="arrow-alt-circle-right"
                    class="arrow"></fa-icon>

            </button>

			<button
                (click)="onNoClick();"
                class="readMoreYellow">

                No

                <fa-icon
                    icon="arrow-alt-circle-right"
                    class="arrow"></fa-icon>

            </button>



        </div>

        <div class="modalImage">

            <figure class="tint"></figure>
            <img src="./assets/_img/preloader/modal_bg.png" alt="">

            <article>

                <fa-icon
                    icon="headset"
                    class="headset">

                </fa-icon>

                <h5>How can we brighten your day today? AskMe anything, or share your feedback.</h5>

                <a href="tel:0709 184 000" class="readMoreWhiteSolid">
                    <fa-icon
                        icon="phone-volume"
                    ></fa-icon>
                    0709 184 444
                </a>

            </article>
        </div>


    </div>

	<div class="modalContainer" *ngIf="type === 'vehicleFailure'">

        <div class="modalContent">

            <button
                (click)="closeModal()"
                *ngIf="link == null || link ==''"
                class="closer">

                <fa-icon
                    icon="times"
                    class="arrow"></fa-icon>

            </button>

            <h3>{{title}}</h3>
            <p>{{description}}</p>
			<p>What would you like to do?</p>


            <button
                (click)="closeModal()"
                class="readMoreYellow">

                Change number plate

                <fa-icon
                    icon="arrow-alt-circle-right"
                    class="arrow"></fa-icon>

            </button>

            <a
                routerLink="/"
                class="readMoreYellow">

                Change make and model

                <fa-icon
                    icon="arrow-alt-circle-right"
                    class="arrow"></fa-icon>

            </a>

        </div>

        <div class="modalImage">

            <figure class="tint"></figure>
            <img src="./assets/_img/preloader/modal_bg.png" alt="">

            <article>

                <fa-icon
                    icon="headset"
                    class="headset">

                </fa-icon>

                <h5>How can we brighten your day today? AskMe anything, or share your feedback.</h5>

                <a href="tel:0709 184 000" class="readMoreWhiteSolid">
                    <fa-icon
                        icon="phone-volume"
                    ></fa-icon>
                    0709 184 444
                </a>

            </article>
        </div>


    </div>

    <div class="modalContainer" *ngIf="type === 'process'">

      <div class="modalContent">

          <div class="preloaderSection">

            <app-animatepreloader>
            </app-animatepreloader>

          </div>

            <div class="modalTextLeft">

              <h4>
                {{title}}
              </h4>

              <!--<app-processor description = "{{processorText}}"></app-processor>-->

              <p>
                {{description}}
              </p>

              <div class="paymentInfo" *ngIf="modalData.hasOwnProperty('transactionId')" >

                  <div class="row">
                    <p class="textLeft">Transaction ID:</p>
                    <p class="textRight">{{modalData?.transactionId}}</p>
                  </div>

                  <div class="row">
                    <p class="textLeft">Amount:</p>
                    <p class="textRight">{{modalData?.amount | currency : 'code' : 'KES ' :'1.0-0'}}</p>
                  </div>

                  <div class="row">
                    <p class="textLeft">Status:</p>
                    <p class="textRight">
                      <span class="yellowText">Pending</span>
                    </p>
                  </div>

                  <div class="row">
                    <p class="textLeft">Date:</p>
                    <p class="textRight">{{modalData?.date | date : 'longDate'}}</p>
                  </div>

              </div>

              <p>
                {{processorText}}
              </p>

          </div>

      </div>

      <div class="modalImage">

          <figure class="tint"></figure>
          <img src="./assets/_img/preloader/modal_bg.png" alt="">

          <article>

            <div class="iconSection">
              <fa-icon icon="mobile-alt" class="iconWhite iconSize"></fa-icon>
            </div>

            <div class="modalTextRight">

              <h5>Please lookout for an M-Pesa payment request on your phone. Enter your M-Pesa PIN to authorise.</h5>


              <div class="modalRightFooter">

                <p>How can we brighten your day today? AskMe anything, or share your feedback.</p>

                <button class="readMoreWhiteSolid p-5">

                    <fa-icon icon="phone-volume" class="iconPosition">
                    </fa-icon>

                  <span class="btnInfo">
                    <a href="tel:0709 184 000">
                      0709 184 444
                    </a>
                    <p>Call for any assistance</p>
                  </span>

                </button>

              </div>

            </div>

          </article>

      </div>


    </div>


    <div class="modalContainer" *ngIf="type === 'processInstallmentPay'">

      <div class="modalContent">

          <div class="preloaderSection">

            <app-animatepreloader>
            </app-animatepreloader>

          </div>

            <div class="modalTextLeft">

              <h4>
                {{title}}
              </h4>

              <p>
                {{description}}
              </p>

              <div class="paymentInfo">

                  <div class="row">
                    <p class="textLeft">Policy Number:</p>
                    <p class="textRight">{{this.modalData?.policyNumber}}</p>
                  </div>

                  <div class="row">
                    <p class="textLeft">Mobile Number:</p>
                    <p class="textRight">+{{this.modalData?.paymentPhoneNumber}}</p>
                  </div>

                  <div class="row">
                    <p class="textLeft">Amount:</p>
                    <p class="textRight">{{this.modalData?.amount | currency : 'code' : 'KES ' :'1.0-0'}}</p>
                  </div>

                  <div class="row">
                    <p class="textLeft">Status:</p>
                    <p class="textRight">
                      <span class="yellowText">Processing</span>
                    </p>
                  </div>

                  <div class="row">
                    <p class="textLeft">Date:</p>
                    <p class="textRight">{{this.modalData?.date | date : 'longDate'}}</p>
                  </div>

              </div>

              <p>
                {{processorText}}
              </p>

          </div>

      </div>

      <div class="modalImage">

          <figure class="tint"></figure>
          <img src="./assets/_img/preloader/modal_bg.png" alt="">

          <article>

            <div class="iconSection">
              <fa-icon icon="mobile-alt" class="iconWhite iconSize"></fa-icon>
            </div>

            <div class="modalTextRight">

              <h5>Please lookout for an M-Pesa payment request on your phone. Enter your M-Pesa PIN to authorise.</h5>


              <div class="modalRightFooter">

                <p>How can we brighten your day today? AskMe anything, or share your feedback.</p>

                <button class="readMoreWhiteSolid p-5">

                    <fa-icon icon="phone-volume" class="iconPosition">
                    </fa-icon>

                  <span class="btnInfo">
                    <a href="tel:0709 184 000">
                      0709 184 444
                    </a>
                    <p>Call for any assistance</p>
                  </span>

                </button>

              </div>

            </div>

          </article>

      </div>


    </div>

    <div class="modalContainer" *ngIf="type === 'received'">

      <div class="modalContent">

            <div class="iconSection">

              <fa-icon icon="wallet" class="iconYellow iconSize"></fa-icon>

            </div>


          <div class="modalTextLeft">

              <h4>
                Thank you {{description}}, your payment has been successfully received.
              </h4>

              <p>Your Certificate Of Insurance is being processed. You’ll receive a
                notification on your email address and SMS once it’s ready.</p>

              <div class="modalFooterLeft">
                  <a
                      class="readMoreYellow"
                      routerLink="{{link}}">
                    Continue
                  <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
                </a>
              </div>

          </div>

      </div>

      <div class="modalImage">

          <figure class="tint"></figure>
          <img src="./assets/_img/preloader/modal_bg.png" alt="">

          <article>

            <div class="iconSection">
              <fa-icon icon="headset" class="iconSize iconWhite"></fa-icon>
            </div>

            <div class="modalTextRight">

              <h5>How can we brighten your day today? Ask Me anything, or share your feedback.</h5>

              <div class="modalRightFooter">

                <button class="readMoreWhiteSolid p-5">

                  <fa-icon icon="phone-volume" class="iconPosition">
                  </fa-icon>

                <span class="btnInfo">
                  <a href="tel:0709 184 000">
                    0709 184 444
                  </a>
                  <p>Call for any assistance</p>
                </span>

                </button>

              </div>

            </div>

          </article>

      </div>

    </div>

    <div class="modalContainer" *ngIf="type === 'failed'">

      <div class="modalContent">

            <div class="iconSection">

              <fa-icon icon="exclamation-circle" class="iconRed iconSize"></fa-icon>

            </div>

            <div class="modalTextLeft">
              <h4>{{title}}</h4>

              <p>
                {{description}}
              </p>

              <div class="paymentInfo">

                <div class="row">
                  <p class="textLeft">Transaction ID:</p>
                  <p class="textRight">{{this.modalData?.transactionId}}</p>
                </div>

                <div class="row">
                  <p class="textLeft">Amount:</p>
                  <p class="textRight">{{this.modalData?.amount | currency : 'KES ' : 'code' :'1.0-0'}}</p>
                </div>

                <div class="row">
                  <p class="textLeft">Status:</p>
                  <p class="textRight">
                    <span class="redText">Unsuccessful</span>
                  </p>
                </div>

                <div class="row">
                  <p class="textLeft">Date:</p>
                  <p class="textRight">{{this.modalData?.date | date : 'longDate'}}</p>
                </div>

            </div>

                <div class="modalFooterLeft">
                  <button
                      class="readMoreYellow"
                      id="PaymentRetryPaymentClick"
                      (click)="closeModal();">
                        Re-try Payment
                    <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
                  </button>
                </div>

            </div>

      </div>

      <div class="modalImage">

          <figure class="tint"></figure>
          <img src="./assets/_img/preloader/modal_bg.png" alt="">

          <article>

            <div class="iconSection">

              <fa-icon icon="mobile-alt" class="iconWhite iconSize mr-15"></fa-icon>

              <fa-icon icon="exchange-alt" class="iconWhite iconSizeSm mr-15"></fa-icon>

              <fa-icon icon="mobile-alt" class="iconWhite iconSize"></fa-icon>

            </div>

            <div class="modalTextRight">

              <h5>You can make payment using our Paybill.</h5>

              <h4>Lipa Na Mpesa:</h4>
              <ul>
                <li>Paybill No.: 7873633</li>
                <li>Account No.: {{this.modalData?.vehicleNoPlate}}</li>
                <li>Amount: {{this.modalData?.amount | currency : 'KES ' : 'code' :'1.0-0'}}</li>
              </ul>

              <div class="modalRightFooter mt-30">

                <button
                  class="readMoreWhiteSolid"
                  (click)="onConfirmationClick(this.modalData.transactionId)">
                  Confirm Payment
                  <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
                </button>

              </div>

            </div>

          </article>
      </div>


    </div>

    <div class="modalContainer" *ngIf="type === 'cancelled'">

      <div class="modalContent">

        <div class="preloaderSection">

          <app-animatepreloader>
          </app-animatepreloader>

        </div>


            <div class="modalTextLeft">

              <h4>{{title}}</h4>

              <p>
                {{description}}
              </p>

              <div class="paymentInfo">

                <div class="row">
                  <p class="textLeft">Transaction ID:</p>
                  <p class="textRight">{{this.modalData?.transactionId}}</p>
                </div>

                <div class="row">
                  <p class="textLeft">Amount:</p>
                  <p class="textRight">{{this.modalData?.amount | currency : 'KES ' : 'code' :'1.0-0'}}</p>
                </div>

                <div class="row">
                  <p class="textLeft">Status:</p>
                  <p class="textRight">
                    <span class="yellowText">Cancelled</span>
                  </p>
                </div>

                <div class="row">
                  <p class="textLeft">Date:</p>
                  <p class="textRight">{{this.modalData?.date | date : 'longDate'}}</p>
                </div>

            </div>

                <div class="modalFooterLeft">
                  <button
                      class="readMoreYellow"
                      (click)="closeModal();">
                    Re-try Payment
                    <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
                  </button>
                </div>

            </div>

      </div>

      <div class="modalImage">

          <figure class="tint"></figure>
          <img src="./assets/_img/preloader/modal_bg.png" alt="">

          <article>

            <div class="iconSection">

              <fa-icon icon="mobile-alt" class="iconWhite iconSize mr-15"></fa-icon>

              <fa-icon icon="exchange-alt" class="iconWhite iconSizeSm mr-15"></fa-icon>

              <fa-icon icon="mobile-alt" class="iconWhite iconSize"></fa-icon>

            </div>

            <div class="modalTextRight">

              <h5>You can make payment using our Paybill.</h5>

              <h4>Lipa Na Mpesa:</h4>
              <ul>
                <li>Paybill No.: 7873633</li>
                <li>Account No.: {{this.modalData?.vehicleNoPlate}}</li>
                <li>Amount: {{this.modalData?.amount | currency : 'KES ' : 'code' :'1.0-0'}}</li>
              </ul>

              <div class="modalRightFooter mt-30">

                <button class="readMoreWhiteSolid"
                (click)="onConfirmationClick(this.modalData.transactionId)">
                  Confirm Payment
                  <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
                </button>

              </div>

            </div>

          </article>
      </div>


    </div>


</div>
