import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from '../_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser && currentUser.token) {
      // authorized so return true
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      // this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
      this.router.navigate(['/']);

      return false;
    }
  }
}
