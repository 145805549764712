
<app-modal
    *ngIf="showModal"
    title="{{modalTitle}}"
    link="{{modalLink}}"
    linkText="{{modalLinkText}}"
    description="{{modalDescription}}">

</app-modal>

<div class="motorBanner">

    <!--  Cityscape banner  -->
    <div class="imageContainer">

        <!--  City Landscape  -->

        <figure class="cityLandscape">
            <img src="img" alt="" src="./assets/_img/home/city_skyline.png">
        </figure>
        <!--END  City Landscape  -->

        <figure class="accidentScene">
            <img alt="" src="./assets/_img/home/accident.png">
        </figure>

        <figure class="road">
            <img src="img" alt="" src="./assets/_img/home/road.png">
        </figure>

        <app-car-generator class="traffic"  >
        </app-car-generator>

        <figure class="overlay"></figure>

    </div>
    <!--END Cityscape banner   -->

    <!--  Quote Overlay  -->
    <figure class="quoteOverlay"></figure>
    <!--END  Quote Overlay  -->

    <!--  Introduction Text  -->
    <article class="introduction">
        <h1>
            Instant <strong>car insurance,</strong> anywhere, anytime!
        </h1>

        <p>Get your comprehensive car insurance now and pay in installments with our <strong>Lipa Pole Pole</strong> financing option. <strong>Underwritten By Heritage Insurance.</strong></p>



        <!-- Get Quote Form   -->
        <form
            action=""
            class="formQuote"
            id="formQuoteSection"
            (ngSubmit)="formQuote.valid  && carSelection.valid && showSummary();"
            #formQuote="ngForm"
            name="formQuote">

            <fieldset>

                <div class="one_quarter">

                    <label for="">Type of car - Select an option</label>

                    <input
                        type="text"
                        placeholder="Please type vehicle model"
                        required
                        matInput
                        (keyup)="onVehicleChange($event)"
                        [formControl]="carSelection"
                        [matAutocomplete]="auto"
                        class="make">

                    <mat-autocomplete
                        #auto="matAutocomplete"
                        (optionSelected)='vehicleSelected($event.option.value)'>

                        <mat-option *ngFor="let option of filteredOptions " [value]="option.value">
                            {{formatVehicleName(option.text)}}
                        </mat-option>

                    </mat-autocomplete>

                    <div
                    class="invalid-field"
                    *ngIf="formQuote.submitted && quote.vehicle === '' ">

                        <label
                        *ngIf="carSelection.hasError('required') || quote.vehicle === ''"
                        class="invalid-field">Please select from the dropdown
                        </label>

                    </div>

                </div>

                <div class="one_quarter yom">
                    <label for="">Year of manufacture</label>

                    <mat-form-field class="year input" appearance="fill">

                        <mat-select
                            placeholder="Please select Y.O.M"
                            name="year"
                            (selectionChange)="yearChange($event)"
                            [formControl]="carYom"
                            required>

                            <mat-option value="{{year}}" *ngFor="let year of yearsArray" >{{year}}</mat-option>

                        </mat-select>

                        <mat-select-trigger class="selectStyling">
                            <fa-icon icon="calendar-alt">

                            </fa-icon>
                        </mat-select-trigger>

                    </mat-form-field>

                    <div
                    class="invalid-field"
                    *ngIf="formQuote.submitted && carYom.hasError('required')">

                        <label class="invalid-field" *ngIf="carYom.hasError('required')">You must select year of manufacture</label>

                    </div>

                </div>

                <div class="one_quarter estimate">
                    <span>KES</span>
                    <label for="">Estimate value</label>
                    <input
                        type="text"
                        class="value"
                        minlength="5"
                        
                        currencyMask
                        ngModel
                         #estimateValue="ngModel"
                        (ngModelChange)="onAmountChange(estimateValue.value)"
                        min="100000"
                        name="estimatedValue"
                        required>

                        <div
                        class="invalid-field"
                        *ngIf="formQuote.submitted && estimateValue.invalid">

                            <label
                            *ngIf="estimateValue.invalid"
                            class="invalid-field">Vehicle Value is too low
                            </label>


                            <label *ngIf="estimateValue.errors.minlength"
                        class="invalid-field">Vehicle Amount Too Low</label>

                        </div>
                </div>



                <div class="one_quarter submitView">

                    <button
                        class="submit readMoreYellow getCover"
                        type="submit"
                        *ngIf="quoteReceived === false">

                        Get Quote

                        <fa-icon
                        icon="arrow-circle-right"
                        class="arrow"

                        ></fa-icon>

                    </button>



                    <button
                        class="submit readMoreBlack getCover"
                        type="submit"
                        *ngIf="quoteReceived == true"
                        (click)="hideSummary();" >

                        Back

                        <fa-icon
                        icon="arrow-circle-left"
                        class="arrow"

                        ></fa-icon>

                    </button>
                </div>

            </fieldset>

            <!--  Quote Summary  -->
            <section class="quoteSummary shadow">

                    <app-processor *ngIf="loading" >

                    </app-processor>

                    <section class="tabWrapper" *ngIf="quoteReceived">

                        <!--  Quote Navigation Desktop  -->
                        <section class="tabNavigation noborder desktop"  >

                            <ng-container *ngFor="let tab of policySummary">

                                <button
                                    class=""
                                    type="button"
                                    *ngIf="(tab?.coverName?.hasOwnProperty('name') && tab?.coverName?.name != '') "
                                    value="{{tab?.coverName?.slug}}"
                                    (click)="tabQuoteSummary($event,tab?.coverName?.slug)"
                                    >

                                    {{tab?.coverName?.name}}
                                </button>

                            </ng-container>



                        </section>
                        <!--END Quote Navigation  Desktop  -->



                        <!-- Comprehensive Cover   -->
                        <ng-container *ngFor="let tabContent of policySummary;let last=last; let index = index;">
                            <!--  Quote Navigation Mobile  -->
                            <section class="tabNavigation noborder mobile"  >

                                <button
                                    class="readMoreYellow"
                                    type="button"
                                    *ngIf="(tabContent?.coverName?.hasOwnProperty('name') && tabContent?.coverName?.name != '' )"
                                    value="{{tabContent?.coverName?.slug}}"
                                    (click)="tabQuoteSummary($event,tabContent?.coverName?.slug)"
                                    >

                                    {{tabContent?.coverName?.name}}

                                    <figure class="icon">

                                        <fa-icon

                                        icon="times-circle"
                                        class="closer"></fa-icon>

                                        <fa-icon

                                        icon="plus-circle"
                                        class="opener"></fa-icon>

                                    </figure>


                                </button>

                            </section>
                            <!--END Quote Navigation Mobile  -->
                            <section
                                class="tabContent {{tabContent?.coverName?.slug}}"
                                *ngIf="tabContent?.coverName?.hasOwnProperty('name')"
                                 >


                                    <article class="half specifications">
                                        <h3>Lipa In Full</h3>
                                        <p>{{tabContent?.coverName?.name}}</p>
                                        <p>{{vehicleDetails?.carDetails?.vehicle}}, {{vehicleDetails?.carDetails?.year}}</p>
                                        <p>Estimate Value - {{vehicleDetails?.carDetails?.estimatedValue | currency : 'KES ':'code' :'1.0-0'}}</p>

                                        <h5 *ngIf="tabContent?.coverName.hasOwnProperty('ipf')">12 months premium amount</h5>

                                        <h2 *ngIf="tabContent?.coverName.hasOwnProperty('ipf')">{{tabContent?.coverName?.premiumAmountNoTax | currency : 'KES ':'code' :'1.0-0'}}</h2>

                                    </article>

                                    <article class="half noborder" *ngIf="tabContent?.coverName.hasOwnProperty('ipf')">

                                        <h3>Lipa Pole Pole
                                            <span
                                            class="tooltip"
                                            #tooltip="matTooltip"
                                            matTooltip="Insurance premium financing that allows you to pay for your premium in convenient installments"
                                            matTooltipClass="tipdescription"
                                            matTooltipPosition="right"
                                            >
                                                <fa-icon icon="info" class="info"></fa-icon>
                                            </span>
                                        </h3>

                                        <p>Your deposit amount includes Third Party Only insurance cover, 60 days premium, valuation amount, taxes and levies. Pay <strong>{{tabContent?.coverName?.ipf?.freqPay | currency :'KES ' : 'code' :'1.0-0'}} per month for {{tabContent?.coverName?.ipf?.estimateTime}} months.</strong></p>

                                        <h5>Deposit required</h5>

                                        <h2>{{tabContent?.coverName?.ipf?.principleDeposit | currency :'KES ' : 'code' :'1.0-0'}}</h2>

                                        <button
                                            class="readMoreYellow getgoing HomePageGetStartedNowClick"
                                            type="button"
                                            id="HomePageGetStartedNowClick"
                                            (click)="navigateToNewCover(index);scrollUpTerms();"
                                             >

                                            Click Here to Get Started Now

                                            <fa-icon

                                            icon="arrow-circle-right"
                                            class="arrow"></fa-icon>
                                        </button>


                                    </article>

                                    <article class="half noborder" *ngIf="!tabContent?.coverName.hasOwnProperty('ipf')">


                                        <h5 *ngIf="!tabContent?.coverName.hasOwnProperty('ipf')">12 months premium amount</h5>

                                        <h2 *ngIf="!tabContent?.coverName.hasOwnProperty('ipf')">{{tabContent?.coverName?.premiumAmountNoTax | currency : 'KES ':'code' :'1.0-0'}}</h2>


                                        <button
                                            class="readMoreYellow getgoing HomePageGetStartedNowClick"
                                            type="button"
                                            id="HomePageGetStartedNowClick"
                                            (click)="navigateToNewCover(index);scrollUpTerms();"
                                             >

                                            Click Here to Get Started Now

                                            <fa-icon

                                            icon="arrow-circle-right"
                                            class="arrow"></fa-icon>
                                        </button>


                                    </article>

                            </section>
                        </ng-container>
                        <!--END Comprehensive Cover   -->

                    </section>

            </section>
            <!--END Quote Summary   -->



        </form>
        <!--END  Get Quote Form  -->



        <div class="coverSummary">
            <app-claimsummary

                title="Comprehensive  Cover"
                type="white"
                class="z30"

                image="./assets/_img/summary/hand.png"
                description="This is a superior cover to all the others. In addition to Third party, fire and theft, it covers your vehicle against most risks, including accidents and damages caused by natural disasters. You can modify your cover with additional benefits such as Windscreen protector, Entertainment unit, Excess protector, Political violence among others."

            ></app-claimsummary>


            <app-claimsummary
                title="Third Party Fire & Theft"
                type="white"
                class="z20"

                description="Third-party, Fire and Theft Car Insurance covers damages you have caused to other parties and their property. It also covers your car against, fire related damages and partial or total theft."
                image="./assets/_img/summary/car_shield.png"
            ></app-claimsummary>

            <app-claimsummary
                title="Third Party Cover"
                type="white"
                class="z10"

                description="Third party only Car Insurance is the most basic insurance policy covering the damages to other parties and their property in the event of an accident where you were the cause. This is a mandatory cover that every motorist should have."
                image="./assets/_img/summary/car_shield.png"

            ></app-claimsummary>

            <app-claimsummary
                title="Underwritten By Heritage"
                type="white"
                class="z10"

                description="We are a subsidiary of Liberty Kenya Holdings Plc which is listed on the Nairobi Securities Exchange. Liberty Kenya Holdings Plc is also the holding company for Liberty Life Assurance Kenya Ltd"
                image="./assets/_img/home/heritage.png"

            ></app-claimsummary>


        </div>


    </article>
    <!--END  Introduction Text  -->


    <!--  Banner Social Media Navigation  -->

    <app-socialnav>
    </app-socialnav>

    <!--END  Banner Social Media Navigation  -->

    <!--  Ambient Traffic  -->
    <!--<audio id="ambientAudio" src="./assets/audio/cityaudio.mp3" autoplay muted loop></audio>-->
    <!--END  Ambient Traffic  -->


</div>
