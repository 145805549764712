<section class="featureBanner">

    <img
    *ngIf="image === ''"
        src="/assets/_img/feature/feature2.png"
        alt=""
        class="backgroundImage">

    <img
    *ngIf="image != ''"
        src="{{image}}"
        alt=""
        class="backgroundImage">

    <div class="overlay"></div>

    <article class="featureArticle">

        <fa-icon
            icon="headset"
            class="headset">

        </fa-icon>

        <h3>How can we brighten your day today? AskMe anything, or share your feedback.</h3>

        <p>Talk to Me, I reply in under 15 minutes</p>

        <a href="tel:0709 184 000" class="readMoreWhiteSolid">
            <fa-icon
                icon="phone-volume"


            ></fa-icon>
            0709 184 000
        </a>


    </article>
</section>
