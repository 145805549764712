import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { QuestionService } from '../../_services/question/question.service';
import { QuestionControlService } from '../../_services/question/question-control.service';
import { QuestionBase } from '../../_models/question/question-base';

import { Observable } from 'rxjs';

import  $  from 'jquery';

@Component({
	selector: 'app-dynamictest',
	templateUrl: './dynamictest.component.html',
	styleUrls: ['./dynamictest.component.css'],
	providers:[
		QuestionService,
		QuestionControlService
	]
})
export class DynamictestComponent implements OnInit {

	questions$: Observable<QuestionBase<any>[]>;

	homeQuestions:any = [];
	buildingQuestions:any = [];
	ekycQuestions:any = [];
	individualoption:any = [];
	fullPage:any = [];
	loading:boolean = false;
	pageId:string = "";
	jsonFileName: string = "";
	formSubmissionURL:string = "";

	//Modal definitions
	showModal: boolean = false;
	modalTitle: string = "";
	modalDescription: string = "";
	modalType: string = "info";

	constructor(
		private questionService: QuestionService,
		private route: ActivatedRoute,
		private questionControlService:QuestionControlService
	) {

		this.questions$ = questionService.getQuestions();

	}

	ngOnInit(): void {

		this.pageId = this.route.snapshot.paramMap.get('pageId');

		//this.getDynamicQuestion(this.pageId);

		this.getDynamicLocalQuestions(this.pageId);
		
	}

	getDynamicQuestion(pageId){

		this.loading = true;

		this.questionService.getQuestionsById(pageId).subscribe(response => {
			
			if(response["body"]["surveyDefinitionPages"].length > 0){

				this.loading = false;

				this.formSubmissionURL = response["body"]["submissionUrl"];

				this.fullPage = response["body"]["surveyDefinitionPages"];

			} 
			
			else {

				this.showModal = true;
				this.modalTitle = "Network Error";
				this.modalDescription = "There seems to be a problem with your network";
				this.modalType = "info";
				$(".modal").fadeIn();

			}

		}, error => {

			this.showModal = true;
			this.modalTitle = "Network Error";
			this.modalDescription = "There seems to be a problem with your network. Ensure that your connection is stable and refresh your browser.";
			this.modalType = "info";

		});


	}

	getDynamicLocalQuestions(jsonFileName){

		this.loading = true;

		this.questionService.getJSONLocalFile(jsonFileName).subscribe(response => {

			if(response[0]["surveyDefinitionPages"].length > 0){

				this.loading = false;

				this.fullPage = response[0]["surveyDefinitionPages"];

			} 
			
			else {

				this.showModal = true;
				this.modalTitle = "Network Error";
				this.modalDescription = "There seems to be a problem with your network";
				this.modalType = "info";
				$(".modal").fadeIn();

			}

		}, error => {

			this.showModal = true;
			this.modalTitle = "Network Error";
			this.modalDescription = "There seems to be a problem with your network. Ensure that your connection is stable and refresh your browser.";
			this.modalType = "info";

		});


	}

}
