import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class AutocompletedataService {

  constructor(
    private http:HttpClient
  ) { }


  getAutoCompleteData(searchValue:string, apiUrl: string):Observable<any>{

		return this.http.get(`${apiUrl}${searchValue}`);
    
	}

  fetchDynamicSelectValues(apiUrl:string):Observable<any>{

    return this.http.get(`${apiUrl}`);
    
  }

}
