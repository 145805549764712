import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import $ from 'jquery';

@Component({
  selector: 'app-claimsfaq',
  templateUrl: './claimsfaq.component.html',
  styleUrls: ['./claimsfaq.component.css']
})
export class ClaimsfaqComponent implements OnInit {

  claimsFaqs: any;

  constructor(
    private http: HttpClient
  ) {}

  ngOnInit(): void {

    this.http.get("/assets/data/ClaimsFaqs.json")
      .subscribe(data => {
      this.claimsFaqs= data;

    });

    $(".faqHeader").click(this.toggleTabs);

  }

  toggleTabs() {

    $(".accordionContainer").stop().slideUp();

    if ($(this).hasClass("activeFaq")) {

      $(".faqHeader").removeClass("activeFaq");
      $(this).removeClass("activeFaq").next(".accordionContainer").stop().slideUp();

    } else {

      $(".faqHeader").removeClass("activeFaq");
      $(this).addClass("activeFaq").next(".accordionContainer").stop().slideDown();

    }
  }

  openAccordion(event) {

    $(".singleFAQ .accordion").stop().slideUp();

    if ($(event.target).hasClass("open")) {

      $(".singleFAQ .accordionTitle").removeClass("open");
      $(event.target).removeClass("open").next(".accordion").stop().slideUp();

    } else {

      $(".singleFAQ .accordionTitle").removeClass("open");
      $(event.target).addClass("open").next(".accordion").stop().slideDown();

    }

  }

}
