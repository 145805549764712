import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import $ from 'jquery';
import { first } from 'rxjs/operators';
import { AlertService, AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-authoverlay',
  templateUrl: './authoverlay.component.html',
  styleUrls: ['./authoverlay.component.css'],
})
export class AuthoverlayComponent implements OnInit {
  model: any = {};
  resetModel: any = {};
  loading: boolean = false;
  resetLoading: boolean = false;
  returnUrl: string;
  isResetPassword: boolean;
  successMessage: any;
  errorMessage: any;
  validationError: string = '';
  isLogin: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    // redirect to home if already logged in
    // if (!this.authenticationService.currentUserValue()) {
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // console.log

    this.isLogin = this.authenticationService.userLoggedIn();

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        $('.authoverlay').fadeOut();
      }
    });
  }

  onGetContacts() {
    this.loading = true;
    this.authenticationService
      .fetchUserContact(this.model.nationalId)
      .pipe(first())
      .subscribe(
        (res) => {
          this.loading = false;

          if (res['success']) {
            this.successMessage = res.message;
          } else {
            this.validationError = res.message;
          }
          
          this.model.email = res.data.email;
          this.model.phoneNumber = res.data.phoneNumber;
          this.toggleAuthenticationView('communication-channel');
        },
        (error) => {
          this.loading = false;
          if (error.error.message != null && error.error.message != ""){
            this.validationError = error.error.message;
          } else {
            this.validationError = 'Oops something went wrong, please try again';
          }
          
        }
      );
  }

  onGetOtp() {
    this.loading = true;

    // reset alerts on submit
    this.alertService.clear();

    const data = {
      communicationChannel: this.model.communicationChannel,
      nationalId: this.model.nationalId,
    };

    this.authenticationService
      .fetchOtp(data)
      .pipe(first())
      .subscribe(
        (res) => {

          this.successMessage = res.message;
          this.toggleAuthenticationView('otp');
        },
        (error) => {
          this.errorMessage = error.message;
          this.alertService.error(error);
        }
      );
    this.loading = false;
  }

  onValidateContacts() {
    this.loading = true;

    // reset alerts on submit
    this.alertService.clear();

    const data = {
      nationalId: this.model.nationalId,
      otp: this.model.otp,
    };

    this.authenticationService
      .validateOtp(data)
      .pipe(first())
      .subscribe(
        (res) => {
          this.loading = false;

          this.isResetPassword = res.data;
          this.successMessage = res.message;

          if (!res['success']) {
            this.validationError = res.message;
          }
          this.toggleAuthenticationView('login-password');
        },
        (error) => {
          this.loading = false;
          this.errorMessage = error.message;
          this.alertService.error(error);
          this.validationError = 'Otp match failed. Tyr again';
        }
      );
    this.loading = false;
  }

  onSubmit() {
    this.loading = true;

    // reset alerts on submit
    this.alertService.clear();

    this.authenticationService
      .login(this.model.nationalId, this.model.password)
      .pipe(first())
      .subscribe(
        (data) => {

          this.isLogin = data.token ? true : false;
          this.router.navigate(['userprofile']); //login
          $('.authoverlay').fadeOut();

          if (!data['success']) {
            this.validationError = data['message'];
          }

          setTimeout(() => {
            window.location.reload();
          }, 400);
        },
        (error) => {
          this.errorMessage = error.message;
          this.alertService.error(error);

          this.validationError = 'Invalid password';
        }
      );
    this.loading = false;
  }

  onResetPassword() {
    this.resetLoading = true;

    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.resetModel));

    // reset alerts on submit
    this.alertService.clear();

    const data = {};

    this.authenticationService
      .resetPassword(this.resetModel)
      .pipe(first())
      .subscribe(
        (data) => {

          this.router.navigate(['userprofile']); //login
        },
        (error) => {
          this.errorMessage = error.message;
          this.alertService.error(error);
        }
      );
    this.resetLoading = false;
  }

  // showLogin(){
  //
  //     $(".authoverlay").show();
  //     $(".authoverlay .formWrapper").hide();
  //     $(".authoverlay .login").show();
  //     $("header").css({
  //         "position":"fixed"
  //     });
  //     $("body").css({
  //         "padding-top":"115px"
  //     });
  //
  // }

  toggleAuthenticationView(viewName: string) {
    this.validationError = '';

    $('.authoverlay')
      .stop()
      .fadeIn(1000, function () {
        $('header').css({
          position: 'fixed',
        });

        $('body').css({
          'padding-top': '115px',
        });

        $('.authoverlay .formWrapper')
          .stop()
          .slideUp(1000, function () {
            $('.authoverlay .' + viewName).slideDown(1000);
          });
      });
  }

  closeLogin() {
    $('.authoverlay')
      .stop()
      .fadeOut(1000, function () {
        $('header').css({
          position: 'relative',
        });
        $('body').css({
          'padding-top': '0px',
        });
      });
  }
}
