import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-socialnav',
  templateUrl: './socialnav.component.html',
  styleUrls: ['./socialnav.component.css']
})
export class SocialnavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
