<!-- [formGroupName]="form" -->
<!-- [formGroup]="form" -->

<!-- <div [formGroup]="form">

	<label [attr.for]="question?.questionText" *ngIf="question?.questionText !== ''" >{{question?.questionText}}</label>

	<input
		*ngIf=" question?.fieldType == 'textfield' || question?.fieldType == 'integer'  "
		[id]="question.fieldName"
		[type]="question.fieldType">

	<select
		[id]="question.fieldName"
		*ngIf="question?.fieldType == 'select_one'" >

		<option *ngFor="let opt of question?.options" [value]="opt?.value">
			{{opt?.label}}
		</option>
	</select>

	<input
		*ngIf=" question?.fieldType == 'textfield' || question?.fieldType == 'integer'  "
		[id]="question.fieldName"
		[type]="question.fieldType">



	<div class="errorMessage" *ngIf="!isValid">{{question.fieldName}} is required</div>

</div> -->

<div 
	*ngFor="let question of pages.questions; let questionIndex = index "
	class="form-row {{question.questionLayout}} singleQuestion" 
	id="{{question.questionOrder}}">

		<label 
			[attr.for]="question?.questionText" 
			*ngIf="question?.questionText !== ''"
			[ngClass]="question?.fieldType == 'select_multiple' ? 'labelCheckbox' : '' ">
			{{question?.questionText}}
		</label>

		<input 
			*ngIf=" question?.fieldType == 'textfield' || question?.fieldType == 'integer'  "
			[formControlName]="question.fieldName" 
			[pattern]="question.regularExpression" 
			[maxLength]="question.max"
			[minLength]="question.min" 
			(change)="onFormControlChange(pages.pageName)" 
			[id]="question.fieldName"
			[type]="question.fieldType">

  		<div 
			[ngClass]="question?.fieldType == 'dateTime' ? 'dateTimeInput' : '' "
    		*ngIf=" question?.fieldType == 'dateTime' ">

		<input 
			matInput [ngxMatDatetimePicker]="picker" 
			[ngxMatDatetimePickerFilter]="dateFilter" 
			[min]="minDate"
			[max]="maxDate" 
			[formControlName]="question.fieldName" 
			[id]="question.fieldName" 
			[type]="question.fieldType"
			(change)="onFormControlChange(pages.pageName)" 
			(click)="picker.open()">

		<button 
			mat-icon-button 
			matSuffix 
			class="iconPosition" 
			(click)="picker.open();">

			<fa-icon icon="calendar-alt"></fa-icon>

		</button>

		<ngx-mat-datetime-picker 
			#picker 
			[showSpinners]="showSpinners" 
			[showSeconds]="showSeconds" 
			[stepHour]="stepHour"
			[stepMinute]="stepMinute" 
			[enableMeridian]="enableMeridian">
		</ngx-mat-datetime-picker>

		<mat-datepicker #picker></mat-datepicker>

  	</div>

	<input 
		*ngIf="question?.fieldType == 'autocomplete'" 
		matInput 
		(change)="onFormControlChange(pages.pageName)"
		[formControl]="autocompleteForm" 
		(keyup)="onInputChange($event, question.autoCompleteUrl)" 
		[matAutocomplete]="auto"
		[id]="question.fieldName" 
		[type]="question.fieldType">

  	<mat-autocomplete 
		#auto="matAutocomplete" 
		(optionSelected)="onDataSelected($event.option.value, pages.pageName)">

			<mat-option 
				*ngFor="let option of options" 
				[value]="option.name">
			{{option.name}}
			</mat-option>

  	</mat-autocomplete>

	<div [ngClass]="question?.fieldType == 'file' ? 'uploadValuation' : '' ">
	
		<input
			*ngIf="question?.fieldType == 'file' "
			[formControlName]="question.fieldName"
			(change)="onFormControlChange(pages.pageName)"
			[id]="question.fieldName"
			placeholder="Upload file"
			[type]="question.fieldType"
			data-extension="/(\.pdf | \.jpg,.jpeg,.png)$/i"/>

			<ng-container *ngIf="question?.fieldType == 'file' ">

				<label 
					for="uploadId"
					class="uploadLabel">
					Upload
				</label>

				<fa-icon 
					class="iconPosition"
					icon="upload">
				</fa-icon>

			</ng-container>

	</div>

	<div [ngClass]="question?.fieldType == 'geopoint' ? 'locationInput' : '' ">

		<mat-google-maps-autocomplete
			*ngIf="question?.fieldType == 'geopoint'"
			[formControlName]="question.fieldName"
			[id]="question.fieldName"
			[type]="question.fieldType"
			country="ke"
			type="establishment"
			(onAutocompleteSelected)="onAutocompleteSelected($event)"
			(onLocationSelected)="onLocationSelected($event)">
		</mat-google-maps-autocomplete>
		
	</div>

</div>
