import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import jwt_decode from 'jwt-decode';

import { environment } from '../../environments/environment.dev';

import { User, Response } from '../_models';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;

	constructor(private http: HttpClient) {

		this.currentUserSubject = new BehaviorSubject<User>(
			JSON.parse(localStorage.getItem('insuremeRiders'))
		);

		this.currentUser = this.currentUserSubject.asObservable();

	}

	public createAuthorizationHeader(headers: HttpHeaders) {

		headers.set('Authorization', 'Bearer ' +
		btoa('username:password'))
		.set('content-type', 'application/json')
   		.set('Access-Control-Allow-Origin', '*')
		.set('username','johnakello').
		set('password','12345');

	}

	public headerBasedRequest(url) {

		let headers = new HttpHeaders();

		this.createAuthorizationHeader(headers);

		return this.http.get(url, {
			headers: headers
		});

	}

	public get currentUserValue(): User {

		return this.currentUserSubject.value;

	}

	login(nationalId, password) {

		return this.http
		.post<Response>(`${environment.customerFacingApi}auth/login`, {
			nationalId,
			password,
		})
		.pipe(
			map((response) => {
				const user: User = response.data;
				// const decoded = await jwt_decode(token);
				// console.log('Decoded token', decoded);

				// const user = {
				//   ...decoded,
				//   token,
				// };
				localStorage.setItem('insuremeRiders', JSON.stringify(user));
				this.currentUserSubject.next(user);
				return user;
			})
		);

	}

	getUserToken() {

		if (localStorage.getItem('insuremeRiders')) {

			let user = JSON.parse(localStorage.getItem('insuremeRiders'));
			let token = user.token;

			return token;

		}

	}

	getUserNationalId() {

		if (localStorage.getItem('insuremeRiders')) {

			let user = JSON.parse(localStorage.getItem('insuremeRiders'));
			let id = user.username;

			return id;

		}

	}

	getUserSystemId() {

		if (localStorage.getItem('insuremeRiders')) {

			let user = JSON.parse(localStorage.getItem('insuremeRiders'));
			let id = user.id;
			return id;

		}

	}

	getUserName() {

		if (localStorage.getItem('insuremeRiders')) {

			let user = JSON.parse(localStorage.getItem('insuremeRiders'));
			let name = user.customerName;

			return name;

		}

	}

	userLoggedIn() {

		if (localStorage.getItem('insuremeRiders')) {
			return true;
		} else {
			return false;
		}
	}

	logout() {
		// remove user from local storage and set current user to null
		localStorage.removeItem('insuremeRiders');
		this.currentUserSubject.next(null);
	}

	fetchUserContact(nationalId) {
		return this.http.get<Response>(
			`${environment.customerFacingApi}auth/getUserContacts?nationalId=${nationalId}`
		);
	}

	fetchOtp(data) {
		return this.http.post<Response>(
			`${environment.customerFacingApi}auth/otp`,
			data
		);
	}

	validateOtp(contact) {
		return this.http
		.post<Response>(
			`${environment.customerFacingApi}auth/validateContacts`,
			contact
		)
		.pipe(
			map((data) => {

				return data;
			})
		);
	}

	resetPassword(data) {
		return this.http
		.post<Response>(
			`${environment.customerFacingApi}auth/resetPassword`,
			data
		)
		.pipe(
			map((response) => {

				const user: User = response.data;

				localStorage.setItem('insuremeRiders', JSON.stringify(user));
				this.currentUserSubject.next(user);
				return user;
			})
		);
	}

	getIp() {

		return this.http.get('https://jsonip.com');

	}
}
