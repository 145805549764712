
<div class="faqGroupContainer">

    <div class="faqHeader">
        <h2>Claims FAQs</h2>
    </div>

    <!--  Accordion Container  -->
    <div class="accordionContainer inactive">
        
        <!--  Single Accordion  -->
        <div class="singleFAQ" *ngFor="let faq of claimsFaqs">

            <h3 class="accordionTitle" (click)="openAccordion($event)">
                
                {{faq.question}}

                <fa-icon

                icon="plus-circle"
                class="arrow closed"></fa-icon>

                <fa-icon

                    icon="minus-circle"
                    class="arrow open"></fa-icon>

            </h3>

            <div class="accordion">
                <p>
                    {{faq.answer}}
                </p>
                
                <ul *ngIf="faq.subitems">
                    <li *ngFor="let subitem of faq.subitems">
                        {{subitem.item}}
                    </li>
                </ul>
                
            </div>

        </div>
        <!--  End of Single Accordion  -->

    </div>

</div>