import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomValidationService {
  
  MatchDeposit(controlName: string, matchingControlName: number) {
      return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
         
  
        // return null if controls haven't initialised yet
        if (!control || !matchingControlName) {
          return null;
        }
  
        // set error on matchingControl if validation fails
        if (control.value < matchingControlName) {
          control.setErrors({ mustMatch: true });
        } else {
          control.setErrors(null);
        }
      };
  }

  MatchPassword(controlName: string, matchingControlName: string) {
    
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      // return null if controls haven't initialised yet
      if (!control || !matchingControl) {
        return null;
      }

      // return null if another validator has already found an error on the matchingControl
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return null;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  

}
