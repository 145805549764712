<section class="centerAligner shadow singlePolicy rounded ">

	<!--  Overlay Modal  -->
    <app-modal
        *ngIf="showModal"
        title="{{modalTitle}}"
        link="{{modalLink}}"
		(yesButtonClick)="approveCancellation($event)"
		(noButtonClick)="declineCancellation($event);"
        linkText="{{modalLinkText}}"
        description="{{modalDescription}}"
        type = "{{modalType}}"
        [modalData] = "modalData"
         >
    </app-modal>
	<!--END  Overlay Modal  -->

	<!--  Policy Loader Processor  -->
	<app-processor
		*ngIf="loading"
		description="Loading policy data">
	</app-processor>
	<!--END Policy Loader Processor   -->

    <!--  Policy Summary  -->
    <div class="policySummary singlePolicy">

		<!--  Policy Cancellation Header  -->
        <div class="headerRow" *ngIf="!loading">

			<div class="left full_width">

				<h2>Policy Cancellation - {{policyDetails?.vehicle | slice:0:8}} {{policyDetails?.vehicle | slice:8:30 | titlecase}} </h2>

				<span class="border"></span>

			</div>



		</div>
		<!--END Policy Cancellation Header   -->

		<!--  Policy Single Row  -->
        <div class="row">
            <h3 class="left">Policy No </h3>
            <h3 class="right">{{policyId}}</h3>
        </div>
		<!--END  Policy Single Row  -->

		<!--  Policy Single Row  -->
        <div class="row">

            <p class="left">Cover Type</p>
            <p class="right">{{policyDetails?.coverType}}</p>

        </div>
		<!--END  Policy Single Row  -->

		<!--  Policy Single Row  -->
        <div class="row">

            <p class="left">Estimate Value</p>
            <p class="right">{{policyDetails?.estimateValue | currency : 'code' : 'KES '  : '1.0-0'}}</p>

        </div>
		<!--END  Policy Single Row  -->

		<!--  Policy Single Row  -->
        <div class="row">

            <p class="left">Policy application Date</p>
            <p class="right">{{policyDetails?.policyStartDate | date : 'fullDate'}}</p>

        </div>
		<!--END  Policy Single Row  -->

		<!--  Policy Single Row  -->
        <div class="row">

            <p class="left">Policy Start Date</p>
            <p class="right">{{policyDetails?.policyStartDate | date : 'fullDate'}}</p>

        </div>
		<!--END  Policy Single Row  -->

		<!--  Policy Single Row  -->
        <div class="row">

            <p class="left">Policy End Date</p>
            <p class="right">{{policyDetails?.policyEndDate | date : 'fullDate'}} </p>

        </div>
		<!--END  Policy Single Row  -->

		<!--  Policy Single Row  -->
        <div class="row">

            <p class="left">Vehicle</p>
            <p class="right">{{policyDetails?.vehicle }} </p>

        </div>
		<!--END  Policy Single Row  -->


		<!--  Row Seperator  -->
		<div class="rowSeperator">
		</div>
		<!--END Row Seperator   -->

		<!--  Policy Utilisation  -->

		<div class="row col3 noborder">
			<h3 class="left">Basic Premium</h3>
			<h3 class="center">12 Months</h3>
			<h3 class="right">{{refundObject?.basicPremium | currency : 'code' : 'KES '  : '1.0-0'}}</h3>
		</div>

		<!--END  Policy Utilisation  -->

		<!--  Policy Utilisation  -->

		<div class="row col3 noborder">
			<h3 class="left">Days car has been covered</h3>
			<h3 class="center">{{refundObject?.daysUtilized}} Days</h3>
			<h3 class="right">{{ refundObject?.daysUtilizedAmount | currency : 'code' : 'KES '  : '1.0-0'}}</h3>
		</div>

		<!--END  Policy Utilisation  -->

		<!--  Policy Utilisation  -->

		<div class="row col3 noborder">
			<h3 class="left">Days To Be refunded</h3>
			<h3 class="center">{{ refundObject?.daysToBeRefunded}} Days</h3>
			<h3 class="right">{{refundObject?.totalDaysAmountRefundable | currency : 'code' : 'KES '  : '1.0-0'}}</h3>
		</div>

		<!--END  Policy Utilisation  -->

		<!--END   Refundable Fields  -->
		<div class="row">
			<h3 class="left">Refundable taxes and benefits</h3>
		</div>

		<!--END   Refundable Fields  -->
		<div class="row noborder">

			<p class="left">Policy Holder Compensation Fund (PHCF)</p>

			<p class="right">{{refundObject?.phcfRefundableAmount | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		</div>

		<!--   Refundable Fields  -->
		<div class="row noborder">

			<p class="left">Training Levy</p>

			<p class="right">{{refundObject?.transactionLevyRefundable | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		</div>
		<!--END   Refundable Fields  -->

		<!--   Refundable Fields  -->
		<div class="row noborder">

			<p class="left">Refundable benefits</p>

			<p class="right">{{refundObject?.totalBenefitAmount | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		</div>
		<!--END  Refundable Fields  -->



		<!--  Non Refundable Fields  -->
		<!--END  Non Refundable Fields  -->
		<div class="row">
			<h3 class="left">Non-Refundable</h3>
		</div>

		<!--  Non Refundable Fields  -->


		<!--END  Non Refundable Fields  -->
		<div class="row noborder">

			<p class="left">Stamp Duty</p>

			<p class="right">{{refundObject?.nonRefundables?.stampDuty | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		</div>

		<!--  Non Refundable Fields  -->
		<div class="row noborder">

			<p class="left">Transaction Fee</p>

			<p class="right">{{refundObject?.nonRefundables?.transactionFee | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		</div>
		<!--END  Non Refundable Fields  -->

		<!--  Non Refundable Fields  -->
		<div class="row noborder" *ngIf="refundObject?.nonRefundables?.valuationFee != '' " >

			<p class="left">Valuation Fee</p>

			<p class="right">{{refundObject?.nonRefundables?.valuationFee | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		</div>
		<!--END  Non Refundable Fields  -->



		<!--  Non Refundable Fields  -->
		<div class="row noBorder"
		*ngIf="refundObject?.totalAmountRefundable !== '' || refundObject?.totalAmountRefundable !== null"  >

			<h3 class="left">Total Amount Refundable</h3>

			<h3 class="right">{{refundObject?.totalMonthsRefundableAmount | currency : 'code' : 'KES '  : '1.0-0'}}</h3>

		</div>
		<!--END  Non Refundable Fields  -->

		<!--  Cancellation Reason  -->
		<div class="cancelDetails">

			<h4>Please share with us your reason for cancelling the policy?</h4>

			<p>We appreciate your feedback because it helps us serve your better.</p>
			<form action="">
				<div class="row noborder">

					<div class="full_width">

						<select
							name="reason" id="reason"
							(change)="updateCancellationReason($event);">

							<option value="car_sale">Sold Car</option>

							<option value="naming_error">My naming details have an error</option>

							<option value="registration_error">My vehicle has a registration error</option>

							<option value="certification_not_required">I no longer require policy</option>

							<option value="premium_default">I defaulted on my premium</option>

							<option value="vehicle_writeoff">My vehicle is written off</option>

							<option value="ammend_period">I want to ammend cover period</option>

							<option value="wrong_class">My vehicle is in wrong class</option>

							<option value="terms_compliance">Non Compliance with terms</option>

							<option value="certificate_void">Certificate declared void</option>

						</select>

					</div>

				</div>
			</form>


		</div>
		<!--END  Cancellation Reason  -->

		<!--  Payment Module  -->

		<div class="paymentNumber">

			<h3>Which number would you like the amount sent to?</h3>

			<label for="">Enter your Safaricom mobile number to receive your refund</label>

			<form action="">

				<div class="row noborder">

					<div class="three_quarter fl">

						<span class="countryHighlight">+254</span>

						<input

							type="text"
							class="phoneNumber"
				            name="customerMobile"
							(keyup)="changePaymentPhoneNumber($event);"
				            placeholder="Enter mobile number"
							[(ngModel)]="phoneNumber"
				            #paymentMobile="ngModel"
				            pattern="^(?:0)((?:7|1)[0-9]{8})$"
				            required>

							<div
					        class="invalid-field"
					        *ngIf=" paymentMobile.invalid">

						        <label
						        *ngIf="paymentMobile.errors.required"
						        class="invalid-field">Phone Number is required
							    </label>

							    <label *ngIf="paymentMobile.errors.pattern"
							    class="invalid-field">Phone Number invalid</label>

							</div>

					</div>

					<div class="one_quarter fl">

						<button class="readMoreYellow"
						[disabled]="paymentMobile.invalid"
						(click)="updatePaymentPhoneNumber()">

							Update

							<fa-icon
		                        class="arrow"
		                        icon="arrow-alt-circle-right">
		                    </fa-icon>

						</button>

					</div>



				</div>


			</form>

		</div>

		<!--END  Payment Module  -->

		<!--  Cancellation Buttons  -->

		<div class="row noborder">

			<!--  Cancel Policy Button  -->
			<button
				(click)="cancelPolicy();"
				class="readMoreYellow">
				Cancel Policy
				<fa-icon
					class="arrow"
					icon="arrow-alt-circle-right">
				</fa-icon>

			</button>
			<!--END  Cancel Policy Button  -->

			<!--  Back Button  -->
			<a class="readMoreGrey"
				routerLink="/userprofile/policy/{{policyId}}">
				Back
				<fa-icon
					class="arrow"
					icon="arrow-alt-circle-left">
				</fa-icon>
			</a>
			<!--END  Back Button  -->

		</div>
		<!--END  Cancellation Buttons  -->

    </div>
    <!--END  Policy Summary  -->


</section>
