import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import $ from 'jquery';

@Component({
  selector: 'app-generalmotorfaq',
  templateUrl: './generalmotorfaq.component.html',
  styleUrls: ['./generalmotorfaq.component.css']
})
export class GeneralmotorfaqComponent implements OnInit {

  generalMotorFaqs:any;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {

    this.http.get("/assets/data/GeneralMotorFaqs.json")
      .subscribe(data => {
      this.generalMotorFaqs = data;
    });

   $(".faqHeader").click(this.toggleTabs);

  }

  openAccordion(event){

    $(".singleFAQ .accordion").stop().slideUp();

    if ($(event.target).hasClass("open")) {

      $(".singleFAQ .accordionTitle").removeClass("open");
      $(event.target).removeClass("open").next(".accordion").stop().slideUp();

    } else {

      $(".singleFAQ .accordionTitle").removeClass("open");
      $(event.target).addClass("open").next(".accordion").stop().slideDown();

    }

  }

  toggleTabs() {

   $(".accordionContainer").stop().slideUp();

    if ($(this).hasClass("activeFaq")) {

      $(".faqHeader").removeClass("activeFaq");
      $(this).removeClass("activeFaq").next(".accordionContainer").stop().slideUp();

    } else {

      $(".faqHeader").removeClass("activeFaq");
      $(this).addClass("activeFaq").next(".accordionContainer").stop().slideDown();

    }
  }

}
