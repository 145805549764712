
<section class="centerAligner">

  <!-- SINGLE SLIDE -->
    <div class="banner clear">

      <img src="./assets/_img/coverimage.jpg" class=" half fl">

      

      <div class="introDescription half fr"  >

        <article>

         

            <h1>Creative Thinking : A Journey Through the 8 Facets of Intelligence</h1>

            <p>
          

            Creativity can be defined as the use of imagination or intelligence to create something distinct or inventive. Considering that creativity is based on intelligence, let us look at the definition of intelligence.

            </p>

            <p>
                Intelligence: The ability to acquire, retain and apply knowledge or skill.
            </p>

            

            <p>The book takes a multifaceted approach towards attaining creativity and walks you through an indepth and individual analysis of how to use each Facet of Intelligence and then put them all together to create novel and unique ideas that you can leverage on to deliver value, either functional, societal or monetary</p>

            <p>The following are the purcahse options available, including a free sample</p>

            

            <a href="https://nuriakenya.com/product/creative-thinking-a-journey-through-the-8-facets-of-intelligence-by-john-akello/" target="_blank" class="readMoreBlue">Hard copy - KES 1,500 on Nuriakenya.com  </a>
          
           <a href="https://play.google.com/store/apps/details?id=com.dapperjd.creativethinking" target="_blank" class="readMoreRed">Buy Full Copy @ KES 1,000 - Android play store  </a>

          <a href="https://play.google.com/store/apps/details?id=com.dapperjd.creativethinkingsample" target="_blank" class="readMoreBlue">Get Sample Copy - Try before you buy</a>
          

        </article>
        

      </div>

    </div>
    <!--END  SINGLE SLIDE  -->  

    <section class="snippets clear">

        <h2>Snippets from the book</h2>
        
        <article>
            
            <section class="one_third fl">
                <img src="./assets/_img/snippets/01-faith.jpg" alt="">
                <h3>Effect of Faith on Creativity</h3>
                <p>Faith is the utomost belief that you venture will work out, despite any failures that might be encountered</p>
            </section>
            <section class="one_third fl">
                <img src="./assets/_img/snippets/06-optimism.jpg" alt="">
                <h3>Optimism</h3>
                <p>Optimism is the ability to turn any negative situation and find multiple positive oppotunities</p>
            </section>
            <section class="one_third fl">
                <img src="./assets/_img/snippets/11-imagination.jpg" alt="">
                <h3>Imagination</h3>
                <p>Imagination acts as gateway to reals the eye cannot see yet.</p>
            </section>
        </article>
    </section>
</section>


<!-- <app-myname></app-myname> -->


