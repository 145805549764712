<app-preloader
*ngIf="siteImages.length > 0"
[images]="siteImages"
></app-preloader>

<app-modal
*ngIf="showModal"
title="{{modalTitle}}"
link="{{modalLink}}"
linkText="{{modalLinkText}}"
description="{{modalDescription}}">


</app-modal>

<div class="centerAligner shadow rounded welcomeClaim mt-30">

  <!--Claims Section-->
  <section class="claimIntro active">
    <div class="claimHeader">
      <h1><span class="yellowLine">Hi {{clientName}},</span> welcome to our simple &amp; fast claim process</h1>
    </div>

    <div class="claim-intro-content">
      <p>
        Hello there. If you are visiting this page, chances are you are going through a tough time. We will make the
        process as easy and transparent as possible to help provide the peace of mind you need.
      </p>
    </div>

    <div class="noticeSection">

      <div class="noticeContent">

        <h2>Important Notice</h2>

        <ol>
          <li>The information provided is to enable the company and its Solicitors to advise on and to conduct any
            legal proceedings which may ensure.</li>
          <li>No liability under the policy is admitted by Issue of this electronic form.</li>
          <li>Neither owner nor driver must admit fault or liability for this Accident.</li>
          <li>Do not answer communications about this Accident, but send them to the Insurers for consideration.</li>
          <li>All questions must be answered in full by yourself or to your dictation</li>
          <li>Repairs must not be authorised without prior authority of the Insurers.</li>
        </ol>

      </div>

      <div class="noticeCheckbox">
        <mat-checkbox
          [(ngModel)]="confirmCheck"
          name="confirmCheck">
          <p>
            Please confirm you have read and understood the above notice to continue
          </p>
        </mat-checkbox>
      </div>

    </div>
    <!-- -->
  <div class="claimPolicies"  *ngIf="confirmCheck" >

    <div class="claim-policy-content">

      <form>
        <div class="row">
          <div class="full_width">
            <label>Select your policy</label>
            <select
                data-key="claimDetails"
                name="policyNumber"
                id="policyNumber"
                (change)="onChange($event);"
                [ngModel]="selectedPolicy"
                #policyNumber="ngModel">
                    <option value="">Select</option>
                     <option value="{{policy.policyNumber}}" *ngFor="let policy of policies" >Motor Private-{{policy.policyType}} -{{policy.registrationNo}}</option>

            </select>
          </div>
        </div>
      </form>

    </div>

    <div class="claims" *ngIf="policyNumber.value !== '' && (thirdPartyCheck === false || thirdParyIndex >0)" >
      <p>Select a claim to submit</p>

      <div class="singleClaim one_fifth">
        <div class="clickArea" data-form="damage-vehicle" data-target="damage-vehicle-claim" (click)="showNotification($event);"></div>
        <div class="claimIcon">
          <img src="./assets/_img/claims/damage-to-vehicle.png" alt="" class="currentIcon">
          <img src="./assets/_img/claims/damage-to-vehicle-active.png" alt="" class="activeIcon">
        </div>
        <h5>Damage to Vehicle</h5>
      </div>

      <div class="singleClaim one_fifth">
        <div class="clickArea" data-form="windscreen-damage" data-target="windscreen-damage-claim" (click)="showNotification($event);"></div>
        <div class="claimIcon">
          <img src="./assets/_img/claims/windscreen-damage.png" alt="" class="currentIcon">
          <img src="./assets/_img/claims/windscreen-damage-active.png" alt="" class="activeIcon">
        </div>
        <h5>Windscreen or Window Damage</h5>
      </div>

      <div class="singleClaim one_fifth hideform">
        <div class="clickArea" data-form="theft-parts" (click)="showNotification($event);"></div>
        <div class="claimIcon">
          <img src="./assets/_img/claims/theft-vehicle-parts.png" alt="" class="currentIcon">
          <img src="./assets/_img/claims/theft-vehicle-parts-active.png" alt="" class="activeIcon">
        </div>
        <h5>Theft of Vehicle Parts</h5>
      </div>

      <div class="singleClaim one_fifth">
        <div class="clickArea" data-form="theft-vehicle" (click)="showNotification($event);"></div>
        <div class="claimIcon">
          <img src="./assets/_img/claims/total-theft-vehicle.png" alt="" class="currentIcon">
          <img src="./assets/_img/claims/total-theft-vehicle-active.png" alt="" class="activeIcon">
        </div>
        <h5>Total Theft Of Vehicle</h5>

      </div>


      <div class="singleClaim one_fifth">
        <div class="clickArea" data-form="theft-recovery" (click)="showNotification($event);"></div>
        <div class="claimIcon">
          <img src="./assets/_img/claims/theft-recovery.png" alt="" class="currentIcon">
          <img src="./assets/_img/claims/theft-recovery-active.png" alt="" class="activeIcon">
        </div>
        <h5>Theft &amp; Recovery of Vehicle</h5>
      </div>


    </div>

    <div class="claims" *ngIf="policyNumber.value === ''" >

        <h5>Please select a policy from list above to proceed</h5>

    </div>

    <div class="claims" *ngIf="thirdPartyCheck === true || thirdParyIndex > -1" >

        <h5>Third party only cover cannot lodge a claim</h5>

    </div>


  </div>

  </section>
  <!--End of Claims Section-->

</div>

<figure class="overlay"></figure>

<!-- Damage Vehicle Modal Overlay  -->
<section class="formModal damage-vehicle shadow rounded">

  <button class="close-icon" (click)="closeModal();">

    <fa-icon icon="times" class="icon">
    </fa-icon>

  </button>

  <!--  Notification Section  -->
  <section class="notification-section">

    <div class="modalHeader">
      <p>Please prepare the documents below for faster claim processing. You can always upload these documents later
        by accessing your policy page.</p>
    </div>

    <div class="modalContent">
      <ul>

        <li>
          <strong>Before and After Photos</strong>
          <br />
          <em>If the car has already been fixed</em>
        </li>

        <li>
          <strong>Replacement Receipts or ETR</strong>
          <br />
          <em>If any item has been replaced</em>
        </li>

        <li>
          <strong>Towing Receipt</strong>
          <br />
          <em>If any item has been replaced</em>
        </li>

        <li>
          <strong>Police Abstract</strong>
        </li>

        <li>
          <strong>Accident Scene Photos or Video</strong>
        </li>
      </ul>
    </div>

    <div class="modalFooter">
      <button
          class="readMoreYellow"
          data-claim="damageVehicle"
          data-type="Damage of vehicle"
           (click)="handleModalProgression($event);">
        Continue
        <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
      </button>
    </div>

  </section>
  <!--  <End Notification Section  -->

</section>
<!--Damage Vehicle Modal Overlay   -->

<!-- Theft Vehicle Modal Overlay  -->
<section class="formModal theft-vehicle shadow rounded">

  <button class="close-icon" (click)="closeModal();">

    <fa-icon icon="times" class="icon">
    </fa-icon>

  </button>

  <!--  Notification Section  -->
  <section class="notification-section">

    <div class="modalHeader">
      <p>Please prepare the documents below for faster claim processing. You can always upload these documents later
        by
        accessing your policy page.</p>
    </div>

    <div class="modalContent">
      <ul>

        <li>
          <strong>Police Abstract</strong>
        </li>

      </ul>
    </div>

    <div class="modalFooter">
      <button
          class="readMoreYellow"
          data-claim="totaltheftVehicle"
          data-type="Total Theft"
          (click)="handleModalProgression($event);">
        Continue
        <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
      </button>
    </div>

  </section>
  <!--  <End Notification Section  -->

</section>
<!--END Theft Vehicle Modal Overlay   -->

<!-- Center Theft Recovery Overlay  -->
<section class="formModal theft-recovery shadow rounded">

  <button class="close-icon" (click)="closeModal();">

    <fa-icon icon="times" class="icon">
    </fa-icon>

  </button>

  <!--  Notification Section  -->
  <section class="notification-section">

    <div class="modalHeader">
      <p>Please prepare the documents below for faster claim processing. You can always upload these documents later
        by
        accessing your policy page.</p>
    </div>

    <div class="modalContent">
      <ul>

        <li>
          <strong>Before and After Photos</strong>
          <br />
          <em>If the car has already been fixed</em>
        </li>

        <li>
          <strong>Replacement Receipts or ETR</strong>
          <br />
          <em>If any item has been replaced</em>
        </li>

        <li>
          <strong>Towing Receipt</strong>
          <br />
          <em>If any item has been replaced</em>
        </li>

        <li>
          <strong>Police Abstract</strong>
        </li>

      </ul>
    </div>

    <div class="modalFooter">
      <button
          class="readMoreYellow"
          data-claim="theftRecoveryVehicle"
          data-type="Theft and recovery of vehicle"
          (click)="handleModalProgression($event);">
        Continue
        <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
      </button>
    </div>

  </section>
  <!--  <End Notification Section  -->

</section>
<!--END Theft Recovery Modal Overlay   -->

<!-- Center Theft Parts Overlay  -->
<section class="formModal theft-parts shadow rounded">

  <button class="close-icon" (click)="closeModal();">

    <fa-icon icon="times" class="icon">
    </fa-icon>

  </button>

  <!--  Notification Section  -->
  <section class="notification-section">

    <div class="modalHeader">
      <p>Please prepare the documents below for faster claim processing. You can always upload these documents later
        by
        accessing your policy page.</p>
    </div>

    <div class="modalContent">
      <ul>

        <li>
          <strong>Before and After Photos</strong>
          <br />
          <em>If the car has already been fixed</em>
        </li>

        <li>
          <strong>Replacement Receipts or ETR</strong>
          <br />
          <em>If any item has been replaced</em>
        </li>

      </ul>
    </div>

    <div class="modalFooter">
      <button class="readMoreYellow"
          data-claim="theftVehicleParts"
          data-type="Theft of Parts"
          (click)="handleModalProgression($event);">
        Continue
        <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
      </button>
    </div>

  </section>
  <!--  <End Notification Section  -->

</section>
<!--END Theft Parts Modal Overlay   -->

<!-- Windscreen Damage Overlay  -->
<section class="formModal windscreen-damage shadow rounded">

  <button class="close-icon" (click)="closeModal();">

    <fa-icon icon="times" class="icon">
    </fa-icon>

  </button>

  <!--  Notification Section  -->
  <section class="notification-section">

    <div class="modalHeader">
      <p>Please prepare the documents below for faster claim processing. You can always upload these documents later
        by
        accessing your policy page.</p>
    </div>

    <div class="modalContent">
      <ul>

        <li>
          <strong>Before and After Photos</strong>
          <br />
          <em>If the car has already been fixed</em>
        </li>

        <li>
          <strong>Replacement Receipts or ETR</strong>
          <br />
          <em>If any item has been replaced</em>
        </li>

      </ul>
    </div>

    <div class="modalFooter">
      <button
          class="readMoreYellow"
          data-claim="windowDamage"
          data-type="Window Damage"
          (click)="handleModalProgression($event);">
        Continue
        <fa-icon icon="arrow-alt-circle-right" class="arrow"></fa-icon>
      </button>
    </div>

  </section>
  <!--  <End Notification Section  -->

</section>
<!--END Windscreen Damage Modal Overlay   -->


<section class="infoForm">

  <!--Damage Vehicle Claim-->
  <div class="damageVehicle claimType">
    <!--About Accident Form-->
    <form
        action=""
        #aboutAccident = "ngForm"
        class="aboutAccident shadow multistep">

      <div class="formHeader">
        <h4><span class="yellowLine">Tell us</span> about the accident</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">When did it happen?</label>

        <div class="half halfLeft fl mr-0 ml-0 width-50">

          <label
            for=""
            class="dateLabel cursor"
            (click)="incidentDatePicker.open()"
            *ngIf="damageClaim?.claimDetails?.date !=='' ">
            {{damageClaim.claimDetails.date | date: 'fullDate' }}
          </label>

          <input
                [matDatepicker]="incidentDatePicker"
                class="inputLeft"
                id="date5"
                placeholder="DD-MM-YYYY"
                name = "date"
                data-key="claimDetails"
                (click)="incidentDatePicker.open()"
                (dateChange)="onDateChange($event)"
                #dateTime="ngModel"
                [(ngModel)]="damageClaim['claimDetails']['date']"
                [value]="accidentDate"
                [max]="maxDate"
                required>

            <!-- {{eventDate}} -->

          <mat-datepicker-toggle matSuffix [for]="incidentDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #incidentDatePicker></mat-datepicker>

          <div *ngIf="dateTime.invalid">
              <label
              class="invalid-field"
              *ngIf="dateTime.invalid">
                  Accident date required
              </label>
          </div>

        </div>

        <div class="half halfRight fl mr-0 ml-0 width-50">

            <select
                name ="dateTime"
                class="inputRight"
                (change)="onTimeChange($event)"
                [(ngModel)]="eventTime"
                #accidentTime="ngModel"
                required>
                <option value=""></option>
                  <option
                      *ngFor = "let time of timeListArray"
                      [value] = "time.value">
                      {{time.viewValue}}
                  </option>
          </select>

          <div *ngIf="accidentTime.invalid">
              <label
              class="invalid-field"
              *ngIf="accidentTime.errors.required">
                  Accident time required
              </label>
          </div>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Where did the accident happen?</label>
        <div class="full_width">

            <mat-google-maps-autocomplete
                    country="ke"
                    type="address"
                    class="location"
                    required
                    (onAutocompleteSelected)="onAutocompleteSelected($event,'damageLocation')"
                    (onLocationSelected)="onLocationSelected($event,'claimDetails')">
            </mat-google-maps-autocomplete>

          <fa-icon class="iconPosition" icon="map-marker-alt">
          </fa-icon>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">State the exact purpose for which the vehicle was being used at the time of the accident</label>
        <div class="full_width">
          <textarea
               type="text"
               placeholder="Type here"
               name="purpose"
               required
               minlength="5"
               data-key="claimDetails"
               (change)="onChange($event);"
               [(ngModel)]="damageClaim.claimDetails.purpose"
               #purpose = "ngModel">
          </textarea>

          <div *ngIf="purpose.invalid">
              <label
              class="invalid-field"
              *ngIf="purpose.errors.required">
                  Vehicle purpose required
              </label>

              <label
              class="invalid-field"
              *ngIf="purpose.errors.minlength">
                  Vehicle purpose too short
              </label>
          </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">What was the type of Road Surface?</label>
          <select
              name = "roadSurface"
              data-key="claimDetails"
              (change)="onChange($event);"
              [(ngModel)]="damageClaim.claimDetails.roadSurface"
              #roadSurface = "ngModel"
              required>
                <option value="">Select</option>
                <option value="Smooth">Smooth</option>
                <option value="Rough">Rough</option>
                <option value="Wet">Wet</option>
          </select>

          <div *ngIf="roadSurface.invalid">
              <label
              class="invalid-field"
              *ngIf="roadSurface.errors.required">
                  Road surface required
              </label>

          </div>

        </div>

        <div class="half fl ml-3 mr-0">

          <label for="">How was the Visibility?</label>
          <select
              name = "visibilty"
              data-key="claimDetails"
              (change)="onChange($event);"
              [(ngModel)]="damageClaim.claimDetails.visibilty"
              #visibilty = "ngModel"
              required>
                <option value="">Select</option>
                <option value="Foggy">Foggy</option>
                <option value="Misty">Misty</option>
                <option value="Clear">Clear</option>
          </select>
          <div *ngIf="visibilty.invalid">
              <label
              class="invalid-field"
              *ngIf="visibilty.errors.required">
                  Visibility required
              </label>

          </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">What was the weather conditions that day?</label>
          <select
              name = "weatherCondition"
              data-key="claimDetails"
              (change)="onChange($event);"
              [(ngModel)]="damageClaim.claimDetails.weatherCondition"
              #weatherCondition = "ngModel"
              required>
            <option value="">Select</option>
            <option value="Rainy">Rainy</option>
            <option value="Windy">Windy</option>
            <option value="Sunny">Sunny</option>
          </select>

          <div *ngIf="weatherCondition.invalid">
              <label
              class="invalid-field"
              *ngIf="weatherCondition.errors.required">
                  Weather condition required
              </label>

          </div>
        </div>

        <div class="half fl ml-3 mr-0">
          <label for="">What was your estimated speed at the time of the accident?</label>
          <input
           type="number"
           min="2"
           max="360"
           placeholder="Km/hr"
           name="estematedSpeed"
           data-key="claimDetails"
           (change)="onChange($event);"
           [(ngModel)]="damageClaim.claimDetails.estematedSpeed"
           #estematedSpeed = "ngModel"
           required>

           <div *ngIf="estematedSpeed.invalid">

               <label
               class="invalid-field"
               *ngIf="estematedSpeed.errors.required">
                   Estimated speed required
               </label>

               <label
               class="invalid-field"
               *ngIf="estematedSpeed.value > 500">
                   Estimated speed too high
               </label>

           </div>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">Were the lights On or Off?</label>

          <mat-radio-group
              aria-label="Select an option"
              name="wereLightsOn"
              data-key="claimDetails"
              (change)="onClaimDetailsRadioChange($event);"
              [(ngModel)]="damageClaim.claimDetails.wereLightsOn"
              #wereLightsOn = "ngModel">
                <mat-radio-button value='true'>ON</mat-radio-button>
                <mat-radio-button value='false' checked>OFF</mat-radio-button>
          </mat-radio-group>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">

        <div class="full-width">
          <label>Please upload photos, videos and drawings of the scene of accident, you can select multiple files by clicking control key</label>
        </div>

        <div class="one_fifth fl uploadFileContainer">

            <form
                id="photosUploadForm"
                #photosUpload="ngForm"
            >
                <input
                    type="file"
                    id="photos"
                    name="photos"
                    [ngModel]="fileContent"
                    #photos = "ngModel"
                    accept=".jpg,.png,.jpeg"
                    data-extension="image"
                    multiple
                    required
                    (change)="onFileChange($event,photosUpload);"
                     />
                <label for="photos" class="icon-section">
                  <img src="./assets/_img/claims/photo-video-icon.png" alt="">
                  <p>Add Photos of car accident</p>
                </label>

                <div
                  class="invalid-field"
                  *ngIf="photos.invalid"
                >
                  <label *ngIf="photos.invalid" class="invalid-field"
                    >Photos Required
                  </label>


                </div>

            </form>

        </div>

        <div class="one_fifth fl uploadFileContainer">

            <form
                id="drawingsUploadForm"
                #drawingsUpload="ngForm"
            >
              <input
                  type="file"
                  id="drawings"
                  name="drawings"
                  [ngModel]="fileContent"
                  #drawings = "ngModel"
                  accept=".jpg,.png,.jpeg"
                  data-extension="image"
                  multiple
                  required
                  (change)="onFileChange($event,drawingsUpload);"
                   />
              <label for="drawings" class="icon-section">
                <img src="./assets/_img/claims/drawings-icon.png" alt="">
                <p>Add
                  Drawings of the car in the accident</p>
              </label>

              <div
                class="invalid-field"
                *ngIf="drawings.invalid"
              >
                <label *ngIf="drawings.invalid" class="invalid-field"
                  >Drawings Required
                </label>


              </div>
            </form>
        </div>

      </div>
      <!-- End of Form Row-->

      <div class="btnFooter">
        <div class="btnFooter">
          <button
              class="readMoreYellow"
              (click)="handleProgression('driverInfo');"
              [disabled]="aboutAccident.invalid || photos.invalid || drawings.invalid">
            Save &amp; Continue

            <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>

          </button>

          <button class="readMoreWhiteSolid back fl" (click)="handleBackProgression('welcomeClaim');">

            <fa-icon class="arrow" icon="arrow-circle-left"> </fa-icon>
            Back
          </button>

        </div>
        <div *ngIf="aboutAccident.invalid">

            <label
            class="invalid-field"
            *ngIf="aboutAccident.invalid">
                Please fill in all required fields to proceed
            </label>

        </div>
      </div>

    </form>
    <!--END About Accident Form-->

    <!--Driver Info Form-->
    <form
        #driverInfo = "ngForm"
        class="driverInfo shadow multistep">

      <div class="formHeader">
        <h4><span class="yellowLine">Who was</span> driving your car at the time of the accident?</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <mat-radio-group
              aria-label="Select an option"
              name="driver"
              data-key="claimDetails"
              (change)="onClaimDetailsRadioChange($event);"
              [(ngModel)]="damageClaim.claimDetails.driver"
              #driver = "ngModel">
            <mat-radio-button value="self" checked>Self</mat-radio-button>
            <mat-radio-button value="other-driver">Other Driver</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">Please enter name of driver</label>
          <input
            type="text"
            placeholder="Enter name"
            name="name"
            required
            minlength="3"
            data-key="driverDetails"
            (change)="onChange($event);"
            [(ngModel)]="damageClaim.claimDetails.driverDetails.name"
            #driverName = "ngModel">

            <div
            class="invalid-field"
            *ngIf="driverName.invalid">

                <label
                *ngIf="driverName.errors.required"
                class="invalid-field">Driver name is required
                </label>

                <label *ngIf="driverName.errors.minlength"
                class="invalid-field">Driver name Too Short</label>

            </div>
        </div>

        <div class="half fl">
          <label for="">ID Number</label>
          <input
            type="text"
            placeholder="Enter Number"
            name="idNumber"
            required
            pattern="\d*"
            minlength="4"
            maxlength="8"
            data-key="driverDetails"
            (change)="onChange($event);"
            [(ngModel)]="damageClaim.claimDetails.driverDetails.idNumber"
            #idNumber = "ngModel"  />

            <div
            class="invalid-field"
            *ngIf="idNumber.invalid">

                <label
                *ngIf="idNumber.errors.required"
                class="invalid-field">National Id is required
                </label>

                <label *ngIf="idNumber.errors.maxlength"
                class="invalid-field">National Id Too Long</label>

                <label *ngIf="idNumber.errors.minlength"
                class="invalid-field">National Id Too Short</label>

                <label *ngIf="idNumber.errors.pattern"
                class="invalid-field">National Id should only contain numbers</label>

            </div>
        </div>


      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">

          <div class="half fl mr-0">
            <label for="">Mobile Number</label>
            <input
              type="text"
              placeholder="Enter Number"
              name="phoneNumber"
              data-key="claimDetails"
              (change)="onChange($event);"
              [(ngModel)]="damageClaim.claimDetails.driverDetails.phoneNumber"
              #driverPhoneNo = "ngModel"
              pattern="^(?:254|\\+254|0)((?:7|1)[0-9]{8})$"
              required>

              <div
              class="invalid-field"
              *ngIf="driverPhoneNo.invalid">

                      <label
                      *ngIf="driverPhoneNo.errors.required"
                      class="invalid-field">Phone Number is required
                  </label>

                  <label *ngIf="driverPhoneNo.errors.pattern"
                  class="invalid-field">Phone Number invalid</label>

              </div>
          </div>


        <div
            class="half fl ml-3 mr-0"
            *ngIf="damageClaim.claimDetails.driver === 'self'">

          <label for="">Date of birth</label>

          <label
            for=""
            class="dateLabel cursor"
            *ngIf="damageClaim?.claimDetails?.driverDetails?.dateOfBirth !=='' "
            (click)="picker2.open()">
              {{ damageClaim.claimDetails.driverDetails.dateOfBirth | date: 'fullDate' }}
          </label>

          <input
            matInput
            [matDatepicker]="picker2"
            (click)="picker2.open();"
            placeholder="DD-MM-YYYY"
            [max]="adultLimitDate"
            required
            name="dateOfBirth"
            (dateChange)="onDateChange($event);"
            [(ngModel)]="damageClaim.claimDetails.driverDetails.dateOfBirth"
            #dateOfBirth = "ngModel"
            data-key="driverDetails" />

            <div *ngIf="dateOfBirth.invalid">
                <label
                class="invalid-field"
                *ngIf="dateOfBirth.invalid">
                Date of birth Invalid
                </label>
            </div>

          <mat-datepicker-toggle matSuffix [for]="picker2">
          </mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>

        <div
            class="half fl ml-3 mr-0"
            *ngIf="damageClaim.claimDetails.driver === 'other-driver'">
          <label for="">Date of birth</label>

          <label
            for=""
            class="dateLabel cursor"
            *ngIf="damageClaim?.claimDetails?.driverDetails?.dateOfBirth !=='' "
            (click)="picker2.open()">
              {{ damageClaim.claimDetails.driverDetails.dateOfBirth | date: 'fullDate' }}
          </label>

          <input
            matInput
            [matDatepicker]="picker2"
            (click)="picker2.open();"
            placeholder="DD-MM-YYYY"
            [max]="maxDate"
            required
            name="dateOfBirth"
            (dateChange)="onDateChange($event);"
            [(ngModel)]="damageClaim.claimDetails.driverDetails.dateOfBirth"
            #dateOfBirth = "ngModel"
            data-key="driverDetails" />

            <div *ngIf="dateOfBirth.invalid">
                <label
                class="invalid-field"
                *ngIf="dateOfBirth.invalid">
                Date of birth Invalid
                </label>
            </div>

          <mat-datepicker-toggle matSuffix [for]="picker2">
          </mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">

          <div class="half fl">
            <label for="">Occupation</label>
            <input
              type="text"
              required
              placeholder="Enter occupation"
              name="occupation"
              data-key="driverDetails"
              (change)="onChange($event);"
              [(ngModel)]="damageClaim.claimDetails.driverDetails.occupation"
              #occupation = "ngModel" />

              <div
              class="invalid-field"
              *ngIf="occupation.invalid">

                      <label
                      *ngIf="occupation.errors.required"
                      class="invalid-field">Occupation is required
                  </label>

              </div>
          </div>

        <div class="half fl mr-0">
          <label for="">KRA PIN</label>
          <input
            type="text"
            required
            placeholder="KRA PIN"
            name="kraPin"
            pattern="^[A-Za-z]{1}[0-9]{9}[A-Za-z]{1}"
            maxlength="11"
            data-key="driverDetails"
            (change)="onChange($event);"
            [(ngModel)]="damageClaim.claimDetails.driverDetails.kraPin"
            #kraPin = "ngModel" />

            <div
              class="invalid-field"
              *ngIf="kraPin.invalid"
            >
              <label *ngIf="kraPin.errors.required" class="invalid-field"
                >KRA Pin is required
              </label>

              <label *ngIf="kraPin.errors.pattern" class="invalid-field"
                >KRA Pin Invalid</label
              >
            </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Upload Copy of Driving License</label>
        <div class="full_width uploadContainer bg-grey">
          <input
            type="file"
            id="driversLicence"
            #driversLicence="ngModel"
            accept=".pdf,.jpeg,.jpg,.png"
            data-extension="multimedia"
            [ngModel]="fileContent"
            required
            (change)="onFileChange($event);"
            name="copyOfDriverLicense" />
          <label for="driversLicence" class="icon-section">
            <p>Upload Driving License</p>
          </label>
          <fa-icon icon="upload" class="arrow upload"></fa-icon>
        </div>

        <div
          class="invalid-field"
          *ngIf="driversLicence.invalid"
        >
          <label *ngIf="driversLicence.invalid" class="invalid-field"
            >Driving License pdf required
          </label>


        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="driver.value !== 'self'" >
        <label for="">Is the driver employed by you?</label>
        <div class="half fl">
          <mat-radio-group
            aria-label="Select an option"
            name="isDriverEmployedByYou"
            [(ngModel)]="damageClaim.claimDetails.driverDetails.isDriverEmployedByYou"
            #isDriverEmployedByYou = "ngModel"
            (change) = "onDriverDetailsRadioChange($event)" >
            <mat-radio-button value="true" >YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

        <!--  Form Row  -->
        <!-- <div *ngIf = "driverEmployed.value === 'yes'" class="row marginTopBottom-40"> -->
        <div *ngIf = "damageClaim.claimDetails.driverDetails.isDriverEmployedByYou === 'true'" class="row marginTopBottom-40">
          <div class="full_width">
            <label for="">How long has he/she been working for you?</label>
            <select
                name = "howLongHasHeBeenWorking"
                data-key="driverDetails"
                (change)="onChange($event);"
                required
                [(ngModel)]="damageClaim.claimDetails.driverDetails.howLongHasHeBeenWorking"
                #howLongHasHeBeenWorking = "ngModel">
              <option value="">Select</option>
              <option value="1-year">1 Year</option>
              <option value="6-months">6 Months</option>
              <option value="3-months">3 Months</option>
            </select>

            <div
              class="invalid-field"
              *ngIf="howLongHasHeBeenWorking.invalid"
            >
              <label *ngIf="howLongHasHeBeenWorking.invalid" class="invalid-field"
                >Field is required
              </label>


            </div>
          </div>
        </div>
        <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">

        <div class="one_third fl">
          <label for="">Was driver to blame for accident?</label>
          <mat-radio-group
              aria-label="Select an option"
              name="driverToBlameForAccident"
              [(ngModel)]="damageClaim.claimDetails.driverDetails.driverToBlameForAccident"
              #driverToBlameForAccident = "ngModel"
              (change) = "onDriverDetailsRadioChange($event)" >
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="one_third fl">
          <label for="">Has driver admitted liability?</label>
          <mat-radio-group
              aria-label="Select an option"
              name="hasDriverAdmittedLiability"
              [(ngModel)]="damageClaim.claimDetails.driverDetails.hasDriverAdmittedLiability"
              #hasDriverAdmittedLiability = "ngModel"
              (change) = "onDriverDetailsRadioChange($event);">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="one_third fl">
          <label for="">Does driver hold valid driver’s license?</label>
          <mat-radio-group
              aria-label="Select an option"
              name="isDriverLicenseValid"
              [(ngModel)]="damageClaim.claimDetails.driverDetails.isDriverLicenseValid"
              #isDriverLicenseValid = "ngModel"
              (change) = "onDriverDetailsRadioChange($event);">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="one_third fl">
          <label for="">Does driver own a motor vehicle?</label>
          <mat-radio-group
              aria-label="Select an option"
              name="DoesDriverOwnsVehicle"
              [(ngModel)]="damageClaim.claimDetails.driverDetails.DoesDriverOwnsVehicle"
              #doesDriverOwnsVehicle = "ngModel"
              (change)="onDriverDetailsRadioChange($event);">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  vehiclePolicyNo  -->
      <!-- <div *ngIf = "ownsVehicle.value === 'yes'" class="vehiclePolicyNo"> -->
      <div *ngIf="doesDriverOwnsVehicle.value === 'true'"  class="vehiclePolicyNo">
        <!--  Form Row  -->
        <div class="row marginTopBottom-40">
          <div class="half fl">
            <label for="">Name of insurer</label>
            <input
                type="text"
                placeholder="Enter Insurance Company"
                name="nameOfInsurer"
                data-key="driverDetails"
                (change)="onChange($event);"
                required
                [(ngModel)]="damageClaim.claimDetails.driverDetails.nameOfInsurer"
                #nameOfInsurer = "ngModel">
                <div
                  class="invalid-field"
                  *ngIf="nameOfInsurer.invalid"
                >
                  <label *ngIf="nameOfInsurer.invalid" class="invalid-field"
                    >Insurance Company is required
                  </label>


                </div>
          </div>

          <div class="half fl ml-3 mr-0">
            <label for="">Policy number of vehicle</label>
            <input
                type="text"
                placeholder="Enter Number"
                name="vehiclePolicyNumber"
                data-key="driverDetails"
                required
                (change)="onChange($event);"
                [(ngModel)]="damageClaim.claimDetails.driverDetails.vehiclePolicyNumber"
                #vehiclePolicyNumber = "ngModel">

            <div
              class="invalid-field"
              *ngIf="vehiclePolicyNumber.invalid"
            >
              <label *ngIf="vehiclePolicyNumber.invalid" class="invalid-field"
                >Policy number is required
              </label>


            </div>
          </div>
        </div>
        <!-- End of Form Row-->
      </div>
      <!--  End of vehiclePolicyNo  -->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">Do you know if the driver has had previous accidents?</label>
          <mat-radio-group
            aria-label="Select an option"
            name="driverHadPreviousAccidents"
            [(ngModel)]="damageClaim.claimDetails.driverDetails.driverHadPreviousAccidents"
            #driverHadPreviousAccidents = "ngModel"
            (change)="onDriverDetailsRadioChange($event);">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <!-- <div *ngIf = "hadAccidents.value === 'yes'" class="row marginTopBottom-40"> -->
      <div *ngIf = "driverHadPreviousAccidents.value === 'true'" class="row marginTopBottom-40">

        <div class="full_width">

          <label for="">Date of accident</label>

          <label
            for="accidentDate"
            class="dateLabel cursor"
            (click)="picker3.open()"
            *ngIf="damageClaim?.claimDetails?.driverDetails?.dateOfAccident !=='' "
          >
            {{damageClaim.claimDetails.driverDetails.dateOfAccident | date: 'fullDate'}}
          </label>

          <input
              matInput
              [matDatepicker]="picker3"
              (click)="picker3.open();"
              placeholder="DD-MM-YYYY"
              [min]="minDate"
              [max]="maxDate"
              name="dateOfAccident"
              data-key="driverDetails"
              (dateChange)="onDateChange($event);"
              [(ngModel)]="damageClaim.claimDetails.driverDetails.dateOfAccident"
              #dateOfAccident = "ngModel"
              >
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-datepicker #picker3>
          </mat-datepicker>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <!-- <div class="row marginTopBottom-40">
        <button class="addButton full_width" data-target = "another-accident" (click)="addAnotherAccident()">
          Add another accident
          <fa-icon class="iconPosition" icon="plus"></fa-icon>
        </button>
      </div> -->
      <!-- End of Form Row-->

      <div class="btnFooter">
        <button
            class="readMoreYellow"
            [disabled]="driverInfo.invalid"
            (click)="handleProgression('passengersInfo');">
          Save &amp; Continue

          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>

        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleProgression('aboutAccident');">

          <fa-icon class="arrow" icon="arrow-circle-left">

          </fa-icon>
          Back
        </button>
      </div>
    </form>
    <!--END Driver Info Form-->

    <!--Passengers Info Form-->
    <form
        action=""
        class="passengersInfo shadow multistep"
        #passengersInfo="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Were there </span> any passengers in the vehicle?</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <mat-radio-group
              aria-label="Select an option"
              name = "werePassangesInVehicle"
              [(ngModel)]="damageClaim.claimDetails.werePassangesInVehicle"
              #werePassangesInVehicle = "ngModel"
              (change) = "onClaimDetailsRadioChange($event)" >
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>

        </div>
      </div>
      <!-- End of Form Row-->

    <!-- passengerDetailsForm -->
    <!-- <div *ngIf = "werePassang
      esInVehicle.value === 'true'" class="passengerDetailsForm"> -->
    <div class="passengerDetailsForm" *ngIf = "werePassangesInVehicle.value === 'true'">

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngFor="let passenger of passengersArray; let i = index;" >

            <h4>Details of Passenger {{i+1}}</h4>

            <button
                class="closer"
                *ngIf="passengersArray.length > 1"
                (click)="removePassenger(i)"
                >
                X
            </button>

            <div class="half fl">
              <label for="">Name</label>
              <input
                type="text"
                id="passenger{{i+1}}name"
                placeholder="Enter Name"
                name="passenger{{i+1}}name"
                minlength="3"
                required
                (change)="onPassengerChange($event,i);"
                [(ngModel)]="damageClaim.claimDetails.passangersInVehicle[i]['name']"
                #passengerName = "ngModel">

                <div
                    class="invalid-field"
                    *ngIf="passengerName.invalid">

                    <label
                        *ngIf="passengerName.errors.required"
                          class="invalid-field">Name is required
                    </label>

                    <label *ngIf="passengerName.errors.minlength"
                    class="invalid-field">Name Too Short</label>

                </div>
            </div>

            <div class="half fl ml-3 mr-0">
              <label for="">Mobile Number</label>
              <input
                type="text"
                id="passenger{{i+1}}phoneNumber"
                placeholder="Enter Number"
                name="passenger{{i+1}}phoneNumber"
                (change)="onPassengerChange($event,i);"
                required
                pattern="^(?:254|\\+254|0)((?:7|1)[0-9]{8})$"
                [(ngModel)]="damageClaim.claimDetails.passangersInVehicle[i]['phoneNumber']"
                #passengerphoneNumber = "ngModel"
                >

                <div
                    class="invalid-field"
                    *ngIf="passengerphoneNumber.invalid">

                    <label
                        *ngIf="passengerphoneNumber.errors.required"
                          class="invalid-field">Passenger phone number required
                    </label>

                    <label *ngIf="passengerphoneNumber.errors.pattern"
                    class="invalid-field">Phone Number Invalid</label>

                </div>
            </div>

      </div>
      <!-- End of Form Row-->

      <div class="row marginTopBottom-40" *ngIf="passengersArray.length < 7" >
        <button
            class="addButton full_width"
            (click)="addPassenger()">
              Add Vehicle Passenger
          <fa-icon class="iconPosition" icon="plus"></fa-icon>
        </button>
      </div>


      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">Was anyone injured?</label>
          <mat-radio-group
              aria-label="Select an option"
              name="wasAnyoneInjured"
              name = "wasAnyoneInjured"
              [(ngModel)]="damageClaim.claimDetails.wasAnyoneInjured"
              #wasAnyoneInjured = "ngModel"
              (change) = "onClaimDetailsRadioChange($event)">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!-- <div *ngIf = "anyoneInjured.value === 'yes'" class="injuredPeopleForm"> -->
      <div class="injuredPeopleForm" *ngIf="wasAnyoneInjured.value === 'true'" >


            <!--  injuredPersonForm section  -->
            <div class="injuredPersonForm" *ngFor="let injuredPassenger of injuredPassengersArray;let i=index;" >

              <!--  Form Row  -->
              <div class="row marginTopBottom-40">
                  <h4>Details of injured person {{i+1}}</h4>

                  <button
                      class="closer"
                      *ngIf="injuredPassengersArray.length > 1"
                      (click)="removeInjuredPerson(i)"
                      >
                      X
                  </button>

                <div class="half fl">
                  <label for="">Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    name = "injured{{i+1}}name"
                    minlength="3"
                    id = "injured{{i+1}}name"
                    (change)="onInjuredPassengerChange($event,i);"
                    [(ngModel)]="damageClaim.claimDetails.injuredPassangers[i]['name']"
                    #injuredname = "ngModel"
                    required>
                    <div
                    class="invalid-field"
                    *ngIf="injuredname.invalid">

                        <label
                        *ngIf="injuredname.errors.required"
                        class="invalid-field">Name is required
                        </label>

                        <label *ngIf="injuredname.errors.minlength"
                        class="invalid-field">Name Too Short</label>

                    </div>
                </div>

                <div class="half fl ml-3 mr-0">
                  <label for="">Mobile Number</label>
                  <input
                    type="text"
                    placeholder="Enter Number"
                    name = "injured{{i+1}}phoneNumber"
                    id = "injured{{i+1}}phoneNumber"
                    (change)="onInjuredPassengerChange($event,i);"
                    required
                    pattern="^(?:254|\\+254|0)((?:7|1)[0-9]{8})$"
                    [(ngModel)]="damageClaim.claimDetails.injuredPassangers[i]['phoneNumber']"
                    #injuredphoneNumber = "ngModel">

                    <div
                    class="invalid-field"
                    *ngIf="injuredphoneNumber.invalid">

                        <label
                        *ngIf="injuredphoneNumber.errors.required"
                        class="invalid-field">Phone Number is required
                        </label>

                        <label *ngIf="injuredphoneNumber.errors.pattern"
                        class="invalid-field">Phone Number invalid</label>

                    </div>
                </div>
              </div>
              <!-- End of Form Row-->

              <!--  Form Row  -->
              <div class="row marginTopBottom-40">
                <div class="half fl">
                  <label for="">Relationship</label>
                  <!-- <input
                    type="text"
                    placeholder="Enter Relationship"
                    name="injured{{i+1}}relationship"
                    id = "injured{{i+1}}relationship"
                    (change)="onInjuredPassengerChange($event,i);"
                    required
                    [(ngModel)]="damageClaim.claimDetails.injuredPassangers[i]['relationship']"
                    #injuredrelationship = "ngModel"> -->

                    <select
                        placeholder="Enter Relationship"
                        name="injured{{i+1}}relationship"
                        id = "injured{{i+1}}relationship"
                        (change)="onInjuredPassengerChange($event,i);"
                        required
                        [(ngModel)]="damageClaim.claimDetails.injuredPassangers[i]['relationship']"
                        #injuredrelationship = "ngModel">
                        <option value=""></option>
                        <option value="parent">Parent</option>
                        <option value="spouse">Spouse</option>
                        <option value="child">Child</option>
                        <option value="sibling">Sibling</option>
                        <option value="relative">Relative</option>
                        <option value="friend">Friend</option>
                        <option value="other">Other</option>
                    </select>

                    <div
                      class="invalid-field"
                      *ngIf="injuredrelationship.invalid"
                    >
                      <label *ngIf="injuredrelationship.errors.required" class="invalid-field"
                        >Relationship required
                      </label>


                    </div>
                </div>

                <div class="half fl ml-3 mr-0">
                  <label for="">Vehicle Registration Number (If Third Party)</label>
                  <input
                    type="text"
                    placeholder="Enter Reg No."
                    name="injured{{i+1}}vehicleNo"
                    id="injured{{i+1}}vehicleNo"
                    (change)="onInjuredPassengerChange($event,i);"
                    (keyup)="onInjuredPassengerChange($event,i);"
                    (blur)="onInjuredPassengerChange($event,i);"
                    [(ngModel)]="damageClaim.claimDetails.injuredPassangers[i]['vehicleNo']"
                    #injuredvehicleNo = "ngModel">

                    <div
                      class="invalid-field"
                      *ngIf="injuredvehicleNo.invalid"
                    >
                      <label *ngIf="injuredvehicleNo.errors.required" class="invalid-field"
                        >Registration Number required
                      </label>


                    </div>
                </div>
              </div>
              <!-- End of Form Row-->

              <!--  Form Row  -->
              <div class="row marginTopBottom-40">
                <label for="">Apparent injuries</label>
                <div class="full_width">
                  <textarea
                    type="text"
                    placeholder="Please describe…"
                    name="injured{{i+1}}apparentInjuries"
                    id="injured{{i+1}}apparentInjuries"
                    (change)="onInjuredPassengerChange($event,i);"
                    required
                    minlength="20"
                    [(ngModel)]="damageClaim.claimDetails.injuredPassangers[i]['apparentInjuries']"
                    #injuredapparentInjuries = "ngModel">
                  </textarea>

                  <div
                    class="invalid-field"
                    *ngIf="injuredapparentInjuries.invalid"
                  >

                    <label *ngIf="injuredapparentInjuries.errors.required" class="invalid-field"
                      >Description required
                    </label>

                    <label *ngIf="injuredapparentInjuries.errors.minlength" class="invalid-field"
                      >Description too short
                    </label>

                  </div>
                </div>
              </div>
              <!-- End of Form Row-->

            </div>
            <!-- End of injuredPersonForm section-->

                  <!--  Form Row  -->
          <div class="row marginTopBottom-40">
            <button
                class="addButton full_width"
                data-target = "injured-passenger"
                (click)="addInjuredPerson()"
                *ngIf="injuredPassengersArray.length<15" >
                Add Another Injured Person
              <fa-icon class="iconPosition" icon="plus"></fa-icon>
            </button>
          </div>
          <!-- End of Form Row-->

      </div>

    </div>
    <!-- End of passengerDetailsForm-->

      <div class="btnFooter">
        <button
            class="readMoreYellow"
            (click)="handleProgression('damageInfo');"
            [disabled]="passengersInfo.invalid">
          Save &amp; Continue

          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>

        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleProgression('driverInfo');">

          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>

      </div>


    </form>
    <!--END Passengers Info Form-->

    <!--Damage Info Form-->
    <form
        action=""
        class="damageInfo shadow multistep"
        #damageInfo="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Was there </span>damage to the insured vehicle?</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <mat-radio-group
              aria-label="Select an option"
              name="vehicleInsuredDamaged"
              [(ngModel)]="damageClaim.claimDetails.vehicleInsuredDamaged"
              #vehicleInsuredDamaged = "ngModel"
              (change)="onClaimDetailsRadioChange($event)">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->


      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="vehicleInsuredDamaged.value === 'true'" >
        <label for="">State briefly the apparent damage</label>
        <div class="full_width">
          <textarea
              type="text"
              placeholder="Type here"
              name="apparentDamageDescription"
              data-key="claimDetails"
              (change)="onChange($event);"
              minlength="20"
              required
              [(ngModel)]="damageClaim.claimDetails.apparentDamageDescription"
              #apparentDamageDescription="ngModel">
            </textarea>

            <div
              class="invalid-field"
              *ngIf="apparentDamageDescription.invalid"
            >

              <label *ngIf="apparentDamageDescription.errors.required" class="invalid-field"
                >Description required
              </label>

              <label *ngIf="apparentDamageDescription.errors.minlength" class="invalid-field"
                >Description too short
              </label>

            </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="vehicleInsuredDamaged.value === 'true'">
        <div class="half fl">
          <label for="">Is the vehicle still in use?</label>
          <mat-radio-group
              aria-label="Select an option"
              name="vehicleStillInUse"
              #vehicleStillInUse = "ngModel"
              [(ngModel)]="damageClaim.claimDetails.vehicleStillInUse"
              (change)="onClaimDetailsRadioChange($event)">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="vehicleInsuredDamaged.value === 'true'">
        <label for="">Where can the vehicle be inspected?</label>
        <div class="full_width">

        <mat-google-maps-autocomplete
            country="ke"
            type="address"
            class="location"
            required
            (onAutocompleteSelected)="onAutocompleteSelected($event, 'inspectionLocation')"
            (onLocationSelected)="onLocationSelected($event,'damageClaim')">
        </mat-google-maps-autocomplete>

          <fa-icon class="iconPosition" icon="map-marker-alt">
          </fa-icon>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="vehicleInsuredDamaged.value === 'true'">
        <label for="">When can the vehicle be inspected?</label>

        <div class="half halfLeft fl mr-0 ml-0 width-50">

          <label
            for="inspectionDate"
            class="dateLabel cursor"
            (click)="inspectionDatePicker.open()"
            *ngIf="damageClaim?.claimDetails?.inspectionDate !=='' ">
            {{damageClaim.claimDetails.inspectionDate | date: 'fullDate' }}
          </label>

            <input
              matInput
              [matDatepicker]="inspectionDatePicker"
              (click)="inspectionDatePicker.open();"
              placeholder="DD-MM-YYYY"
              name="inspectionDate"
			        id="inspectionDate"
              (dateChange)="onDateChange($event)"
              [(ngModel)]="damageClaim.claimDetails.inspectionDate"
              #inspectionDateTime="ngModel"
              data-key="claimDetails"
              [min]="maxDate"
              required
              class="inputLeft"
              />

            <mat-datepicker-toggle matSuffix [for]="inspectionDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #inspectionDatePicker></mat-datepicker>

            <div *ngIf="inspectionDateTime.invalid">
              <label
              class="invalid-field"
              *ngIf="inspectionDateTime.errors.required">
                  Accident date required
              </label>
            </div>

        </div>

        <div class="half halfRight fl mr-0 ml-0 width-50" *ngIf="vehicleInsuredDamaged.value === 'true'">
            <select
              name = "inspectionDateTime"
              class="inputRight"
              data-key="damageVehicle"
              (change)="onTimeChange($event)"
              ngModel
              #vehicleInspectionTime ="ngModel"
              required>
              <option value="">Select Vehicle Inspection Time</option>
              <option
                  *ngFor = "let time of timeListArray"
                  [value] = "time.value">
                  {{time.viewValue}}
              </option>
            </select>

            <div *ngIf="vehicleInspectionTime.invalid">
              <label
              class="invalid-field"
              *ngIf="vehicleInspectionTime.errors.required">
                  Accident time required
              </label>
            </div>

        </div>

      </div>
      <!-- End of Form Row-->

      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->

      <div class="formHeader">
        <h4><span class="yellowLine">Was any </span>other vehicle damaged?</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <mat-radio-group
              aria-label="Select an option"
              name="otherVehicleDamaged"
              [(ngModel)]="damageClaim.claimDetails.otherVehicleDamaged"
              #otherVehicleDamaged = "ngModel"
              (change) = "onClaimDetailsRadioChange($event)">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  damagedCarForm Group -->
      <div class="damagedCarForm" *ngFor="let damagedVehicle of damagedVehiclesArray;let i = index;"  >

          <!--  Form Row  -->
          <div class="row marginTopBottom-40">

            <h4>Damaged car {{i+1}}</h4>

            <button
                class="closer"
                *ngIf="damagedVehiclesArray.length > 1"
                (click)="removeAnotherCar(i);">
                X
            </button>

            <div class="half fl">
              <label for="">Name of owner</label>
              <input
                type="text"
                placeholder="Enter name"
                name="damageToVehicle{{i+1}}nameofOwner"
                minlength="3"
                id="damageToVehicle{{i+1}}nameofOwner"
                (change)="onOtherVehicleDamageChange($event,i);"
                required
                [(ngModel)]="damageClaim.claimDetails.damagedVehicles[i]['apparentInjuries']"
                #damageToVehiclenameofOwner = "ngModel">

                <div
                  class="invalid-field"
                  *ngIf="damageToVehiclenameofOwner.invalid"
                >

                  <label *ngIf="damageToVehiclenameofOwner.errors.required" class="invalid-field"
                    >Owner name required
                  </label>

                  <label *ngIf="damageToVehiclenameofOwner.errors.minlength" class="invalid-field"
                    >Owner name too short
                  </label>

                </div>
            </div>

            <div class="half fl ml-3 mr-0">
              <label for="">Registration Number of Car</label>
              <input
                type="text"
                placeholder="Enter Number"
                name="damageToVehicle{{i+1}}carRegistrationNo"
                id="damageToVehicle{{i+1}}carRegistrationNo"
                (change)="onOtherVehicleDamageChange($event,i);"
                (keyup)="onOtherVehicleDamageChange($event,i);"
                (blur)="onOtherVehicleDamageChange($event,i);"
                required
                [(ngModel)]="damageClaim.claimDetails.damagedVehicles[i]['carRegistrationNo']"
                #damageToVehiclecarRegistrationNo = "ngModel">

                <div
                  class="invalid-field"
                  *ngIf="damageToVehiclecarRegistrationNo.invalid"
                >

                  <label *ngIf="damageToVehiclecarRegistrationNo.errors.required" class="invalid-field"
                    >Registration number required
                  </label>

                </div>
            </div>
          </div>
          <!-- End of Form Row-->

          <!--  Form Row  -->
          <div class="row marginTopBottom-40">
            <div class="full_width">
              <label for="">Name of Insurer</label>
              <input
                type="text"
                placeholder="Enter name"
                name="damageToVehicle{{i+1}}nameOfInsurer"
                id="damageToVehicle{{i+1}}nameOfInsurer"
                (change)="onOtherVehicleDamageChange($event,i);"
                required
                [(ngModel)]="damageClaim.claimDetails.damagedVehicles[i]['nameOfInsurer']"
                #damageToVehiclenameOfInsurer = "ngModel">

                <div
                  class="invalid-field"
                  *ngIf="damageToVehiclenameOfInsurer.invalid"
                >

                  <label *ngIf="damageToVehiclenameOfInsurer.errors.required" class="invalid-field"
                    >Name of Insurer required
                  </label>

                </div>
            </div>
          </div>
          <!-- End of Form Row-->

      </div>
      <!--  End of damagedCarForm Group -->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="damagedVehiclesArray.length < 4&&otherVehicleDamaged.value==='true'" >
        <button class="addButton full_width" data-target="add-car" (click)="addAnotherCar()">
          Add another car
          <fa-icon class="iconPosition" icon="plus"></fa-icon>
        </button>
      </div>
      <!-- End of Form Row-->

      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->


      <div class="formHeader">
        <h4><span class="yellowLine">Was any </span>property damaged?</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <mat-radio-group
              aria-label="Select an option"
              name="wasAnyPropertyDamaged"
              [(ngModel)]="damageClaim.claimDetails.wasAnyPropertyDamaged"
              #wasAnyPropertyDamaged = "ngModel"
              (change) = "onClaimDetailsRadioChange($event)">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div
        class="row formGroup marginTopBottom-40"
        *ngFor="let singleProperty of propertiesDamagedArray;
        let i=index;" >

        <h4>Damaged property {{i+1}}</h4>

        <button
            class="closer"
            *ngIf="propertiesDamagedArray.length > 1"
            (click)="removeProperty(i);">
            X
        </button>


        <div class="half fl">
          <label for="">Name of property owner</label>
          <input
            type="text"
            placeholder="Enter Name"
            required
            id = "damageToProperty{{i+1}}nameOfPropertyOwner"
            name = "damageToProperty{{i+1}}nameOfPropertyOwner"
            (change)="onPropertyDamageChange($event, i)"
            minlength="4"
            [(ngModel)]="damageClaim.claimDetails.propertiesDamaged[i]['nameOfPropertyOwner']"
            #nameOfPropertyOwner="ngModel">

            <div
              class="invalid-field"
              *ngIf="nameOfPropertyOwner.invalid"
            >

              <label *ngIf="nameOfPropertyOwner.errors.required" class="invalid-field"
                >Name of property owner required
              </label>

              <label *ngIf="nameOfPropertyOwner.errors.minlength" class="invalid-field"
                >Name of property owner too short
              </label>

            </div>
        </div>

        <div class="half fl ml-3 mr-0">
          <label for="">Name of property</label>
          <input
            type="text"
            placeholder="Enter Name"
            required
            id="damageToProperty{{i+1}}nameOfProperty"
            name="damageToProperty{{i+1}}nameOfProperty"
            (change)="onPropertyDamageChange($event,i)"
            [(ngModel)]="damageClaim.claimDetails.propertiesDamaged[i]['nameOfProperty']"
            #nameOfProperty="ngModel">

            <div
              class="invalid-field"
              *ngIf="nameOfProperty.invalid"
            >

              <label *ngIf="nameOfProperty.errors.required" class="invalid-field"
                >Name of property required
              </label>

              <label *ngIf="nameOfProperty.errors.minlength" class="invalid-field"
                >Name of property too short
              </label>

            </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="wasAnyPropertyDamaged.value === 'true' && propertiesDamagedArray.length < 4">
        <button class="addButton full_width" data-target="add-property" (click)="addProperty()">
          Add Another Property
          <fa-icon class="iconPosition" icon="plus"></fa-icon>
        </button>
      </div>

      <!-- End of Form Row-->
      <div class="btnFooter">
        <button
            class="readMoreYellow"
            [disabled]="damageInfo.invalid"
            (click)="handleProgression('witnessInfo');">
          Save &amp; Continue

          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>

        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleProgression('passengersInfo');">

          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>
      </div>

    </form>
    <!--END Damage Info Form-->

    <!--Witness Info Form-->
    <form
        action=""
        class="witnessInfo shadow multistep"
        #witnessInfo="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Do you have</span> any independent witnesses to the accident?</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <mat-radio-group
              aria-label="Select an option"
              name="haveAnyIndependentWitnesses"
              [(ngModel)]="damageClaim.claimDetails.haveAnyIndependentWitnesses"
              #haveAnyIndependentWitnesses = "ngModel"
              (change) = "onClaimDetailsRadioChange($event)">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row formGroup marginTopBottom-40" *ngFor="let witness of independentWitnessesArray;let i = index;" >

        <h4>Witness {{i+1}}</h4>

        <button
            class="closer"
            (click)="removeWitness(i);">X</button>

        <div class="half fl">
          <label for="">Name of witness</label>
          <input
            type="text"
            placeholder="Enter name"
            id="witness{{i+1}}Name"
            name="witness{{i+1}}Name"
            required
            (change)="onWitnessChange($event,i);"
            [(ngModel)]="damageClaim.claimDetails.independentWitnesses[i]['Name']"
            #wintessName = "ngModel">

            <div
              class="invalid-field"
              *ngIf="wintessName.invalid"
            >

              <label *ngIf="wintessName.errors.required" class="invalid-field"
                >Name required
              </label>

            </div>
        </div>

        <div class="half fl ml-3 mr-0">
          <label for="">Mobile Number</label>
          <input
            type="text"
            placeholder="Enter Number"
            id = "witness{{i+1}}phoneNumber"
            name = "witness{{i+1}}phoneNumber"
            (change)="onWitnessChange($event,i);"
            [(ngModel)]="damageClaim.claimDetails.independentWitnesses[i]['phoneNumber']"
            #wintessphoneNumber = "ngModel"
            pattern="^(?:254|\\+254|0)((?:7|1)[0-9]{8})$"
            required>

            <div
              class="invalid-field"
              *ngIf="wintessphoneNumber.invalid"
            >

              <label *ngIf="wintessphoneNumber.errors.required" class="invalid-field"
                >Phone number required
              </label>

              <label *ngIf="wintessphoneNumber.errors.pattern" class="invalid-field"
                >Phone number invalid
              </label>

            </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="haveAnyIndependentWitnesses.value === 'true' && independentWitnessesArray.length < 5" >
        <button class="addButton full_width"
            data-target="add-property"
            (click)="addAnotherWitness()">
          Add Another Witness
          <fa-icon class="iconPosition" icon="plus"></fa-icon>
        </button>
      </div>
      <!-- End of Form Row-->


      <div class="btnFooter">
        <button
            class="readMoreYellow"
            [disabled]="witnessInfo.invalid"
            (click)="handleProgression('policeInfo');"
            >
          Save &amp; Continue
          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleProgression('damageInfo');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>
      </div>

    </form>
    <!--END Witness Info Form-->

    <!--Police Info Form-->
    <form
        action=""
        class="policeInfo shadow multistep"
        #policeInfo="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Have you</span> reported the accident to the Police?</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <mat-radio-group
              aria-label="Select an option"
              name="reportedAccidentToPolice"
              [(ngModel)]="damageClaim.claimDetails.reportedAccidentToPolice"
              #reportedAccidentToPolice = "ngModel"
              (change)="onClaimDetailsRadioChange($event)">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="reportedAccidentToPolice.value === 'true'" >
        <div class="half fl">
          <label for="">Police Station where accident was reported</label>
<!--
          <input
            type="text"
            required
            placeholder="Enter Police Station"
            name="policeSTation"
            data-key="claimDetails"
            (change)="onChange($event);"
            required
            [(ngModel)]="damageClaim.claimDetails.policeSTation"
            #policeSTation = "ngModel">
        -->
        <input
            matInput
            matGoogleMapsAutocomplete
            country="ke"
            type="address"
            class="location"
            required
            (onAutocompleteSelected)="onAutocompleteSelected($event, 'policeStationLocation')"
            (onLocationSelected)="onLocationSelected($event,'policeStationLocation')">

          <fa-icon class="iconPosition" icon="map-marker-alt"></fa-icon>

        </div>

        <div class="half fl ml-3 mr-0">
          <label for="">OB Number</label>
          <input
              type="text"
              placeholder="Enter OB Number"
              name="AOBNumber"
              (change)="onChange($event);"
              data-key="claimDetails"
              required
              [(ngModel)]="damageClaim.claimDetails.AOBNumber"
              #AOBNumber="ngModel">

              <div
                class="invalid-field"
                *ngIf="AOBNumber.invalid"
              >

                <label *ngIf="AOBNumber.errors.required" class="invalid-field"
                  >Abstract OB required
                </label>

              </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="reportedAccidentToPolice.value === 'true'">
        <label for="">Upload Police Abstract (Within 14 Days)</label>
        <div class="full_width uploadContainer bg-grey">
          <input
            type="file"
            id="policeAbstractDoc"
            accept=".pdf,.jpeg,.jpg,.png"
            data-extension="multimedia"
            (change)="onFileChange($event);"
            name="policeAbstractDoc"
            #policeAbstractDoc = "ngModel"
            [ngModel]="fileContent"
            required/>

          <label for="policeAbstractDoc" class="icon-section">
            <p>Add Police Abstract</p>
          </label>
          <fa-icon icon="upload" class="arrow upload"></fa-icon>

        </div>

        <div
        class="invalid-field"
        *ngIf="policeAbstractDoc.invalid"
      >
          <label *ngIf="policeAbstractDoc.invalid" class="invalid-field"
            >Police Abstract Required
          </label>

        </div>

      </div>
      <!-- End of Form Row-->

      <div class="btnFooter">
        <button
            class="readMoreYellow"
            (click)="handleProgression('insuranceSummary');"
            [disabled]="policeInfo.invalid">
          Save &amp; Finish
          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>

        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleProgression('witnessInfo');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>
      </div>

    </form>
    <!--END Police Info Form-->

    <!-- Summary Container   -->
    <div class="summaryDetails hideForm">

      <!--  Policy Details  -->
      <!-- <section class="singleDetail policyDetails">
        <h4>Policy Details</h4>
        <p>Policy No. - {{policyNumber.value}}</p>
        <p>Expiry - June 14th 2022</p>
      </section> -->
      <!--END  Policy Details  -->

      <!-- <section class="singleDetail vehicleDetails">
        <div class="detailHeader">
          <h4>Vehicle Details</h4>
        </div>
        <div class="detailContent">
          <p>KBD 675F</p>
          <p>2015</p>
          <p>Subaru Outback, 2500 CC</p>
          <p>KES 1,500,000</p>
        </div>
      </section> -->

      <div class="row">
        <h4>Damage to Vehicle Claim</h4>
      </div>

      <!--  Policy Details  -->
      <section class="singleDetail policyDetails">
        <h4>Policy Details</h4>
        <p>Policy No. : {{damageClaim.claimDetails.policyNumber}}</p>

      </section>
      <!--END  Policy Details  -->

      <section class="singleDetail accidentDetails">
        <div class="detailHeader">
          <h4>About the Accident</h4>
          <button>
            <fa-icon class="iconPosition" icon="edit"></fa-icon>
          </button>
        </div>
        <div class="detailContent">
          <p>Date of Accident : {{dateTime.value | date: 'fullDate'}}</p>
          <!-- <p>Policy No. - {{policyNumber.value}}</p> -->
          <!-- <p>{{accidentTime.value}}</p> -->
          <p>Visibility : {{visibilty.value}}</p>
          <p>Road Surface : {{roadSurface.value}}</p>
          <p>Weather Condition : {{weatherCondition.value}}</p>
        </div>
      </section>

      <section class="singleDetail driverDetails">
        <div class="detailHeader">
          <h4>Driver Details</h4>
          <button >
            <fa-icon class="iconPosition" icon="edit"></fa-icon>
          </button>
        </div>
        <div class="detailContent">
          <p>Name : {{driverName.value}}</p>
          <p>Phone : {{driverPhoneNo.value}}</p>
          <p>Occupation : {{occupation.value}}</p>
          <p>ID : {{idNumber.value }}</p>
        </div>
      </section>

      <section
        class="singleDetail passengersDetails"
        *ngIf="werePassangesInVehicle.value === 'true'">
        <div class="detailHeader">
          <h4>Passengers</h4>
          <!-- <p *ngFor="let passenger of passengersArray" >
            Name : {{passenger?.name}}
          </p> -->
          <button mat-icon-button>
            <fa-icon class="iconPosition" icon="edit"></fa-icon>
          </button>
        </div>
        <div class="detailContent" *ngFor="let passenger of passengersArray; let i = index;">
          <h5>Details of Passenger {{i+1}}</h5>
          <p>Name : {{passenger?.name}}</p>
          <p>Phone : {{passenger?.phoneNumber}}</p>
        </div>
      </section>

      <section class="singleDetail damageDetails">
        <div class="detailHeader">
          <h4>Damaged Vehicles</h4>
          <button>
            <fa-icon class="iconPosition" icon="edit"></fa-icon>
          </button>
        </div>

        <p *ngFor="let damaged of damagedVehiclesArray" >
          Registration No : {{damaged?.carRegistrationNo}}
        </p>
      </section>


    </div>
    <!--END  Summary Container  -->

  </div>
  <!--End of Damage Vehicle Claim-->

  <!--Windscreen or Window Damage Claim-->
  <div class="windowDamage claimType">

   <!--About Window Damage Form-->
  <form
      action=""
      class="aboutWindowDamage shadow multistep"
      #aboutWindowDamage="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Tell us</span> about the damage</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">When did the damage occur?</label>

        <div class="half halfLeft fl mr-0 ml-0 width-50">

          <label
            for=""
            class="dateLabel cursor"
            (click)="picker13.open()"
            *ngIf="windscreenDamageClaim?.claimDetails?.date !=='' ">
            {{windscreenDamageClaim.claimDetails.date | date: 'fullDate' }}
          </label>

          <input
            matInput
            [matDatepicker]="picker13"
            placeholder="DD-MM-YYYY"
            data-target="windscreenDamage"
            class="inputLeft"
            name="date"
            id="date2"
            data-key="windscreenDamage"
            (click)="picker13.open()"
            (dateChange)="onDateChange($event)"
            #damageDate="ngModel"
            [(ngModel)]="windscreenDamageClaim.claimDetails.date"
            [value]="damageDate"
            [min]="minDate"
            [max]="maxDate"
            required>

              <mat-datepicker-toggle matSuffix [for]="picker13"></mat-datepicker-toggle>
              <mat-datepicker #picker13></mat-datepicker>

              <div
                class="invalid-field"
                *ngIf="damageDate.invalid"
              >

                <label *ngIf="damageDate.invalid" class="invalid-field"
                  >Incident date required
                </label>

              </div>
        </div>

        <div class="half halfRight fl mr-0 ml-0 width-50">
          <select
            name = "dateTime"
            class = "inputRight"
            (change)="onTimeChange($event)"
            [(ngModel)]="eventTime"
            #damageTime="ngModel"
            required>
            <option value="">Select Time</option>
            <option
                *ngFor = "let time of timeListArray"
                [value] = "time.value">
                {{time.viewValue}}
            </option>
        </select>

          <div
            class="invalid-field"
            *ngIf="damageTime.invalid"
          >

            <label *ngIf="damageTime.errors.required" class="invalid-field"
              >Incident time required
            </label>

          </div>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Where did the damage occur?</label>
        <div class="full_width">
            <mat-google-maps-autocomplete
                    country="ke"
                    type="address"
                    class="location"
                    required
                    (onAutocompleteSelected)="onAutocompleteSelected($event, 'windscreenDamageLocation')"
                    (onLocationSelected)="onLocationSelected($event,'windscreenDamage')">
            </mat-google-maps-autocomplete>

          <fa-icon class="iconPosition" icon="map-marker-alt">
          </fa-icon>
        </div>
      </div>
      <!-- End of Form Row-->

       <!--  Form Row  -->
       <div class="row marginTopBottom-40">
        <label for="">Describe the incident and damage?</label>
        <div class="full_width">
          <textarea
            type="text"
            placeholder="Please give details....."
            name="describeIncidentAndDamage"
            data-key="claimDetails"
            (change)="onChange($event);"
            minlength="10"
            required
            [(ngModel)]="windscreenDamageClaim.claimDetails.describeIncidentAndDamage"
            #describeIncidentAndDamage = "ngModel">
          </textarea>

          <div
          class="invalid-field"
          *ngIf="describeIncidentAndDamage.invalid">

              <label
              *ngIf="describeIncidentAndDamage.errors.required"
              class="invalid-field">Incident description required
              </label>

              <label *ngIf="describeIncidentAndDamage.errors.minlength"
              class="invalid-field">Incident description Too Short</label>

          </div>
        </div>
      </div>
      <!-- End of Form Row-->

    <!--  Form Row  -->
    <div class="row marginTopBottom-40 mb-0">
      <div class="half">
        <label for="">Is there any damage other than windscreen/window?</label>
        <mat-radio-group
            aria-label="Select an option"
            data-key="claimDetails"
            name="anyDamageOtherThanWindscreen"
            (change)="onClaimDetailsRadioChange($event);"
            [(ngModel)]="windscreenDamageClaim.claimDetails.anyDamageOtherThanWindscreen"
            #anyDamageOtherThanWindscreen = "ngModel" >
          <mat-radio-button value="true">YES</mat-radio-button>
          <mat-radio-button value="false">NO</mat-radio-button>
        </mat-radio-group>

        <div
        class="invalid-field"
        *ngIf="anyDamageOtherThanWindscreen.invalid">

            <label
            *ngIf="anyDamageOtherThanWindscreen.invalid"
            class="invalid-field">Other damage selection required
            </label>

        </div>
      </div>
    </div>
    <!-- End of Form Row-->

    <!--  Form Row  -->
    <div *ngIf = "anyDamageOtherThanWindscreen.value === 'true' " class="row">
      <div class="full_width">
        <textarea
            type="text"
            data-key="claimDetails"
            placeholder="Please give details…."
            (change)="onChange($event);"
            name= "otherDamageDetails"
            required
            [(ngModel)]="windscreenDamageClaim.claimDetails.otherDamageDetails"
            #otherDamageDetails = "ngModel">
          </textarea>
          <div
          class="invalid-field"
          *ngIf="otherDamageDetails.invalid">

              <label
              *ngIf="otherDamageDetails.errors.required"
              class="invalid-field">Other damage selection required
              </label>

          </div>
      </div>
    </div>
    <!-- End of Form Row-->

    <div class="btnFooter">
      <button
        [disabled]="aboutWindowDamage.invalid"
        class="readMoreYellow"
        (click)="handleProgression('windowReplacement');">
        Save &amp; Continue
        <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
      </button>

      <button class="readMoreWhiteSolid back fl" (click)="handleBackProgression('welcomeClaim');">
        <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
        Back
      </button>

    </div>

  </form>
  <!--End of About Window Damage Form-->

  <!--Window Replacement Form-->
  <form
      action=""
      class="windowReplacement shadow multistep"
      #windowReplacement="ngForm">

      <!--  Form Row  -->
      <div class="formHeader row">
        <h4><span class="yellowLine">Has any </span> windscreen been replaced?</h4>
        <div class="half">

          <mat-radio-group
            aria-label="Select an option"
            data-key="windowDamage"
            name="hasWindscreenBeenReplaced"
            [(ngModel)]="windscreenDamageClaim.claimDetails.hasWindscreenBeenReplaced"
            #hasWindscreenBeenReplaced = "ngModel"
            (change)="onClaimDetailsRadioChange($event)">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>

          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="hasWindscreenBeenReplaced.value === 'true' ">

        <div class="half fl">
          <label for="">Estimate cost of replacement (KES)</label>
          <input
            type="number"
            placeholder="Enter Amount"
            pattern="\d*"
            min="10000"
            data-key="claimDetails"
            name="estimatedReplacementCost"
            [(ngModel)]="windscreenDamageClaim.claimDetails.estimatedReplacementCost"
            #estimatedReplacementCost = "ngModel"
            (change)="onChange($event);"
            required />

            <div
            class="invalid-field"
            *ngIf="estimatedReplacementCost.invalid">

                <label
                *ngIf="estimatedReplacementCost.errors.required"
                class="invalid-field">Estimated amount required
                </label>

                <label *ngIf="estimatedReplacementCost.errors.pattern"
                class="invalid-field">Estimated amount should only be a number</label>

            </div>
        </div>

        <div class="half fl">
          <label for="">Name of Garage</label>
          <input
                type="text"
                placeholder="Enter Garage"
                name="nameOfGarage"
                data-key="claimDetails"
                (change)="onChange($event);"
                [(ngModel)]="windscreenDamageClaim.claimDetails.nameOfGarage"
                required
                #nameOfGarage = "ngModel"/>

            <div
                class="invalid-field"
                *ngIf="nameOfGarage.invalid">

                  <label
                    *ngIf="nameOfGarage.errors.required"
                    class="invalid-field">Name of garage required
                  </label>
            </div>

        </div>

      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="hasWindscreenBeenReplaced.value === 'true' ">

          <label for="">Upload Receipts</label>

          <div class="full_width uploadContainer bg-grey">

              <form
                id = "uploadReceiptsForm"
                #receiptsForm="ngForm"
              >
                  <input
                    type="file"
                    id="uploadreceipts"
                    name="uploadreceipts"
                    ngModel
                    #uploadreceipts = "ngModel"
                    accept=".pdf,.jpg,.png,.jpeg"
                    data-extension="multimedia"
                    multiple
                    required
                    (change)="onFileChange($event,receiptsForm);"
                    />

                  <label for="uploadreceipts" class="icon-section">
                    <p>Upload Receipts</p>
                  </label>

                  <fa-icon icon="upload" class="arrow upload"></fa-icon>

              </form>

          </div>

          <div
            class="invalid-field"
            *ngIf="uploadreceipts.invalid"
          >

              <label *ngIf="uploadreceipts.invalid" class="invalid-field"
                >Receipts Required
              </label>

          </div>

        <!--Declaration checkbox-->
        <div class="row">

          <div class="three_quarter smallText marginTopBottom-40">
            <mat-checkbox
              [(ngModel)]="declarationCheckBox"
              name="declarationCheckBox"
            >
              <small>
                I DECLARE that these particulars are true and correct and undertake to forward immediately (and
                unanswered)
                any correspondence relating to this accident.
              </small>
            </mat-checkbox>
          </div>

          <button
            class="readMoreYellow"
            (click)="handleProgression('insuranceSummary');"
            [disabled]="windowReplacement.invalid || uploadreceipts.invalid || !declarationCheckBox">

            Save &amp; Finish

            <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
          </button>

          <button class="readMoreWhiteSolid back fl" (click)="handleProgression('aboutWindowDamage');">
            <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
            Back
          </button>

        </div>
        <!--End of declaration checkbox-->

      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row" *ngIf="hasWindscreenBeenReplaced.value === 'false'">

        <div class="full_width marginTopBottom-40">
          <label for="">Select a garage from our panel</label>
          <select
              name="nameOfGarage"
              data-key="claimDetails"
              (change)="onChange($event);"
              required
              [(ngModel)]="windscreenDamageClaim.claimDetails.nameOfGarage"
              #nameOfGarageSelect = "ngModel">
                <option value="">Select a garage</option>
                <option *ngFor="let garage of generalGarages"  value="{{garage.name | titlecase }}">{{garage.name | titlecase}} - {{garage.location | titlecase}}</option>
          </select>

          <div
              class="invalid-field"
              *ngIf="nameOfGarageSelect.invalid">

                  <label
                  *ngIf="nameOfGarageSelect.errors.required"
                  class="invalid-field">Name of garage required
                  </label>
          </div>

        </div>

        <!--  Row  -->
        <div class="row">
          <div class="dashedLine"></div>
        </div>
        <!-- End of Row-->

              <!--  Form Row  -->
      <div class="row marginTopBottom-40">

        <label for="">Upload Before and After Photos of your Windscreen (Please capture the registration number in your
          photos)</label>

          <div class="one_fifth fl uploadFileContainer">

              <form
                  id="photosWindscreen"
                  #photosWindscreen="ngForm"
              >
                  <input
                      type="file"
                      id="windscreenphotos"
                      name="windscreenphotos"
                      [ngModel]="fileContent"
                      #windscreenphotos = "ngModel"
                      accept=".jpg,.png,.jpeg"
                      data-extension="image"
                      multiple
                      required
                      (change)="onFileChange($event,photosWindscreen);"
                      />
                  <label for="windscreenphotos" class="icon-section">
                    <img src="./assets/_img/claims/photo-video-icon.png" alt="">
                    <p>Add Photos </p>
                  </label>

                  <div
                    class="invalid-field"
                    *ngIf="windscreenphotos.invalid"
                  >
                    <label *ngIf="windscreenphotos.invalid" class="invalid-field"
                      >Photos required
                    </label>

                  </div>

              </form>

          </div>

           <!-- Form Row  -->
          <!-- <div class="row marginTopBottom-40">
            <div class="three_quarter smallText">
              <mat-checkbox
                ngModel
                #declareCheckBox2="ngModel"
                name="declarationCheck">
                <small>
                  I DECLARE that these particulars are true and correct and undertake to forward immediately (and
                  unanswered)
                  any correspondence relating to this accident.
                </small>
              </mat-checkbox>
            </div>
          </div> -->
          <!-- End of Form Row -->

          <div class="row btnFooterSpacing">

            <div class="three_quarter smallText marginTopBottom-40">
              <mat-checkbox
                [(ngModel)]="declarationCheckBox"
                name="declarationCheckBox"
              >
                <small>
                  I DECLARE that these particulars are true and correct and undertake to forward immediately (and
                  unanswered)
                  any correspondence relating to this accident.
                </small>
              </mat-checkbox>
            </div>

            <button
              class="readMoreYellow"
              (click)="handleProgression('insuranceSummary');"
              [disabled]="windowReplacement.invalid || windscreenphotos.invalid || !declarationCheckBox">

              Save &amp; Finish

              <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
            </button>

            <button class="readMoreWhiteSolid back fl" (click)="handleProgression('aboutWindowDamage');">
              <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
              Back
            </button>

          </div>

      </div>
      <!-- End of Form Row-->

      </div>
      <!-- End of Form Row-->



  </form>
  <!--END Window Replacement Form-->

    <div class="summaryDetails hideForm">
      <h4>Windscreen or Window Damage Claim</h4>
      <!--  Policy Details  -->
      <section class="singleDetail policyDetails">
        <h4>Policy Details</h4>
        <p>Policy No. : {{windscreenDamageClaim.claimDetails.policyNumber}}</p>

      </section>
      <!--END  Policy Details  -->

      <section class="singleDetail theftDetails">
        <div class="detailHeader">
          <h4 class="inactive">About the Damage</h4>
          <button mat-icon-button>
            <fa-icon class="iconPosition" icon="edit"></fa-icon>
          </button>
        </div>
        <div class="detailContent">
          <!-- <p>{{damageLocation.value}}</p> -->
          <p>Location Damage : {{windscreenDamageClaim.claimDetails.damagePlace}}</p>
          <p>Date of damage: {{windscreenDamageClaim.claimDetails.date | date: 'fullDate'}}</p>
        </div>
      </section>

      <section class="singleDetail vehicleDetails">
        <div class="detailHeader">
          <h4>Garage Details</h4>
        </div>
        <div class="detailContent" *ngIf="hasWindscreenBeenReplaced.value === 'true'">
          <p>Cost of Replacement : {{windscreenDamageClaim.claimDetails.estimatedReplacementCost | currency: 'KES ' : 'code' : '1.0-0'}}</p>
          <p>Garage Name: {{windscreenDamageClaim.claimDetails.nameOfGarage}}</p>
        </div>

        <div class="detailContent" *ngIf="hasWindscreenBeenReplaced.value === 'false'">
          <p>Garage Name: {{windscreenDamageClaim.claimDetails.nameOfGarage}}</p>
        </div>

      </section>

    </div>
  </div>
  <!--End of Windscreen or Window Damage Claim-->

  <!--Theft of Vehicle Parts Claim-->
  <div class="theftVehicleParts claimType">

    <form
        class="aboutTheft shadow multistep"
        #theftPartsaboutDamage="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Tell us</span> about the theft</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Describe the vehicle parts or accessories damaged</label>
        <div class="full_width">
          <textarea
            type="text"
            placeholder="Side Mirrors, Head lamps, Spare Wheel Cover"
            name="vehiclePartsStolen"
            data-key="claimDetails"
            (change)="onChange($event);"
            [(ngModel)]="theftPartsClaim.claimDetails.vehiclePartsStolen"
            #vehiclePartsStolen = "ngModel"
            required>
          </textarea>
          <div
            class="invalid-field"
            *ngIf="vehiclePartsStolen.invalid"
          >

            <label *ngIf="vehiclePartsStolen.errors.required" class="invalid-field"
              >Parts stolen description required
            </label>

          </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">When did the theft occur?</label>
        <div class="half halfLeft fl mr-0 ml-0 width-50">

          <label
            for=""
            class="dateLabel cursor"
            (click)="picker11.open()"
            *ngIf="theftPartsClaim?.claimDetails?.dateTime !=='' ">
            {{theftPartsClaim.claimDetails.dateTime | date: 'fullDate' }}
          </label>

          <input
            matInput
            [matDatepicker]="picker11"
            placeholder="DD-MM-YYYY"
            name="dateTime"
            id="date3"
            class="inputLeft"
            (click)="picker11.open()"
            data-key="theftVehicleParts"
            (dateChange)="onDateChange($event)"
            #theftPartsDate="ngModel"
            ngModel
            [min]="minDate"
            [max]="maxDate"
            required>

          <mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
          <mat-datepicker #picker11></mat-datepicker>

          <div *ngIf="theftPartsDate.invalid">
            <label
            class="invalid-field"
            *ngIf="theftPartsDate.invalid">
                Accident date required
            </label>
          </div>

        </div>

        <div class="half halfRight fl mr-0 ml-0 width-50">
          <select
              name = "dateTime"
              class="inputRight"
              data-key="claimDetails"
              (change)="onTimeChange($event)"
              [(ngModel)]="eventTime"
              #theftTime="ngModel"
              required>
                <option value="">Select Time</option>
                <option
                    *ngFor = "let time of timeListArray"
                    [value] = "time.value">
                    {{time.viewValue}}
                </option>
          </select>

          <div
            class="invalid-field"
            *ngIf="theftTime.invalid"
          >

            <label *ngIf="theftTime.errors.required" class="invalid-field"
              >Incident time required
            </label>

          </div>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Where did it happen?</label>
        <div class="full_width">

            <mat-google-maps-autocomplete
                    country="ke"
                    type="address"
                    class="location"
                    required
                    (onAutocompleteSelected)="onAutocompleteSelected($event, 'theftVehicleLocation')"
                    (onLocationSelected)="onLocationSelected($event,'theftVehicleParts')">
            </mat-google-maps-autocomplete>

          <fa-icon class="iconPosition" icon="map-marker-alt">
          </fa-icon>
        </div>
      </div>
      <!-- End of Form Row-->

       <!--  Form Row  -->
       <div class="row marginTopBottom-40">
        <label for="">Describe the incident</label>
        <div class="full_width">
          <textarea
            type="text"
            placeholder="Please give details…."
            name="incidentDescription"
            data-key="claimDetails"
            (change)="onChange($event);"
            [(ngModel)]="theftPartsClaim.claimDetails.incidentDescription"
            #incidentDescription = "ngModel"
            minlength="10"
            required>
          </textarea>

          <div
            class="invalid-field"
            *ngIf="incidentDescription.invalid"
          >

            <label *ngIf="incidentDescription.errors.required" class="invalid-field"
              >Incident description required
            </label>

            <label *ngIf="incidentDescription.errors.minlength" class="invalid-field"
              >Incident description too short
            </label>

          </div>
        </div>
      </div>
      <!-- End of Form Row-->


      <div class="btnFooter">
        <button
            class="readMoreYellow"
            type="button"
            (click)="handleProgression('carItemsRepair');"
            [disabled]="theftPartsaboutDamage.invalid">
          Save &amp; Continue
          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleBackProgression('welcomeClaim');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>
      </div>

    </form>

    <!--Car Items Repair Form-->
    <form
        class="carItemsRepair shadow multistep"
        #carItemsRepair="ngForm">

      <!--  Form Row  -->
      <div class="formHeader row">
        <h4><span class="yellowLine">Has any </span>item been replaced?</h4>
        <div class="half">
          <mat-radio-group
            aria-label="Select an option"
            name="hasAnyItemBeenReplaced"
            (change)="onClaimDetailsRadioChange($event);"
            [(ngModel)]="theftPartsClaim.claimDetails.hasAnyItemBeenReplaced"
            #hasAnyItemBeenReplaced="ngModel"
            required>

            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>

          </mat-radio-group>

          <div
            class="invalid-field"
            *ngIf="hasAnyItemBeenReplaced.invalid"
          >

            <label *ngIf="hasAnyItemBeenReplaced.invalid" class="invalid-field"
              >Please select an option above
            </label>

          </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Group  -->
      <div *ngIf="hasAnyItemBeenReplaced.value === 'true' " class="formGroup" >

        <div class="row marginTopBottom-40" *ngFor="let item of theftRepairsArray; let i=index;" >

            <h4>Repair Item {{i+1}}</h4>

            <button
                class="closer"
                type="button"
                (click)="removeRepair(i); calculateTotalCost();"
                *ngIf="theftRepairsArray.length>1"
                >
                X
            </button>

          <div class="half fl">
            <label for="">Estimate cost of replacement (KES)</label>
            <input
              type="number"
              placeholder="Enter Amount"
              id="repair{{i+1}}estimatedCost"
              pattern="\d*"
              name="repair{{i+1}}estimatedCost"
              (change)="onRepairChange($event,i);calculateTotalCost();"
              required
              [(ngModel)]="theftPartsClaim.claimDetails.replacedItems[i]['estimatedCost']"
              #estimatedRepairCost = "ngModel">

              <div
                class="invalid-field"
                *ngIf="estimatedRepairCost.invalid"
              >

                <label *ngIf="estimatedRepairCost.errors.required" class="invalid-field"
                  >Amount required
                </label>

                <label *ngIf="estimatedRepairCost.errors.pattern" class="invalid-field"
                  >Amount should only be a number
                </label>

              </div>
          </div>

          <div class="half fl">
            <label for="">Name of Garage</label>
            <input
              type="text"
              placeholder="Enter Garage"
              id="repair{{i+1}}garageName"
              name="repair{{i+1}}garageName"
              (change)="onRepairChange($event,i);"
              required
              [(ngModel)]="theftPartsClaim.claimDetails.replacedItems[i]['garageName']"
              #garageName = "ngModel">

              <div
                class="invalid-field"
                *ngIf="garageName.invalid"
              >

                <label *ngIf="garageName.errors.required" class="invalid-field"
                  >
                      Garage name required
                </label>


              </div>
          </div>

        </div>

        <div class="row marginTopBottom-40" *ngIf="theftRepairsArray.length < 5" >
          <button
              class="addButton full_width"
              (click)="addAnotherRepair();">
                Add replaced part
            <fa-icon class="iconPosition" icon="plus"></fa-icon>
          </button>
        </div>

         <!--  Row  -->
        <div class="row">
          <div class="dashedLine"></div>
        </div>
        <!-- End of Row-->

        <!--  Form Row  -->
        <div class="row marginTopBottom-40">
          <label for="">Upload Compilation of Receipts</label>
          <div class="full_width uploadContainer bg-grey">
              <form
                  id="uploadReplacementreceipts"
                  #receiptsReplacementForm="ngForm">

                  <input
                    type="file"
                    id="uploadreplacementreceipts"
                    accept=".pdf,.jpg,.png,.jpeg"
                    data-extension="multimedia"
                    ngModel
                    #uploadreplacementreceipts="ngModel"
                    multiple
                    required
                    (change)="onFileChange($event, receiptsReplacementForm);"
                    name="uploadreplacementreceipts" />

                  <label for="uploadreplacementreceipts" class="icon-section">
                    <p>Upload Receipts</p>
                  </label>

                  <fa-icon icon="upload" class="arrow upload"></fa-icon>

              </form>

          </div>

        <div
          class="invalid-field"
          *ngIf="uploadreplacementreceipts.invalid"
        >

          <label *ngIf="uploadreplacementreceipts.invalid" class="invalid-field"
            >Compiled Receipts are Required
          </label>

        </div>

        </div>
        <!-- End of Form Row-->

      <!-- Declaration checkbox  -->
      <div class="row">

        <div class="three_quarter smallText marginTopBottom-40">

        <mat-checkbox
          [(ngModel)]="declarationCheckBox"
            name="declarationCheckBox"
        >
            <small>
              I DECLARE that these particulars are true and correct and undertake to forward immediately (and
              unanswered)
              any correspondence relating to this accident.
            </small>
          </mat-checkbox>
        </div>

          <button
            class="readMoreYellow"
            [disabled]="(carItemsRepair.invalid || uploadreplacementreceipts.invalid || !declarationCheckBox)"
            (click)="handleProgression('insuranceSummary');">
            Save &amp; Finish
            <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
          </button>

          <button class="readMoreWhiteSolid back fl" (click)="handleProgression('aboutTheft');">
            <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
            Back
          </button>

      </div>
      <!-- End of Declaration checkbox-->

      </div>
      <!-- End of Form Group-->

      <!--  Form Group  -->
      <div *ngIf="hasAnyItemBeenReplaced.value === 'false' " class="formGroup">

        <div class="row marginTopBottom-40">

          <div class="full_width">
            <label for="">Select a garage from our panel</label>
            <select
                id="garageName"
                name="garageName"
                 [(ngModel)]="theftPartsClaim.claimDetails.garageName"
                #garageName="ngModel"
                data-key="claimDetails"
                (change)="onChange($event);"
                required>
                <option value="">Select a garage</option>
                 <option *ngFor="let garage of generalGarages"  value="{{garage.name | titlecase }}">{{garage.name | titlecase}} - {{garage.location | titlecase}}</option>

            </select>

            <div
              class="invalid-field"
              *ngIf="garageName.invalid"
            >

              <label *ngIf="garageName.errors.required" class="invalid-field"
                >
                    Please select a garage from list in the dropdown
              </label>


            </div>


          </div>

        </div>
        <!-- End of Form Row-->

        <!--  Row  -->
        <div class="row">
          <div class="dashedLine"></div>
        </div>
        <!-- End of Row-->

      <!-- Declaration checkbox  -->
      <div class="row">

        <div class="three_quarter smallText marginTopBottom-40">

        <mat-checkbox
          [(ngModel)]="declarationCheckBox"
            name="declarationCheckBox"
        >
            <small>
              I DECLARE that these particulars are true and correct and undertake to forward immediately (and
              unanswered)
              any correspondence relating to this accident.
            </small>
          </mat-checkbox>
        </div>

          <button
            class="readMoreYellow"
            [disabled]="(carItemsRepair.invalid || !declarationCheckBox)"
            (click)="handleProgression('insuranceSummary');">
            Save &amp; Finish
            <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
          </button>

          <button class="readMoreWhiteSolid back fl" (click)="handleProgression('aboutTheft');">
            <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
            Back
          </button>

      </div>
      <!-- End of Declaration checkbox-->

      </div>
       <!-- End of Form Group-->

    </form>
    <!--END Car Items Repair Form-->

    <div class="summaryDetails hideForm">

      <h4>Theft of Vehicle Parts Claim</h4>

      <!--  Policy Details  -->
      <section class="singleDetail policyDetails">
        <h4>Policy Details</h4>
        <p>Policy No. : {{theftPartsClaim.claimDetails.policyNumber}}</p>
        <!-- <p>Expiry - June 14th 2022</p> -->
      </section>
      <!--END  Policy Details  -->

      <section class="singleDetail theftDetails">

        <div class="detailHeader">
          <h4>About the Theft</h4>
          <button mat-icon-button>
            <fa-icon class="iconPosition" icon="edit"></fa-icon>
          </button>
        </div>

        <div class="detailContent">

          <p>Date of Theft: {{theftPartsClaim.claimDetails.dateTime | date: 'fullDate'}}</p>

          <p>Location of Theft: {{theftPartsClaim.claimDetails.theftPlace}}</p>

        </div>
      </section>

      <section class="singleDetail repairDetails">

        <div class="detailHeader">
          <h4>Repair Details</h4>
          <button mat-icon-button>
            <fa-icon class="iconPosition" icon="edit"></fa-icon>
          </button>
        </div>

        <div class="detailContent">

          <div *ngIf="hasAnyItemBeenReplaced.value === 'false' ">

            <p>
                Garage Name : {{theftPartsClaim.claimDetails.garageName}}
            </p>

        </div>

        <div *ngIf="hasAnyItemBeenReplaced.value === 'true'">

          <p>Replaced Items : {{theftRepairsArray.length}}</p>

          <div *ngIf="theftRepairsArray.length < 1">
            <p>
                Garage : {{theftPartsClaim.claimDetails.garageName}}
            </p>
          </div>

        </div>

        </div>


      </section>

    </div>
    <!--END  Summary Container  -->
  </div>
  <!--End of Theft of Vehicle Parts Claim-->

  <!--Total Theft Vehicle Claim-->
  <div class="totaltheftVehicle claimType">

    <!--About Theft Form-->
    <form
        action=""
        class="aboutTheft shadow multistep"
        #totalTheftAboutDamage="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Tell us</span> about the theft</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">When did it happen?</label>
        <div class="half halfLeft fl mr-0 ml-0 width-50">

          <label
            for=""
            class="dateLabel cursor"
            (click)="theftDatePicker.open()"
            *ngIf="totalTheftClaim?.claimDetails?.date4 !=='' ">
            {{totalTheftClaim.claimDetails.dateTime| date: 'fullDate' }}
          </label>

          <input
            matInput
            [matDatepicker]="theftDatePicker"
            placeholder="DD-MM-YYYY"
            class="inputLeft"
            name="date"
            id="date4"
            (click)="theftDatePicker.open()"
            data-key = "totaltheftVehicle"
            (dateChange)="onDateChange($event)"
            #aboutTheftDate="ngModel"
            ngModel
            [min]="minDate"
            [max]="maxDate"
            >

          <mat-datepicker-toggle matSuffix [for]="theftDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #theftDatePicker></mat-datepicker>

        </div>

        <div class="half halfRight fl mr-0 ml-0 width-50">
          <select
            name ="dateTime"
            class="inputRight"
            data-key="totaltheftVehicle"
            (change)="onTimeChange($event)"
            ngModel
            #totaltheftTime="ngModel"
            required>
            <option value=""></option>
              <option
                  *ngFor = "let time of timeListArray"
                  [value] = "time.value">
                  {{time.viewValue}}
              </option>
          </select>

          <div
            class="invalid-field"
            *ngIf="totaltheftTime.invalid"
          >

            <label *ngIf="totaltheftTime.errors.required" class="invalid-field"
              >Theft time required
            </label>

          </div>
        </div>

      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Where did it happen?</label>
        <div class="full_width">
          <mat-google-maps-autocomplete
              country="ke"
              type="address"
              class="location"
              required
              (onAutocompleteSelected)="onAutocompleteSelected($event, 'totaltheftLocation')"
              (onLocationSelected)="onLocationSelected($event,'totaltheftVehicle')">
          </mat-google-maps-autocomplete>
          <fa-icon class="iconPosition" icon="map-marker-alt">
          </fa-icon>
        </div>
      </div>
      <!-- End of Form Row-->

      <div class="btnFooter">
        <button
            class="readMoreYellow"
            (click)="handleProgression('driverInfo');"
            [disabled]="!totalTheftAboutDamage.valid">
          Save &amp; Continue
          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleBackProgression('welcomeClaim');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>
      </div>

    </form>
    <!--END About Theft Form-->

    <!--Driver Info Form-->
    <form
        action=""
        class="driverInfo shadow multistep"
        #totalTheftDriverDetails="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Who was</span> driving your car at the time of the theft?</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <mat-radio-group
              aria-label="Select an option"
              data-key="claimDetails"
              name="driver"
              (change)="onClaimDetailsRadioChange($event);"
              [(ngModel)]="totalTheftClaim.claimDetails.driver"
              #theftDriver="ngModel">
            <mat-radio-button value="self" checked>Self</mat-radio-button>
            <mat-radio-button value="other-driver">Other Driver</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">Please enter name of driver</label>
          <input
            type="text"
            placeholder="Enter name"
            name="name"
            [(ngModel)]="totalTheftClaim.claimDetails.driverDetails.name"
            data-key="driverDetails"
            minlength="3"
            required
            #aboutTheftname="ngModel"
            (change)="onChange($event);"
            required>

            <div
            class="invalid-field"
            *ngIf="aboutTheftname.invalid">

                <label
                *ngIf="aboutTheftname.errors.required"
                class="invalid-field">Driver name is required
                </label>

                <label
                *ngIf="aboutTheftname.errors.minlength"
                class="invalid-field">Driver name is too short
                </label>



            </div>
        </div>

        <div class="half fl ml-3 mr-0">
          <label for="">Mobile Number</label>
          <input
            type="text"
            placeholder="Enter Number"
            name="phoneNumber"
            [(ngModel)]="totalTheftClaim.claimDetails.driverDetails.phoneNumber"
            data-key="driverDetails"
            #aboutTheftphoneNumber="ngModel"
            pattern="^(?:254|\\+254|0)((?:7|1)[0-9]{8})$"
            (change)="onChange($event);"
            required>

            <div
            class="invalid-field"
            *ngIf="aboutTheftphoneNumber.invalid">

                    <label
                    *ngIf="aboutTheftphoneNumber.errors.required"
                    class="invalid-field">Phone Number is required
                </label>

                <label *ngIf="aboutTheftphoneNumber.errors.pattern"
                class="invalid-field">Phone Number invalid</label>

            </div>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">ID Number</label>
          <input
            type="text"
            placeholder="Enter Number"
            name="idNumber"
            [(ngModel)]="totalTheftClaim.claimDetails.driverDetails.idNumber"
            data-key="driverDetails"
            #aboutTheftidNumber="ngModel"
            pattern="\d*"
            minlength="4"
            maxlength="8"
            required
            (change)="onChange($event);">

            <div
            class="invalid-field"
            *ngIf="aboutTheftidNumber.invalid">

                <label
                *ngIf="aboutTheftidNumber.errors.required"
                class="invalid-field">National Id is required
                </label>

                <label *ngIf="aboutTheftidNumber.errors.maxlength"
                class="invalid-field">National Id Too Long</label>

                <label *ngIf="aboutTheftidNumber.errors.minlength"
                class="invalid-field">National Id Too Short</label>

                <label *ngIf="aboutTheftidNumber.errors.pattern"
                class="invalid-field">National Id should only contain numbers</label>

            </div>
        </div>

        <div class="half fl ml-3 mr-0">
          <label for="">Email Address</label>
          <input
            type="email"
            placeholder="Enter Email Address"
            name="email"
            [(ngModel)]="totalTheftClaim.claimDetails.driverDetails.email"
            data-key="driverDetails"
            #aboutTheftemail="ngModel"
            (change)="onChange($event);"
            required>

            <div
            class="invalid-field"
            *ngIf="aboutTheftemail.invalid">

            <label
            *ngIf="aboutTheftemail.errors.required"
            class="invalid-field">Email is required
        </label>

        <label *ngIf="aboutTheftemail.errors.email"
        class="invalid-field">Email must be a valid email address</label>

    </div>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">Did the driver have your permission?</label>
          <mat-radio-group
              aria-label="Select an option"
              data-key="driverDetails"
               (change)="onClaimDetailsRadioChange($event);"
              name="driverHaspermission"
              [(ngModel)]="totalTheftClaim.claimDetails.driverDetails.driverHaspermission"
              #driverHaspermission="ngModel">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Upload Copy of Driving License</label>
        <div class="full_width uploadContainer bg-grey">
          <input
              type="file"
              id="drivinglicense"
              accept=".pdf,.jpgeg,.jpg,.png"
              data-extension="multimedia"
              [ngModel]="fileContent"
              name="drivinglicense"
              #drivinglicenseTheft = "ngModel"
              (change)="onFileChange($event);"
              type="file"
              required />

          <label for="drivinglicense" class="icon-section">
            <p>Upload Driving License</p>
          </label>

          <fa-icon icon="upload" class="arrow upload"></fa-icon>


        </div>

        <div
          class="invalid-field"
          *ngIf="drivinglicenseTheft.invalid"
        >
          <label *ngIf="drivinglicenseTheft.errors.required" class="invalid-field"
            >Driving License Required
          </label>


        </div>
      </div>
      <!-- End of Form Row-->
      <div class="btnFooter">
        <button
            class="readMoreYellow"
            (click)="handleProgression('carBeforeTheft');"
            [disabled]="!totalTheftDriverDetails.valid">
          Save &amp; Continue
          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleProgression('aboutTheft');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>
      </div>

    </form>
    <!--END Driver Info Form-->

    <!--Car Before Theft Form-->
    <form
        action=""
        class="carBeforeTheft shadow multistep"
        #totalTheftBeforeTheft="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Tell us </span>about the car before the theft</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">What was the vehicle being used for when it was stolen?</label>
        <div class="full_width">
          <textarea
              type="text"
              data-key="claimDetails"
              name="carDescriptionBeforeTheft"
              (change)="onChange($event)"
              placeholder="Please give details…."
              minlength="10"
              [(ngModel)]="totalTheftClaim.claimDetails.carDescriptionBeforeTheft"
              #carDescriptionBeforeTheft="ngModel"
              required
            ></textarea>

            <div
              class="invalid-field"
              *ngIf="carDescriptionBeforeTheft.invalid"
            >
              <label *ngIf="carDescriptionBeforeTheft.errors.required" class="invalid-field"
                >Car description required
              </label>

              <label *ngIf="carDescriptionBeforeTheft.errors.minlength" class="invalid-field"
                >Car description too short
              </label>


            </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Please provide details of how the theft occurred</label>
        <div class="full_width">
          <textarea
              type="text"
              placeholder="Please give details…."
              [(ngModel)]="totalTheftClaim.claimDetails.theftOccurDetails"
              #theftOccurDetails="ngModel"
              data-key="claimDetails"
              name="theftOccurDetails"
              (change)="onChange($event)"
              minlength="10"
              required
              ></textarea>
              <div
                class="invalid-field"
                *ngIf="theftOccurDetails.invalid"
              >
                <label *ngIf="theftOccurDetails.errors.required" class="invalid-field"
                  >Theft description required
                </label>

                <label *ngIf="theftOccurDetails.errors.minlength" class="invalid-field"
                  >Theft description too short
                </label>

              </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">

        <div class="one_third fl">
          <label for="">Is there any other insurance against Burglary, Housebreaking or Theft on this vehicle? </label>
          <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="totalTheftClaim.claimDetails.otherInsuranceAgainstTheft"
              #otherInsuranceAgainstTheft="ngModel"
              data-key="claimDetails"
               (change)="onClaimDetailsRadioChange($event);"
              name="otherInsuranceAgainstTheft">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="one_third fl">
          <label for="">Does vehicle have tracking device?</label>
          <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="totalTheftClaim.claimDetails.vehicleHasTrackingDevice"
              #vehicleHasTrackingDevice="ngModel"
              data-key="claimDetails"
              (change)="onClaimDetailsRadioChange($event);"
              name="vehicleHasTrackingDevice"

              >
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="one_third fl">
          <label for="">Was vehicle locked?</label>
          <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="totalTheftClaim.claimDetails.wasVehicleLocked"
              #wasVehicleLocked="ngModel"
              data-key="claimDetails"
              (change)="onClaimDetailsRadioChange($event);"
              name="wasVehicleLocked"
              >
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40 mb-0">
        <div class="half">
          <label for="">Any modification that is recognisable?</label>
          <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="totalTheftClaim.claimDetails.anyModification"
              #anyModification="ngModel"
              data-key="claimDetails"
              (change)="onClaimDetailsRadioChange($event);"
              name="anyModification">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  *ngIf="anyModification.value === 'true'" -->
      <div class="row" *ngIf="anyModification.value === 'true'" >
        <div class="full_width">
          <textarea
              type="text"
              placeholder="Please give details…."
              [(ngModel)]="totalTheftClaim.claimDetails.modificationDetails"
              #modificationDetails="ngModel"
              data-key="claimDetails"
              minlength="10"
              (change)="onClaimDetailsRadioChange($event);"
              name="modificationDetails"
              (change)="onChange($event)"
              required></textarea>

              <div
                class="invalid-field"
                *ngIf="modificationDetails.invalid"
              >
                <label *ngIf="modificationDetails.errors.required" class="invalid-field"
                  >Modifications description required
                </label>

                <label *ngIf="modificationDetails.errors.minlength" class="invalid-field"
                  >Modifications description too short
                </label>

              </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40 mb-0">
        <div class="half">
          <label for="">Any special fitments or accessories?</label>
          <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="totalTheftClaim.claimDetails.anySpecialFitments"
              #anySpecialFitments="ngModel"
              data-key="claimDetails"
              (change)="onClaimDetailsRadioChange($event);"
              name="anySpecialFitments">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row" *ngIf="anySpecialFitments.value === 'true'">
        <div class="full_width">
          <textarea
              type="text"
              placeholder="Please give details…."
              [(ngModel)]="totalTheftClaim.claimDetails.specialFitmentDetails"
              #specialFitmentDetails="ngModel"
              name="specialFitmentDetails"
              data-key="claimDetails"
              required
              (change)="onChange($event)"></textarea>
              <div
                class="invalid-field"
                *ngIf="specialFitmentDetails.invalid"
              >
                <label *ngIf="specialFitmentDetails.errors.required" class="invalid-field"
                  >Special fitments description required
                </label>

                <label *ngIf="specialFitmentDetails.errors.minlength" class="invalid-field"
                  >Special fitments description too short
                </label>

              </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40 mb-0">
        <div class="half">
          <label for="">Any identifying features, e.g. marks, scratches, etc?</label>
          <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="totalTheftClaim.claimDetails.anyIdentifyingFeatures"
              #anyIdentifyingFeatures="ngModel"
              data-key="claimDetails"
              (change)="onClaimDetailsRadioChange($event);"
              name="anyIdentifyingFeatures">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row" *ngIf="anyIdentifyingFeatures.value === 'true'">
        <div class="full_width">
          <textarea
              type="text"
              placeholder="Please give details…."
              (change)="onChange($event)"
              [(ngModel)]="totalTheftClaim.claimDetails.identifyingFeaturesDescription"
              #identifyingFeaturesDescription="ngModel"
              minlength="10"
              required
              name="identifyingFeaturesDescription"
              data-key="claimDetails"
              (change)="onChange($event)"></textarea>

              <div
                class="invalid-field"
                *ngIf="identifyingFeaturesDescription.invalid"
              >
                <label *ngIf="identifyingFeaturesDescription.errors.required" class="invalid-field"
                  >Identifying description required
                </label>

                <label *ngIf="identifyingFeaturesDescription.errors.minlength" class="invalid-field"
                  >Identifying description too short
                </label>

              </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="full_width">
          <label for="">Estimated mileage at time of loss</label>
          <input
              type="text"
              placeholder="Enter Mileage (KM)"
              pattern="\d*"
              [(ngModel)]="totalTheftClaim.claimDetails.estimatedMileage"
              #estimatedMileage="ngModel"
              name="estimatedMileage"
              min="0"
              data-key="claimDetails"
              required
              (change)="onChange($event)"
              >

              <div
                class="invalid-field"
                *ngIf="estimatedMileage.invalid"
              >
                <label *ngIf="estimatedMileage.errors.required" class="invalid-field"
                  >Vehicle Milage required
                </label>

                <label *ngIf="estimatedMileage.errors.pattern" class="invalid-field"
                  >Vehicle mileage must be a number
                </label>

              </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half">
          <label for="">Is there a hire purchase interest on this vehicle?</label>
          <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="totalTheftClaim.claimDetails.isThereHigherPurchaseInterest"
              #isThereHigherPurchaseInterest="ngModel"
              data-key="claimDetails"
              (change)="onClaimDetailsRadioChange($event);"
              name="isThereHigherPurchaseInterest">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="full_width">
          <label for="">Where was the car purchased?</label>
          <select
              [(ngModel)]="totalTheftClaim.claimDetails.placeOfCarPurchase"
              #placeOfCarPurchase="ngModel"
              name="placeOfCarPurchase"
              data-key="claimDetails"
              required
              (change)="onChange($event)"
          >
            <option value=""></option>
            <option value="Showroom">Showroom</option>
            <option value="Imported">Imported</option>
          </select>

          <div
            class="invalid-field"
            *ngIf="placeOfCarPurchase.invalid"
          >

            <label *ngIf="placeOfCarPurchase.errors.required" class="invalid-field"
              >Place of purchase required
            </label>

          </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="full_width">
          <label for="">Date of purchase</label>

          <label
            for=""
            class="dateLabel cursor"
            *ngIf="totalTheftClaim?.claimDetails?.dateOfPurchase !=='' "
            (click)="picker5.open()">
              {{ totalTheftClaim.claimDetails.dateOfPurchase | date: 'fullDate' }}
          </label>

          <input
              (click)="picker5.open();"
              matInput
              id="dateOfPurchase"
              name="dateOfPurchase"
              [(ngModel)]="totalTheftClaim.claimDetails.dateOfPurchase"
              #dateOfPurchase="ngModel"
              data-key="totaltheftVehicle"
              [matDatepicker]="picker5"
              [max]="maxDate"
              required
              (dateChange)="onDateChange($event)"
              placeholder="DD-MM-YYYY">

          <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
          <mat-datepicker
              #picker5>
          </mat-datepicker>
          <div *ngIf="dateOfPurchase.invalid">
              <label
              class="invalid-field"
              *ngIf="dateOfPurchase.invalid">
              Date of purchase invalid
              </label>
          </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">Are you the sole owner?</label>
          <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="totalTheftClaim.claimDetails.areYouTheSoleOwner"
              #areYouTheSoleOwner="ngModel"
              data-key="claimDetails"
              (change)="onClaimDetailsRadioChange($event);"
              name="areYouTheSoleOwner">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->
      <div class="btnFooter">
        <button
            class="readMoreYellow"
            (click)="handleProgression('policeInfo');"
            [disabled]="!totalTheftBeforeTheft.valid">
          Save &amp; Continue
          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleProgression('driverInfo');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>

      </div>

    </form>
    <!--END Car Before Theft Form-->

        <!--Police Info Form-->
    <form
        action=""
        class="policeInfo shadow multistep"
        #totalTheftPoliceInfo="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Have you</span> reported the theft to the Police?</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="totalTheftClaim.claimDetails.theftReportedToPolice"
              #theftReportedToPolice="ngModel"
              (change)="onClaimDetailsRadioChange($event);"
              name="theftReportedToPolice"
              data-key="totaltheftVehicle">
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="theftReportedToPolice.value === 'true'" >
        <div class="half fl">
          <label for="">Police Station where the theft was reported</label>
          <!-- <input
              type="text"
              placeholder="Police station"
              ngModel
              #policeStation="ngModel"
              name="policeStation"
              required
              data-key="theftReportDetails"
              (change)="onChange($event)"> -->

            <input
              matInput
              matGoogleMapsAutocomplete
              country="ke"
              type="address"
              class="location"
              required
              (onAutocompleteSelected)="onAutocompleteSelected($event, 'totalTheftPoliceStation')"/>

              <fa-icon class="iconPosition" icon="map-marker-alt"></fa-icon>
        </div>

        <div class="half fl ml-3 mr-0">
          <label for="">OB Number</label>
          <input
              type="text"
              placeholder="Enter OB Number"
              ngModel
              #OBNumber="ngModel"
              required
              name="OBNumber"
              data-key="theftReportDetails"
              (change)="onChange($event)">

              <div *ngIf="OBNumber.invalid">
                  <label
                  class="invalid-field"
                  *ngIf="OBNumber.errors.required">
                  OB number required
                  </label>
              </div>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="theftReportedToPolice.value === 'true'">
        <label for="">When did you report the theft?</label>
        <div class="half halfLeft fl mr-0 ml-0 width-50">

          <label
            for=""
            class="dateLabel cursor"
            *ngIf="totalTheftClaim?.claimDetails?.theftReportDetails?.theftReportedDate !=='' "
            (click)="picker9.open()">
              {{ totalTheftClaim.claimDetails.theftReportDetails.theftReportedDate | date: 'fullDate' }}
          </label>

          <input
            matInput [matDatepicker]="picker9"
            placeholder="DD-MM-YYYY"
            class = "inputLeft"
            name="theftReportedDate"
            id="theftReportedDate"
            (click)="picker9.open()"
            (dateChange)="onDateChange($event)"
            [(ngModel)]="totalTheftClaim.claimDetails.theftReportDetails.theftReportedDate"
            #theftReportedDate="ngModel"
            data-key="theftReportDetails"
            [min]="minDate"
            [max]="maxDate"
            [value]="theftReportedDate"
            required />

          <mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
          <mat-datepicker #picker9></mat-datepicker>
          <div *ngIf="theftReportedDate.invalid">
              <label
              class="invalid-field"
              *ngIf="theftReportedDate.invalid">
              Date of theft is required
              </label>
          </div>
        </div>

        <div class="half halfRight fl mr-0 ml-0 width-50">

          <select
            name ="theftReportedDateTime"
            class="inputRight"
            (change)="onTimeChange($event)"
            ngModel
            #theftReportedDateTime="ngModel"
            data-key="totaltheftVehicle"
            required>
            <option value=""></option>
              <option
                  *ngFor = "let time of timeListArray"
                  [value] = "time.value">
                  {{time.viewValue}}
              </option>
          </select>

          <div *ngIf="theftReportedDateTime.invalid">
              <label
              class="invalid-field"
              *ngIf="theftReportedDateTime.errors.required">
              Time of theft required
              </label>
          </div>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40" *ngIf="theftReportedToPolice.value === 'true'">
        <label for="">Upload Police Abstract</label>
        <div class="full_width uploadContainer bg-grey">
          <input
            type="file"
            id="policeabstract"
            accept=".pdf,.jpgeg,.jpg,.png"
            data-extension="multimedia"
            [ngModel]="fileContent"
            name="policeabstract"
            #totalTheftpoliceabstract = "ngModel"
            required
            (change)="onFileChange($event);"
            />

          <label for="policeabstract" class="icon-section">
            <p>Add Police Abstract</p>
          </label>

          <fa-icon icon="upload" class="arrow upload"></fa-icon>
        </div>

        <div
          class="invalid-field"
          *ngIf="totalTheftpoliceabstract.invalid"
        >
          <label *ngIf="totalTheftpoliceabstract.invalid" class="invalid-field"
            >Police abstract required
          </label>


        </div>
      </div>
      <!-- End of Form Row-->
      <div class="btnFooter">
        <button
            class="readMoreYellow"
            (click)="handleProgression('insuranceSummary');"
            [disabled]="totalTheftPoliceInfo.invalid">
          Save &amp; Finish

          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>

        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleProgression('carBeforeTheft');">

          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>

      </div>
    </form>
    <!--END Police Info Form-->


        <!-- Summary Container   -->
        <div class="summaryDetails hideForm">
          <h4>Total Theft of Vehicle Claim</h4>
          <!--  Policy Details  -->
          <section class="singleDetail policyDetails">
            <h4>Policy Details</h4>
            <p>Policy No. : {{totalTheftClaim.claimDetails.policyNumber}}</p>

          </section>
          <!--END  Policy Details  -->

          <!-- <section class="singleDetail vehicleDetails">
            <div class="detailHeader">
              <h4>Vehicle Details</h4>
            </div>
            <div class="detailContent">
              <p>KBD 675F</p>

              <p>Subaru Outback, 2500 CC</p>
              <p>KES 1,500,000</p>
            </div>
          </section> -->

          <!-- Driver Details   -->
          <section class="singleDetail driverDetails">
            <div class="detailHeader">
              <h4>Driver Details</h4>
              <!-- <button mat-icon-button>
                <fa-icon class="iconPosition" icon="edit"></fa-icon>
              </button> -->
              <div class="detailContent">
                <p>Driver : {{aboutTheftname.value}}</p>
                <p>ID : {{aboutTheftidNumber.value}}</p>
                <p>Phone : {{aboutTheftphoneNumber.value}}</p>
                <p>Email : {{aboutTheftemail.value}}</p>
              </div>
            </div>

          </section>
          <!--END  Driver Details  -->

          <!--  About Theft  -->
          <section class="singleDetail theftDetails">
            <div class="detailHeader">
              <h4 class="inactive">Details before theft</h4>
              <!-- <button mat-icon-button>
                <fa-icon class="iconPosition" icon="edit"></fa-icon>
              </button> -->
            </div>
            <div class="detailContent">

              <p>Hire Purchase : {{isThereHigherPurchaseInterest.value === 'true' ? "Yes" : "No"}}</p>
              <p>Place of purchase : {{placeOfCarPurchase.value}}</p>
              <p>Sole owner : {{areYouTheSoleOwner.value === 'true' ? "Yes" : "No"}}</p>
              <p>Date of purchase : {{dateOfPurchase.value | date : 'fullDate'}}</p>
            </div>
          </section>
          <!--END About Theft   -->

          <!--  Details Before Theft  -->
          <section class="singleDetail theftDetails">
            <div class="detailHeader">
              <h4 class="inactive">About the Theft</h4>
              <!-- <button mat-icon-button>
                <fa-icon class="iconPosition" icon="edit"></fa-icon>
              </button> -->
            </div>
            <div class="detailContent">
              <p>Tracking device : {{vehicleHasTrackingDevice.value === 'true' ? "Yes" : "No"}}</p>
              <p>Vehicle locked : {{wasVehicleLocked.value === 'true' ? "Yes" : "No"}}</p>
              <p>Modifications : {{anySpecialFitments.value === 'true' ? "Yes" : "No"}}</p>
              <p>Special fitments : {{anyModification.value === 'true' ? "Yes" : "No"}}</p>
              <p>Mileage at loss : {{estimatedMileage.value !== "" ? estimatedMileage.value : "0" }} Km</p>

            </div>
          </section>
          <!--END Details Before Theft   -->



          <section class="singleDetail reportDetails">
            <h4 class="inactive">Police Report</h4>
          </section>

        </div>
        <!--END  Summary Container  -->
  </div>
  <!--End of Total Theft Vehicle Claim-->

  <!--Theft Recovery Vehicle Claim-->
  <div class="theftRecoveryVehicle claimType">

    <!--About Theft Form-->
    <form
      action=""
      #aboutTheft="ngForm"
      class="aboutTheft shadow multistep">

      <div class="formHeader">
        <h4><span class="yellowLine">Tell us</span> about the theft</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">When did it happen?</label>
          <div class="half halfLeft fl mr-0 ml-0 width-50">

          <label
            for=""
            class="dateLabel cursor"
            *ngIf="theftRecoveryClaim?.claimDetails?.date !=='' "
            (click)="picker7.open()">
              {{ theftRecoveryClaim.claimDetails.date | date: 'fullDate' }}
          </label>

          <input
            matInput
            [matDatepicker]="picker7"
            placeholder="DD-MM-YYYY"
            class="inputLeft"
            name="date"
            id="date"
            data-key="theftRecoveryVehicle"
            (click)="picker7.open()"
            (dateChange)="onDateChange($event)"
            #totalTheftDate="ngModel"
            [(ngModel)]="theftRecoveryClaim.claimDetails.date"
            [value]="totalTheftDate"
            [max]="maxDate"
            required />

          <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
          <mat-datepicker #picker7></mat-datepicker>

          <div *ngIf="totalTheftDate.invalid">
            <label
            class="invalid-field"
            *ngIf="totalTheftDate.invalid">
               Date of theft required
            </label>
          </div>

        </div>

        <div class="half halfRight fl mr-0 ml-0 width-50">

          <select
            name ="dateTime"
            class="inputRight"
            (change)="onTimeChange($event)"
            [(ngModel)]="eventTime"
            #totalTheftTime="ngModel"
            required>
            <option value="">Select Time</option>
              <option
                  *ngFor = "let time of timeListArray"
                  [value] = "time.value">
                  {{time.viewValue}}
              </option>
          </select>

          <div *ngIf="totalTheftTime.invalid">
            <label
            class="invalid-field"
            *ngIf="totalTheftTime.errors.required">
              Time of theft required
            </label>
          </div>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Where did it happen?</label>
        <div class="full_width">

          <mat-google-maps-autocomplete
            country="ke"
            type="address"
            class="location"
            required
            (onAutocompleteSelected)="onAutocompleteSelected($event,'theftLocation')"
            (onLocationSelected)="onLocationSelected($event,'theftLocation')"
            required>
          </mat-google-maps-autocomplete>

          <fa-icon class="iconPosition" icon="map-marker-alt">
          </fa-icon>
        </div>
      </div>
      <!-- End of Form Row-->

      <div class="btnFooter">
        <button
          class="readMoreYellow"
          (click)="handleProgression('driverDetailsInfo');"
          [disabled]="aboutTheft.invalid || totalTheftDate.invalid">
          Save &amp; Continue
          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleBackProgression('welcomeClaim');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>
      </div>

    </form>
    <!--END About Theft Form-->

    <!--Driver Details Info Form-->
    <form
      action=""
      class="driverDetailsInfo shadow multistep"
      #driverDetailsInfo="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Who was</span> driving your car at the time of the theft?</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <mat-radio-group
            aria-label="Select an option"
            name="driver"
            data-key="theftRecoveryVehicle"
            (change)="onClaimDetailsRadioChange($event);"
            [(ngModel)]="theftRecoveryClaim.claimDetails.driver"
            #driverTheft = "ngModel">
            <mat-radio-button value="self">Self</mat-radio-button>
            <mat-radio-button value="other-driver">Other Driver</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">Please enter name of driver</label>
          <input
            type="text"
            name="name"
            placeholder="Enter name"
            [(ngModel)]="theftRecoveryClaim.claimDetails.driverDetails.name"
            #driverNameTheft="ngModel"
            minlength="3"
            data-key="driverDetails"
            (change)="onChange($event);"
            required />

            <div
            class="invalid-field"
            *ngIf="driverNameTheft.invalid">

                <label
                *ngIf="driverNameTheft.errors.required"
                class="invalid-field">Driver name is required
                </label>

                <label *ngIf="driverNameTheft.errors.minlength"
                class="invalid-field">Driver name Too Short</label>

            </div>
        </div>

        <div class="half fl ml-3 mr-0">
          <label for="">Mobile Number</label>
          <input
          type="text"
          placeholder="Enter Number"
          name="phoneNumber"
          [(ngModel)]="theftRecoveryClaim.claimDetails.driverDetails.phoneNumber"
          #driverMobileNo = "ngModel"
          data-key="driverDetails"
          (change)="onChange($event);"
          pattern="^(?:254|\\+254|0)((?:7|1)[0-9]{8})$"
          required />

          <div
              class="invalid-field"
              *ngIf="driverMobileNo.invalid">

              <label
                *ngIf="driverMobileNo.errors.required"
                class="invalid-field">Phone Number is required
              </label>

              <label *ngIf="driverMobileNo.errors.pattern"
                class="invalid-field">Phone Number is invalid</label>

          </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">ID Number</label>
          <input
            type="text"
            placeholder="Enter Number"
            name="idNumber"
            pattern="\d*"
            minlength="4"
            maxlength="8"
            data-key="driverDetails"
            (change)="onChange($event);"
            [(ngModel)]="theftRecoveryClaim.claimDetails.driverDetails.idNumber"
            #driverIDNumber = "ngModel"
            required />

            <div
            class="invalid-field"
            *ngIf="driverIDNumber.invalid">

                <label
                *ngIf="driverIDNumber.errors.required"
                class="invalid-field">National Id is required
                </label>

                <label *ngIf="driverIDNumber.errors.maxlength"
                class="invalid-field">National Id Too Long</label>

                <label *ngIf="driverIDNumber.errors.minlength"
                class="invalid-field">National Id Too Short</label>

                <label *ngIf="driverIDNumber.errors.pattern"
                class="invalid-field">National Id should only contain numbers</label>

            </div>

        </div>

        <div class="half fl ml-3 mr-0">
          <label for="">Email Address</label>
          <input
            type="text"
            placeholder="Enter Email Address"
            name="email"
            (change)="onChange($event);"
            [(ngModel)]="theftRecoveryClaim.claimDetails.driverDetails.email"
            data-key="driverDetails"
            #driverEmailAddress = "ngModel"
            required/>

          <div
            class="invalid-field"
            *ngIf="driverEmailAddress.invalid">

            <label
              *ngIf="driverEmailAddress.errors.required"
              class="invalid-field">Email Address is required
            </label>

          </div>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <div class="half fl">
          <label for="">Did the driver have your permission?</label>
          <mat-radio-group
            aria-label="Select an option"
            name="driverHavePermission"
            [(ngModel)]="theftRecoveryClaim.claimDetails.driverDetails.driverHavePermission"
            #driverHavePermission = "ngModel"
            (change)="onDriverPermissionRadioChange($event)" >
            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false" checked>NO</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Upload Copy of Driving License</label>

        <div class="full_width uploadContainer bg-grey">

            <input
              type="file"
              id="drivingLicense"
              [ngModel]="fileContent"
              #drivingLicense ="ngModel"
              accept=".pdf,.jpeg,.jpg,.png"
              data-extension="multimedia"
              name="copyOfDriverLicense"
              (change)="onFileChange($event);"
              required />

            <label for="drivingLicense" class="icon-section">
              <p>Upload Driving License</p>
            </label>

            <fa-icon icon="upload" class="arrow upload"></fa-icon>

        </div>

        <div
        class="invalid-field"
        *ngIf="drivingLicense.invalid"
      >
        <label *ngIf="drivingLicense.invalid" class="invalid-field"
          >Driving License pdf required
        </label>

      </div>

      </div>
      <!-- End of Form Row-->
      <div class="btnFooter">
        <button
          class="readMoreYellow"
          [disabled]="driverDetailsInfo.invalid"
          (click)="handleProgression('carAfterRecovery');">
          Save &amp; Continue

          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleProgression('aboutTheft');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>
      </div>

    </form>
    <!--END Driver Details Info Info Form-->

    <!--Car After Recovery Form-->
    <form action=""
      class="carAfterRecovery shadow multistep"
      #carAfterRecovery ="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Please provide </span>details of vehicle after recovery</h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">

        <div class="half fl">
          <label for="">Date of recovery</label>

          <label
            for=""
            class="dateLabel cursor"
            (click)="incidentDatePicker.open()"
            *ngIf="theftRecoveryClaim?.claimDetails?.vehicleDetailsAfterRecovery?.dateOfRecovery !=='' ">
            {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.dateOfRecovery | date: 'fullDate' }}
          </label>



          <input
            [matDatepicker]="recoveryDate"
            (click)="recoveryDate.open();"
            placeholder="DD-MM-YYYY"
            [max]="maxDate"
            name="dateOfRecovery"
            (dateChange)="onDateChange($event);"
            [(ngModel)]="theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.dateOfRecovery"
            #dateRecovery="ngModel"
            data-key="theftRecoveryVehicle"
            required />

          <mat-datepicker-toggle matSuffix [for]="recoveryDate"></mat-datepicker-toggle>
          <mat-datepicker #recoveryDate>
          </mat-datepicker>

          <div *ngIf="dateRecovery.invalid">
            <label
            class="invalid-field"
            *ngIf="dateRecovery.invalid">
                Date of recovery required
            </label>
          </div>

        </div>

        <div class="half fl">
          <label for="">Mileage at recovery (KM)</label>
          <input
            name="milageOfRecovery"
            type="number"
            min = 0
            max = 100000
            data-key="theftRecoveryDetails"
            placeholder="Enter Mileage (KM)"
            (change)="onChange($event)"
            [(ngModel)]="theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.milageOfRecovery"
            #mileageRecovery="ngModel"
            required />

            <div *ngIf="mileageRecovery.invalid">

              <label
              class="invalid-field"
              *ngIf="mileageRecovery.errors.required">
                  Mileage at recovery required
              </label>


            </div>
        </div>

      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Place of recovery</label>
        <div class="full_width">

          <mat-google-maps-autocomplete
                country="ke"
                type="address"
                class="location"
                required
                (onAutocompleteSelected)="onAutocompleteSelected($event, 'locationRecovery')"
                (onLocationSelected)="onLocationSelected($event,'locationRecovery')"
                required>
        </mat-google-maps-autocomplete>


          <fa-icon class="iconPosition" icon="map-marker-alt">
          </fa-icon>
        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Where can the vehicle be inspected?</label>
        <div class="full_width">

          <mat-google-maps-autocomplete
                country="ke"
                type="address"
                class="location"
                required
                (onAutocompleteSelected)="onAutocompleteSelected($event, 'vehicleInspectionLocation')">
        </mat-google-maps-autocomplete>

          <fa-icon class="iconPosition" icon="map-marker-alt">
          </fa-icon>
        </div>
      </div>
      <!-- End of Form Row-->


      <div class="btnFooter">
        <button
          class="readMoreYellow"
          (click)="handleProgression('carAtRecovery');"
          [disabled]="carAfterRecovery.invalid">
          Save &amp; Continue
          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
        </button>

        <button class="readMoreWhiteSolid back fl" (click)="handleProgression('driverDetailsInfo');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>

      </div>

    </form>
    <!--END Car After Recovery Form-->

    <!--Damage to Vehicle at Time of Recovery Form-->
    <form
      action=""
      class="carAtRecovery shadow multistep"
      #carAtRecovery="ngForm">

      <div class="formHeader">
        <h4><span class="yellowLine">Please provide </span>details of any damage to the vehicle at the time of recovery
        </h4>
      </div>

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">
        <label for="">Describe the vehicle parts or accessories damaged</label>
        <div class="full_width">
          <textarea
            type="text"
            placeholder="Side Mirrors, Head lamps, Spare Wheel Cover"
            name="descriptionOfAnyDamage"
            data-key="theftRecoveryDetails"
            (change)="onChange($event);"
            [(ngModel)]="theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.descriptionOfAnyDamage"
            #descriptionOfAnyDamage="ngModel"
            required>
          </textarea>

          <div
          class="invalid-field"
          *ngIf="descriptionOfAnyDamage.invalid">

          <label
            *ngIf="descriptionOfAnyDamage.errors.required"
            class="invalid-field">
            Parts or accessories damaged description required
          </label>

        </div>

        </div>
      </div>
      <!-- End of Form Row-->

      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->

      <!--  Form Row  -->
      <div class="row marginTopBottom-40">

        <div class="half fl">
          <label for="">Has any item been replaced?</label>
          <mat-radio-group
            aria-label="Select an option"
            name="hasItemBeenReplaced"
            (change)="onClaimDetailsRadioChange($event);"
            [(ngModel)]="theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.hasAnyItemBeenReplaced"
            #hasItemBeenReplaced="ngModel"
            required>

            <mat-radio-button value="true">YES</mat-radio-button>
            <mat-radio-button value="false">NO</mat-radio-button>

          </mat-radio-group>

          <div
            class="invalid-field"
            *ngIf = "hasItemBeenReplaced.invalid">

            <label *ngIf = "hasItemBeenReplaced.invalid" class="invalid-field">
              Please select the option above
            </label>

          </div>
        </div>

      </div>
      <!-- End of Form Row-->

      <!--  Form Group  -->
      <div *ngIf = "hasItemBeenReplaced.value === 'true' " class="formGroup">

        <div class="formGroup" *ngFor="let item of theftReplacedItemsArray; let i=index;">

          <div class="row">

            <h4>Replaced Item {{i+1}}</h4>

            <button
              class="closer"
              type="button"
              (click)="removeReplacedItem(i); calculateTotalCost();"
              *ngIf="theftReplacedItemsArray.length>1"
              >
              X
            </button>

            <div class="half fl">
              <label for="">Estimate cost of replacement (KES)</label>
              <input
                placeholder="Enter Amount"
                type="text"
                name="replacement{{i+1}}estimatedCost"
                id="replacement{{i+1}}estimatedCost"
                pattern="\d*"
                min="1"
                [(ngModel)]="theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems[i]['estimatedCost']"
                #estimateReplacementCost="ngModel"
                (change)="onReplacedItemChange($event,i); calculateTotalCost()"
                required>

                  <div
                  class="invalid-field"
                  *ngIf="estimateReplacementCost.invalid"
                >

                  <label *ngIf="estimateReplacementCost.errors.required" class="invalid-field">
                    Estimate cost of replacement required
                  </label>

                  <label *ngIf="estimateReplacementCost.errors.pattern" class="invalid-field">
                    Estimated amount should only be a number
                  </label>

                  <label *ngIf="estimateReplacementCost.errors.minlength">
                    Estimated amount should be more than 0
                  </label>

                </div>
              </div>

            <div class="half fl">
                <label for="">Date of purchase</label>

                <label
                  for=""
                  class="dateLabel cursor"
                  (click)="picker6.open()"
                  *ngIf="theftRecoveryClaim?.claimDetails?.vehicleDetailsAfterRecovery?.replacedItems?.dateOfPurchase !=='' ">
                  {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems[i].dateOfPurchase| date: 'fullDate' }}
                </label>

                <input
                    matInput
                    [matDatepicker]="picker6"
                    placeholder="DD-MM-YYYY"
                    name="replacement{{i+1}}dateofPurchase"
                    id="replacement{{i+1}}dateofPurchase"
                    (click)="picker6.open()"
                    (dateChange)="onDatePurchaseChange($event,i);"
                    [(ngModel)]="theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems[i]['dateOfPurchase']"
                    [max]="maxDate"
                    #dateofPurchase = "ngModel"
                    data-key="theftRecoveryVehicle"
                    required />

                <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                <mat-datepicker #picker6>
                </mat-datepicker>

                <div
                class="invalid-field"
                *ngIf="dateofPurchase.invalid"
              >

                <label *ngIf="dateofPurchase.errors.required" class="invalid-field"
                  >Date of purchase required
                </label>

              </div>
            </div>

          </div>

        </div>

        <div class="row marginTopBottom-40" *ngIf="theftReplacedItemsArray.length < 5">
          <button
            class="addButton full_width"
            (click)="addAnotherReplacedItem();">
            Add replaced part
            <fa-icon class="iconPosition" icon="plus"></fa-icon>
          </button>
        </div>

        <div class="row marginTopBottom-40">
          <label for="">Upload Compilation of Receipts</label>
          <div class="full_width uploadContainer bg-grey">
            <form
              #receiptsCompilationForm = "ngForm">

              <input
                type="file"
                id="uploadreplacementreceipts"
                name="uploadreplacementreceipts"
                [ngModel]="fileContent"
                #uploadreplacementreceipts="ngModel"
                accept=".pdf,.jpg,.png,.jpeg"
                data-extension="multimedia"
                multiple
                required
                (change)="onFileChange($event);"/>

              <label for="uploadreplacementreceipts" class="icon-section">
                <p>Upload Receipts</p>
              </label>

              <fa-icon icon="upload" class="arrow upload"></fa-icon>

            </form>

          </div>

            <div
            class="invalid-field"
            *ngIf="uploadreplacementreceipts.invalid"
            >

            <label *ngIf="uploadreplacementreceipts.errors.required" class="invalid-field"
              >Compilation of receipt is required
            </label>

          </div>

        </div>
        <!-- End of Form Row-->


      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->

        <div class="btnFooter">
          <button
            class="readMoreYellow"
            [disabled]="carAtRecovery.invalid || uploadreplacementreceipts.invalid"
            (click)="handleProgression('policeReport');">
            Save &amp; Continue
            <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
          </button>

          <button
            class="readMoreWhiteSolid back fl"
            (click)="handleProgression('carAfterRecovery');">
            <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
            Back
          </button>

        </div>

      </div>
      <!-- End of Form Group-->


      <!--  Form Group  -->
      <div *ngIf="hasItemBeenReplaced.value === 'false' " class="formGroup">

        <div class="row marginTopBottom-40">
          <div class="full_width">
            <label for="">Select a garage from our panel</label>
            <select
              name="replacementGarage"
              [(ngModel)]="theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacementGarage"
              #replacementGarage="ngModel"
              data-key="theftRecoveryDetails"
              (change)="onChange($event);"
              required>
              <option value="">Select a garage</option>
              <option *ngFor="let garage of generalGarages"  value="{{garage.name | titlecase }}">{{garage.name | titlecase}} - {{garage.location | titlecase}}</option>
            </select>

            <div
            class="invalid-field"
            *ngIf="replacementGarage.invalid"
            >

              <label *ngIf="replacementGarage.errors.required" class="invalid-field"
                >
                Please select a garage from options above
              </label>

            </div>

          </div>

        </div>


      <!--  Row  -->
      <div class="row">
        <div class="dashedLine"></div>
      </div>
      <!-- End of Row-->

      <div class="btnFooter">
        <button
          class="readMoreYellow"
          [disabled]="carAtRecovery.invalid"
          (click)="handleProgression('policeReport');">
          Save &amp; Continue
          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
        </button>

        <button
          class="readMoreWhiteSolid back fl"
          (click)="handleProgression('carAfterRecovery');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>

      </div>

      </div>
      <!-- End of Form Group-->

<!--
      <div class="btnFooter">
        <button
          class="readMoreYellow"
          [disabled]="carAtRecovery.invalid"
          (click)="handleProgression('policeReport');">
          Save &amp; Continue
          <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
        </button>

        <button
          class="readMoreWhiteSolid back fl"
          (click)="handleProgression('carAfterRecovery');">
          <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
          Back
        </button>

      </div> -->

    </form>
    <!--END Damage to Vehicle at Time of Recovery Form-->

    <!--Police Report Form-->
    <form
      action=""
      class="policeReport shadow multistep"
      #policeReport = "ngForm">

          <div class="formHeader">
            <h4><span class="yellowLine">Have you</span> reported the theft to the Police?</h4>
          </div>

          <!--  Form Row  -->
          <div class="row marginTopBottom-40">
            <div class="half fl">
              <mat-radio-group
                aria-label="Select an option"
                name="theftReportedToPlace"
                [(ngModel)]="theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportedToPlace"
                #theftReportedToPlace = "ngModel"
                (change)="onClaimDetailsRadioChange($event)">
                <mat-radio-button value="true">YES</mat-radio-button>
                <mat-radio-button value="false">NO</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <!-- End of Form Row-->

      <!-- FormGroup-->
      <div class="formGroup" *ngIf="theftReportedToPlace.value === 'true' ">
        <!--  Form Row  -->
        <div class="row marginTopBottom-40">
          <div class="half fl">
            <label for="">Police Station where theft was reported</label>
            <input
              matInput
              matGoogleMapsAutocomplete
              country="ke"
              type="address"
              class="location"
              required
              (onAutocompleteSelected)="onAutocompleteSelected($event, 'policeStationLocation')"
              >

            <fa-icon class="iconPosition" icon="map-marker-alt"></fa-icon>
          </div>

          <div class="half fl ml-3 mr-0">
            <label for="">OB Number</label>
            <input
                type="text"
                name="AOBNumber"
                placeholder="Enter Number"
                [(ngModel)]="theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.OBNumber"
                #OBNumber = "ngModel"
                data-key="theftRecoveryDetails"
                (change)="onChange($event)"
                required />
            <div
                class="invalid-field"
                *ngIf="OBNumber.invalid"
              >

                <label *ngIf="OBNumber.errors.required" class="invalid-field"
                  >Abstract OB required
                </label>

            </div>
          </div>
        </div>
        <!-- End of Form Row-->

        <!--  Form Row  -->
        <div class="row marginTopBottom-40">

          <label for="">When did you report the theft?</label>

            <div class="half halfLeft fl mr-0 ml-0 width-50">

            <label
              for=""
              class="dateLabel cursor"
              (click)="incidentDatePicker.open()"
              *ngIf="theftRecoveryClaim?.claimDetails?.vehicleDetailsAfterRecovery?.theftReportDetails?.theftReportedDate !=='' ">
              {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.theftReportedDate | date: 'fullDate' }}
            </label>

            <input
              matInput
              [matDatepicker]="picker10"
              placeholder="DD-MM-YYYY"
              class="inputLeft"
              name="theftReportedDate"
              (click)="picker10.open()"
              data-key="theftRecoveryVehicle"
              (dateChange)="onDateChange($event)"
              [(ngModel)]="theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.theftReportedDate"
              #theftReportedDate="ngModel"
              [value]="theftReportedDate"
              [max]="maxDate"
              required />

            <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
            <mat-datepicker #picker10></mat-datepicker>

            <div *ngIf="theftReportedDate.invalid">
              <label
              class="invalid-field"
              *ngIf="theftReportedDate.invalid">
              Date of theft is required
              </label>
          </div>

          </div>

          <div class="half halfRight fl mr-0 ml-0 width-50">

            <select
              name ="theftReportedDateTime"
              class="inputRight"
              data-key="theftRecoveryVehicle"
              (change)="onTimeChange($event)"
              ngModel
              #theftReportedDateTime="ngModel"
              required>
              <option value="">Select the time</option>
                <option
                    *ngFor = "let time of timeListArray"
                    [value] = "time.value">
                    {{time.viewValue}}
                </option>
            </select>

            <div
            class="invalid-field"
            *ngIf="theftReportedDateTime.invalid"
          >

            <label *ngIf="theftReportedDateTime.errors.required" class="invalid-field"
              >Time is required
            </label>

        </div>

          </div>
        </div>
        <!-- End of Form Row-->

          <!--  Row  -->
          <div class="row">
            <div class="dashedLine"></div>
          </div>
          <!-- End of Row-->

          <!--  Form Row  -->
          <div class="row marginTopBottom-40">
            <label for="">Upload Police Abstract (Within 14 Days)</label>
            <div class="full_width uploadContainer bg-grey">
                <input
                  type="file"
                  id="uploadAbstract"
                  [ngModel]="fileContent"
                  #uploadAbstract ="ngModel"
                  accept=".pdf,.jpg,.png"
                  data-extension="multimedia"
                  (change)="onFileChange($event);"
                  name="policeAbstractDoc"
                  required/>
                <label for="uploadAbstract" class="icon-section">
                  <p>Add Police Abstract</p>
                </label>
                <fa-icon icon="upload" class="arrow upload"></fa-icon>

            </div>

            <div
              class="invalid-field"
              *ngIf="uploadAbstract.invalid">

              <label *ngIf="uploadAbstract.errors.required" class="invalid-field">
                Police Abstract is required
              </label>

            </div>
          </div>
          <!-- End of Form Row-->

        </div>
        <!-- End of FormGroup-->

          <div class="btnFooter">
            <button
              class="readMoreYellow"
              (click)="handleProgression('insuranceSummary');"
              [disabled]="policeReport.invalid">
              Save &amp; Finish
              <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>

            </button>

            <button class="readMoreWhiteSolid back fl" (click)="handleProgression('carAtRecovery');">
              <fa-icon class="arrow" icon="arrow-circle-left"></fa-icon>
              Back
            </button>
          </div>
        </form>
        <!--END Police Report Form-->

    <!-- Summary Container   -->
    <div class="summaryDetails hideForm">

          <!--  Policy Details
          <section class="singleDetail policyDetails">
            <h4>Policy Details</h4>
            <p>Policy No. - 12379</p>
            <p>Expiry - June 14th 2022</p>
          </section>

          END  Policy Details

          <section class="singleDetail vehicleDetails">
            <div class="detailHeader">
              <h4>Vehicle Details</h4>
            </div>
            <div class="detailContent">
              <p>KBD 675F</p>
              <p>2015</p>
              <p>Subaru Outback, 2500 CC</p>
              <p>KES 1,500,000</p>
            </div>
          </section>
        -->


          <section class="singleDetail theftDetails">
            <h4>Theft and Recovery of Vehicle Claim</h4>
            <div class="detailHeader">
              <h4>About the Theft</h4>
              <button mat-icon-button>
                <fa-icon class="iconPosition" icon="edit"></fa-icon>
              </button>
            </div>
            <div class="detailContent">
              <p>Date of Theft: {{totalTheftDate.value | date: 'fullDate' }}</p>
              <p>Location of Theft: {{theftRecoveryClaim.claimDetails.theftLocation}}</p>
            </div>
          </section>

          <section class="singleDetail driverDetails">
            <div class="detailHeader">
              <h4>Driver Details</h4>
              <button mat-icon-button>
                <fa-icon class="iconPosition" icon="edit"></fa-icon>
              </button>
            </div>
            <div class="detailContent">
              <p>Name: {{driverNameTheft.value}}</p>
              <p>Phone No.: {{driverMobileNo.value}}</p>
              <p>ID No.: {{driverIDNumber.value}}</p>
              <p>Email: {{driverEmailAddress.value}}</p>
            </div>
          </section>

          <section class="singleDetail carRecoveryDetails">
            <div class="detailHeader">
              <h4>Recovery Details</h4>
              <button mat-icon-button>
                <fa-icon class="iconPosition" icon="edit"></fa-icon>
              </button>
            </div>
            <div class="detailContent">
              <p>Date of Recovery: {{dateRecovery.value | date: 'fullDate'}}</p>
              <p>Mileage at Recovery(KM): {{mileageRecovery.value}}</p>
              <p>Place of Recovery: {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.placeOfRecovery}}</p>
              <p>Place of Inspection: {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.placeOfInspection}}</p>
            </div>
          </section>

          <section class="singleDetail carDamageDetails">
            <div class="detailHeader">
              <h4>Damage Details</h4>
              <button mat-icon-button>
                <fa-icon class="iconPosition" icon="edit"></fa-icon>
              </button>
            </div>

            <div class="detailContent">
              <div *ngIf="hasItemBeenReplaced.value === 'true'">

                <div *ngFor="let item of theftReplacedItemsArray; let i=index;">
                  <h5>Replaced Item {{i+1}}</h5>
                  <p>Cost of Replacement: {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems[i]['estimatedCost'] | currency : 'KES ' : 'code' : '1.0-0'}}</p>
                  <p>Date of Purchase: {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems[i]['dateOfPurchase'] | date : 'fullDate'}}</p>
                </div>

              </div>

              <div *ngIf="hasItemBeenReplaced.value === 'false'">
                <p>Garage Name: {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacementGarage}}</p>
              </div>
            </div>

          </section>

          <section class="singleDetail policeReport">
            <div *ngIf="theftReportedToPlace.value === 'true' ">

              <div class="detailHeader">
                <h4>Police Report</h4>
                <button mat-icon-button>
                  <fa-icon class="iconPosition" icon="edit"></fa-icon>
                </button>
              </div>

              <div class="detailContent">
                  <p>Police Station: {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.policeStation}}</p>
                  <p>OB Number: {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.OBNumber}}</p>
                  <p>Date of Report: {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.theftReportedDate | date: 'fullDate'}}</p>
              </div>

            </div>


          </section>

        </div>
        <!--END  Summary Container  -->

  </div>

  </section>

<!--Claim Summary-->
<div class="centerAligner shadow rounded insuranceSummary hideForm">
  <section class="claimSection">

    <div class="claimHeader">
      <h1><span class="yellowLine">Here is a</span> summary of your insurance claim.</h1>
    </div>

    <div class="summaryContent">

      <div class="row">
        <h4 class="left">Type of claim</h4>
        <h4 class="right">{{selectedClaim}}</h4>
      </div>

      <!--Selected Claim: Theft Recovery-->
      <div *ngIf="selectedClaim === 'theftRecoveryVehicle'">

        <div class="row">
          <p class="left">Policy Number:</p>
          <p class="right">{{theftPartsClaim.claimDetails.policyNumber}}</p>
        </div>

        <div class="row">
          <p class="left">Name</p>
          <p class="right">{{driverNameTheft.value}}</p>
        </div>

        <div class="row">
          <p class="left">Mobile Number</p>
          <p class="right">{{driverMobileNo.value}}</p>
        </div>

        <div class="row">
          <p class="left">ID Number</p>
          <p class="right">{{driverIDNumber.value}}</p>
        </div>

        <div class="row">
          <p class="left">Email Address</p>
          <p class="right">{{driverEmailAddress.value}}</p>
        </div>

        <div class="row">
          <p class="left">Date of Vehicle Recovery</p>
          <p class="right"> {{dateRecovery.value | date : 'fullDate'}}</p>
        </div>

        <div class="row">
          <p class="left">Mileage at Recovery(KM)</p>
          <p class="right"> {{mileageRecovery.value}}</p>
        </div>

        <div class="row">
          <p class="left">Place of Recovery</p>
          <p class="right"> {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.placeOfRecovery}}</p>
        </div>

        <div class="row">
          <p class="left">Place of Inspection</p>
          <p class="right"> {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.placeOfInspection}}</p>
        </div>

        <div *ngIf="hasItemBeenReplaced.value === 'true'">
          <div class="row" *ngFor="let item of theftReplacedItemsArray; let i=index;">
            <p class="left">Estimated Cost of Replacement for Item {{i+1}}:</p>
            <p class="right"> {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacedItems[i]['estimatedCost'] | currency: 'KES ' : 'code' : '1.0-0'}}</p>
          </div>
        </div>

        <div class="row" *ngIf="hasItemBeenReplaced.value === 'true'">
            <p class="left">Total Cost of Replacement for Items</p>
            <p class="right"><u>{{totalRepairsCost | currency: 'KES ' :'code' :'1.0-0'}}</u></p>
        </div>

        <div class="row" *ngIf="hasItemBeenReplaced.value === 'false'">
          <p class="left">Garage Name</p>
          <p class="right"> {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.replacementGarage}}</p>
        </div>

        <div class="row" *ngIf="theftReportedToPlace.value === 'true'">
          <p class="left">Police Station of Theft Report</p>
          <p class="right"> {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.policeStation}}</p>
        </div>

        <div class="row" *ngIf="theftReportedToPlace.value === 'true'">
          <p class="left">Date of Report</p>
          <p class="right"> {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.theftReportedDate | date : 'fullDate'}}</p>
        </div>

        <div class="row" *ngIf="theftReportedToPlace.value === 'true'">
          <p class="left">OB Number</p>
          <p class="right"> {{theftRecoveryClaim.claimDetails.vehicleDetailsAfterRecovery.theftReportDetails.OBNumber}}</p>
        </div>

      </div>
      <!--End of Selected Claim: Theft Recovery-->

      <!--Selected Claim: Damage Vehicle-->
      <div *ngIf="selectedClaim === 'damageVehicle'">

        <div class="row">
          <p class="left">Policy Number</p>
          <p class="right">{{damageClaim?.claimDetails?.policyNumber}}</p>
        </div>

        <div class="row">
          <p class="left">Name</p>
          <p class="right">{{driverName.value}}</p>
        </div>

        <div class="row">
          <p class="left">Mobile Number</p>
          <p class="right">{{driverPhoneNo.value}}</p>
        </div>

        <div class="row">
          <p class="left">ID Number</p>
          <p class="right">{{idNumber.value }}</p>
        </div>

        <div class="row">
          <p class="left">KRA Pin</p>
          <p class="right">{{kraPin.value }}</p>
        </div>

        <div class="row">
          <p class="left">Business/Profession</p>
          <p class="right"> {{occupation.value}}</p>
        </div>

        <div class="row">
          <p class="left">Passengers</p>
          <p class="right"> {{damageClaim?.claimDetails?.passangersInVehicle.length}}</p>
        </div>

        <div class="row">
          <p class="left">Injured passengers</p>
          <p class="right"> {{damageClaim?.claimDetails?.injuredPassangers.length}}</p>
        </div>

        <div class="row">
          <p class="left">Damaged Vehicles</p>
          <p class="right"> {{damageClaim?.claimDetails?.damagedVehicles.length}}</p>
        </div>

        <div class="row">
          <p class="left">Damaged Properties</p>
          <p class="right"> {{damageClaim?.claimDetails?.propertiesDamaged.length}}</p>
        </div>

        <div class="row">
          <p class="left">Witnesses</p>
          <p class="right"> {{damageClaim?.claimDetails?.independentWitnesses.length}}</p>
        </div>

        <div class="row" *ngIf="reportedAccidentToPolice.value === 'true'">
          <p class="left">Police Station of Accident Report </p>
          <p class="right"> {{damageClaim?.claimDetails?.policeSTation}}</p>
        </div>

        <div class="row" *ngIf="reportedAccidentToPolice.value === 'true'">
          <p class="left">OB Number</p>
          <p class="right"> {{damageClaim?.claimDetails?.AOBNumber}}</p>
        </div>

      </div>
      <!--End of Selected Claim: Damage Vehicle-->

      <!--Selected Claim: Windscreen Damage-->
      <div *ngIf="selectedClaim === 'windowDamage'">

        <div class="row">
          <p class="left">Policy Number</p>
          <p class="right">{{windscreenDamageClaim.claimDetails.policyNumber}}</p>
        </div>

        <div class="row">
          <p class="left">Date of Damage</p>
          <p class="right">{{windscreenDamageClaim.claimDetails.date | date: 'fullDate'}}</p>
        </div>

        <div class="row">
          <p class="left">Location of Damage</p>
          <p class="right">{{windscreenDamageClaim.claimDetails.damagePlace}}</p>
        </div>

        <div *ngIf="hasWindscreenBeenReplaced.value === 'true'">
          <div class="row">
            <p class="left">Cost of Replacement</p>
            <p class="right"> {{windscreenDamageClaim.claimDetails.estimatedReplacementCost | currency : 'KES ' : 'code' : '1.0-0'}}</p>
          </div>

          <div class="row">
            <p class="left">Name of Garage</p>
            <p class="right"> {{windscreenDamageClaim.claimDetails.nameOfGarage}}</p>
          </div>

        </div>

        <div *ngIf="hasWindscreenBeenReplaced.value === 'false'">
          <div class="row">
            <p class="left">Name of Garage</p>
            <p class="right"> {{windscreenDamageClaim.claimDetails.nameOfGarage}}</p>
          </div>
        </div>

      </div>
      <!--End of Selected Claim: Windscreen Damage-->

      <!--Selected Claim: Theft Vehicle Parts Damage-->
      <div *ngIf="selectedClaim === 'theftVehicleParts'">

        <div class="row">
          <p class="left">Policy Number:</p>
          <p class="right">{{theftPartsClaim.claimDetails.policyNumber}}</p>
        </div>

        <div class="row">
          <p class="left">Date of Theft:</p>
          <p class="right">{{theftPartsClaim.claimDetails.dateTime | date: 'fullDate'}}</p>
        </div>

        <div class="row">
          <p class="left">Location of Theft:</p>
          <p class="right">{{theftPartsClaim.claimDetails.theftPlace}}</p>
        </div>

        <div class="row" *ngIf="hasItemBeenReplaced.value === 'true'">
          <p class="left">Replaced Items:</p>
          <p class="right">{{theftRepairsArray.length}}</p>
        </div>

        <div *ngIf="hasAnyItemBeenReplaced.value === 'true' ">

          <div class="row" *ngFor="let item of theftRepairsArray; let i=index;">
            <p class="left">Estimate Cost of Replacement for Item {{i+1}}:</p>
            <p class="right">{{theftPartsClaim.claimDetails.replacedItems[i]['estimatedCost'] | currency: 'KES ' :'code' :'1.0-0'}}</p>
          </div>

          <div class="row">
            <p class="left">Total Cost of Replacement of Items:</p>
            <p class="right"><u>{{totalRepairsCost | currency: 'KES ' :'code' :'1.0-0'}}</u></p>
          </div>

        </div>

        <div *ngIf="hasAnyItemBeenReplaced.value === 'false' ">

          <div class="row">
            <p class="left">Garage Name:</p>
            <p class="right">{{theftPartsClaim.claimDetails.garageName}}</p>
          </div>

        </div>

      </div>
      <!--Selected Claim: Theft Vehicle Parts Damage-->

      <div *ngIf="selectedClaim === 'totaltheftVehicle'">

        <div class="row">
          <p class="left">Policy Number</p>
          <p class="right"> {{totalTheftClaim.claimDetails.policyNumber}}</p>
        </div>

        <div class="row">
          <p class="left">Name of Driver</p>
          <p class="right"> {{totalTheftClaim.claimDetails.driverDetails.name}}</p>
        </div>

        <div class="row">
          <p class="left">Mobile Number</p>
          <p class="right"> {{totalTheftClaim.claimDetails.driverDetails.phoneNumber}}</p>
        </div>

        <div class="row">
          <p class="left">ID Number</p>
          <p class="right"> {{totalTheftClaim.claimDetails.driverDetails.idNumber}}</p>
        </div>

        <div class="row">
          <p class="left">Hire purchase</p>
          <p class="right"> {{isThereHigherPurchaseInterest.value === 'true' ? "Yes" : "No"}}</p>
        </div>

        <div class="row">
          <p class="left">Place of purchase</p>
          <p class="right"> {{placeOfCarPurchase.value}}</p>
        </div>

        <div class="row">
          <p class="left">Sole Owner</p>
          <p class="right">{{areYouTheSoleOwner.value === 'true' ? "Yes" : "No"}}</p>
        </div>

        <div class="row">
          <p class="left">Date of Purchase</p>
          <p class="right"> {{dateOfPurchase.value | date : 'fullDate'}}</p>
        </div>

        <div class="row">
          <p class="left">Tracking Device</p>
          <p class="right"> {{vehicleHasTrackingDevice.value === 'true' ? "Yes" : "No"}}</p>
        </div>

        <div class="row">
          <p class="left">Vehicle Locked</p>
          <p class="right"> {{wasVehicleLocked.value === 'true' ? "Yes" : "No"}}</p>
        </div>

        <div class="row">
          <p class="left">Modifications</p>
          <p class="right">{{anySpecialFitments.value === 'true' ? "Yes" : "No"}}</p>
        </div>

        <div class="row">
          <p class="left">Mileage at Loss (in KMS)</p>
          <p class="right">{{estimatedMileage.value}}</p>
        </div>

        <div *ngIf="theftReportedToPolice.value === 'true'">

          <div class="row">
            <p class="left">Police Station where theft was reported</p>
            <p class="right">{{totalTheftClaim.claimDetails.theftReportDetails.policeStation}}</p>
          </div>

          <div class="row">
            <p class="left">Date of Report</p>
            <p class="right">{{totalTheftClaim.claimDetails.theftReportDetails.theftReportedDate | date : "fullDate"}}</p>
          </div>

        </div>

      </div>

    </div>

    <div class="summaryContent mt-4">

      <!-- <div class="row">
        <p class="left">Registration No.</p>
        <p class="right">KBD 675F</p>
      </div> -->
    </div>

    <div class="btnFooter">

      <button
          class="readMoreYellow"
          [disabled]="loading"
          type="button"
          (click)="submitClaim(damageClaim);"
          *ngIf="selectedClaim === 'damageVehicle' && !loading">
            Submit claim
        <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
      </button>

      <button
          class="readMoreYellow"
          [disabled]="loading"
          type="button"
          (click)="submitClaim(windscreenDamageClaim);"
          *ngIf="selectedClaim === 'windowDamage' && !loading">
            Submit claim
        <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
      </button>

      <button
          class="readMoreYellow"
          [disabled]="loading"
          type="button"
          (click)="submitClaim(theftPartsClaim);"
          *ngIf="selectedClaim === 'theftVehicleParts' && !loading">
            Submit claim
        <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
      </button>


      <button
          class="readMoreYellow"
          [disabled]="loading"
          type="button"
          (click)="submitClaim(totalTheftClaim);"
          *ngIf="selectedClaim === 'totaltheftVehicle' && !loading">
            Submit claim
        <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
      </button>

    <button
        class="readMoreYellow"
        [disabled]="loading"
        type="button"
        (click)="submitClaim(theftRecoveryClaim);"
        *ngIf="selectedClaim === 'theftRecoveryVehicle' && !loading">
          Submit claim
      <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
    </button>

      <!-- <button class="readMoreGrey"
          (click)="handleCancelProgression('welcomeClaim');"> -->
         <button class="readMoreGrey"
             type="button"
             (click)="handleBackProgression('welcomeClaim');"
             *ngIf="!loading" >
                Cancel
                <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
          </button>

      <app-processor
          *ngIf="loading"
          description="{{processorDescription}}" ></app-processor>

    </div>


  </section>
</div>
<!--END Claim Summary-->
