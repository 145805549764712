import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fullwidthheader',
  templateUrl: './fullwidthheader.component.html',
  styleUrls: ['./fullwidthheader.component.css']
})
export class FullwidthheaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
