import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';

import { environment } from '../../../environments/environment.dev';

import {AuthenticationService} from '../authentication.service';

@Injectable({
  providedIn: 'root'
})

export class PolicyService {

    constructor(
        private http:HttpClient,
		private authenticationService:AuthenticationService,
    ){

    }


	// Get listing of all policies under a single user
    getAllPolicies(){

        // return this.http.post<any>(`${environment.quoteApi}quote`, quoteDetails);

    }

	// Get all policies by customer Id
    getPolicyByCustomerId(){

    }

	// Get a single policy by its policyId
    getSinglePolicyById(policyId){

		const headers = new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${this.authenticationService.getUserToken()}`
        });

        return this.http.get<Object[]>(`${environment.customerFacingApi}userProfile/viewPolicy?policyNo=${policyId}`,{'headers' : headers});

    }

	getBenefitsByPolicyId(policyId){

		//post policy/fetchBenefits
		return this.http.post<any>(`${environment.customerFacingApi}policy/fetchBenefits`, {"policyNo": policyId } );


	}

	// Get the sumation of policy cancellation refund
	getPolicyCancellationRefund(policyId){

		//post policy/cancellationRefund

		return this.http.post<any>(`${environment.customerFacingApi}policy/cancellationRefund`, { "policyNo" : policyId });

	}

	// Endorse a policy by submitting additional policy data
	endorsePolicy(policyData:any){

		//post policy/postEndorsement
		return this.http.post<any>(`${environment.customerFacingApi}policy/postEndorsement`, policyData);

	}

	getBenefitEndorsementQuote(quoteDetails){

		//post policy/quoteDetails
		
		return this.http.post<any>(`${environment.customerFacingApi}policy/getBenefitEndorsementQuote`, quoteDetails);

	}

	// Cancel policy by submitting policyId and reason
	cancelPolicy(policyData:any){

		//post policy/cancelPolicy
		return this.http.post<any>(`${environment.customerFacingApi}policy/cancelPolicy`, policyData);

	}


}
