export class LipaPolePole{
    
    noOfInstallments:number;
    loanTerm:number;
    principal: number;
    commenceDate:string;
    deposit: number;
    type: string = "LIPA POLE POLE";


}
