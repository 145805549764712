import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';

import { CustomerService,PaymentService,AuthenticationService } from '../../_services';

@Component({
    selector: 'app-thankyou',
    templateUrl: './thankyou.component.html',
    styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

    siteImages:any = [];

    summary: any;

    policyGenerated:boolean = false;
    paymentComplete:boolean = false;
    policyLink:string = "";
    showModal:boolean = false;
    modalTitle:string = "";
    modalDescription:string = "";
    paymentRef:any = {};
    modalLink:string;
    modalLinkText:string = "Proceed";
    paymentLink:string = "/coversummary/";
    customerName:string = "";
    paymentType:string = "";

    customerProfile:any = {};
    valuerDetails:any = {};
    valuationDetails:any = {};
    policyDetails:any ={};

    lipaPolePoleSchedule:any = [];

    isLogin:boolean = false;

    constructor(
        private customerService: CustomerService,
        private paymentService:PaymentService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute) {

    }

    ngOnInit(): void {

        this.isLogin = this.authenticationService.userLoggedIn();

        this.paymentRef = this.route.snapshot.paramMap.get('customerId');

        if(localStorage.getItem('paymentType')){

            this.paymentType = localStorage.getItem('paymentType');

        }

        if(localStorage.getItem('customerName')){

            this.customerName = localStorage.getItem('customerName');

        }

        if(localStorage.getItem('lipaPolePoleSchedule')){

            this.lipaPolePoleSchedule = JSON.parse(localStorage.getItem('lipaPolePoleSchedule'));
        }

        localStorage.removeItem("policyData");
        localStorage.removeItem("coverstep");
        localStorage.removeItem("vehicleDetails");
        localStorage.removeItem("selectedPolicy");
        localStorage.removeItem("customerVerified");
        localStorage.removeItem("benefitsTotal");
        localStorage.removeItem("policyTaxes");
        localStorage.removeItem("paymentRef");
        localStorage.removeItem("totalTaxes");


        this.customerService.getPolicyPaymentStatus(this.paymentRef).subscribe(response => {

            if(response["success"]){

                this.customerProfile = response["data"]["customerDetails"];
                this.valuerDetails = response["data"]["solverDetails"];
                this.valuationDetails = response["data"]["valuationDetails"];
                this.policyDetails = response["data"]["policyDetails"];

                //this.customerName = this.customerProfile?.customer_name;

            }

        },error => {

            // console.log(error);

        });

        //this.loadSummary(this.customerId);

    }

    ngAfterViewInit():void{

        this.getImages();

    }

    getImages(){

        $("img").each( (index,value) => {

            // console.log($(value).attr("src"));

            if($(value).attr("src") != "" || $(value).attr("src") == "undefined" || $(value).attr("src") == null){

                this.siteImages.push($(value).attr("src"));

            }

        });

    }

    loadSummary(customerId) {

        this.customerService.getPolicyById(customerId).subscribe(
            (data) => {



            },
            (error) => {
                // console.log(error);

                this.modalTitle = "Error processing request";
                this.modalDescription = error.message;


                this.showModal = true;

                $(".modal").fadeIn();
            }
        );

    }

    onFileChange(event) {


        if (event.target.files.length > 0) {

            let fileName = event.target.files[0].name;
            let fileType = event.target.files[0].type;
            let documentType = event.target.files[0].type;

            // let fileReference = new Document();
            //
            // fileReference.fileType = fileType;
            // fileReference.documentType = documentType;
            // fileReference.document = fileName;

            $(".uploadLabel").text(fileName);

        } else {

            // Delete from array

            $(".uploadLabel").text("Choose File To Upload");
            $("#uploadid").val("");

        }


    }

    getLocationByCoordinates(lat,long){
        //https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=-33.8670522,151.1957362&radius=1500&type=addresskey=YOUR_API_KEY
    }

    toggleAuthenticationView(viewName:string){

        $(".authoverlay").stop().fadeIn(1000,function(){

            $("header").css({
                "position":"fixed"
            });

            $("body").css({
                "padding-top":"115px"
            });

            $(".authoverlay .formWrapper").stop().slideUp(1000,function(){

                $(".authoverlay ."+viewName).stop().slideDown(1000);

            });

        });


    }


}
