import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { environment } from '../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})

export class ContentManagementService {

  constructor(
      private http:HttpClient
  ){

  }

  submitContactDetails(formData){

      return this.http.post(`${environment.customerFacingApi}enquiry/postEnquiry`,formData);

  }

  getAllFaq(){

      return this.http.get('https://jsonplaceholder.typicode.com/posts/12');

  }

}
