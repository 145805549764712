<!--  Authentication Overlay  -->
<section class="authoverlay">
  <!--  Main site over  -->

  <figure class="overlay"></figure>
  <!--END  Main site over  -->

  <!--  Forms container  -->
  <section class="formContainer" *ngIf="!isLogin">
    <!--  Closer Button  -->
    <button class="closer" (click)="closeLogin()">

      <fa-icon icon="times"> </fa-icon>
    </button>
    <!--END Closer Button   -->

    <!--  Login-username Form  -->
    <section class="login formWrapper">
      <form
        class="loginForm"
        name="form"
        (ngSubmit)="f.form.valid && onGetContacts()"
        #f="ngForm"
        novalidate
      >
        <h5>Login</h5>
        <fieldset>
          <div class="full_width">
            <label for="">To login enter your national Id</label>
            <input
              type="text"
              name="nationalId"
              class="form-control"
              minlength="5"
              [(ngModel)]="model.nationalId"
              #nationalId="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && nationalId.invalid }"
              required
            />
            <div *ngIf="nationalId.invalid" class="invalid-field">
              <div *ngIf="nationalId.errors.required">
                National Id is required
              </div>

              <div *ngIf="nationalId.errors.minlength">
                National Id too short
              </div>
            </div>

            <label
              *ngIf="validationError !== ''"
              for=""
              class="invalid-field"
              >{{ validationError }}</label
            >
          </div>

          <button
            [disabled]="nationalId.invalid"
            *ngIf="!loading"
            class="readMoreYellow"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            proceed
            <fa-icon icon="arrow-circle-right" class="arrow"> </fa-icon>
          </button>

          <app-processor *ngIf="loading" description="processing">
          </app-processor>

          <div class="full_width authlinks">
            <span
              class="resetPassword"
              (click)="toggleAuthenticationView('resetPassword')"
              >Reset your password?
            </span>
          </div>
        </fieldset>
      </form>
    </section>
    <!--END  Login-username Form  -->

    <!--  Communication Channel Form  -->
    <section class="communication-channel formWrapper">
      <form
        class="loginForm"
        name="form"
        (ngSubmit)="f.form.valid && onGetOtp()"
        #f="ngForm"
        novalidate
      >
        <h5>OTP Option</h5>

        <h3>An OTP will be sent to this phone number or email</h3>

        <fieldset>
          <div class="full_width">
            <input
              type="radio"
              name="phoneNumber"
              class="form-control"
              [(ngModel)]="model.communicationChannel"
              #phoneNumber="ngModel"
              value="phoneNumber"
              [ngClass]="{ 'is-invalid': f.submitted && phoneNumber.invalid }"
              required
              checked
            />

            <label class="form-check-label" for="gridRadios1">
              {{ model.phoneNumber }}
            </label>
            <div *ngIf="phoneNumber.invalid" class="invalid-feedback">
              <div *ngIf="phoneNumber.errors.required" class="invalid-field">
                Phone Number is required
              </div>
            </div>
          </div>

          <div class="full_width">
            <input
              type="radio"
              name="email"
              class="form-control"
              [(ngModel)]="model.communicationChannel"
              #email="ngModel"
              value="email"
              [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
              required
            />
            <label class="form-check-label" for="gridRadios1">
              {{ model.email }}
            </label>
            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
              <div *ngIf="email.errors.required" class="invalid-field">
                Email is required
              </div>
            </div>
          </div>

          <button
            [disabled]="phoneNumber.invalid || email.invalid"
            class="readMoreYellow"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Send Code
            <fa-icon icon="arrow-circle-right" class="arrow"> </fa-icon>
          </button>

          <app-processor *ngIf="loading" description="Sending OTP">
          </app-processor>
        </fieldset>
      </form>
    </section>
    <!--END  Communication Channel Form  -->

    <!--  Otp Form  -->
    <section class="otp formWrapper">
      <form
        class="loginForm"
        name="form"
        (ngSubmit)="f.form.valid && onValidateContacts()"
        #f="ngForm"
        novalidate
      >
	  	
        <h5>Login</h5>

        <h3>
          Please wait for an OTP. Please check your
          {{ model.communicationChannel }}.
        </h3>
        <fieldset>
          <div class="full_width">
            <label for="">OTP</label>
            <input
              type="text"
              name="otp"
              class="form-control"
              [(ngModel)]="model.otp"
              #otp="ngModel"
              minlength="6"
              [ngClass]="{ 'is-invalid': f.submitted && otp.invalid }"
              required
            />
          </div>

          <div *ngIf="otp.invalid" class="invalid-feedback">
            <div *ngIf="otp.errors.required" class="invalid-field">
              OTP is required
            </div>
            <div *ngIf="otp.errors.minlength" class="invalid-field">
              OTP should be 6 characters long
            </div>
          </div>

          <label *ngIf="validationError !== ''" for="" class="invalid-field">{{
            validationError
          }}</label>

          <button
            [disabled]="otp.invalid"
            *ngIf="!loading"
            class="readMoreYellow"
          >
            <span class="spinner-border spinner-border-sm mr-1"></span>
            Validate
            <fa-icon icon="arrow-circle-right" class="arrow"> </fa-icon>
          </button>

          <app-processor *ngIf="loading" description="Validating OTP">
          </app-processor>
        </fieldset>
      </form>
    </section>
    <!--END  Login-username Form  -->

    <!--  Login-password Form  -->
    <section class="login-password formWrapper">
      <form
        class="loginForm"
        name="form"
        (ngSubmit)="f.form.valid && onSubmit()"
        #f="ngForm"
        novalidate
      >
        <h5>Login</h5>
        <div *ngIf="isResetPassword; else notResetPassword">
          <h3>Please enter your password</h3>
        </div>
        <ng-template #notResetPassword>
          <h3 *ngIf="model.communicationChannel === phoneNumber">
            Please wait for an sms with your one time password
          </h3>
          <h3 *ngIf="model.communicationChannel === email">
            Please wait for an email with your one time password
          </h3>
        </ng-template>
        <fieldset>
          <div class="full_width">
            <label for="">Password</label>
            <input
              type="password"
              name="password"
              class="form-control"
              [(ngModel)]="model.password"
              #password="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
              required
              minlength="6"
            />
            <div *ngIf="password.invalid" class="invalid-feedback">
              <label *ngIf="password.errors.required" class="invalid-field"
                >Password is required</label
              >
              <label *ngIf="password.errors.minlength" class="invalid-field">
                Password must be at least 6 characters
              </label>
            </div>

            <label
              *ngIf="validationError !== ''"
              for=""
              class="invalid-field"
              >{{ validationError }}</label
            >
          </div>

          <button
            *ngIf="!loading"
            [disabled]="password.invalid"
            class="readMoreYellow"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Login
            <fa-icon icon="arrow-circle-right" class="arrow"> </fa-icon>
          </button>

          <app-processor *ngIf="loading" description="Authentecating user">
          </app-processor>
        </fieldset>
      </form>
    </section>
    <!--END  Login-username Form  -->

    <!--  Reset Password  -->
    <section class="resetPassword formWrapper">

      <form
        name="resetForm"
        (ngSubmit)="resetForm.form.valid && onResetPassword()"
        #resetForm="ngForm"
        [appMatchPassword]="['password', 'confirmPassword']"
        novalidate
        autocomplete="off"
      >
        <h5>Reset password</h5>
        <fieldset>
          <div class="full_width">
            <label for="">National Id</label>
            <input
              type="text"
              name="nationalId"
              class="form-control"
              [(ngModel)]="resetModel.nationalId"
              #nationalId="ngModel"
              [ngClass]="{ 'is-invalid': resetForm.submitted && nationalId.invalid }"
              required
            />
            <div
              *ngIf="resetForm.submitted && nationalId.invalid"
              class="invalid-field"
            >
              <div *ngIf="nationalId.errors.required">
                National Id is required
              </div>
            </div>
          </div>

          <div class="full_width">
            <label for="">Password</label>
            <input
              type="password"
              name="password"
              class="form-control"
              [(ngModel)]="resetModel.password"
              #password="ngModel"
              [ngClass]="{ 'is-invalid': resetForm.submitted && password.invalid }"
              required
              minlength="6"
            />
            <div
              *ngIf="resetForm.submitted && password.invalid"
              class="invalid-field"
            >
              <div *ngIf="password.errors.required">Password is required</div>
              <div *ngIf="password.errors.minlength">
                Password must be at least 6 characters
              </div>
            </div>
          </div>

          <div class="full_width">
            <label>Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              class="form-control"
              [(ngModel)]="resetModel.confirmPassword"
              #confirmPassword="ngModel"
              [ngClass]="{
                'is-invalid': resetForm.submitted && confirmPassword.invalid
              }"
              required
            />
            <div
              *ngIf="resetForm.submitted && confirmPassword.invalid"
              class="invalid-field"
            >
              <div *ngIf="confirmPassword.errors.required">
                Confirm Password is required
              </div>
              <div *ngIf="confirmPassword.errors.mustMatch">
                Passwords must match
              </div>
            </div>
          </div>

          <button [disabled]="resetLoading" class="readMoreYellow">
            <span
              *ngIf="resetLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Reset
            <fa-icon icon="arrow-circle-right" class="arrow"> </fa-icon>
          </button>
        </fieldset>
      </form>
    </section>
    <!--END Reset Password   -->
  </section>
  <!--END  Forms container  -->

  <section class="formContainer" *ngIf="isLogin">
    <button class="closer" (click)="closeLogin()">
      <fa-icon icon="times"> </fa-icon>
    </button>

    <ul class="returnLinks">
      <li><a routerLink="/userprofile">User profile</a></li>
      <li><a routerLink="/userprofile">My policies</a></li>
      <li><a routerLink="/userprofile">My Claims</a></li>
    </ul>
  </section>
</section>
<!--END  Authentication Overlay  -->
