import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';

import { QuestionBase } from '../../_models/question/question-base';

import { QuestionControlService } from '../../_services/question/question-control.service';

@Component({
  selector: 'app-form-array',
  templateUrl: './form-array.component.html',
  styleUrls: ['./form-array.component.css'],
  providers: [ QuestionControlService ]
})
export class FormArrayComponent implements OnInit {

  @Input() questions: QuestionBase<string>[] | null = [];
  @Input() pages?:any = [];

  form !: FormGroup;

  dynamicObject !: FormArray;

  payLoad = '';

  constructor(
    private qcs: QuestionControlService,
    private fb: FormBuilder) { }

    
  //   form = this.fb.group({

  //     details: this.fb.array([])

  // });
  
  ngOnInit(): void {

    this.form = this.qcs.toFormGroupDynamic2(this.pages);

  }

  // get details() {

  //   return this.form.controls["details"] as FormArray;

  // }

  addDetails(){

    const sampleData: any = [
			{
				value: 'yourname'
			},
			{
				value: 'youremail'
			},
			{
				value: 'yournextofkin'
			}
		];

    let sampleResponse = this.qcs.toFormGroupDynamic2(this.pages);

    console.log("New sample response: ",sampleResponse);

  }

  removeDetail(i){

   // this.details.removeAt(i);

    console.log(i);

  }

  submitDetails(){

    this.payLoad = this.form.getRawValue();

  }

}
