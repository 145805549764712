<div class="mobileBanner">

    <article>

        <h3>Welcome  {{user}}</h3>

        <h4>Last Expense Insurance Plan for {{numberOfAdults}} Adults and {{numberOfChildren}} Children</h4>

        <p>This insurance plan offers peace of mind to the family by paying for funeral expenses of fixed amounts on the untimely demise of any member covered.</p>

    </article>

    <img src="./assets/_img/banner/family.svg" alt="">


</div>
