import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import $ from 'jquery';

@Component({
  selector: 'app-termssummary',
  templateUrl: './termssummary.component.html',
  styleUrls: ['./termssummary.component.css']
})
export class TermssummaryComponent implements OnInit {

  constructor(
      private router:Router,
  ) { }

  ngOnInit(): void {

    $('html, body').animate({
        scrollTop: $(".multistep").offset().top
    }, 300);
    
  }

  closeTermsModal(){

      $("header").css({
          "position":"relative"
      });

      $(".termsModal").fadeOut();

  }

  scrollUpPage(){
        $('html, body').stop().animate({
            scrollTop: $(".submitView").offset().top -20
        }, 400);
  }
 

  progressTerms(view){

      $("header").css({
          "position":"relative"
      });

      this.router.navigateByUrl(view);

  }


}
function multiStepAdjust() {
    throw new Error('Function not implemented.');
}

