import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-banner-image',
  templateUrl: './banner-image.component.html',
  styleUrls: ['./banner-image.component.css']
})
export class BannerImageComponent implements OnInit {

    @Input() image:string;
    @Input() heading:string;
    @Input() description:string;

  constructor() { }

  ngOnInit() {
  }

}
