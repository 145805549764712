export class Valuation{

    nationalId: string = "";
    date:string = "";
    time:string = "";
    latitude:string = "-1.2361741";
    longitude:string = "36.763074";
    autoCompleteLocation:string = "";
    valuationDateTime: string = "";

}
