<section class="centerAligner">

	<!--Preloader-->
	<app-processor *ngIf="loading"></app-processor>
	<!--END Preloader-->

	<!--Network Error modal-->
	<app-modal
		*ngIf="showModal"
		title="{{modalTitle}}"
		description="{{modalDescription}}"
		type = "{{modalType}}">
	</app-modal>
	<!--END Network Error modal-->

	<app-dynamic-form
		[pages]="fullPage"
		layoutType="accordion"
		[submissionURL]="formSubmissionURL"
		*ngIf="fullPage.length>0" >
	</app-dynamic-form>


</section>
