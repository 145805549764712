<section class="centerAligner shadow rounded padding-40 policyCancellation">

  <div class="claimHeader">
    <h1><span class="yellowLine">Policy Cancellation</span> — KCD 321A - Subaru Outback, 2500 CC</h1>
  </div>

  <!--  Policy Summary  -->
  <div class="summaryContent">

      <div class="row">
        <h3 class="left">Policy No </h3>
        <h3 class="right">102077003709/XXXXX</h3>
      </div>

      <div class="row">

        <p class="left">Cover Type</p>
        <p class="right">Motor Private Comprehensive</p>

      </div>

      <div class="row">

        <p class="left">Estimate Value</p>
        <p class="right">KES 1,500,000</p>

      </div>

      <div class="row">

        <p class="left">Policy application Date</p>
        <p class="right">June 3rd, 2021: 10:51am</p>

      </div>

      <div class="row">

        <p class="left">Policy Start Date</p>
        <p class="right">June 3rd, 2021: 11.19am/XXXXX</p>

      </div>

      <div class="row">

        <p class="left">Policy End Date</p>
        <p class="right">June 2nd, 2022: 11.59pm </p>

      </div>

  </div>

  <div class="dashedLine"></div>

  <section class="premiumPaidSection">

      <div class="tableHeader">

        <div class="row">

          <div class="leftSection">
              <h4 class="right">Total Premium Paid</h4>
              <button class="readMoreWhiteSolid">
                        View All Payments
                        <fa-icon
                            class="plus-circle yellow iconPosition iconMedium"
                            icon="plus-circle"></fa-icon>
              </button>
          </div>

          <div class="rightSection">
            <p>KES XXXX</p>
          </div>

        </div>

      </div>

      <!--  Policy Summary  -->
    <div class="paymentBreakdown">

      <div class="row">

        <div class="paymentDate">
          <p>February 14th 2021</p>
        </div>

        <div class="amount">
            <p>KES 10,750</p>
        </div>

        <div class="paymentStatus">
          <p class="amountPaid">
              Paid
              <fa-icon icon="check-circle" class="iconYellow iconMedium iconPosition"></fa-icon>
          </p>
        </div>

      </div>

      <div class="row">

        <div class="paymentDate">
          <p>March 14th 2021</p>
        </div>

        <div class="amount">
            <p>KES 10,750</p>
        </div>

        <div class="paymentStatus">
          <p class="amountPaid">
              Paid
              <fa-icon icon="check-circle" class="iconYellow iconMedium iconPosition"></fa-icon>
          </p>
        </div>

      </div>

      <div class="row">

        <div class="paymentDate">
          <p>April 14th 2021</p>
        </div>

        <div class="amount">
            <p>KES 10,750</p>
        </div>

        <div class="paymentStatus">
          <p class="amountPaid">
              Paid
              <fa-icon icon="check-circle" class="iconYellow iconMedium iconPosition"></fa-icon>
          </p>
        </div>

      </div>

      <div class="row">

        <div class="paymentDate">
          <p>May 14th 2021</p>
        </div>

        <div class="amount">
            <p>KES 10,750</p>
        </div>

        <div class="paymentStatus">
          <p class="amountPaid">
              Paid
              <fa-icon icon="check-circle" class="iconYellow iconMedium iconPosition"></fa-icon>
          </p>
        </div>

      </div>

      <div class="row">

        <div class="paymentDate">
          <p class="currentPayDate">June 14th 2021</p>
        </div>

        <div class="amount">
            <p class="currentPayAmount">KES 10,750</p>
        </div>

        <div class="paymentStatus">
          <p class="payNowBtn">
              Pay Now
              <fa-icon
                  icon="arrow-circle-right"
                  class="iconYellow iconMedium arrow"></fa-icon>
          </p>
        </div>

      </div>

      <div class="row">

        <div class="paymentDate">
          <p>July 14th 2021</p>
        </div>

        <div class="amount">
            <p>KES 10,750</p>
        </div>

        <div class="paymentStatus">
          <p class= "pendingBtn">
              Pending
              <fa-icon
                  icon="exclamation-circle"
                  class="iconMedium iconGrey iconPosition"></fa-icon>
          </p>
        </div>

      </div>

      <div class="row">

        <div class="paymentDate">
          <p>August 14th 2021</p>
        </div>

        <div class="amount">
            <p>KES 10,750</p>
        </div>

        <div class="paymentStatus">
          <p class= "pendingBtn">
              Pending
              <fa-icon
                  icon="exclamation-circle"
                  class="iconMedium iconGrey iconPosition"></fa-icon>
          </p>
        </div>

      </div>

      <div class="row">

        <div class="paymentDate">
          <p>September 14th 2021</p>
        </div>

        <div class="amount">
            <p>KES 10,750</p>
        </div>

        <div class="paymentStatus">
          <p class= "pendingBtn">
              Pending
              <fa-icon
                  icon="exclamation-circle"
                  class="iconMedium iconGrey iconPosition"></fa-icon>
          </p>
        </div>

      </div>

      <div class="row">

        <div class="paymentDate">
          <p>October 14th 2021</p>
        </div>

        <div class="amount">
            <p>KES 10,750</p>
        </div>

        <div class="paymentStatus">
          <p class= "pendingBtn">
              Pending
              <fa-icon
                  icon="exclamation-circle"
                  class="iconMedium iconGrey iconPosition"></fa-icon>
          </p>
        </div>

      </div>

      <div class="row">

        <div class="paymentDate">
          <p>November 14th 2021</p>
        </div>

        <div class="amount">
            <p>KES 10,750</p>
        </div>

        <div class="paymentStatus">
          <p class= "pendingBtn">
              Pending
              <fa-icon
                  icon="exclamation-circle"
                  class="iconMedium iconGrey iconPosition"></fa-icon>
          </p>
        </div>

      </div>


      <button class="readMoreWhiteSolid">
        Close
        <fa-icon
            class="times-circle yellow iconPosition iconMedium"
            icon="times-circle"></fa-icon>
      </button>

    </div>


  </section>

  <section class="totalPremiumSection">

    <div class="tableHeader">

      <div class="row">

        <div class="leftSection">
            <h4 class="right">Total Premium Utilised</h4>
        </div>

        <div class="rightSection">
          <p>KES XXXX</p>
        </div>

      </div>

    </div>

    <div class="dashedLine"></div>

    <div class="tableHeader">

      <div class="row">

        <div class="leftSection">
            <h4 class="right">Non-Refundable</h4>
        </div>

        <div class="rightSection">
          <p>KES XXXX</p>
        </div>

      </div>

    </div>

    <div class="nonRefundableSection">

      <div class="row">

        <p class="left">Policy Holder Compensation Fund (PCHF)</p>
        <p class="right">KES 150</p>

      </div>

      <div class="row">

        <p class="left">Training Levy</p>
        <p class="right">KES 33</p>

      </div>

      <div class="row">

        <p class="left">Stamp Duty</p>
        <p class="right">KES 40</p>

      </div>

      <div class="row">

        <p class="left">Transaction Fee + VAT</p>
        <p class="right">KES 348</p>

      </div>

      <div class="row">

        <p class="left">Third Party Fees</p>
        <p class="right">KES XXXX</p>

      </div>


    <div class="dashedLine"></div>

    <div class="tableFooter">
      <div class="row">

        <h4 class="left">Total Refundable</h4>
        <p class="right">KES XXXX</p>

      </div>
    </div>

    <div class="dashedLine"></div>

    </div>

  </section>

  <div class="feedbackSection">
    <h4>Please share with us your reason for cancelling the policy?</h4>
    <p>We appreciate your feedback because it helps us serve your better.</p>

    <form>
      <div class="row">
        <div class="full_width">
          <select>
              <option value="">Select Reason</option>
              <option value="">Reason 1</option>
              <option value="">Reason 2</option>
              <option value="">No reason whatsoever</option>
          </select>
        </div>
      </div>
    </form>

  </div>

  <div class="greyBoxSection">
      <h4>Which number would you like the amount sent to?</h4>
      <p>Enter your Safaricom mobile number to receive your refund</p>

    <form>

      <div class="row paymentsubmission">

        <div class="fl phoneNumberField">

            <!--END  Phone Highlight  -->
            <input
                type="text"
                class="phoneNumber"
                pattern="^(?:254|\\+254|0)((?:7|1)[0-9]{8})$"
                required>

        </div>

        <div class="one_quarter fl paybutton">
          <button
            class="readMoreYellow"
            type="button">
              Update
            <fa-icon
            icon="arrow-alt-circle-right"
            class="arrow"></fa-icon>
        </button>
      </div>


    </div>


    </form>

    </div>


  <div class="btnFooter">

    <button class="readMoreYellow">
        Cancel Policy
      <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
    </button>

    <button class="readMoreGrey">
      Back
      <fa-icon class="arrow" icon="arrow-circle-right"></fa-icon>
    </button>

  </div>

</section>
