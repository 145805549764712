export class Endorsement{

	  policyNo: string = "102277000126";
	  endorsementAmount: string = "";
	  endorsementType: string = "";
	  endorsementQuote:any =  {};
	  endorsementComputations:any = {}
	  endorsementBenefits: any = [];
	  endorsementAction: string = "";
	  comment: string= "";
	  paymentPhoneNo: string = "";

}
