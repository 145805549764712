<app-preloader
    *ngIf="siteImages.length > 0"
    [images]="siteImages"
></app-preloader>


<section class="centerAligner shadow padding rounded contactUs">

    <h1>Contact Us</h1>

    <p>If you have any questions, concerns or feedback, get in touch with us. We’d love to hear from you. </p>

    <!--  Contact Form  -->
    <form
        (ngSubmit)="contactForm.form.valid && onSubmit(contactForm.value)"
        #contactForm="ngForm"
        name="contactForm"
        class="contactForm">

        <div class="form-group">

            <div class="row">

            </div>

            <div class="row">

                <div class="half fl">

                    <label
                        for="email">Email</label>
                         <input
                             type="text"
                             id="email"
                             name="email"
                             ngModel
                             #email="ngModel"
                             required
                             email
                              />

                         <div
                             class="invalid-field"
                             *ngIf="email.invalid">

                             <label
                                 *ngIf="email.errors.required"
                                   class="invalid-field">Email is required
                             </label>

                             <label *ngIf="email.errors.email"
                             class="invalid-field">Email must be a valid email address</label>

                         </div>

                </div>

                <div class="half fl">
                    <label for="">Subject</label>
                    <input
                        type="text"
                        name="subject"
                        id="subject"
                        ngModel
                        #subject="ngModel"
                        required>

                        <div

                        *ngIf="subject.invalid"
                        >
                            <label
                                *ngIf="subject.errors.required"
                                class="invalid-field">
                                Subject is required!
                            </label>
                        </div>
                </div>

				<div class="full_width clear phone">

			        <label for="">Mobile Number*</label>
			        <!--  Phone Highlight  -->

			        <div class="phoneNumberField">

			            <span class="phoneHighlight">

			                <span class="number">254</span>

			            </span>
			            <!--END  Phone Highlight  -->
			            <input
			            type="text"
			            class="phoneNumber"
			            name="customerMobile"
			            placeholder="Enter mobile number"

			            ngModel
			            #customerMobile="ngModel"
			            pattern="^(?:254|\\+254|0)((?:7|1)[0-9]{8})$"
			            required>

			        </div>


			        <div
			        class="invalid-field"
			        *ngIf="customerMobile.invalid">

				        <label
				        *ngIf="customerMobile.errors.required"
				        class="invalid-field">Phone Number is required
					    </label>

					    <label *ngIf="customerMobile.errors.pattern"
					    class="invalid-field">Phone Number invalid</label>

					</div>
				</div>



            </div>

            <div class="row">
                <div class="full_width">
                    <label for="">Message</label>
                    <textarea
                        name=""
                         id=""
                         cols="30"
                         rows="10"
                         name="message"
                        required
                        minlength="10"
                        maxlength="200"
                        #message="ngModel"
                         ngModel></textarea>

                         <label
                         class="invalid-field"
                         *ngIf="message.invalid"
                         >


                             <div *ngIf="message.errors.required">
                                Message is required!
                              </div>
                              <div *ngIf="message.errors.minlength">
                                Message must be at least 10 characters long.
                              </div>
                              <div *ngIf="message.errors.maxlength">
                                Message must be at most 200 characters
                              </div>
                         </label>


                </div>

            </div>

            <button
                class="readMoreYellow"
                [disabled]="contactForm.invalid"
                *ngIf="!loading"
                type="submit">
                Send Email

                <fa-icon

                    icon="arrow-alt-circle-right"
                    class="arrow"></fa-icon>
            </button>

            <div class="row errors">
                <label for="" *ngIf="errorStatus" class="invalid-field">{{statusMessage}}</label>
                <label for="" *ngIf="!errorStatus" class="successlabel">{{statusMessage}}</label>
            </div>
        </div>
    </form>
    <!--END  Contact Form  -->

    <div>
        <p>You can also find information here…. <a routerLink="/faqs" target="_blank">FAQs</a>  </p>
        <p>Our lines are always open for you</p>
        <p><fa-icon icon="phone-volume"></fa-icon> <a href="tel:0709184444">0709184444</a></p>
        <p><fa-icon icon="envelope"></fa-icon> <a href="mailto:info@insureme.co.ke">info@insureme.co.ke</a></p>
        <!-- <p><fa-icon [icon]="['fab', 'whatsapp']"></fa-icon> <a href="https://api.whatsapp.com/send?phone= "></a></p> -->

    </div>


</section>
