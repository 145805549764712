
<app-modal
	*ngIf="showModal"
	title="{{modalTitle}}"
	link="{{modalLink}}"
	(okButtonClick)="restoreEndorsementAccordion();"
	(yesButtonClick)="acknowledgeEndorsement($event)"
	(noButtonClick)="declineEndorsement($event);"
	linkText="{{modalLinkText}}"
	description="{{modalDescription}}"
	type = "{{modalType}}"
	[modalData] = "modalData"
	 >

</app-modal>

<!--  Valuation Notification  -->
<div class="centerAligner valuationNotification">

	<!--  Payment Module  -->
	<div class="yellowHighlight hide">

		<div class="paymentNumber">

			<button
				class="readMoreBlack back"
				(click)="restoreEndorsementAccordion();">

				Back

				<fa-icon
					icon="arrow-circle-left"
					class="arrow">

				</fa-icon>

			</button>

			<h3>

				Which number would you like

				<span *ngIf="policyEndorsement?.endorsementAction === 'ADDITIONAL_PAYMENT' " >
					to use to make your payment?
				</span>

				<span
					*ngIf="policyEndorsement?.endorsementAction === 'REFUND' ">

					the amount sent to?

				</span>

			</h3>

			<label for="">Enter your Safaricom mobile number to

				<span *ngIf="policyEndorsement?.endorsementAction === 'ADDITIONAL_PAYMENT' " >
					make your payment
				</span>

				<span
					*ngIf="policyEndorsement?.endorsementAction === 'REFUND' ">

					receive your refund

				</span>
			</label>

			<form action="">

				<div class="row noborder">

					<div class="three_quarter fl">

						<span class="countryHighlight">+254</span>

						<input

							type="text"
							class="phoneNumber"
							name="customerMobile"
							(keyup)="changePaymentPhoneNumber($event);"
							placeholder="Enter mobile number"
							[(ngModel)]="phoneNumber"
							#paymentMobile="ngModel"
							pattern="^(?:0)((?:7|1)[0-9]{8})$"
							required>

							<div
							class="invalid-field"
							*ngIf=" paymentMobile.invalid">

								<label
								*ngIf="paymentMobile.errors.required"
								class="invalid-field">Phone Number is required
								</label>

								<label *ngIf="paymentMobile.errors.pattern"
								class="invalid-field">Phone Number invalid</label>

							</div>

					</div>

					<div class="one_quarter fl">

						<button class="readMoreBlack"
						[disabled]="paymentMobile.invalid"
						(click)="updatePaymentPhoneNumber();approveEndorsement($event);">

							Submit

							<fa-icon
								class="arrow"
								icon="arrow-alt-circle-right">
							</fa-icon>

						</button>

					</div>

				</div>


			</form>

		</div>

	</div>
	<!--END  Payment Module  -->

	<!--  Valuation Accordion  -->

	<section class="accordionContainer"
		*ngIf="valuationSummary.hasOwnProperty('endorsementDetails') && valuationSummary?.endorsementComputations != null" >

		<!--  Valuation Report TItle  -->

		<section
			class="accordionTitle"
			>

				<section
				   class="clickTarget"
				   (click)="openDynamicAccordion($event);$event.stopPropagation();"
			   ></section>

				<h3>Valuation Report

				{{policyDetails?.vehicle | slice:8:30 | titlecase}} -  {{policyDetails?.vehicle | slice:0:8}} </h3>

			<fa-icon

				icon="plus-circle"
				class="arrow closed"></fa-icon>

			<fa-icon
				icon="times-circle"
				class="arrow open"></fa-icon>

		</section>
		<!--END Valuation Report TItle   -->

		<!--  Valuation Item Accordion  -->


		<section class="accordion shadow">

			<section class="valuationDetails">

				<div class="row noborder">

					<p class="left">Estimate Value</p>
					<p class="right">{{valuationSummary?.estimatedValue | currency : 'KES ' : 'code' :'1.0-0'}}</p>

				</div>

				<div class="row ">

					<p class="left">Previous Basic Premium</p>
					<p class="right">{{valuationSummary?.endorsementComputations?.previousBasicPremium | currency : 'KES ' : 'code' :'1.0-0'}}</p>

				</div>

				<div class="row noborder">

					<p class="left">Final Value</p>
					<p class="right">KES {{valuationSummary?.valuationValue | currency : 'KES ' : 'code' :'1.0-0'}}</p>

				</div>

				<div class="row ">

					<p class="left">New Basic Premium</p>
					<p class="right">{{valuationSummary?.endorsementComputations?.newBasicPremium | currency : 'KES ' : 'code' :'1.0-0'}}</p>

				</div>

				<div class="row ">

					<p class="left">Basic Premium Difference</p>
					<p class="right">{{valuationSummary?.endorsementComputations?.diffPremium | currency : 'KES ' : 'code' :'1.0-0'}}</p>

				</div>

				<div class="row noborder">

					<h3 *ngIf="valuationSummary?.endorsementDetails?.endorsementAction == 'REFUND'">Refundable taxes and benefits</h3>

					<h3 *ngIf="valuationSummary?.endorsementDetails?.endorsementAction == 'PAY_ADDITIONAL_AMOUNT'">Additional taxes and benefits</h3>

				</div>

				<ng-container *ngIf="valuationSummary?.endorsementComputations !== null || valuationSummary?.endorsementComputations !== 'null' " >

					<div class="row">

						<p class="left">Training Levy</p>

						<p class="right">{{valuationSummary?.endorsementComputations?.newTransLevy | currency : 'KES ' : 'code' :'1.0-0' }}</p>

					</div>

					<div class="row">

						<p class="left">Policy Holders Compensation Fund (PHCF)</p>

						<p class="right">
							{{valuationSummary?.endorsementComputations?.newPhcf | currency : 'KES ' : 'code' :'1.0-0' }}
						</p>
					</div>

					<div class="row">

						<p class="left">Transaction Fee</p>

						<p class="right">
							{{valuationSummary?.endorsementComputations?.transactionFee | currency : 'KES ' : 'code' :'1.0-0' }}
						</p>
					</div>

					<ng-container *ngIf="valuationSummary?.endorsementComputations?.endorseBenefits.length > 0" >

						<ng-container class="row" *ngFor="let benefit of valuationSummary?.endorsementComputations?.endorseBenefits" >

							<div class="row" *ngIf="benefit?.diffPremium > 0" >

								<p class="left">{{benefit?.name}}</p>

								<p class="right">
									{{benefit?.diffPremium | currency : 'KES ' : 'code' :'1.0-0' }}
								</p>

							</div>


						</ng-container>

					</ng-container>

				</ng-container>

				<div class="row" *ngIf="valuationSummary?.endorsementDetails?.endorsementAction == 'REFUND' || valuationSummary?.endorsementDetails?.endorsementAction == 'PAY_ADDITIONAL_AMOUNT' ">

					<h3 class="left" *ngIf="valuationSummary?.endorsementDetails?.endorsementAction == 'REFUND'" >Refundable Amount</h3>

					<h3 class="left" *ngIf="valuationSummary?.endorsementDetails?.endorsementAction == 'PAY_ADDITIONAL_AMOUNT'" >Premium Top Up Amount</h3>

					<h3 class="right">{{valuationSummary?.endorsementDetails?.endorsementAmount | currency : 'KES ' : 'code' :'1.0-0'}}</h3>

				</div>



				<div class="row noborder buttons">

					<button
						class="readMoreBlack"
						(click)="endorsePolicy(valuationSummary);"
						*ngIf="valuationSummary?.endorsementDetails?.endorsementAction == 'REFUND'">

						Get Refund

						<fa-icon
							class="arrow yellow"
							icon="arrow-circle-right"
							></fa-icon>

					</button>

					<button
						class="readMoreBlack"
						(click)="endorsePolicy(valuationSummary);"
						*ngIf="valuationSummary?.endorsementDetails?.endorsementAction == 'PAY_ADDITIONAL_AMOUNT'">

						Make Payment

						<fa-icon
							class="arrow yellow"
							icon="arrow-circle-right"
							></fa-icon>

					</button>



					<button
						class="readMoreBlack"
						(click)="proceedWithCurrentEndorsement(valuationSummary);">

						Continue With Estimate Value

						<fa-icon
							class="arrow yellow"
							icon="arrow-circle-right"
							></fa-icon>

					</button>

					<!--  Download Valution Button  -->
				    <a
						class="readMoreBlack downloadDocuments"
						[href]="valuationSummary?.valuationReportURL"
						*ngIf="valuationSummary?.valuationReportURL !== ''"
						target="_blank"
						download >

				        Download Valuation Report

				        <fa-icon
				            icon="file-pdf"
				            class="arrow"
				            ></fa-icon>

				    </a>
				    <!--END  Download Valution Button  -->

				</div>

			</section>

		</section>
		<!--END  Valuation Item Accordion  -->

	</section>
	<!--END  Valuation Accordion  -->

</div>
<!--END  Valuation Notification  -->

<section class="centerAligner shadow singlePolicy rounded ">



    <!--  Policy Summary  -->
    <div
		class="policySummary singlePolicy"
		>

        <a routerLink="/userprofile"
            class="readMoreYellow back">
                View Profile
                <fa-icon
                    icon="arrow-circle-left"
                    class="arrow"></fa-icon>
        </a>

		<!--  Pending Documents  -->

		<div class="documentsContainer"
			*ngIf="pendingDocumentsArray.length > 0" >

			<h3>Please share the remaining documents as soon as possible</h3>

			<div class="row">

			    <div class="full_width">
			        <form
			        id="nationalIdForm"
			        #nationalIdForm="ngForm">

				        <label for="">Upload Copy of National Id</label>

				        <div class="uploadContainer">

				            <fa-icon

				            icon="upload"
				            class="upload"
				            ></fa-icon>

				            <label for="uploadid" class="uploadLabel">Upload National Id, 5mb or less</label>

				            <input
					            type="file"
					            id="uploadid"
					            (change)="onFileChange($event,nationalIdForm);"
					            accept=".pdf,.jpg,.jpeg,.png"
					            data-extension="/(\.pdf | \.jpg,.jpeg,.png)$/i"
					            name="nationalIdUpload"
								ngModel
					            #nationalIdUpload="ngModel"
					            #idUpload
								required
					            placeholder="National Id in pdf format"
					            type="file" />

				        </div>

				        <div
				        class="invalid-field"
				        *ngIf="nationalIdUpload.invalid"
				        >

					        <label *ngIf="nationalIdUpload.invalid" class="invalid-field"
					        >National Id required
					    	</label>

						</div>

					</form>

				</div>

			</div>

			<div class="row">

			    <div class="full_width">

			        <form action=""
			        id="logbookForm"
			        #logbookForm="ngForm">
			        <label for="">Upload Copy of Logbook*</label>

			        <div class="uploadContainer">
			            <fa-icon

			            icon="upload"
			            class="upload"
			            ></fa-icon>

			            <label for="uploadlogbook" class="uploadLabel">Upload Logbook, 5mb or less</label>
			            <input
				            type="file"
				            id="uploadlogbook"
				            (change)="onFileChange($event,logbookForm);"
				            accept=".pdf,.jpg,.jpeg,.png"
				            data-extension="/(\.pdf | \.jpg,.jpeg,.png)$/i"
				            name="logBook"
				           ngModel
				            #logBookUpload="ngModel"
				            placeholder="Log Book"
							required
				            type="file" />
			        </div>

			        <label *ngIf="logBookUpload.invalid" class="invalid-field"
			        >Logbook required
			    </label>

			</form>


			</div>
			</div>

			<button class="readMoreGrey">

				Submit Documents

				<fa-icon
					icon="arrow-circle-right"
					class="arrow">

				</fa-icon>

			</button>

		</div>
		<!--END  Pending Documents  -->

		<!--  Policies header row  -->

		<div class="headerRow" *ngIf="!loading">

			<div class="left">

				<h2>{{policyDetails?.vehicle | slice:0:8}} {{policyDetails?.vehicle | slice:8:30 | titlecase}} </h2>

				<span class="border"></span>

			</div>

			<div class="right">
				<a
					[routerLink]="['/userprofile/claim', {policyNumber: policyId}]"
					class="readMoreYellow">
						Submit Claim for {{policyDetails?.vehicle | slice:0:8}}

						<fa-icon
							icon="arrow-circle-right"
							class="arrow">

						</fa-icon>
				</a>

				<a
					routerLink="/userprofile/cancelpolicy/{{policyId}}"
					class="readMoreGrey">
					Cancel Policy

					<fa-icon
						icon="arrow-circle-right"
						class="arrow">

					</fa-icon>
				</a>

			</div>

		</div>

		<!--  Policy Loader Processor  -->
	    <app-processor
	        *ngIf="loading"
	        description="Loading policy data"
			class="marginTop20">
	    </app-processor>
		<!--END Policy Loader Processor   -->

		<!--END Policies header row   -->
		<section class="accordionContainer transactionDetails">

	        <h3 class="accordionTitle shadow">

	            Your transaction details

	            <fa-icon
		            icon="plus-circle"
		            class="arrow closed"></fa-icon>

	            <fa-icon

	            icon="minus-circle"
	            class="arrow open"></fa-icon>
	        </h3>

			<div class="accordion">

				<!--  Transaction Number  -->
				<div class="row">

		            <p class="left">Transaction Number</p>
		            <p class="right">{{policyDetails?.transactionNumber}}</p>

		        </div>
				<!--END  Transaction Number  -->

				<!--  Transaction Date  -->
				<div class="row">

		            <p class="left">Transaction Date and Time</p>

		            <p class="right">{{policyDetails?.transactionDateTime | date : 'fullDate'}}</p>

		        </div>
				<!--  End Transaction Date  -->

				<!--  Sum Insured  -->
				<div class="row">

		            <p class="left">Sum Insured</p>

		            <p class="right">{{policyDetails?.estimateValue | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		        </div>
				<!--  End Sum Insured  -->

				<!--  Policy Number  -->
				<div class="row">

		            <p class="left">Policy No</p>

		            <p class="right">{{policyId}}</p>

		        </div>
				<!--  End Policy Number  -->

				<!--  Policy Application Date  -->
				<div class="row">

		            <p class="left">Policy application Date</p>
		            <p class="right">{{policyDetails?.policyStartDate | date : 'fullDate'}}</p>

		        </div>
				<!--  End Policy Application Date  -->

				<!--  Policy Start Date  -->
		        <div class="row">

		            <p class="left">Policy Start Date</p>
		            <p class="right">{{policyDetails?.policyStartDate | date : 'fullDate'}}</p>

		        </div>
				<!--  End Policy Start Date  -->

				<!--  Policy End Date  -->
		        <div class="row">

		            <p class="left">Policy End Date</p>
		            <p class="right">{{policyDetails?.policyEndDate | date : 'fullDate'}} </p>

		        </div>
				<!--  End Policy End Date  -->

				<!--  Policy Underwriter  -->
				<div class="row">

		            <p class="left">Policy Underwriter</p>
		            <p class="right">{{policyDetails?.underwritter}}</p>

		        </div>
				<!--  End Policy Underwriter  -->

				<!--  Policy Agent  -->
				<div class="row">

		            <p class="left">Policy Agent</p>
		            <p class="right">{{policyDetails?.policyAgent}}</p>

		        </div>
				<!--  End Policy Agent  -->

				<!--  Class of Insurance  -->
		        <div class="row">

		            <p class="left">Class of Insurance</p>
		            <p class="right">{{policyDetails?.coverType}}</p>

		        </div>
				<!--  End Class of Insurance  -->

				<!--  Policy Underwriter  -->
				<div class="row" *ngIf="policyDetails?.basicPremium !== ' ' || !policyDetails?.basicPremium ">

		            <p class="left">Basic Premium</p>

		            <p class="right">{{policyDetails?.basicPremium | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		        </div>
				<!--  End Policy Underwriter  -->

				<!--  Policy Agent  -->
				<div class="row">

		            <p class="left">Additional Benefits</p>
		            <p class="right">

						{{policyDetails?.totalBenefitsAmount | currency : 'code' : 'KES '  : '1.0-0'}}

					</p>

		        </div>
				<!--  End Policy Agent  -->

				<!--  Policy Underwriter  -->
				<div class="row">

		            <p class="left">Taxes and Levies</p>
		            <p class="right">{{ totalTaxes  | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		        </div>
				<!--  End Policy Underwriter  -->

				<!--  Policy Agent  -->
				<div class="row">

		            <p class="left">Services Fees</p>
		            <p class="right">KES 320</p>

		        </div>
				<!--  End Policy Agent  -->

				<!--  Policy Underwriter  -->
				<div class="row">

		            <p class="left">Total Premium + Fees</p>
		            <p class="right">{{policyDetails?.totalPremiumPlusFees | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		        </div>
				<!--  End Policy Underwriter  -->

				<!--  Policy Agent  -->
				<div class="row" *ngIf="policyDetails?.depositAmount !== '' || policyDetails?.depositAmount !== null" >

		            <p class="left">Deposit Amount</p>
		            <p class="right">{{policyDetails?.depositAmount | currency : 'code' : 'KES '  : '1.0-0'}}</p>

		        </div>
				<!--  End Policy Agent  -->

				<!--  Policy Agent  -->
				<div class="row">

		            <p class="left">Payment Plan</p>
		            <p class="right">{{paymentType}}</p>

		        </div>
				<!--  End Policy Agent  -->

			</div>
		</section>

		<!--  Download Policy Button  -->
	    <!-- <button class="readMoreGrey downloadDocuments hide">
	        Download Your Policy Document Here

	        <fa-icon
	            icon="file-pdf"
	            class="arrow"
	            ></fa-icon>
	    </button> -->
	    <!--END  Download Policy Button  -->

    </div>
    <!--END  Policy Summary  -->

	<!--  Policy Schedule  -->
	<!-- <section
        class="paymentSchedule policySummary"
        *ngIf="paymentSchedule.length > 0" > -->

    <section
        class="paymentSchedule policySummary"
		*ngIf="paymentSchedule.length > 0"
         >

		<!--  Lipa Pole Pole Header  -->
        <div class="row paymentHighlight" *ngFor="let schedule of paymentSchedule| slice:0:1">

			<!--  Wallet Icon  -->
            <fa-icon
                icon="wallet"
                class="wallet"
            ></fa-icon>
			<!--END Wallet Icon   -->

            <h2 *ngIf="!loanCleared">Current installment </h2>
            <h2 *ngIf="loanCleared">Payment cleared</h2>

			<!--  Header Status Buttons  -->
            <section class="buttons fr">

				<!--  Pay now button  -->
                <button
                    class="readMoreBlack"
                    *ngIf="!loanCleared"
                    (click)="postPayment(schedule.amount,policyId,phoneNumber);"
                    >

                    Pay now - {{schedule.amount | currency : 'code' : 'KES '  : '1.0-0'}}

                    <fa-icon
                        class="arrow yellow"
                        icon="arrow-alt-circle-right"></fa-icon>

                </button>
				<!--END  Pay now button  -->

                <!-- <button class="readMoreWhiteSolid">

                    Close

                    <fa-icon
                        class="arrow yellow"
                        icon="times"></fa-icon>

                </button> -->
            </section>

			<!--END  Header Status Buttons  -->

        </div>
		<!--END Lipa Pole Pole Header   -->

        <!--  Single Schedule Row  -->
        <div
			class="row"
			*ngFor="let schedule of paymentSchedule| slice:0:10"

			[ngClass]="{'paid': schedule.status === 'PAID'}" >

            <p class="left">{{schedule.dueDate | date : 'fullDate'}}</p>

            <div class="right">

                <p class="amount">{{schedule.amount | currency : 'code' : 'KES '  : '1.0-0'}}</p>

                <button
                    class="payment paid"
                    *ngIf="schedule.status === 'PAID'"
                    >
                    Paid

                    <fa-icon
                        icon="check-circle"
                        class="status"></fa-icon>
                </button>

                <!-- <button
                    class="payment paynow"
                    *ngIf="schedule.status === 'CURRENT'"
                    (click)="postPayment(schedule.amount,policyId,phoneNumber);">
                    Pay Now

                    <fa-icon
                        icon="arrow-circle-right"
                        class="status">

                    </fa-icon>

                </button> -->

                <button
                    class="payment pending"
                    *ngIf="schedule.status === 'CURRENT'" >
                    Pending

                    <fa-icon
                        icon="exclamation-circle"
                        class="status"></fa-icon>
                </button>

                <button
                    class="payment pending"
                    *ngIf="schedule.status === 'DEFAULT'" >
                    Default

                    <fa-icon
                        icon="exclamation-circle"
                        class="status"></fa-icon>
                </button>

            </div>

        </div>
        <!--END  Single Schedule Row  -->

    </section>

	<!--  Lipa Pole Pole Footer  -->
	<section
        class="paymentSchedule paymentScheduleBlack policySummary"
		*ngIf="paymentSchedule.length > 0 && !loanCleared"
         >
		<div class="row paymentHighlightBlack paymentFooter" >

			<!--  Wallet Icon  -->
			<fa-icon
				icon="wallet"
				class="wallet"
			></fa-icon>
			<!--END Wallet Icon   -->

			<h2 >Pay full amount</h2>

			<!--  Footer Status Buttons  -->
			<section class="buttons fr">

				<!--  Pay now button  -->
				<button
					class="readMoreYellow"
					(click)="postPayment(loanBalance,policyId,phoneNumber);"
					>

					Pay now - {{loanBalance | currency : 'code' : 'KES '  : '1.0-0'}}

					<fa-icon
						class="arrow black"
						icon="arrow-alt-circle-right"></fa-icon>

				</button>
				<!--END  Pay now button  -->

			</section>

			<!--END  Footer Status Buttons  -->

		</div>
	</section>
	<!--END Lipa Pole Pole Footer   -->


    <!--END Policy Schedule   -->

    <section class="accordionContainer ">

		<!-- Benefits Accordion   -->

        <h3
			class="accordionTitle shadow benefitsTitle"
			[ngClass]="policyBenefits.length > 0 && policyDetails?.coverType === 'MOTOR PRIVATE Comprehensive' ? '' : 'hide'">
            Click here to Add Benefits

            <fa-icon

            icon="plus-circle"
            class="arrow closed"></fa-icon>

            <fa-icon

            icon="times-circle"
            class="arrow open"></fa-icon>
        </h3>

		<div class="accordion benefitsContainer benefitsAccordion benefitsForm" *ngIf="policyBenefits.length > 0 && policyDetails?.coverType === 'MOTOR PRIVATE Comprehensive' " >

            <div class="row toggle" *ngFor="let benefit of policyBenefits; let i = index" >
                <label for="">

                    {{benefit?.name}} {{benefit?.limitAmount | currency : 'code' : 'KES ' : '1.0-0'}}

					<span *ngIf="benefit?.default_amount > 0 && benefit?.name !== 'Car Hire'" >- Free upto  {{benefit?.default_amount | currency : 'KES ' : 'code' :'1.0-0'}}</span>
                    <span *ngIf="benefit.minimum_cover > 0" >- Minimum {{benefit?.minimum_cover | currency : 'KES ' : 'code' :'1.0-0'}}</span>
                    <span *ngIf="benefit?.default_amount > 0 && benefit?.name === 'Car Hire'" >- Min {{(benefit?.default_amount/benefit?.rate) | currency : 'KES ' : 'code' :'1.0-0'}} for {{benefit?.default_amount | currency : 'KES ' : 'code' :'1.0-0'}}</span>

                </label>

                <mat-slide-toggle

                    class="slideToggle"
					id="{{benefit.id}}"
					[disabled]="benefit?.disabled
					|| benefit.name ==='Valuation service'"
                    name="{{benefit.name}}"
					#benefit="ngModel"
					(change)="onBenefitToggle($event,i,benefit.rate_multiplier,benefit.default_amount)"
	                data-key="benefits"
					[(ngModel)] = "benefit.checked"

                    >
                </mat-slide-toggle>

				<div class="full_width benfitField"
					[ngClass]="confirmBenefitConditional(benefit.rate_multiplier,benefit.checked)"   >

                    <input
                    type="number"
                    class="benefitAmounts"

                    (keyup)="onBenefitChange($event,benefit.id);"
                    placeholder="Enter {{benefit?.name}} Amount"
                    [ngModel]="benefit?.default_amount"
                    [min]="benefit?.default_amount"
                    name="estimatedValue{{i+1}}"
                    pattern="\d*"
                    #benefitValue='ngModel'>


                    <div
                    class="invalid-field"
                    *ngIf="(benefitValue.value < benefit.default_amount)"
                    >
	                    <label
	                    class="invalid-field"
	                    >{{benefit.name}} amount must be above {{benefit.default_amount | currency : 'KES ' : 'code' :'1.0-0'}}
	                	</label>

            		</div>

		            <div
		            class="invalid-field"
		            *ngIf="benefitValue.invalid"
		            >
			            <label
			            *ngIf="benefitValue.errors.required"
			            class="invalid-field"
			            >{{benefit.name}} amount required
			        	</label>

				        <label
				        *ngIf="benefitValue.errors.pattern"
				        class="invalid-field"
				        >Field accepts numbers only
				    	</label>
					</div>
				</div>

            </div>

			<div class="row marginTop20">

				<button
					class="readMoreYellow"
					type="button"
					(click)="submitBenefits();"
					*ngIf="selectedBenefits.length > 0 && !loading"
				>
					Endorse benefits

					<fa-icon
					icon="arrow-alt-circle-right"
					class="arrow"

					></fa-icon>

				</button>

				<!-- <a
					class="readMoreYellow"
					routerLink="/contactus"
					*ngIf="selectedBenefits.length > 0 && !loading"
				>
					Request Endorsement Assistance

					<fa-icon
					icon="arrow-alt-circle-right"
					class="arrow"

					></fa-icon>

				</a> -->

				<app-processor *ngIf="loading" >
				</app-processor>

			</div>




        </div>

        <div class="accordion benefitsContainer benefitsAccordion" *ngIf="benefits.length > 0" >
            <div class="row toggle" *ngFor="let benefit of benefits; let i = index" >
                <label for="">

                    {{benefit?.name}} - {{benefit?.limitAmount | currency : 'code' : 'KES ' : '1.0-0'}}

                </label>

                <mat-slide-toggle

                    class="slideToggle"
                    [checked] = "true"
					disabled
                    id="{{benefit.id}}"
                    name="{{benefit.name}}"
                    >
                </mat-slide-toggle>

            </div>
        </div>


        <div class="accordion benefitsContainer" *ngIf="benefits.length < 1" >
            <p>You did not select any additional benefits for your policy</p>
        </div>
		<!--END  Benefits Accordion  -->




		<!--  Policy Inclustions  -->

        <h3 class="accordionTitle shadow">
            What does the policy cover?

            <fa-icon

            icon="plus-circle"
            class="arrow closed"></fa-icon>

            <fa-icon

            icon="minus-circle"
            class="arrow open"></fa-icon>
        </h3>

		<!--  Comprehensive Inclusions  -->
        <div class="accordion" *ngIf="policyDetails?.coverType === 'MOTOR PRIVATE Comprehensive'">
            <p>Our comprehensive private car Insurance cover protects you against:</p>

            <ol>
                <li>
                    Loss or damage to your car and its accessories, arising from

                    <ol>
                        <li>Theft</li>
                        <li>Vandalism</li>
                        <li>Natural disasters such as earthquakes, storms, landslides etc</li>
                        <li>Civil disturbances such as riots and strikes</li>
                        <li>Vehicle stored with Motor Trader (Garage, etc)</li>
                        <li>Fire</li>
                        <li>Falling objects such as trees</li>
                        <li>Material damage following an accident</li>
                    </ol>
                </li>

                <li>
                    It also protects you or your authorized driver from legal liabilities for damages (including the related costs and expenses) to Third Parties and passengers for:

                    <ol>
                        <li>Death or bodily injury to any person.</li>
                        <li>Damage to property arising as a result of an accident by or in connection with your vehicle, including while loading and unloading the vehicle as long as such costs will not exceed the amounts of cover provided for under this Policy.</li>
                    </ol>
                </li>
            </ol>


        </div>
		<!--END Comprehensive Inclusions   -->

		<!--  Third Party Only  -->
        <div class="accordion" *ngIf="policyDetails?.coverType === 'MOTOR PRIVATE Third Party Only'">
            <p>Our Third Party Only Insurance covers you against:</p>

            <ol>
                <li>
                    You or your authorized driver from legal liabilities for damages (including the related costs and expenses) to Third Parties and passengers for:

                    <ol>
                        <li>Death or bodily injury to any person</li>
                        <li>Damage to property arising as a result of an accident by or in connection with your vehicle, including while loading and unloading the vehicle as long as such costs will not exceed the amounts of cover provided for under this Policy.</li>

                    </ol>
                </li>

            </ol>


        </div>
		<!--END  Third Party Only  -->

		<!--  Third party fire and theft  -->
        <div class="accordion" *ngIf="policyDetails?.coverType === 'MOTOR PRIVATE Third Party Fire And Theft'">
            <p>Our Third Party, Fire and Theft Insurance cover protects you against:</p>

            <ol>
                <li>
                    Loss or damage to your car and its accessories caused directly by;

                    <ol>
                        <li>Fire</li>
                        <li>Self-ignition, </li>
                        <li>Lightning</li>
                        <li>Explosion</li>
                        <li>Theft or attempted theft.</li>

                    </ol>
                </li>

                <li>
                    It also protects you or your authorized driver from legal liabilities for damages (including the related costs and expenses) to Third Parties and passengers for:

                    <ol>
                        <li>Death or bodily injury to any person.</li>
                        <li>Damage to property arising as a result of an accident by or in connection with your vehicle, including while loading and unloading the vehicle as long as such costs will not exceed the amounts of cover provided for under this Policy. </li>
                    </ol>
                </li>
            </ol>


        </div>
		<!--END Third party fire and theft   -->

		<!--END  Policy Inclustions  -->

		<!--  Policy Exclusions  -->
        <h3 class="accordionTitle shadow">
            View Exclusions

            <fa-icon

            icon="plus-circle"
            class="arrow closed"></fa-icon>

            <fa-icon

            icon="minus-circle"
            class="arrow open"></fa-icon>
        </h3>

		<!--  Policy Exclusions Comprehensive  -->

        <div class="accordion" *ngIf="policyDetails?.coverType === 'MOTOR PRIVATE Comprehensive'">

            <p>Some losses which are not covered by comprehensive car insurance include;</p>

            <ol>
                <li>Damage incurred while in use within the airport (beyond areas allowed to the public)</li>
                <li>Passenger liability to insured household
                    Nuclear Fission, War, Terrorism and Kindred Risks.</li>
                    <li>Consequential loss such as loss of profit or income</li>
                    <li>Depreciation from wear and tear, mechanical, electrical or electronic breakdown, failures or breakages.

                        <ol>
                            <li>Damage to tyres unless damage is caused to other parts of the vehicle at the same time.</li>
                            <li>Loss of or damage to personal contents being carried in or on the vehicle</li>
                            <li>Damage caused by overloading or strain.</li>
                            <li>Death of or bodily injury to any person in your employment arising out of and in the course of such employment.</li>
                            <li>Damage to property held in your trust , custody or control or belonging to you or any member of your household.</li>
                        </ol>
                    </li>
                </ol>

        </div>

		<!--END  Policy Exclusions Comprehensive  -->

		<!--  Third Party Only Exclusions  -->

        <div class="accordion" *ngIf="policyDetails?.coverType === 'MOTOR PRIVATE Third Party Only'">

            <p>Some losses which are not covered by third party car insurance include;</p>

            <ol>
                <li>Damages incurred while in use within the airport (beyond areas allowed to the public)</li>
                <li>Passenger liability to insured household</li>
                <li>Damages or loss resulting from Nuclear Fission, War, Terrorism and Kindred Risks.</li>
                <li>Consequential loss such as loss of profit or income</li>
                <li>Failures or breakages that are a result of Depreciation, wear and tear, mechanical, electrical or electronic breakdown, failures or breakages.</li>
                <li>Damage to tyres unless damage is caused to other parts of the vehicle at the same time.</li>
                <li>Loss of or damage to the contents being carried in or on the vehicle</li>
                <li>Damage caused by overloading or strain.</li>
                <li>Loss of or damage to the contents being carried in or on the vehicle.</li>
                <li>Death of or bodily injury to any person in your employment arising out of and in the course of such employment.</li>
                <li>Damage to property held in your trust , custody or control or belonging to you or any member of your household.</li>


            </ol>

        </div>

		<!--END  Third Party Only  Exclusions -->

		<!--  TPFT Exclusions  -->
        <div class="accordion" *ngIf="policyDetails?.coverType === 'MOTOR PRIVATE Third Party Fire And Theft'">

            <p>Losses not covered by the Third Party, Fire and Theft Policy include;</p>

            <ol>

                <li>Damages incurred while in use within the airport (beyond areas allowed to the public)</li>
                <li>Passenger liability to insured household</li>
                <li>Damages or loss from Nuclear Fission, War, Terrorism and Kindred Risks.</li>
                <li>Consequential loss, such as  loss of profit or income</li>
                <li>Failures or breakages caused by depreciation, wear and tear, mechanical, electrical or electronic breakdown, </li>
                <li>Damage to tyres unless damage is caused to other parts of the vehicle at the same time.</li>
                <li>Loss of or damage to the contents being carried in or on the vehicle;</li>
                <li>Damage caused by overloading or strain.</li>
                <li>Loss of or damage to the contents being carried in or on the vehicle</li>
                <li>Death of or bodily injury to any person in your employment arising out of and in the course of such employment.</li>
                <li>Damage to property held in your trust , custody or control, or belonging to you or any member of your household.</li>

            </ol>

        </div>
		<!--END  TPFT Exclusions  -->

		<!--END  Policy Exclusions  -->

    </section>

</section>


<!--  Benefits Endorsement   -->

<section class="shadow centerAligner rounded benefitsAccordion"
	*ngIf="endorsedBenefits?.endorsementAmount > 0" >



	<!--  Valuation Item Accordion  -->
	<section class="">

		<section class="valuationDetails benefitsEndorsed">

			<!--  Endorsement Back  -->
			<button
				class="back readMoreBlack"
				(click)="scrollToTarget('benefitsTitle');">

				Edit Benefits

				<fa-icon
					icon="arrow-circle-left" class="arrow">
				</fa-icon>

			</button>
			<!--END  Endorsement Back  -->

			<!--  Valuation Report TItle  -->

			<h3
				class=""
				>

					Additional Benefits Benefits Endorsement

					{{policyDetails?.vehicle | slice:8:30 | titlecase}} -  {{policyDetails?.vehicle | slice:0:8}}


			</h3>
			<!--END Valuation Report TItle   -->

			<div class="row toggle">

				<p class="left">Estimate Value</p>
				<p class="right">{{policyDetails?.estimateValue | currency : 'code' : 'KES '  : '1.0-0'}}</p>

			</div>

			<div class="row toggle">

				<p class="left">Annual Premium</p>
				<p class="right">{{policyDetails?.totalPremiumPlusFees | currency : 'code' : 'KES '  : '1.0-0'}}</p>

			</div>

			<!-- Endoresed Benefits Response   -->
			<ng-container *ngIf="endorsedBenefits?.endorsementComputations?.endorseBenefits.length > 0">

				<ng-container  *ngFor="let benefit of endorsedBenefits?.endorsementComputations?.endorseBenefits">

					<div class="row toggle endorsement" *ngIf="benefit?.diffPremium > 0" >

						<p class="left">
							{{benefit?.name}}
						</p>

						<p class="right">
							{{benefit?.benefitNewPremium | currency : 'code' : 'KES '  : '1.0-0'}}
						</p>

					</div>

				</ng-container>

			</ng-container>
			<!--END  Endoresed Benefits Response  -->

			<div class="row toggle" >

				<p class="left">
					Policy Holders Compensation Fee (PHCF)
				</p>

				<p class="right">
					{{endorsedBenefits?.endorsementComputations?.newPhcf | currency : 'code' : 'KES '  : '1.0-0'}}
				</p>

			</div>

			<div class="row toggle" >

				<p class="left">
					Training Levy
				</p>

				<p class="right">
					{{endorsedBenefits?.endorsementComputations?.newTransLevy | currency : 'code' : 'KES '  : '1.0-0'}}
				</p>

			</div>

			<div class="row toggle" >

				<p class="left">
					Transaction Fee
				</p>

				<p class="right">
					{{endorsedBenefits?.endorsementComputations?.transactionFee | currency : 'code' : 'KES '  : '1.0-0'}}
				</p>

			</div>

			<div class="row noborder">

				<p class="left">Additional Premium Payable</p>
				<p class="right">{{endorsedBenefits?.endorsementAmount | currency : 'code' : 'KES '  : '1.0-0'}} </p>

			</div>


			<div class="row noborder buttons">

				<button
					class="readMoreBlack"
					(click)="endorsePolicy(endorsedBenefits,'benefits');"
					>

					Make Payment

					<fa-icon
						class="arrow yellow"
						icon="arrow-circle-right"
						></fa-icon>

				</button>


			</div>

		</section>

	</section>
	<!--END  Valuation Item Accordion  -->

</section>
<!--END  Benefits Endorsement View  -->
<!--END  Valuation Notification  -->
