import { Component, OnInit } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

import $ from 'jquery';

@Component({
    selector: 'app-termsandconditions',
    templateUrl: './termsandconditions.component.html',
    styleUrls: ['./termsandconditions.component.css']
})
export class TermsandconditionsComponent implements OnInit {

    siteImages:any = [];

    constructor(
        private titleService: Title,
        private metaService:Meta,
    ) { }

    ngOnInit(): void {

        this.titleService.setTitle("Insure Me - Terms and Conditions");
        this.metaService.updateTag(
            { name: 'keywords', content: 'Car Insurance, InsureMe, Online Insurance, Lipa Pole Pole Car Insurance, Insurance Policy, Motor Insurance, Online Motor Insurance, Auto Insurance, Insurance premium financing, Car Insurance Quotes, Online Insurance, Online car cover, Car Insurance cost, 3rd Party Car Insurance, Comprehensive Insurance, Comprehensive Car Insurance, Private Car Cover, Private Car Insurance, Insure Me, Insurance Certificate, Car Insurance renewal, Renew Car Insurance, Affordable Car Insurance, Cheap Car Insurance, Car Insurance Solutions, '
            }
        );

        this.metaService.updateTag(
            { name: 'description', content: 'Get Comprehensive Car Insurance that Suits Your Pocket. Pay Premiums in Flexible Monthly Installments of Up to 10 Months. Its Easy, Try It Today!  '
            }
        );
    }
/*
    ngAfterViewInit():void{
        this.getImages();
    }*/
/*
    getImages(){

        $("img").each( (index,value) => {

            console.log($(value).attr("src"));

            if($(value).attr("src") != "" || $(value).attr("src") == "undefined" || $(value).attr("src") == null){

                this.siteImages.push($(value).attr("src"));

            }

        });

    }
    */

}
