<section class="centerAligner">
    
    <h3>Tell us a little about yourself and your loved ones</h3>

    <app-dynamic-form
        [pages]="fullPage"
        layoutType="accordion"
        *ngIf="fullPage.length>0" >
    </app-dynamic-form>


</section>