export class SelectedBenefit{

    name:string = '';
    id:number = 0;
    estimatedValue:number = 0;
    additionalAmount:number = 0;
    premiumAmount:number = 0;
    insurancePolicyTypeId:number = 1;
    insuranceClassId:number = 1;
    checked:boolean = false;
    disabled:boolean = false;

}
