import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policysummary',
  templateUrl: './policysummary.component.html',
  styleUrls: ['./policysummary.component.css']
})
export class PolicysummaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
