import { Component, OnInit,Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
declare var $:any;


@Component({
    selector: 'app-fullscreenslider',
    templateUrl: './fullscreenslider.component.html',
    styleUrls: ['./fullscreenslider.component.css'],
    animations:[
        trigger('show', [

          state('hidden', style({
            opacity: 0,

          })),
          state('visible', style({
            opacity: 1,
          })),
          transition('hidden => visible', [
            animate('1s')
          ])
        ]),
    ]
})
export class FullscreensliderComponent implements OnInit {

    @Input() position:string;
    @Input() type:string = '';
    @Input() sliderImages:any = [];

    constructor() { }

    ngOnInit(): void {

    }

    ngAfterViewInit():void{

        if(this.sliderImages.length > 0){

            $( '#mainSlider' ).sliderPro({
                width: '100%',
                autoHeight:true,
    			arrows: true,
    			buttons: false,
    			waitForLayers: true,
                shuffle: true,
                fade:true,
                autoplayDelay:5000,
                fadeDuration:5000,
    			thumbnailPointer: false,
    			autoplay: true,
    			autoScaleLayers: false,
            });
        }



    }

    openDetailView(){

        if($(window).width() > 1100){
            $(".coverDescription").stop().animate({
                left:"50%"
            },1000);
        }else{
            $(".coverDescription").stop().animate({
                left:"0%"
            },1000);
        }


    }

    closeDetailView(){

        $(".coverDescription").stop().animate({
            left:"100%"
        },1000);

    }

    showSummaryDetails(){

        // $(event.target).find("article").stop().animate({
        //     opacity:"1"
        // },500);

    }

    hideSummaryDetails(){
        
        // $(event.target).find("article").stop().animate({
        //     opacity:"0"
        // },500);
    }

}
