export  class Lead{

	fullName:string =  "";
	phoneNumber:string =  "";
	nationalId:string =  "";
	email: string = "";
	gender: string =  "";
	vehicleRegistration:string = "";
	make:string = "";
	ip:string =  "";
}
