<app-preloader
    *ngIf="siteImages.length > 0"
    [images]="siteImages"
></app-preloader>

<section class="centerAligner shadow termsContainer">

    <div class="faqGroupContainer">
        
        <div class="faqHeader activeFaq">
            <h2>Lipa Pole Pole FAQs</h2>
         </div>
        <!--  Accorion Container  -->
        <div class="accordionContainer">

            <!--  Single Accordion  -->

            <h3 class="accordionTitle">
                1) What is Lipa Pole Pole Premium Financing?

                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>

            </h3>
            <div class="accordion">
                <p>Lipa Pole Pole is an Insurance Premium Financing solution offered by InsureMe which enables
                you to buy comprehensive private motor car insurance cover and pay in simple monthly installments of up to 10 months.</p>
            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                2) Will I be required to provide security or make a deposit?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p>Yes. You will be required to pay an upfront deposit of 2 months comprehensive premium + Third Party Only Cover.</p>

            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                3) Why do I have to pay for Third Party Cover?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p>In the unfortunate event that you are unable to make the monthly premium, then we will downgrade your policy to
                Third Party Only. This enables your vehicle to meet the basic insurance regulatory requirements.</p>
            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                4) What documents are required to access Lipa Pole Pole
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p>We only require an upload of a clear copy of your Vehicle Logbook payments ok. If you do not have it
                 at the time of application, you have up to 14 days to upload and submit.</p>
            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                5) Do you finance all types of car policies?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p>No. For now, we are only financing comprehensive private motor car insurance policies.</p>
            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                6) What is the minimum and maximum amount that you can finance?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p>The minimum Sum Assured (value of your vehicle) should be KES 500,000. The maximum depends on the value of your car. </p>
                <p>The minimum insurance premium that we will finance is KES 37,500.</p>
                <p>The maximum age of the vehicle that we will finance is 15 years </p>
            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                7) What is the monthly payment made up of?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">

                <p>The monthly payment is made up of a monthly premium (annual premium divided by 12 months) and a monthly interest charge.</p>

            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                8) What is the monthly interest charge?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p>We charge a flat monthly interest of 5% per month on a reducing balance basis.</p>
            </div>
            <!--END Single Accordion   -->

            <h3 class="accordionTitle">
                9) Are there any other charges or fees?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>Yes. We charge a 1% Service Fee +VAT on the value of the premium and it is payable with the premium deposit.</p>
            </div>

            <h3 class="accordionTitle">
                10) Will you finance 100% of my car insurance premium?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>No. We finance up to 83.3% of the premium or 10 out of 12 months of premium cover.</p>
            </div>

            <h3 class="accordionTitle">
                11) How do I know how much I will be paying?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>Your Lipa Pole Pole monthly installments are provided by InsureMe before you make payment and on
                your InsureMe profile page once you log in as a return customer.</p>
            </div>

            <h3 class="accordionTitle">
                12) What happens if I do not know the estimated value of my car?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>No need to worry. Valuation is part of the policy purchase journey, and we will send one of our trusted
                car valuation partners to your preferred location at a time convenient for you. </p>
            </div>

            <h3 class="accordionTitle">
                13) Do I require security for the facility?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>No. You are only required to pay for the first 2 months premium upfront and pay the balance in 10 monthly installments.</p>
            </div>

            <h3 class="accordionTitle">
                14) How long will it take to receive the Insurance Certificate/Sticker?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>If your application is successful, the insurance certificate will be issued within 15 minutes.</p>
            </div>

            <h3 class="accordionTitle">
                15) What happens if I default?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>You will have up to 14 days from premium payment date to remedy a default. Thereafter, your policy will be
                downgraded from comprehensive motor to Third Party Only. </p>
            </div>

            <h3 class="accordionTitle">
                16) Can my policy be reactivated once downgraded?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>Yes. Your policy can be re-activated within 7 days of downgrade or cancellation.</p>
            </div>

            <h3 class="accordionTitle">
                17) Are there charges associated with reactivation of my car policy once downgraded or cancelled?

                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>Yes. You can re-activate your policy within 7 days of downgrade or cancellation subject to clearing all
                outstanding premium and interest payments and paying a penalty fee of 7.5% on defaulted payments. </p>
            </div>




        </div>
        <!--END  Accorion Container  -->

    </div>

    <div class="faqGroupContainer">

        <div class="faqHeader ">
            <h2>General Motor Insurance FAQs</h2>
        </div>

        <!--  Accorion Container  -->
        <div class="accordionContainer inactive">

            <!--  Single Accordion  -->

            <h3 class="accordionTitle">
                1) Do I need car insurance?

                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>

            </h3>

            <div class="accordion">
                <p>Car insurance is a legal requirement for drivers in Kenya, thanks to the continuous insurance enforcement rules brought in as part of the road safety act CAP 405. Third Party Motor Insurance also known as 'Act Only' cover is the least legal level of cover mandatory to drive a car/motorcycle or any other motorized vehicle on Kenyan public roads failure in which you could be faced with a fine for not insuring your vehicle.</p>
            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                2) What is lipa pole pole?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p>This is an Insurance premium financing option whereby you pay 2 months part-premium upfront and we
                    finance the remainder of the premium for you.
                    The financing period is up to 10 months, but you can choose to shorten the period. </p>

            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                3) What does excess mean?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p> Excess payments refer to the cost of making a claim – it’s essentially how much you’ll need to put towards the total claim cost before your insurer pays the rest. To avoid this, you can add the excess protector benefit to your comprehensive cover quote.</p>
            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                4) Is it cheaper to pay monthly or annually for my car insurance?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p> You’re likely to pay a lower amount of money on your car insurance policy if you pay an annual lump sum rather than in monthly installments. This is because monthly payments are similar to taking out credit – you’ll be covered in full, but without having paid the full amount yet. </p>

                <p>Paying monthly with our lipa pole pole plan can be useful as you’ll be able to spread the cost out, hence not feeling the pressure of having to pay the full amount at once, but you will end up paying a little extra overall due to the interest incurred. </p>
            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                5) Do I have to tell my insurers about an accident if I am not going to claim?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p>If you’re involved in an accident and you decide not to make a claim, you should still inform your insurer. They’ll keep their records up to date, so they know what condition your vehicle is in and whether this will affect your likelihood of claiming some time in the future.</p>

                <p>If you don’t keep your insurer updated, it’s possible this will void your insurance policy so you won’t be able to claim when you really need to.</p>

            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                6) How do I work out my car’s value?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p> In most cases, the value you declare on your insurance policy documents will be the price you initially paid for the vehicle. Before getting a policy, a full valuation is done </p>

                <p>However, when you make a claim and your car has been written off, your insurance provider will pay out the current market value of your car, not the initial price you declared on your insurance documents. This is because vehicle devaluation is also considered.</p>
            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                7) Can I insure more than one car?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">

                <p>Yes you can. Multi-car insurance covers more than one vehicle under the same insurance policy. This will offer you the same features as a single-car policy, but with the added benefit of a discount for the second and third car. Multi-car insurance policies often cover up to five vehicles registered at the same address</p>


            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                8) Will I be charged if I cancel my policy?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p> Usually if you cancel your policy within the first 30 days, most insurance companies won’t charge a cancellation fee. If your policy has been active for longer than one month, you’re likely to have to pay a fee for cancelling plus the cost for the time you’ve been insured (pro-rata)</p>


            </div>
            <!--END Single Accordion   -->

            <h3 class="accordionTitle">
                9) I made a claim last year. Will my insurance go up?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>A couple of factors can affect your insurance premium, including recent claims. Even if it wasn’t your fault, making a claim will almost always lead to an increase in your car insurance premium</p>
            </div>

            <h3 class="accordionTitle">
                10) Do I need to value the car every year?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>Annual valuation of the car is beneficial in determining the current condition of the car and it’s current market value. This can in turn save you money when re-purchasing your insurance as you would pay a premium for the current value which could be lower.   </p>
            </div>

            <h3 class="accordionTitle">
                11) What will happen when my insurance is due for renewal?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>When the renewal date approaches, we will send you reminders with the terms and conditions for your policy renewal to allow you enough time to plan yourself accordingly. </p>
            </div>

            <h3 class="accordionTitle">
                12) What will the renewal premium be worked out?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>Your renewal Insurance premium will be based on information known as ‘risk factors’. This will include claims history or claims made in the past while the vehicle has been insured and current value of the car. </p>
            </div>

            <h3 class="accordionTitle">
                13) What happens to my policy if I fail to pay the premium?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>

            <div class="accordion">
                <p>Failure to pay insurance premiums amounts to breach of policy terms and conditions agreed upon during application and leads to the termination of the contract.</p>
            </div>
        </div>
        <!--END  Accorion Container  -->

    </div>

    <div class="faqGroupContainer">

        <div class="faqHeader">
            <h2>Claims FAQs</h2>
        </div>

        <!--  Accorion Container  -->
        <div class="accordionContainer inactive">

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                1)How do I claim my car insurance?

                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>

            </h3>
            <div class="accordion">
                <p>To make a car insurance claim, you’ll first need to notify the police. They will give you an abstract which you’ll then need to give your insurer.</p>
                <p>You can then start the claim process online or call your insurer to notify them about the accident.</p>
            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                2) When might my claim get rejected?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p>There are a several reasons as to why a claim could be rejected:</p>
                <ul>
                    <li>Incorrect information: This is when you give the wrong information as to how the damage occurred or were dishonest about key personal information.</li>
                    <li>Not reading or understanding your policy: You may miss certain clauses that specify conditions under which you can or cannot claim.</li>
                    <li>Using out-of-date or invalid documentation: For example, if your driving licence isn’t valid.</li>
                    <li>When you have defaulted on your lipa pole pole monthly payments.</li>
                </ul>

            </div>
            <!--END Single Accordion   -->

            <!--  Single Accordion  -->
            <h3 class="accordionTitle">
                3) What does it mean when my car is written off by an insurer?
                <fa-icon

                    icon="plus-circle"
                    class="arrow closed"></fa-icon>

                <fa-icon

                        icon="minus-circle"
                        class="arrow open"></fa-icon>
            </h3>
            <div class="accordion">
                <p>This means that the car repairs cost more than the car’s assigned cash value. In this case, the insurer will pay you the market price of the car you just lost. You shouldn’t get more or less than what you are due.</p>
                <p>Didn’t find what you were looking for, <a routerLink="/contactus" target="_blank">contact us</a>  directly. We’d love to hear from you</p>
            </div>
            <!--END Single Accordion   -->

        </div>
        <!--END  Accorion Container  -->

    </div>



</section>
