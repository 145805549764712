<!-- Terms and Conditions Summary   -->
<section class="termsModal">

    <section class="termsSummary shadow rounded">

        <article>

            <h2>Abridged terms and conditions for the purchase of insurance policies through the insureme™ platform</h2>
            <h3>*From this point on, please provide correct and accurate information pertaining to the E-KYC to follow*</h3>
            <ol>
                <li>
                    <strong>Agreement to the Terms And Conditions</strong>

                    <ol>
                        <li>These are the abridged Terms and Conditions applicable to the registration, access and use of the InsureMe system <strong>(InsureMe)</strong> offered by Qore Insurance Agency Limited <strong>(Qore)</strong> and of which a resultant virtual account has been opened by you.</li>
                        <li>Please visit the Qore website <a routerLink="/termsandconditions" target="_blank">www.insureme.co.ke</a> for the full Terms and Conditions (the <strong>Terms and Conditions</strong>). </li>
                    </ol>

                </li>

                <li>

                    <strong>Acceptance of the Terms and Conditions</strong>

                    <ol>
                        <li>In order to register for an InsureMe Account, you must be at least 18 years old, have legal capacity to do so and be a registered and active subscriber with a mobile services provider that Qore has an agreement with for provision of InsureMe services.</li>
                        <li>By applying to register for InsureMe, you agree to comply with and be bound by and affirm that the Terms and Conditions are without prejudice to any right that Qore may have with respect to InsureMe in law or otherwise.</li>

                        <li><strong>Data Protection and Privacy</strong></li>
                            <li>By accepting the Terms and Conditions you are accepting that:
                                <ol>
                                    <li>Qore shall have a right to access and process your information (including your personal data), subject to the limitations imposed and your rights under applicable law); and</li>
                                    <li>Qore may conduct any know your customer diligence, identity confirmation, fraud and credit checks which it deems necessary on you by reference to any third parties (including any credit referencing agency in accordance with applicable laws). </li>
                                </ol>
                            </li>
                    </ol>

                </li>

                <li>

                    <strong>Anti-Money Laundering</strong>

                    <ol>
                        <li>You acknowledge that your use of InsureMe shall be in accordance with all anti-money laundering laws and Qore shall at all times comply with its obligations under anti-money laundering laws. </li>
                    </ol>

                </li>
                <li>
                    <strong>Fees and other conditions </strong>

                    <ol>
                        <li>You agree to pay all interest charges and transaction fees, and all other expenses and taxes incurred in connection with your use of InsureMe. These fees are to be deducted from your payment. </li>
                        <li>In the event your account goes into default, Qore may forward your information and details of your default in servicing your loan to any credit reference agency for listing or collection, subject to applicable law.</li>
                    </ol>

                </li>

                <li>
                    <strong>Claims Processing</strong>

                    <ol>
                        <li>In the event of a policy claim, you agree to ensure that all outstanding premiums related to Lipa Pole Pole insurance premium financing are fully paid to the financier prior to submitting supporting claim documentation. </li>
                        <li>In the event your premiums are not fully paid at the time of claim submission, your claim will not be processed until such time that payment is received in full.  </li>
                        <li>If you have purchased policy endorsements such as excess protector, windscreen cover, etc, the benefit will only be applicable to the first claim lodged by you. In the event that the policy cover continues to operate, the endorsements will be deemed to have been utilized and you will be required to purchase additional endorsements to revert back to your original endorsement status.</li>
                    </ol>

                </li>


                <li>
                    <strong>Governing Law</strong>

                    <ol>
                        <li>The Terms and Conditions shall be governed by and construed in accordance with the laws of the Republic of Kenya.</li>
                    </ol>

                </li>

                <li>
                    <strong>Intention to be bound</strong>

                    <ol>
                        <li>Please accept terms below to acknowledge that you have read and voluntarily accept the Terms and Conditions.</li>
                    </ol>
                </li>

            </ol>


        </article>

        <a
            routerLink="/termsandconditions"
            class="readMoreWhiteSolid full_width readTerms"
            target="_blank">

            Read All Our Terms and Conditions

            <fa-icon
                class="arrow"
                icon="arrow-circle-right"
            >

            </fa-icon>

        </a>

        <!--  Back Button  -->

        <section class="row">

            <div class="half fl">
                <button
                class="readMoreWhiteSolid back"
                type="button"
                (click)="closeTermsModal();scrollUpPage();">

                    <fa-icon
                        class="arrow"
                        icon="arrow-circle-left"
                    >

                    </fa-icon>

                    Back
                </button>

            </div>
            <!--END  Back Button  -->

            <!--  Accept Terms Button  -->

            <div class="half fr">

                <button
                class="readMoreBlack"
                type="button"
                id="HomePageAcceptTermsAndConditionsClick"
                (click)="progressTerms('newcover');"
                >

                Accept Terms & Conditions

                <fa-icon
                    class="arrow"
                    icon="arrow-circle-right"
                >

                </fa-icon>

            </button>
        </div>
        <!--END  Accept Terms Button  -->
        </section>
    </section>
</section>
<!--END  Terms and Conditions Summary  -->
