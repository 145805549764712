import { Component, OnInit } from '@angular/core';

import { QuestionService } from '../../_services/question/question.service';
import { QuestionBase } from '../../_models/question/question-base';
import { QuestionControlService } from '../../_services/question/question-control.service';

import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-addbeneficiary',
  templateUrl: './addbeneficiary.component.html',
  styleUrls: ['./addbeneficiary.component.css'],
  providers: [
    QuestionService,
    QuestionControlService
  ]
})

export class AddbeneficiaryComponent implements OnInit {

  questions$: Observable<QuestionBase<any>[]>;

  fullPage:any = [];

  constructor(
    private questionService: QuestionService
  ) {

    this.questions$ = questionService.getQuestions();

   }

  ngOnInit(): void {

    this.getDynamicQuestion();

  }

  getDynamicQuestion(){

    this.questionService.getQuestionsById(81).subscribe(response => {

			if(response["body"]["surveyDefinitionPages"].length > 0){

					this.fullPage = response["body"]["surveyDefinitionPages"];

          this.fullPage.sort((firstPage, secondPage) => firstPage.pageOrder - secondPage.pageOrder);

          for (let i = 0; i <this.fullPage.length; i++) {

            this.fullPage[i]["questions"].sort((firstQuestion, secondQuestion) => firstQuestion.questionOrder -  secondQuestion.questionOrder);

          }
			}

		});

  }

}
