<section class="centerAligner">
    
    <h3>Add Next of Kin</h3>

<app-form-array
    [pages]="fullPage"
    *ngIf="fullPage.length>0"
>
</app-form-array>

</section>