import { Directive, Input } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
  FormGroup,
  AbstractControl,
} from '@angular/forms';

import { CustomValidationService } from '../_helpers';

@Directive({
  selector: '[appMatchPassword]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MustMatchDirective, multi: true },
  ],
})
export class MustMatchDirective implements Validator {
  @Input('appMatchPassword') MatchPassword: string[] = [];

  constructor(private customValidationService: CustomValidationService) {}

  validate(formGroup: FormGroup): ValidationErrors {
      
    return this.customValidationService.MatchPassword(
      this.MatchPassword[0],
      this.MatchPassword[1]
    )(formGroup);
  }
}
