import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class FileUploadsService {

  constructor(
    private http:HttpClient
  ) {

   }
    uploadMultipleFiles(files,type){

      const formDataMultiple = new FormData();

      if(files.length > 0){

        for (let i = 0; i < files.length; i++){

          formDataMultiple.append("file", files[i], files[i]['name']);

        }
        
      }

      return this.http.post(`${environment.dynamicFormApi}document/postDocuments?service=${type}`,formDataMultiple)

    }
}
