<div class="processor">
    
    <ngx-ui-loader
        [loaderId]="'loader-01'"
        fgsPosition='{{preloaderPosition}}'
        fgsSize = '{{preloaderSize}}'
        fgsType = '{{spinnerType}}'
        pbDirection = '{{loadingDirection}}'
        [hasProgressBar] = false
        fgsColor = '{{preloaderColor}}'
        overlayColor = "{{overlayColor}}"
        >

    </ngx-ui-loader>

</div>


