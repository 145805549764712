<section class="fullWidthSlider {{position}}">

    <div class="singleSlide">

        <img [src]="image" alt="" />

        <div class="overlay"></div>

    </div>
</section>
