import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { environment } from '../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})

export class QuoteService {

    constructor(
        private http:HttpClient
    ){

    }

    getQuote(quoteDetails:any){

        return this.http.post<any>(`${environment.quoteApi}quote`, quoteDetails);

    }




}
