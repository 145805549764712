import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';

import { environment } from '../../environments/environment.dev';

import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})

export class ClaimsService {

    constructor(
        private http:HttpClient,
        private authenticationService:AuthenticationService,
    ){

        // headers.append('');
        // headers.append('');
    }

    // Create claim method

    createClaim(claimDetails:any){

        return this.http.post<any>(`${environment.customerFacingApi}claim/createClaim`, claimDetails);

    }

    // Create claim type
    createClaimType(claimType:any){

        return this.http.post<any>(`${environment.customerFacingApi}claim/createClaim`, claimType);

    }

    // Download Claim Form
    downloadClaimForm(){

        return this.http.get<Object[]>(`${environment.customerFacingApi}claim/downloadClaimForm`);

    }

    // Find All Claims by National Id
    findClaimByNationalId(nationalId){


        return this.http.get<Object[]>(`${environment.customerFacingApi}claim/findByNationalId?nationalId=${nationalId}`);

    }

    getAllClaims(){

        // return this.http.post<any>(`${environment.quoteApi}quote`, quoteDetails);

    }

    // Get all claims by policy number
    getClaimByPolicyNumber(policyNumber:string){


    }

    getClaimByClaimId(claimId){

        const headers = new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': `Bearer ${this.authenticationService.getUserToken()}`
        });

        return this.http.get<Object[]>(`${environment.customerFacingApi}claim/getClaimById?claimId=${claimId}`,{'headers' : headers});


    }


}
