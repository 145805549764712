import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from "@angular/router";
import {AuthenticationService} from './../../../_services/authentication.service';
import {UserService} from './../../../_services/user.service';
import {PolicyService} from './../../../_services/policy/policy.service';
import {PaymentService} from './../../../_services/payment.service';

import {CancelPolicy} from './../../../_models/policy/CancelPolicy.model';

import $ from 'jquery';


@Component({
  selector: 'app-cancelpolicy',
  templateUrl: './cancelpolicy.component.html',
  styleUrls: ['./cancelpolicy.component.css']
})
export class CancelpolicyComponent implements OnInit {

	siteImages:any = [];
    policyId:string;
    policyDetails:any;
    paymentSchedule:any = [];
    benefits:any = [];
    showModal:boolean = false;

    modalTitle: string = "";
    modalDescription: string = "";
    modalLink: string;
    modalLinkText: string = "Proceed";
    modalType:string = "info";

    loanCleared:boolean = false;
    loanStatus:string = "";

    //paymentData:any = {};
    modalData:any = {};

    userProfile:any = {};

    phoneNumber:string = "";
    customerName: string = "";

    loading:boolean = false;

	refundObject:any = {};

	documentsArray:any = [];

	policyCancellationObject:CancelPolicy = new CancelPolicy();

	constructor(
		private route: ActivatedRoute,
        private router: Router,
        private userService:UserService,
        private paymentService:PaymentService,
		private policyService:PolicyService
	){

	}

	ngOnInit(): void {

		$(".accordionTitle").on('click',this.openAccordion);
        // $(".modalContainer .closer,.modalContainer .readMoreYellow").on('click',this.reloadPolicyView);

        this.policyId = this.route.snapshot.paramMap.get('policyId');

		this.policyCancellationObject.policyNo = this.policyId;

		//

        this.loading = true;

		this.policyService.getPolicyCancellationRefund(this.policyId).subscribe(response => {

			if(response["success"]){

				this.refundObject = response["data"];
				this.policyCancellationObject.refundPayload = response["data"];

			}else{

				// Show failure error


			}


		},error => {

			// Show network error

		});

		this.userService.getSinglePolicy(this.policyId).subscribe(response =>{

            if(response["success"]){

                this.loading = false;

                this.policyDetails = response["data"]["policy"];
                this.paymentSchedule = response["data"]["payment"];
                this.benefits = response["data"]["additionalBenefit"];
                this.loanStatus = response["data"]["loanStatus"];

                if(response["data"]["loanStatus"] === "CURRENT" || response["data"]["loanStatus"] === "DEFAULT"){

                    this.loanCleared =  false;

                }else{

                    this.loanCleared =  true;

                }

                // this.checkPolicyPaymentStatus(this.paymentSchedule);

            }else{

                this.modalTitle = "Policy loading error";
                this.modalDescription = response["message"];
                this.modalLink = null;
                this.modalLinkText = "";
                this.showModal = true;

                this.loading = false;

            }

        },error =>{

            this.modalTitle = "Error";
            this.modalDescription = "An error occurred while processing your policy request";
            this.modalLink = null;
            this.modalLinkText = "";
            this.showModal = true;

            this.loading = false;

        });

        this.userProfile = JSON.parse(localStorage.getItem("insuremeRiders"));

        this.phoneNumber = this.userProfile.phoneNumber.replace("254","0").replace("+254","0");

		this.policyCancellationObject.paymentPhoneNo = this.userProfile.phoneNumber;

        this.customerName = this.userProfile.customerName;

	}

	openAccordion(){

        $(".accordion").stop().slideUp();


        if($(this).hasClass("open")){

            $(".accordionTitle").removeClass("open");
            $(this).removeClass("open").next(".accordion").stop().slideUp();

        }else{

            $(".accordionTitle").removeClass("open");
            $(this).addClass("open").next(".accordion").stop().slideDown();

        }

    }

	checkPolicyPaymentStatus(policyData:any){


        for (let i = 0; i < policyData.length; i++) {

            if(policyData[i]["status"] === "PAID"){

                this.loanCleared = true;

            }else{

                this.loanCleared = false;

            }

        }

    }

	updateCancellationReason(event){

		let name = event.target.name;
		let value = event.target.value;

		this.policyCancellationObject.reason = value;

	}

	changePaymentPhoneNumber(event){

		let name = event.target.name;
		let value = event.target.value;

		this.phoneNumber = value.replace("254 ","0").replace("+254","0");

	}

	updatePaymentPhoneNumber(){

		this.policyCancellationObject.paymentPhoneNo = this.phoneNumber;

	}

	declineCancellation(event){

		this.showModal = false;


	}

	approveCancellation(event){

		this.modalTitle = "Processing policy cancellation";
		this.modalDescription = "Your selected policy "+ this.policyId + " is in process of being cancelled";
		this.modalLink = null;
		this.modalLinkText = "";
		this.showModal = true;
		this.modalType = "process";

		this.policyService.cancelPolicy(this.policyCancellationObject).subscribe(response => {

			if(response["success"]){

				this.modalTitle = "Cancellation request sent";
				this.modalDescription = response["message"];
				this.modalLink = "/userprofile";
				this.modalLinkText = "Back to profile";
				this.showModal = true;
				this.modalType = "info";

			}else{

				this.modalTitle = "Error submitting request";
				this.modalDescription = response["message"];
				this.modalLink = null;
				this.modalLinkText = "";
				this.showModal = true;
				this.modalType = "info";
			}

		},error => {

			this.modalTitle = "Error";
			this.modalDescription = error.error["message"];
			this.modalLink = null;
			this.modalLinkText = "";
			this.showModal = true;
			this.modalType = "info";


		});


	}

	cancelPolicy(){

		this.modalTitle = "Policy cancellation confirmation";
		this.modalDescription = "Are you sure you want to cancel policy "+ this.policyId + "?";
		this.modalLink = null;
		this.modalLinkText = "";
		this.showModal = true;
		this.modalType = "confirmation";


	}





}
