import { Component, OnInit,Input } from '@angular/core';

import {FormGroup, FormControl} from '@angular/forms';
import { Observable } from 'rxjs';
import { QuestionBase } from '../../_models/question/question-base';

//services
import { QuestionService } from '../../_services/question/question.service';
import { QuestionControlService } from '../../_services/question/question-control.service';
import { AutocompletedataService } from '../../_services/autocompletedata.service';

import {Location,Appearance} from '@angular-material-extensions/google-maps-autocomplete';

import PlaceResult = google.maps.places.PlaceResult;

@Component({
	selector: 'app-dynamic-form-question',
	templateUrl: './dynamic-form-question.component.html',
	styleUrls: ['./dynamic-form-question.component.css'],
	providers: [
		QuestionService,
		AutocompletedataService,
		QuestionControlService
	]
})
export class DynamicFormQuestionComponent implements OnInit {

	@Input() question : QuestionBase<string>[] | null = [];
	@Input() form! : FormGroup;
	@Input() pages?:any = [];

	fullPage:any = [];
	pageId:string = "";
	loading: boolean;

	//Date definitions
	minDate: Date;
	maxDate: Date;

	//Time definitions
	showSpinners = false;
	enableMeridian = true;
	showSeconds = false;
	stepHour = 1;
  	stepMinute = 1;

	// Google Maps definitions
	appearance = Appearance;
	zoom: number;
	latitude: number;
	longitude: number;
	selectedAddress: PlaceResult;
	autocompleteForm = new FormControl('');

	//autocomplete definitions
	filteredOptions: Observable<any[]>;
	options:any = [];

	dateFilter = (date: Date | null): boolean => {
		const day = (date || new Date()).getDay();
		return day !==0 && day !==6;
	}

	constructor(
		private qcs: QuestionControlService,
		private autoCompleteData: AutocompletedataService
	) {}

	ngOnInit(): void {

		if(this.pages.length > 0){

			this.form = this.qcs.toFormGroupDynamic2(this.pages);

		}

	}

	get isValid(){

		//return this.form.controls[this.question?.fieldName].valid;

		return true;

	}

	// getDynamicQuestion(pageId){

	// 	this.loading = true;

	// 	this.questionService.getQuestionsById(pageId).subscribe(response => {

	// 		if(response["body"]["surveyDefinitionPages"].length > 0){

	// 			this.loading = false;

	// 			//this.formSubmissionURL = response["body"]["submissionUrl"];

	// 			this.fullPage = response["body"]["surveyDefinitionPages"];

	// 		} 

	// 	});

	// }
	
	onFormControlChange(groupName:string){

		console.log("Group Name",groupName);

	}

	onDataSelected(selectedOption:string, groupName:string){

		console.log("Selected option: ",selectedOption);

		console.log("Group name: ",groupName);
		
		console.log("Updated this form control: ", this.form);

	}

	onAutocompleteSelected(result:PlaceResult){

		console.log(result);
		
	}

	onLocationSelected(location: Location){

		console.log(location);
		
	}

	onInputChange(event, apiUrl){

		// this.autoCompleteData.getAutoCompleteData(event.target.value, apiUrl).subscribe(data=>{

		// 	this.options = data;

		// });

	}
}
