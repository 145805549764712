export class TheftRecoveryDriverDetails{

  name:string = "";
  phoneNumber:string = "";
  occupation:string = "";
  dateOfBirth:string = "";
  idNumber:string = "";
  email:string = "";
  copyOfDriverLicense:string = "";
  driverHavePermission:string = "false";

}
