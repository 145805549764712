
<div class="preLoader">

    <div class="progress">

        <figure class="border"></figure>

        <!-- <fa-icon
            icon="arrow-down"
            class="arrow"
        >

        </fa-icon> -->

        <fa-icon
            icon="heart"
            class="arrow"
        >

        </fa-icon>
    </div>
    
    <div class="loaderContent">


        <!-- <mat-spinner
            class="spinner"
            diameter="60"
            strokeWidth="3"
            color="#ffc401"
        ></mat-spinner> -->

        <figure class="logo">
            <h4>Loaded  {{percentageLoaded}} %</h4>
            <img src="{{loaderCar}}" alt="">
        </figure>

    </div>


</div>
