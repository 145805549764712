import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estimate-quotation',
  templateUrl: './estimate-quotation.component.html',
  styleUrls: ['./estimate-quotation.component.css']
})
export class EstimateQuotationComponent implements OnInit {

  constructor() { }

  dateFilter = (date: Date | null): boolean => {
		const day = (date || new Date()).getDay();
		return day !== 0 && day !== 6;
	}

  ngOnInit(): void {
  }

}
