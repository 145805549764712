<section class="centerAligner">

    <h3>Fill in your details below to get the best quotes for you and your loved ones.</h3>

    <app-dynamic-form
        [pages]="fullPage"
        layoutType="tabs"
        *ngIf="fullPage.length>0" >
    </app-dynamic-form>

    <app-dynamic-form
        [pages]="personsCoveredDetails"
        layoutType="tabs"
        *ngIf="personsCoveredDetails.length>0" >
    </app-dynamic-form>


    <app-estimate-quotation>
        
    </app-estimate-quotation>


</section>
