<!--  Banner Image  -->
<section class="bannerbackground">

    <div class="centerAligner">

        <img src="assets/_img/header/insureme.png" class="logo" />

        <div class="bannerInfo">

            <div class="content"  >
                <h1>{{heading}}</h1>
                <p>{{description}}</p>
            </div>

        </div>

        <div class="highlightImage">
            <img src="/assets/_img/header/family_vector.png" alt="">
        </div>

    </div>


</section>
<!--END Banner Image   -->
